import { useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE } from '../../..';
import { selectAuth } from '../../../redux/slices/authSlice';
import { TakeoffContext } from '../../helper/Context';

import '../../styles/AIPopup.css';

import DefaultButton from '../../../components/DefaultButton';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { IconRobot } from '@tabler/icons-react';
import { Modal } from 'react-bootstrap';

const isProduction = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1' ? false : true;

export default function AIPopup({ handleUpdatePage, showAIPopup, setShowAIPopup }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        setMeasurements, setGroups,
        setTree,
        setPageGroups, setUngroupedPages,
        hintingSAM, setHintingSAM,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        hintingAutoFindDW, setHintingAutoFindDW,
        hintingAutoMeasure, setHintingAutoMeasure,
        hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
        AIMessages, setAIMessages,
        setAIAutoCountRect,
        setAIAutoCountPoly,
        AIAutoMeasureLengthRect, setAIAutoMeasureLengthRect,
        autoNameAllPages, setAutoNameAllPages,
        showLegendsPopup, setShowLegendsPopup,
        AILegends,
        autoCountReference, setAutoCountReference,
        autoCountLocation, setAutoCountLocation,
        currentLegend, setCurrentLegend,
        AIAutoCountExample, setAIAutoCountExample,
    } = useContext(TakeoffContext);

    const [prepTrade, setPrepTrade] = useState('');

    useEffect(() => {
        const handleClick = (e) => {
            if (showAIPopup && !document.getElementById('takeoffnavbar-aipopup')?.contains(e.target)) {
                setShowAIPopup(false);
            }
        }

        setTimeout(() => {
            document.addEventListener('click', handleClick);
        }, 100);

        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [showAIPopup]);

    const handleAutoFindLegend = () => {
        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                'autoFindLegend': {
                    ...prev[pageID].autoFindLegend,
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                }
            }
        }));

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-find-legend/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setAIMessages(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev[pageID],
                        'autoFindLegend': {
                            ...prev[pageID].autoFindLegend,
                            'progress': 1,
                            'show': false,
                            'running': false,
                        }
                    }
                }));

                setMeasurements(response.data.measurements);
                setGroups(response.data.groups);
                setTree(response.data.tree);
                setAILocalization(response.data.localization_symbols);

                if (response.data.new_group) {
                    axios({
                        method: 'post',
                        url: `${API_ROUTE}/api/create-ai-preview/`,
                        data: {
                            'group': response.data.new_group,
                            'page': pageID,
                        },
                        headers: {
                            'Authorization': `Token ${auth.token}`,
                            "Content-Type": "application/json",
                        }
                    })
                        .then((response) => {
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoName = () => {
        let newID = uuidv4();
        let newMessage = (`Auto naming page ${pages[pageID].title}`).length > 50 ? (`Auto naming page ${pages[pageID].title}`).substring(0, 50) + '...' : (`Auto naming page ${pages[pageID].title}`);

        toast.loading(newMessage, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            theme: "light",
            toastId: newID,
        });

        console.log(toast.isActive(newID));

        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                autoNamePage: {
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                    'id': newID,
                }
            }
        }));

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/name-page/`,
            data: {
                'page': pageID,
                'id': newID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                if (toast.isActive(response.data.id)) {
                    toast.done(response.data.id);
                } else {
                    toast.success(`Auto named page ${response.data.generated_text}`, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        theme: "light",
                    });
                }

                console.log(response.data.page);
                setAIMessages(prev => ({
                    ...prev,
                    [response.data.page]: {
                        ...prev[response.data.page],
                        'autoNamePage': {
                            ...prev[response.data.page].autoNamePage,
                            'progress': 1,
                            'show': false,
                            'running': false,
                        }
                    }
                }));

                setPages(prev => ({
                    ...prev,
                    [response.data.page]: {
                        ...prev[response.data.page],
                        title: response.data.generated_text,
                    }
                }));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoNameAll = () => {
        setAutoNameAllPages(true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/name-pages/`,
            data: {
                'project': pages[pageID].project,
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePrep = (trade) => {
        setPrepTrade(trade);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/prep-page/`,
            data: {
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePrepSAM = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/prep-page-sam/`,
            data: {
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                if (response.data?.page?.sam_input_size_1) {
                    setPages(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            'sam_input_size_1': response.data.page.sam_input_size_1,
                            'sam_input_size_2': response.data.page.sam_input_size_2,
                            'sam_original_size_1': response.data.page.sam_original_size_1,
                            'sam_original_size_2': response.data.page.sam_original_size_2,
                        }
                    }));

                    setAIMessages(prev => ({
                        ...prev,
                        [pageID]: {
                            ...prev[pageID],
                            'samPrep': {
                                ...prev[pageID].samPrep,
                                'progress': 1,
                                'show': false,
                                'running': false,
                            }
                        },
                    }));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoMeasureRooms = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-measure-rooms/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoMeasureRoomPerim = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-measure-room-perim/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoFindDW = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-find-door-window/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoCount = () => {
        setAIMessages(prev => ({    
            ...prev,
            [pageID]: {
                ...prev[pageID],
                'autoCount': {
                    ...prev[pageID].autoCount,
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                }
            }
        }));

        if (autoCountReference === 'auto') {
            if (autoCountLocation === 'auto') {
                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/ai-auto-count/`,
                    data: {
                        'page': pageID,
                        'contractor': auth.contractor.id,
                        'reference': 'auto',
                        'location': 'auto',
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (autoCountLocation === 'rectangle') {
                setAIAutoCountRect(prev => ({
                    ...prev,
                    [pageID]: {
                        start: null,
                        end: null,
                        show: true,
                        isComplete: false,
                    }
                }));
            } else if (autoCountLocation === 'polygon') {
                setAIAutoCountPoly(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev?.[pageID],
                        points: [],
                        nextPoint: null,
                        isComplete: false,
                        show: true,
                    }
                }));
            }
        } else if (autoCountReference === 'example') {
            if (autoCountLocation === 'auto') {
                setAIAutoCountExample(prev => ({
                    ...prev,
                    [pageID]: {
                        symbol: null,
                        show: true,
                    }
                }));
            } else if (autoCountLocation === 'rectangle') {
                setAIAutoCountExample(prev => ({
                    ...prev,
                    [pageID]: {
                        symbol: null,
                        show: true,
                    }
                }));
            } else if (autoCountLocation === 'polygon') {
                setAIAutoCountExample(prev => ({
                    ...prev,
                    [pageID]: {
                        symbol: null,
                        show: true,
                    }
                }));
            }
        } else if (autoCountReference === 'legend') {
            if (autoCountLocation === 'auto') {
                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/ai-auto-count/`,
                    data: {
                        'page': pageID,
                        'contractor': auth.contractor.id,
                        'reference': 'legend',
                        'location': 'auto',
                        'legend': currentLegend,
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        console.log(response);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            } else if (autoCountLocation === 'rectangle') {
                setAIAutoCountRect(prev => ({
                    ...prev,
                    [pageID]: {
                        start: null,
                        end: null,
                        show: true,
                        isComplete: false,
                    }
                }));
            } else if (autoCountLocation === 'polygon') {
                setAIAutoCountPoly(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev?.[pageID],
                        points: [],
                        nextPoint: null,
                        isComplete: false,
                        show: true,
                    }
                }));
            }
        }
    }

    if (!showAIPopup) return null;

    return (
        <div
            className="navbar-aipopup-container"
            id="takeoffnavbar-aipopup"
        >
            <div className="navbar-aipopup-title">
                <IconRobot size={20} stroke={1} />
                Bobyard AI tools
            </div>

            <div className="navbar-aipopup-body">
                <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Auto name
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        {autoNameAllPages
                            ? <div className="navbar-aipopup-group-item">
                                All pages
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <div
                                id='navbar-aipopup-item-auto-name-all'
                                className="navbar-aipopup-group-item"
                                onClick={() => handleAutoNameAll()}
                            >
                                All pages
                            </div>
                        }
                        <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-name-all'>
                            Auto name all pages included in this takeoff
                        </Tooltip>

                        {AIMessages?.[pageID]?.autoNamePage?.running
                            ? <div className="navbar-aipopup-group-item">
                                This page
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <div
                                className='navbar-aipopup-group-item'
                                id='navbar-aipopup-item-auto-name'
                                onClick={() => handleAutoName()}
                            >
                                This page
                            </div>
                        }
                        <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-name'>
                            Auto name this page
                        </Tooltip>
                    </div>
                </div>

                <hr className='navbar-aipopup-hr'></hr>


                <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Room areas
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        {AIMessages?.[pageID]?.autoSegRooms?.running
                            ? <div className="navbar-aipopup-group-item">
                                Automatic
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            :
                            <div
                                id='navbar-aipopup-item-auto-measure-rooms'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev[pageID],
                                            'autoSegRooms': {
                                                'progress': 1 / 100,
                                                'show': true,
                                                'running': true,
                                            }
                                        }
                                    }));

                                    handleAutoMeasureRooms();
                                }}
                            >
                                Automatic
                            </div>
                        }
                        <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-measure-rooms'>
                            Automatically measure area of all rooms
                        </Tooltip>

                        <div
                            id='navbar-aipopup-item-auto-measure-rooms-hints'
                            className="navbar-aipopup-group-item"
                            onClick={() => {
                                setHintingAutoMeasure(prev => ({
                                    ...prev,
                                    [pageID]: {
                                        ...prev[pageID],
                                        show: true,
                                    }
                                }))
                                setShowAIPopup(false);
                            }}
                        >
                            Crop
                        </div>
                        <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-measure-rooms-hints'>
                            Draw a rectangular crop to auto measure areas for
                        </Tooltip>
                    </div>
                </div>

                <hr className='navbar-aipopup-hr'></hr>


                <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Room perimeters
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        {AIMessages?.[pageID]?.autoSegRoomsPerim?.running
                            ? <div className="navbar-aipopup-group-item">
                                Automatic
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <>
                                <div
                                    id='navbar-aipopup-item-auto-measure-room-perim'
                                    className="navbar-aipopup-group-item"
                                    onClick={() => {
                                        setAIMessages(prev => ({
                                            ...prev,
                                            [pageID]: {
                                                ...prev[pageID],
                                                'autoSegRoomsPerim': {
                                                    'progress': 1 / 100,
                                                    'show': true,
                                                    'running': true,
                                                }
                                            }
                                        }));
                                        handleAutoMeasureRoomPerim();
                                    }}
                                >
                                    Automatic
                                </div>

                                <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim'>
                                    Automatically measure perimeter of all rooms
                                </Tooltip>
                            </>
                        }

                        <div
                            id='navbar-aipopup-item-auto-measure-room-perim-hints'
                            className="navbar-aipopup-group-item"
                            onClick={() => {
                                setHintingAutoMeasurePerim(prev => ({
                                    ...prev,
                                    [pageID]: {
                                        show: true,
                                    }
                                }))

                                setShowAIPopup(false);
                            }}
                        >
                            Crop
                        </div>

                        <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim-hints'>
                            Draw a rectangular crop to auto measure perimeters for
                        </Tooltip>
                    </div>
                </div>

                <hr className='navbar-aipopup-hr'></hr>


                <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Doors & windows
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        {AIMessages?.[pageID]?.autoFindDW?.running
                            ? <div className="navbar-aipopup-group-item">
                                Automatic
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : <>
                                <div
                                    id='navbar-aipopup-item-auto-find-dw'
                                    className="navbar-aipopup-group-item"
                                    onClick={() => {
                                        setAIMessages(prev => ({
                                            ...prev,
                                            [pageID]: {
                                                ...prev[pageID],
                                                'autoFindDW': {
                                                    ...prev[pageID].autoFindDW,
                                                    'progress': 1 / 100,
                                                    'show': true,
                                                    'running': true,
                                                }
                                            }
                                        }));

                                        handleAutoFindDW();
                                    }}
                                >
                                    Automatic
                                </div>

                                <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-find-dw'>
                                    Automatically find all doors and windows
                                </Tooltip>
                            </>
                        }

                        <div
                            id='navbar-aipopup-item-auto-find-dw-hints'
                            className="navbar-aipopup-group-item"
                            onClick={() => {
                                setHintingAutoFindDW(prev => ({
                                    ...prev,
                                    [pageID]: {
                                        start: null,
                                        end: null,
                                        show: true,
                                    }
                                }))
                                setShowAIPopup(false);
                            }}
                        >
                            Crop
                        </div>

                        <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-find-dw-hints'>
                            Draw a rectangle to auto find doors and windows
                        </Tooltip>
                    </div>
                </div>

                <hr className='navbar-aipopup-hr'></hr>

                {pages[pageID]?.ai_prepped
                    ? <>

                        <div className='navbar-aipopup-group'>
                            <div className='navbar-aipopup-group-header'>
                                <div className='navbar-aipopup-group-title'>
                                    Auto count
                                </div>
                            </div>

                            <div>
                                <div className='navbar-aipopup-auto-count-option'>
                                    <div>
                                        Reference
                                    </div>
                                    <select
                                        className='navbar-aipopup-auto-count-select'
                                        value={autoCountReference}
                                        onChange={(e) => setAutoCountReference(e.target.value)}
                                    >
                                        <option value='auto'>Auto</option>
                                        <option value='example'>Example</option>
                                        <option value='legend'>Legend</option>
                                    </select>
                                </div>

                                {autoCountReference === 'legend' &&
                                    <div className='navbar-aipopup-auto-count-option'>
                                        <div>
                                            Legend
                                        </div>
                                        <select
                                            className='navbar-aipopup-auto-count-select'
                                            value={currentLegend}
                                            onChange={(e) => setCurrentLegend(e.target.value)}
                                        >
                                            {AILegends && Object.values(AILegends)?.sort((a, b) => b.id - a.id)?.map((legend, index) =>
                                                <option key={index} value={legend.id}>{legend.name}</option>
                                            )}
                                        </select>
                                    </div>
                                }

                                <div className='navbar-aipopup-auto-count-option'>
                                    <div>
                                        Location
                                    </div>
                                    <select
                                        className='navbar-aipopup-auto-count-select'
                                        value={autoCountLocation}
                                        onChange={(e) => setAutoCountLocation(e.target.value)}
                                    >
                                        <option value='auto'>Auto</option>
                                        <option value='rectangle'>Rectangle</option>
                                        <option value='polygon'>Polygon</option>
                                    </select>
                                </div>

                                <div>
                                    <button
                                        className='navbar-aipopup-auto-count-button'
                                        onClick={() => {
                                            handleAutoCount();
                                        }}
                                    >
                                        Run auto count
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                    : <>
                        <div className='navbar-aipopup-group'>
                            <div className='navbar-aipopup-group-header'>
                                <div className='navbar-aipopup-group-title'>
                                    Prep page for counting
                                </div>
                            </div>
                            {AIMessages?.[pageID]?.prepLocalization?.running
                                ? <div className='navbar-aipopup-group-item'>
                                    Prepping {prepTrade}

                                    <Blocks
                                        visible={true}
                                        height="20"
                                        width="20"
                                        color="#006AFE"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                    />
                                </div>
                                : <div className='navbar-aipopup-group-body'>
                                    <div
                                        id='navbar-aipopup-item-prep-electrical'
                                        className='navbar-aipopup-group-item'
                                        onClick={() => {
                                            if (!AIMessages?.[pageID]?.prepLocalization?.running) {
                                                handlePrep('electrical');
                                            }
                                        }}
                                    >
                                        Electrical
                                    </div>

                                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-prep-electrical'>
                                        Prep page for electrical symbols
                                    </Tooltip>

                                    <div
                                        id='navbar-aipopup-item-prep-landscaping'
                                        className='navbar-aipopup-group-item'
                                        onClick={() => {
                                            if (!AIMessages?.[pageID]?.prepLocalization?.running) {
                                                handlePrep('landscaping');
                                            }
                                        }}
                                    >
                                        Landscaping
                                    </div>

                                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-prep-landscaping'>
                                        Prep page for landscaping symbols
                                    </Tooltip>
                                </div>
                            }
                        </div>
                    </>
                }

                <hr className='navbar-aipopup-hr'></hr>

                <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Legends
                        </div>

                        <div>
                            <button
                                className='navbar-aipopup-group-button'
                                onClick={() => {
                                    setShowAIPopup(false);
                                    setShowLegendsPopup(true);
                                }}
                            >
                                Manage legends
                            </button>
                        </div>
                    </div>
                </div>

                <hr className='navbar-aipopup-hr'></hr>


                <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Auto fill
                        </div>
                    </div>

                    {pages[pageID]?.sam_input_size_1
                        ? <>
                            <div
                                id='navbar-aipopup-item-hint-sam'
                                className='navbar-aipopup-group-item'
                                onClick={() => {
                                    setAIAutoCountRect(prev => {
                                        let newRect = { ...prev };
                                        Object.values(newRect)?.forEach((r) => {
                                            r.start = null;
                                            r.end = null;
                                            r.show = false;
                                            r.isComplete = false;
                                        })
                                        return newRect;
                                    });
                                    setAIAutoCountPoly(prev => {
                                        let newPoly = { ...prev };
                                        Object.values(newPoly)?.forEach((p) => {
                                            p.points = [];
                                            p.nextPoint = { x: 0, y: 0 };
                                            p.isComplete = false;
                                            p.show = false;
                                        })
                                        return newPoly;
                                    });
                                    setAIAutoClassifying(false);
                                    setAIAutoFinding(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev?.[pageID],
                                            show: false,
                                        }
                                    }))

                                    setHintingSAM(true);
                                    setShowAIPopup(false);
                                    setCurrentMeasurement(null);
                                }}
                            >
                                Hint auto fill
                            </div>

                            <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-hint-sam'>
                                Place points to auto fill areas
                            </Tooltip>
                        </>
                        : <>
                            {AIMessages?.[pageID]?.samPrep?.running
                                ? <>
                                    <div className='navbar-aipopup-group-item'>
                                        Prepping auto fill

                                        <Blocks
                                            visible={true}
                                            height="20"
                                            width="20"
                                            color="#006AFE"
                                            ariaLabel="blocks-loading"
                                            wrapperStyle={{}}
                                            wrapperClass="blocks-wrapper"
                                        />
                                    </div>
                                </>
                                : <>
                                    <div
                                        id='navbar-aipopup-item-prep-sam'
                                        className='navbar-aipopup-group-item'
                                        onClick={() => {
                                            handlePrepSAM();
                                            setAIMessages(prev => ({
                                                ...prev,
                                                [pageID]: {
                                                    ...prev[pageID],
                                                    'samPrep': {
                                                        ...prev[pageID].samPrep,
                                                        'progress': 1 / 100,
                                                        'show': true,
                                                        'running': true,
                                                    }
                                                },
                                            }));

                                            setShowAIPopup(false);
                                        }}
                                    >
                                        Prep page for auto fill
                                    </div>

                                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-prep-sam'>
                                        Prep AI for auto fill
                                    </Tooltip>
                                </>
                            }
                        </>
                    }
                </div>
            </div>
        </div >
    );
}