import { useContext, useEffect, useState } from 'react';

import { TakeoffContext } from '../helper/Context';

import '../styles/MeasurementDetail.css';
import RectangleDetails from './details/RectangleDetails';
import PolygonDetails from './details/PolygonDetails';
import CountDetails from './details/CountDetails';
import LineDetails from './details/LineDetails';
import CircleDetails from './details/CircleDetails';

export default function MeasurementDetail({ }) {
    const {
        project, setProject,
        pages, setPages,
        drawing,
        handleUpdateProject,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
    } = useContext(TakeoffContext);

    return null;

    return (
        <>
            {measurements[currentMeasurement]?.type === 'line' &&
                <LineDetails />
            }

            {measurements[currentMeasurement]?.type === 'count' &&
                <CountDetails />
            }

            {measurements[currentMeasurement]?.type === 'polygon' &&
                <PolygonDetails />
            }

            {measurements[currentMeasurement]?.type === 'rectangle' &&
                <RectangleDetails />
            }

            {measurements[currentMeasurement]?.type === 'circle' &&
                <CircleDetails />
            }
        </>
    )
}

