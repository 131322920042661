import { selectAuth } from '../../../redux/slices/authSlice';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import { TakeoffContext } from '../../helper/Context';
import DefaultButton from '../../../components/DefaultButton';
import { API_ROUTE } from '../../..';

import './styles/AILegendPopup.css';
import ActiveButton from '../../../components/ActiveButton';

export default function LegendsPopup({ }) {
    const auth = useSelector(selectAuth);

    const {
        project,
        showLegendsPopup, setShowLegendsPopup,
        AILegends, setAILegends,
        selectingLegend, setSelectingLegend,
        drawingLegend, setDrawingLegend,
        currentLegend, setCurrentLegend,
    } = useContext(TakeoffContext);

    const handleCreateLegend = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/aivirtuallegends/`,
            data: {
                name: 'New legend',
                project: project.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log(res);

                setAILegends(prev => ({
                    ...prev,
                    [res.data.id]: {
                        ...res.data,
                        entries: [],
                    }
                }))
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <Modal
            show={showLegendsPopup}
            onHide={() => setShowLegendsPopup(false)}
            size='xl'
        >
            <Modal.Header>
                <Modal.Title>
                    Legends
                </Modal.Title>

                <DefaultButton
                    handleClick={() => handleCreateLegend()}
                >
                    Create legend
                </DefaultButton>
            </Modal.Header>
            <Modal.Body>
                <div className='ailegend-popup-container'>
                    {AILegends && Object.keys(AILegends).length
                        ? <div className='ailegend-popup-legends'>
                            {Object.values(AILegends).sort((a, b) => b.id - a.id).map((legend, index) => (
                                <div key={index} className='ailegend-popup-legend'>
                                    <div className='ailegend-popup-legend-name'>
                                        {legend.name ? legend.name : 'No name'}
                                    </div>
                                    {legend?.entries?.length
                                        ? <div className='ailegend-popup-legend-entries'>
                                            {legend.entries.map((entry, index) => (
                                                <div key={index} className='ailegend-popup-legend-entry'>
                                                    <div className='ailegend-popup-legend-entry-image-container'>
                                                        <img
                                                            src={entry.file}
                                                            className='ailegend-popup-legend-entry-image'
                                                        />
                                                    </div>
                                                    <div className='ailegend-popup-legend-entry-text'>
                                                        {entry.text}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        : <div className='ailegend-popup-legend-no-entries'>
                                            <div>No entries</div>

                                            <ActiveButton
                                                handleClick={() => {
                                                    setShowLegendsPopup(false);
                                                    setSelectingLegend(true);
                                                    setCurrentLegend(legend.id);
                                                }}
                                                text='Populate with AI'
                                            />
                                        </div>
                                    }
                                </div>
                            ))}
                        </div>
                        : <div>
                            No legends
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DefaultButton
                    handleClick={() => setShowLegendsPopup(false)}
                >
                    Close
                </DefaultButton>
            </Modal.Footer>
        </Modal>

    )
}