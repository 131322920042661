import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate, useLocation } from 'react-router-dom';

import { useSelector } from 'react-redux';

import { selectAuth } from './redux/slices/authSlice';

import LandingPage from './homepage/LandingPage';

import Login from './authentication/Login';
import Register from './authentication/Register';
import Logout from './authentication/Logout';
import Dashboard from './dashboard/Dashboard';
import Settings from './settings/Settings';
import Profile from './settings/Profile/Profile';
import Keybind from './settings/keybind/Keybind';
import Company from './settings/company/Company';
import ComparePages from './comparepages/ComparePages';
import Takeoff from './takeoff/Takeoff';
import JoinCompanyRegister from './authentication/JoinCompanyRegister';
import JoinCompany from './settings/company/JoinCompany';
import EstimatePDF from './estimate/CreatePDF/EstimatePDF';
import ReportPage from './settings/report/Report';
import ResetPasswordRequest from './authentication/ResetPasswordRequest';
import ResetPassword from './authentication/ResetPassword';
import { API_ROUTE } from '.';
import PilotLanding from './homepage/PilotLanding';
import Meeting from './homepage/Meeting';
import ProjectDetails from './projectdetails/ProjectDetails';
import ErrorBoundary from './helper/ErrorBoundary';
import OneBuildPopup from './onebuild/OneBuildPopup';
import PDFExport from './pdfexport/PDFExport';
import EstimateTable from './estimate2/EstimateRevamp';
import Database from './database/database';
import Playground from './playground/Playground';
import BobyardAdmin from './admin/admin';
import SuperAdmin from './superadmin/SuperAdmin';
import Billing from './billing/Billing';
import Files from './files/Files';

function App() {
  const auth = useSelector(selectAuth);
  const [prevPath, setPrevPath] = useState(null);

  useEffect(() => {
    if (auth.token) {
      FS('setIdentity', {
        uid: auth.contractor.uuid,
        properties: {
          displayName: auth.user.username,
          email: auth.user.email,
        },
      });
    }
  }, [auth]);

  const ProtectedRoute = () => {
    let loc = useLocation();

    if (!auth?.user?.username) {
      if (loc.pathname != '/logout') {
        setPrevPath(loc);
      }
      return <Navigate to={'/login'} replace />;
    }

    return <Outlet />;
  };

  return (
    <ErrorBoundary auth={auth}>
      <Router>
        <Routes>
          {API_ROUTE.includes('pilot') || API_ROUTE.includes('localhost') || API_ROUTE.includes('app.bobyard.com') ? <Route path='/' element={<PilotLanding />} /> : <Route path='/' element={<LandingPage />} />}
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login prevPath={prevPath} />} />
          <Route path='/reset-password-request' element={<ResetPasswordRequest />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />

          <Route path='/meeting' element={<Meeting />} />

          <Route path='/register-join-company/:inviteLink' element={<JoinCompanyRegister />} />

          <Route element={<ProtectedRoute />}>
            <Route path='/join-company/:inviteLink' element={<JoinCompany />} />

            <Route path='/dashboard' element={<Dashboard />} />

            <Route path='/company' element={<Company />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/report' element={<ReportPage />} />
            <Route path='/settings' element={<Settings />} />
            <Route path='/keybind' element={<Keybind />} />
            <Route path='/billing' element={<Billing />} />

            <Route path='/takeoff/:projectUUID/:pageID' element={<Takeoff />} />
            <Route path='/takeoff/:projectUUID' element={<Takeoff />} />

            <Route path='/compare-pages/:projectUUID' element={<ComparePages />} />

            {/*<Route path='/estimate/:projectUUID' element={<EstimateController />} />*/}
            <Route path='/estimate/:projectUUID' element={<EstimateTable />} />
            <Route path='/estimate/:projectUUID/preview' element={<EstimatePDF />} />

            <Route path='/database' element={<Database />} />

            <Route path='/logout' element={<Logout />} />
            {/* <Route path='/project/:projectUUID' element={<ProjectController />} /> */}
            <Route path='/project/:projectUUID' element={<ProjectDetails />} />
            <Route path='/files/:projectUUID' element={<Files />} />
            <Route path='/onebuild' element={<OneBuildPopup />} />
            <Route path='/pdfexport' element={<PDFExport />} />
            <Route path='/playground' element={<Playground />} />

            <Route path='/bobyard-admin' element={<BobyardAdmin />} />
            <Route path='/super-admin' element={<SuperAdmin />} />
          </Route>
        </Routes>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
