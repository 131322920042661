import React, { useContext, useEffect, useState } from "react";
import { Layer, Stage, Line as KonvaLine, Rect, Group, Circle as KonvaCircle } from "react-konva";
import { Html, Portal } from "react-konva-utils";
import axios from "axios";

import { TakeoffContext } from "../../helper/Context";
import ContextMenu from "../../../components/ContextMenu";
import pSBC from "../../helper/Colors";
import { IconCopy, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function Circle({ measurement }) {
    const {
        project, setProject,
        pageID,
        pages,
        groups, setGroups,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        drawing,
        handleCountTransform,
        DeleteMeasurement,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        setShowDetails,
        handleCircleTransform,
        optionDown, shiftDown,
        handleDuplicateMeasurement,
    } = useContext(TakeoffContext);

    const isSelected = currentMeasurement == measurement.id;

    const [dragging, setDragging] = useState(false);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [colorPattern, setColorPattern] = useState([]);

    useEffect(() => {
        if (measurement?.gap > 0) {
            const colors = [];
            let x = Number(measurement.gap) / 4;

            for (let i = 0; i + 2 * x < 100; i += 2 * x) {
                colors.push(i / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, pSBC(-0.05, measurement.color));
                colors.push((i + x) / 100.0, 'white');
                colors.push((i + 2 * x) / 100.0, 'white');
            }

            if (colorPattern !== colors) {
                setColorPattern(colors);
            }
        }
    }, [measurement, measurement.gap, measurement.color]);

    const width = (pages[pageID].width);
    const height = (pages[pageID].height);

    const angleInDeg = measurement.size;
    const angle = ((180 - angleInDeg) / 180) * Math.PI
    const length = Math.abs(width * Math.sin(angle)) + Math.abs(height * Math.cos(angle))
    const halfx = (Math.sin(angle) * length)
    const halfy = (Math.cos(angle) * length)
    const cx = width / 2.0
    const cy = height / 2.0
    const x1 = cx - halfx
    const y1 = cy - halfy
    const x2 = cx + halfx
    const y2 = cy + halfy

    return (
        <>
            <Html>
                {showContextMenu && currentMeasurement === measurement.id &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        {/*<div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Rename
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Group
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set depth
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set pitch
                        </div>*/}

                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                handleDuplicateMeasurement(measurement);
                            }}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>
                                Duplicate
                            </div>
                        </div>

                        <div
                            className="contextmenu-item"
                            onClick={() => handleZoomToMeasurement()}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>
                                Zoom
                            </div>
                        </div>

                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => DeleteMeasurement(measurement)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>
                                Delete
                            </div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                <KonvaCircle
                    x={measurement.circle.x}
                    y={measurement.circle.y}
                    radius={measurement.circle.radius}
                    opacity={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 0.7 : 0.5}
                    strokeWidth={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 2 / pages[pageID].zoom : 1 / pages[pageID].zoom}
                    stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement.color) : measurement?.color}
                    fill={measurement?.gap ? undefined : measurement.color ? measurement.color : 'lightblue'}

                    fillLinearGradientStartPoint={{ x: x1, y: y1 }}
                    fillLinearGradientEndPoint={{ x: x2, y: y2 }}
                    fillLinearGradientColorStops={colorPattern}

                    shadowColor={pSBC(-0.25, measurement.color)}
                    //shadowBlur={isSelected ? 15 / pages[pageID].zoom : 0}
                    draggable={isSelected && !drawing}
                    onMouseLeave={(e) => {
                        e.target.getStage().container().style.cursor = "default";
                    }}
                    onMouseUp={(e) => {
                        setDragging(false);
                        e.target.getStage().container().style.cursor = "pointer";
                    }}
                    onMouseDown={(e) => {
                        setCurrentMeasurement(measurement.id);

                        if (isSelected) {
                            setDragging(true);
                            e.target.getStage().container().style.cursor = "grabbing";
                        } else {
                            e.target.getStage().container().style.cursor = "default";
                        }
                    }}
                    onMouseEnter={(e) => {
                        if (isSelected) {
                            e.target.getStage().container().style.cursor = "pointer";
                        } else {
                            e.target.getStage().container().style.cursor = "default";
                        }
                    }}
                    onClick={(e) => {
                        if (e.evt.metaKey) {
                            if (selectedMeasurements.find(m => m === measurement.id)) {
                                setSelectedMeasurements(selectedMeasurements.filter(m => m !== measurement.id));
                            } else {
                                setSelectedMeasurements([...selectedMeasurements, measurement.id]);
                            }
                        } else {
                            setSelectedMeasurements([measurement.id]);
                            setCurrentMeasurement(measurement.id);
                        }

                        handleChangeTakeoffSettings({
                            ...takeoffSettings,
                            show_measurement_sidebar: true
                        })

                        setShowDetails(true);

                        if (optionDown) {
                            setTimeout(() => {
                                const element = document.getElementById(`measurement-${measurement.id}-name`);
                                if (element) element.click();
                            }, 100);
                        }

                        setTimeout(() => {
                            const element = document.getElementById("measurement-" + measurement.id);
                            if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top > window.innerHeight)) {
                                element.scrollIntoView();
                            }
                        }, 100);
                    }}
                    onContextMenu={(e) => {
                        e.evt.preventDefault();
                        setContextMenuPosition({
                            x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                            y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                        })
                        handleChangeTakeoffSettings({
                            ...takeoffSettings,
                            show_measurement_sidebar: true,
                            show_pages_sidebar: takeoffSettings.pages_sidebar_location === takeoffSettings.measurement_sidebar_location
                                ? false : takeoffSettings.show_pages_sidebar,
                            show_ai_sidebar: takeoffSettings.ai_sidebar_location === takeoffSettings.measurement_sidebar_location
                                ? false : takeoffSettings.show_ai_sidebar,
                        })
                        setShowContextMenu(true);
                        setCurrentMeasurement(measurement.id);
                        setSelectedMeasurements([measurement.id])
                    }}
                    onDragEnd={(e) => {
                        setDragging(false);
                        handleCircleTransform({
                            ...measurement,
                            circle: {
                                ...measurement.circle,
                                x: e.target.x(),
                                y: e.target.y(),
                            }
                        });
                    }}
                    perfectDrawEnabled={false}
                />

                {!dragging && isSelected &&
                    <CircleRezier measurement={measurement} />
                }
            </Portal>
        </>
    );
}

const CircleRezier = ({ measurement }) => {
    const {
        pageID,
        pages,
        measurements, setMeasurements,
        handleCircleTransform,
    } = useContext(TakeoffContext);

    const [position, setPosition] = useState({ x: measurement.circle.x + measurement.circle.radius, y: measurement.circle.y });

    const [prev, setPrev] = useState(null);

    useEffect(() => {
        setPosition({ x: measurement.circle.x + measurement.circle.radius, y: measurement.circle.y });
    }, [measurement?.circle?.x, measurement?.circle?.y]);

    return (
        <KonvaCircle
            x={position.x}
            y={position.y}
            radius={5 / pages[pageID].zoom}
            stroke={pSBC(-0.8, measurement.color)}
            strokeWidth={1 / pages[pageID].zoom}
            fill={'white'}
            opacity={0.5}
            draggable
            onMouseEnter={(e) => {
                e.target.getStage().container().style.cursor = "move";
            }}
            onMouseLeave={(e) => {
                e.target.getStage().container().style.cursor = "default";
            }}
            onDragStart={(e) => {
                setPrev(measurement);
            }}
            onDragMove={(e) => {
                setMeasurements(prev => ({
                    ...prev,
                    [measurement.id]: {
                        ...prev[measurement.id],
                        circle: {
                            ...prev[measurement.id].circle,
                            radius: Math.sqrt(Math.pow(e.target.x() - measurement.circle.x, 2) + Math.pow(e.target.y() - measurement.circle.y, 2)),
                        }
                    }
                }))
                setPosition({ x: e.target.x(), y: e.target.y() });
            }}
            onDragEnd={(e) => {
                handleCircleTransform({
                    ...measurement,
                    circle: {
                        ...measurement.circle,
                        radius: Math.sqrt(Math.pow(e.target.x() - measurement.circle.x, 2) + Math.pow(e.target.y() - measurement.circle.y, 2)),
                    }
                }, false, prev);
            }}
            perfectDrawEnabled={false}
        />
    );
}