import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';

import { selectAuth, updateAuth } from '../redux/slices/authSlice';

import { API_ROUTE } from '../index';
import usePasswordValidator from '../helper/usePasswordValidator';

export default function ResetPassword() {
    const params = useParams();
    const token = params.token;

    const auth = useSelector(selectAuth);

    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const [password, setPassword, passwordError] = usePasswordValidator({
        min: 8,
        max: 20
    });

    const [error, setError] = useState("");

    useEffect(() => {
        if (auth?.user?.username) {
            window.location.assign('/dashboard');
        }
    }, [auth])

    useEffect(
        () => {
            if (!confirmPassword || !password) {
                setConfirmPasswordError("");
            } else {
                if (password !== confirmPassword) {
                    setConfirmPasswordError("The passwords must match.");
                } else {
                    setConfirmPasswordError("");
                }
            }
        },
        [password, confirmPassword]
    );

    const handleSubmit = (e) => {
        e.preventDefault();

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/password_reset/confirm/`,
            data: {
                'token': token,
                'password': password
            },
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                console.log(response);

                window.location.assign('/login');
            })
            .catch((error) => {
                console.log(error);
                
                setError(error?.response?.data?.password?.[0])

                setPassword("");
                setConfirmPassword("");
            });
    }

    return (
        <div className="login-wrapper">
            <a href="/">
                <div className='login-logo-container'>
                    <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png"} className='login-logo-image' />
                </div>
            </a>

            <div className='login-title'>
                Change Password
            </div>

            <div className="login-container">
                {error && <div className="error">{error}</div>}
            
                <div className="error">{passwordError}</div>
                <input
                    value={password}
                    className="inputregister"
                    onChange={e => setPassword(e.target.value)}
                    type="password"
                    placeholder="Password"
                />

                <div className="error">{confirmPasswordError}</div>
                <input
                    value={confirmPassword}
                    className="inputregister"
                    onChange={e => setConfirmPassword(e.target.value)}
                    type="password"
                    placeholder="Confirm Password"
                />
            </div>

            <button
                type="submit"
                className={password && confirmPassword
                    ? "active-button login-button"
                    : "default-button login-button login-button-disabled"
                }
                disabled={!(password && confirmPassword)}
                onClick={(e) => handleSubmit(e)}
            >
                Confrim
            </button>
        </div>
    )
}