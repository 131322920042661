import { Blocks } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'
import '../styles/TakeoffLoading.css';

export default function TakeoffLoading({ }) {
    return (
        <>
            <div className="takeoff-loading-navbar">
                <div>
                    <a href='/dashboard'>
                        <img
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png'
                            alt='logo'
                            className='takeoffnavbar-logo-loading'
                        />
                    </a>
                </div>
                <div><Skeleton width={200} /></div>
                <div><Skeleton width={350} /></div>
                <div><Skeleton width={100} /></div>
                <div><Skeleton width={200} /></div>
            </div>

            <div className="takeoff-loading">
                <div className="takeoff-loading-sidebar">
                    <Skeleton height={30}/>

                    <br></br>
                    <Skeleton count={1.8}/>

                    <br></br>
                    <Skeleton count={5}/>

                    <br></br>
                    <Skeleton count={10.9}/>

                    <br></br>
                    <Skeleton count={9.6}/>
                </div>
                <div className="takeoff-loading-canvas">
                    <Blocks
                        visible={true}
                        height="60"
                        width="60"
                        color="#006AFE"
                        ariaLabel="blocks-loading"
                        radius="10"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                    />
                </div>
                <div className="takeoff-loading-toolbar">
                    <Skeleton circle={true} width={20}/>
                    <Skeleton circle={true} width={20}/>
                    <Skeleton circle={true} width={20}/>
                    <Skeleton circle={true} width={20}/>
                    <Skeleton circle={true} width={20}/>
                    <Skeleton circle={true} width={20}/>
                </div>
            </div>
        </>
    )
}