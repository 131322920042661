import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'

import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';

//add persisted store for user and token

const persistConfig = {
    key: 'root',
    storage: storage,
}

const persistedAuthReducer = persistReducer(persistConfig, authReducer)   

//combine reducers
export const store = configureStore({
    reducer: {
        auth: persistedAuthReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
})
    

export const persistor = persistStore(store)