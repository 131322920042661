export default function EstimatePDFTemplate({ topLevelIDs, treeData, project, company, contractor }) {
    return (
        <div>
            <h3>Project Title: {project.title}</h3>

            <div>
                <b>Company:</b> {company.name}
            </div>

            <div>
            </div>

            <div>
                <h3 style={{ color: "#006AFF" }}>Estimate</h3>
            </div>

            <div
                style={{
                    padding: '20px',
                    border: '1px solid #006AFF',
                    borderRadius: '5px',
                    backgroundColor: '#F2F2F2',
                    position: 'relative',
                    marginTop: '20px',
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '-10px',
                        left: '10px',
                        backgroundColor: 'white',
                        padding: '0 10px',
                        border: '1px solid #006AFF',
                        borderRadius: '5px',
                    }}
                >
                    Example
                </div>

                <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr 5fr 10fr', alignItems: 'center' }}>
                    <div
                        style={{
                            width: '20px',
                            height: '20px',
                            borderRadius: '50%',
                            backgroundColor: '#006AFF'
                        }}
                    >&nbsp;</div>

                    <div><b>Name</b></div>

                    <div>(Page title)</div>

                    <div>Description</div>
                </div>

                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                        alignItems: 'center',
                        position: 'relative',
                        marginTop: '20px',
                        border: '1px solid #006AFF',
                        borderRadius: '5px',
                        padding: '20px 20px 10px 20px',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '-10px',
                            left: '10px',
                            backgroundColor: 'white',
                            padding: '0 10px',
                            border: '1px solid #006AFF',
                            borderRadius: '5px',
                        }}
                    >
                        Line item details
                    </div>
                    <div>Unit amount</div>
                    <div>Unit cost</div>
                    <div>Labor hours</div>
                    <div>Labor cost</div>
                    <div>Subtotal</div>
                    <div>Markup</div>
                    <div>Total</div>
                </div>


                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                        alignItems: 'center',
                        position: 'relative',
                        marginTop: '20px',
                        border: '1px solid #006AFF',
                        borderRadius: '5px',
                        padding: '20px 20px 10px 20px',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '-10px',
                            left: '10px',
                            backgroundColor: 'white',
                            padding: '0 10px',
                            border: '1px solid #006AFF',
                            borderRadius: '5px',
                        }}
                    >
                        Measurement details
                    </div>
                    <div>Length</div>
                    <div>Area</div>
                    <div>Volume</div>
                    <div>Width</div>
                    <div>Height</div>
                    <div>Depth</div>
                    <div>Pitch</div>
                </div>
            </div>

            <div>
                {topLevelIDs.map((id) =>
                    <Row key={id} id={id} treeData={treeData} />
                )}
            </div>
        </div>
    )
}

function Row({ id, treeData }) {
    return (
        <div style={{ paddingLeft: '20px' }}>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 4fr 5fr 10fr', alignItems: 'center' }}>
                <div
                    style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                        backgroundColor: treeData[id].color,
                    }}
                >&nbsp;</div>

                <div><b>{treeData[id].name}</b></div>

                {treeData[id].page_title && <div>({treeData[id].page_title})</div>}

                <div>{treeData[id].desc}</div>
            </div>

            <div key={treeData[id].id} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr' }}>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].unit_amount}&nbsp;</div>
                <div>{treeData[id].unit_cost}&nbsp;</div>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].labor_hours}&nbsp;</div>
                <div>{treeData[id].labor_cost}&nbsp;</div>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].subtotal}&nbsp;</div>
                <div>{treeData[id].markup}&nbsp;</div>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].total}&nbsp;</div>
            </div>

            <div key={treeData[id].id} style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr' }}>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].length}&nbsp;</div>
                <div>{treeData[id].area}&nbsp;</div>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].volume}&nbsp;</div>
                <div>{treeData[id].width}&nbsp;</div>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].height}&nbsp;</div>
                <div>{treeData[id].depth}&nbsp;</div>
                <div style={{ backgroundColor: 'whitesmoke' }}>{treeData[id].pitch}&nbsp;</div>
            </div>

            {treeData[id].type === 'group' && treeData[id].children.length > 0 &&
                <div>
                    {treeData[id].children.map((childID) =>
                        <Row key={childID} id={childID} treeData={treeData} />
                    )}
                </div>
            }
        </div>
    )
}