import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Popup from 'reactjs-popup';

import { IconRulerMeasure, IconLayoutGrid, IconCaretLeft, IconCaretRight, IconCaretDown, IconSettings, IconRobot, IconCircleCheck, IconLayout, IconList, IconKeyboard, IconSelect, IconEyeOff, IconPhotoDown, IconDownload } from '@tabler/icons-react';

import { API_ROUTE } from '../..';

import { TakeoffContext } from '../helper/Context';
import { selectAuth } from '../../redux/slices/authSlice';

import '../styles/TakeoffNavbar.css';

import ButtonHoverInfo from '../../components/ButtonHoverInfo';
import DefaultButton from '../../components/DefaultButton';
import HoverDelay from '../../components/HoverDelay';
import CustomLink from '../../components/CustomLink';
import DefaultIconButton from '../../components/DefaultIconButton';
import NavBarScaleMenu from './navbar/NavBarScaleMenu';
import NavBarSettingsMenu from './navbar/NavBarSettingsMenu';
import ActiveButton from '../../components/ActiveButton';
import AIPopup from './navbar/AIPopup';
import { Tooltip } from 'react-tooltip';
import NavDropDown from '../../components/NavDropDown';
import ProjectDetailsModal from '../../projectdetails/ProjectDetailsModal';
import jsPDF from 'jspdf';
import { Blocks } from 'react-loader-spinner';

export default function TakeoffNavbar({ auth, projectUUID }) {
    const {
        project, setProject,
        pages, setPages,
        pageID,
        drawing,
        handleUpdateProject,
        takeoffSettings,
        handleChangeTakeoffSettings,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        keybinds, setKeybinds,
        setCurrentMeasurement,
        measurements, setMeasurements,
        groups, setGroups,
        tree, setTree,
        handleNewPage,
        drawingScale, setDrawingScale,
        handleTurnOffDrawing,
        globalHide, setGlobalHide,
        stageRef,
        handleResetZoom,
    } = useContext(TakeoffContext);

    const [editingPageTitle, setEditingPageTitle] = useState(false);
    const [pageTitle, setPageTitle] = useState(pages?.[pageID]?.title);

    const [showProjectDetails, setShowProjectDetails] = useState(false);
    const [showPagesDetails, setShowPagesDetails] = useState(false);

    const [exporting, setExporting] = useState(false);

    const [projects, setProjects] = useState([]);

    const [showScaleMenu, setShowScaleMenu] = useState(false);
    const [scaleNumerator, setScaleNumerator] = useState(null);
    const [scaleDenominator, setScaleDenominator] = useState(null);
    const [scaleFeet, setScaleFeet] = useState(null);
    const [scaleInches, setScaleInches] = useState(null);

    useEffect(() => {
        if (auth.token && project) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/projects/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                },
                params: {
                    'companyID': project.company,
                }
            })
                .then((response) => {
                    console.log(response);

                    setProjects(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, project]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                setPageTitle(pages?.[pageID]?.title);
                setEditingPageTitle(false);
            } else if (e.key === 'Enter' && !e.defaultPrevented) {
                document.activeElement.blur();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [pageID, pages]);

    const handleUpdatePage = (newPage) => {
        setPages(prev => ({
            ...prev,
            [newPage.id]: newPage,
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${newPage.id}/`,
            data: {
                'project': newPage.project,
                'title': newPage.title,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const setScale = (referencePage) => {
        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                scale: referencePage.scale,
            }
        }));

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            data: {
                'project': pages[pageID].project,
                'scale': referencePage.scale,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleCopyScale = (in_numerator, in_denominator, in_feet, in_inches) => {
        let in_inch = parseFloat(in_numerator) / parseFloat(in_denominator);
        let out_inch = parseFloat(in_feet) * 12.0 + parseFloat(in_inches);

        let in_px = in_inch * pages[pageID].width / pages[pageID].width_inches;
        let out_ft = out_inch / 12;

        let newScale = out_ft / in_px;

        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                scale: newScale,
            }
        }));

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            data: {
                'project': pages[pageID].project,
                'scale': newScale,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setScaleNumerator(null);
                setScaleDenominator(null);
                setScaleFeet(null);
                setScaleInches(null);
                setShowScaleMenu(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleUpdateScale = () => {
        let in_inch = parseFloat(scaleNumerator) / parseFloat(scaleDenominator);
        let out_inch = parseFloat(scaleFeet) * 12.0 + parseFloat(scaleInches);

        let in_px = in_inch * pages[pageID].width / pages[pageID].width_inches;
        let out_ft = out_inch / 12;

        let newScale = out_ft / in_px;

        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                scale: newScale,
            }
        }));

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            data: {
                'project': pages[pageID].project,
                'scale': newScale,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setScaleNumerator(null);
                setScaleDenominator(null);
                setScaleFeet(null);
                setScaleInches(null);
                setShowScaleMenu(false);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleDownloadPage = () => {
        const url = pages[pageID].file;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', pages[pageID].title);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleExportMeasurements = () => {
        if (exporting) {
            return;
        }

        setExporting(true);

        handleResetZoom();

        let zoom = Math.min(window.innerWidth * 0.8 / pages[pageID].width, window.innerHeight * 0.9 / pages[pageID].height);
        let newX = (window.innerWidth * 0.8 - pages[pageID].width * zoom) / 2;
        let newY = (window.innerHeight * 0.9 - pages[pageID].height * zoom) / 2;

        setTimeout(() => {
            var pdf = new jsPDF('l', 'px', [pages[pageID].width * zoom, pages[pageID].height * zoom]);
            pdf.addImage(
                stageRef.current.toDataURL({ pixelRatio: 6 }),
                -newX,
                -newY,
                stageRef.current.width(),
                stageRef.current.height()
            );

            pdf.save(pages[pageID].title + '-takeoff.pdf');

            setExporting(false);
        }, 1000);

        /*function downloadURI(uri, name) {
            var link = document.createElement('a');
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        const uri = stageRef.current.toDataURL({ pixelRatio: 20 });
        downloadURI(uri, pages[pageID].title + '-takeoff.png');*/
    }

    const leftSidebarWidth = takeoffSettings?.ai_sidebar_location === 'left' && takeoffSettings?.show_ai_sidebar
        ? (takeoffSettings?.ai_sidebar_width || window.innerWidth * 0.2) + 1
        : takeoffSettings?.pages_sidebar_location === 'left' && takeoffSettings?.show_pages_sidebar
            ? (takeoffSettings?.pages_sidebar_width || window.innerWidth * 0.2) + 1
            : takeoffSettings?.measurement_sidebar_location === 'left' && takeoffSettings?.show_measurement_sidebar
                ? (takeoffSettings?.measurement_sidebar_width || window.innerWidth * 0.2) + 1
                : window.innerWidth * 0.2 + 1

    const rightSidebarWidth = takeoffSettings?.ai_sidebar_location === 'right' && takeoffSettings?.show_ai_sidebar
        ? (takeoffSettings?.ai_sidebar_width || window.innerWidth * 0.2) + 1
        : takeoffSettings?.pages_sidebar_location === 'right' && takeoffSettings?.show_pages_sidebar
            ? (takeoffSettings?.pages_sidebar_width || window.innerWidth * 0.2) + 1
            : takeoffSettings?.measurement_sidebar_location === 'right' && takeoffSettings?.show_measurement_sidebar
                ? (takeoffSettings?.measurement_sidebar_width || window.innerWidth * 0.2) + 1
                : window.innerWidth * 0.2 + 1

    return (
        <div className="takeoffnavbar-container">
            <div
                className='takeoffnavbar-toggle-container-left'
                style={{
                    width: leftSidebarWidth,
                }}
            >
                {takeoffSettings?.ai_sidebar_location === 'left' &&
                    <div
                        className='takeoffnavbar-toggle-button'
                        id='takeoffnavbar-settings-button-left-ai'
                        style={takeoffSettings?.show_ai_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                        onClick={() => {
                            let newTakeoffSettings = { ...takeoffSettings };

                            if (!newTakeoffSettings?.show_ai_sidebar) {
                                newTakeoffSettings.show_ai_sidebar = true;

                                if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                    newTakeoffSettings.show_measurement_sidebar = false
                                }

                                if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                    newTakeoffSettings.show_pages_sidebar = false
                                }
                            } else {
                                newTakeoffSettings.show_ai_sidebar = false;
                            }

                            handleChangeTakeoffSettings(newTakeoffSettings);
                        }}
                    >
                        <div><IconRobot size={20} stroke={1} /></div>
                        <div><nobr>AI tools</nobr></div>
                    </div>
                }

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-settings-button-left-ai' place='top-start'>
                    Toggle AI sidebar ({keybinds?.ToggleAISidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleAISidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleAISidebar?.key})
                </Tooltip>

                {takeoffSettings?.pages_sidebar_location === 'left' &&
                    <div
                        className='takeoffnavbar-toggle-button'
                        id='takeoffnavbar-pages-left-button'
                        style={takeoffSettings?.show_pages_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                        onClick={() => {
                            let newTakeoffSettings = { ...takeoffSettings };

                            if (!newTakeoffSettings?.show_pages_sidebar) {
                                newTakeoffSettings.show_pages_sidebar = true;

                                if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                    newTakeoffSettings.show_measurement_sidebar = false
                                }

                                if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                    newTakeoffSettings.show_ai_sidebar = false
                                }
                            } else {
                                newTakeoffSettings.show_pages_sidebar = false;
                            }

                            handleChangeTakeoffSettings(newTakeoffSettings);
                        }}

                    >
                        <div><IconLayoutGrid size={20} stroke={1} /></div>
                        <div>Pages</div>
                    </div>
                }

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-pages-left-button' place='top-start'>
                    Toggle pages sidebar ({keybinds?.TogglePagesSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.TogglePagesSidebar?.shift ? 'Shift + ' : ''}{keybinds?.TogglePagesSidebar?.key})
                </Tooltip>

                {takeoffSettings?.measurement_sidebar_location === 'left' &&
                    <div
                        id='takeoffnavbar-measurements-button-left'
                        className='takeoffnavbar-toggle-button'
                        style={takeoffSettings?.show_measurement_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                        onClick={() => {
                            let newTakeoffSettings = { ...takeoffSettings };

                            if (!newTakeoffSettings?.show_measurement_sidebar) {
                                newTakeoffSettings.show_measurement_sidebar = true;

                                if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                    newTakeoffSettings.show_pages_sidebar = false
                                }

                                if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                    newTakeoffSettings.show_ai_sidebar = false
                                }
                            } else {
                                newTakeoffSettings.show_measurement_sidebar = false;
                            }

                            handleChangeTakeoffSettings(newTakeoffSettings); takeoffSettings?.show_pages_sidebar
                        }}

                    >
                        <div><IconList size={20} stroke={1} /></div>
                        <div>Takeoffs</div>
                    </div>
                }

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-measurements-button-left' place='top-start'>
                    Toggle measurements sidebar ({keybinds?.ToggleMeasurementSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleMeasurementSidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleMeasurementSidebar?.key})
                </Tooltip>
            </div>

            <div
                className='takeoffnavbar-main-container'
                style={{
                    width: `calc(100% - ${leftSidebarWidth}px - ${rightSidebarWidth}px)`,
                }}
            >
                <div className='takeoffnavbar-project-name-container'>
                    {/*<div
                        className='takeoffnavbar-logo-container'
                        id='takeoffnavbar-logo-id'
                    >
                        <a href='/dashboard'>
                            <img
                                src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard-b.png'
                                alt='logo'
                                className='takeoffnavbar-logo'
                            />
                        </a>
                    </div>

                    <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-logo-id' place='top'>
                        Dashboard
                    </Tooltip>*/}

                    <NavDropDown
                        projectUUID={projectUUID}
                    />

                    <div
                        className='takeoffnavbar-project-name'
                        id="takeoffnavbar-project-name"
                        onClick={() => setShowProjectDetails(true)}
                    >
                        {project?.title}
                    </div>

                    <Popup
                        trigger={open => (
                            <div
                                className='takeoffnavbar-project-name-icon'
                                id='takeoffnavbar-project-name-icon'
                                onClick={() => setShowProjectDetails(true)}
                            >
                                <IconSelect size={20} stroke={1} color='#525252' />
                            </div>
                        )}
                        on='click'
                        position="bottom center"
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                    >
                        <div className="navdropdown-items">
                            {projects.sort((a, b) => b.id - a.id).map((p) =>
                                p.uuid === project.uuid
                                    ? <div
                                        key={p.id}
                                        className="navdropdown-item-active"
                                    >
                                        <div>{p.title}</div>
                                    </div>
                                    : <a
                                        key={p.id}
                                        href={"/takeoff/" + p.uuid}
                                        className="navdropdown-item-link"
                                    >
                                        <div className="navdropdown-item">
                                            <div>{p.title}</div>
                                        </div>
                                    </a>
                            )}
                        </div>
                    </Popup>
                </div>

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-project-name' place='top'>
                    <div><b>{project?.title}</b></div>
                    <div>Click to view project details</div>
                </Tooltip>

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-project-name-icon' place='top'>
                    Navigate to another project
                </Tooltip>

                {showProjectDetails && project &&
                    <ProjectDetailsModal
                        project={project}
                        setProject={setProject}
                        showProjectDetails={showProjectDetails}
                        setShowProjectDetails={setShowProjectDetails}
                    />
                }

                <div className='takeoffnavbar-page-container'>
                    <div
                        id="previous-page-button"
                        className='takeoffnavbar-page-button'
                        onClick={() => {
                            let newPageID = Object.keys(pages)[(Object.keys(pages).findIndex(p => String(p) === String(pageID)) - 1 + Object.keys(pages).length) % Object.keys(pages).length]
                            handleNewPage(newPageID);
                            window.history.pushState({}, '', `/takeoff/${project.uuid}/${newPageID}`);
                        }}
                    >
                        <IconCaretLeft size={24} stroke={1} />
                    </div>
                    <Tooltip delayShow={1000} anchorSelect='#previous-page-button' place='top'>
                        Previous page (Alt + Left Arrow)
                    </Tooltip>

                    {editingPageTitle
                        ? <div
                            className='takeoffnavbar-page-name-container'
                            id="takeoffnavbar-page-name-id"
                        >
                            <input
                                type='text'
                                className='takeoffnavbar-page-name-input'
                                value={pageTitle}
                                onChange={(e) => setPageTitle(e.target.value)}
                                onBlur={() => {
                                    handleUpdatePage({
                                        ...pages[pageID],
                                        title: pageTitle,
                                    })
                                    setEditingPageTitle(false);
                                }}
                                autoFocus
                            />
                        </div>
                        : <div
                            className='takeoffnavbar-page-name-container'
                            id="takeoffnavbar-page-name-id"
                            onDoubleClick={() => {
                                setPageTitle(pages?.[pageID]?.title);
                                setEditingPageTitle(true);
                            }}
                        >
                            <div className='takeoffnavbar-page-name'>
                                {pages?.[pageID]?.title}
                            </div>
                        </div>
                    }

                    <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-page-name-id' place='top'>
                        <div>
                            <b>Page name</b>
                        </div>
                        <div>
                            Double click to edit {pages?.[pageID]?.title?.length > 30 && <>({pages?.[pageID]?.title})</>}
                        </div>
                    </Tooltip>

                    <Popup
                        trigger={open => (
                            <div
                                className='takeoffnavbar-page-button'
                                id='takeoff-navbar-pages-button'
                                onClick={() => setShowPagesDetails(true)}
                            >
                                <IconSelect size={20} stroke={1} />
                            </div>
                        )}
                        on='click'
                        position="bottom center"
                        closeOnDocumentClick
                        mouseLeaveDelay={300}
                        mouseEnterDelay={0}
                    >
                        <div className="navdropdown-items takeoffnavbar-pages-container">
                            {pages && Object.values(pages).sort((a, b) => a.id - b.id).map((p) =>
                                Number(p.id) === Number(pageID)
                                    ? <div
                                        key={p.id}
                                        className="navdropdown-item-active takeoffnavbar-pages-item"
                                    >
                                        <div className='takeoffnavbar-pages-item-thumbnail'>
                                            <img
                                                src={p.thumbnail}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />
                                            <div className='takeoffnavbar-pages-item-icon-measurements'>
                                                {p.measurements}
                                            </div>
                                            {p.ai_prepped &&
                                                <div className='takeoffnavbar-pages-item-icon-ai'>
                                                    <IconRobot size={20} stroke={1} color='#006AFF' />
                                                </div>
                                            }
                                        </div>
                                        <div className='takeoffnavbar-pages-item-title'>
                                            {p.title}
                                        </div>
                                    </div>
                                    : <div
                                        key={p.id}
                                        className="navdropdown-item takeoffnavbar-pages-item"
                                        onClick={() => {
                                            handleNewPage(p.id);
                                            window.history.pushState({}, '', `/takeoff/${project.uuid}/${p.id}`);
                                        }}
                                    >
                                        <div className='takeoffnavbar-pages-item-thumbnail'>
                                            <img
                                                src={p.thumbnail}
                                                style={{
                                                    width: '100%',
                                                    height: '100%',
                                                    objectFit: 'cover',
                                                }}
                                            />

                                            <div className='takeoffnavbar-pages-item-icon-measurements'>
                                                {p.measurements}
                                            </div>
                                            {p.ai_prepped &&
                                                <div className='takeoffnavbar-pages-item-icon-ai'>
                                                    <IconRobot size={20} stroke={1} color='#006AFF' />
                                                </div>
                                            }
                                        </div>
                                        <div className='takeoffnavbar-pages-item-title'>
                                            {p.title}
                                        </div>
                                    </div>
                            )}
                        </div>
                    </Popup>

                    <Tooltip delayShow={1000} anchorSelect='#takeoff-navbar-pages-button' place='top'>
                        Pages
                    </Tooltip>

                    <div
                        id="next-page-button"
                        className='takeoffnavbar-page-button'
                        onClick={() => {
                            let newPageID = Object.keys(pages)[(Object.keys(pages).findIndex(p => String(p) === String(pageID)) + 1) % Object.keys(pages).length]
                            handleNewPage(newPageID);
                            window.history.pushState({}, '', `/takeoff/${project.uuid}/${newPageID}`);
                        }}
                    >
                        <IconCaretRight size={24} stroke={1} />
                    </div>

                    <Tooltip delayShow={1000} anchorSelect='#next-page-button' place='top'>
                        Next page (Alt + Right Arrow)
                    </Tooltip>
                </div>

                <div className='takeoffnavbar-buttons-conatiner'>
                    <div className='takeoffnavbar-scale-container'>
                        <Popup
                            trigger={open => (
                                <div
                                    id='takeoffnavbar-scale-text'
                                    className='takeoffnavbar-scale-text'
                                    style={{
                                        color: pages?.[pageID]?.scale ? '#000' : 'red',
                                    }}
                                    onClick={() => setShowScaleMenu(prev => !prev)}
                                >
                                    <IconRulerMeasure size={20} stroke={1} />
                                    <nobr>
                                        {pages?.[pageID]?.scale
                                            ? pages?.[pageID]?.width_inches && pages?.[pageID]?.height_inches
                                                ? pages?.[pageID]?.scale * pages?.[pageID]?.width / pages?.[pageID]?.width_inches > 1000 || pages?.[pageID]?.scale * pages?.[pageID]?.width / pages?.[pageID]?.width_inches < 0.01
                                                    ? (pages?.[pageID]?.scale * pages?.[pageID]?.width / pages?.[pageID]?.width_inches).toExponential(2) + ' ft/in'
                                                    : (pages?.[pageID]?.scale * pages?.[pageID]?.width / pages?.[pageID]?.width_inches).toFixed(2) + ' ft/in'
                                                : pages?.[pageID]?.scale > 1000 || pages?.[pageID]?.scale < 0.01
                                                    ? pages?.[pageID]?.scale?.toExponential(2) + ' ft/px'
                                                    : pages?.[pageID]?.scale?.toFixed(2) + ' ft/px'
                                            : 'No scale'}
                                    </nobr>
                                </div>
                            )}
                            on=''
                            open={showScaleMenu}
                            onClose={() => setShowScaleMenu(false)}
                            position="bottom center"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            contentStyle={{
                                width: '240px',
                            }}
                        >
                            <div className='takeoffnavbar-scale-menu-container'>
                                <div className='takeoffnavbar-scale-menu-header'>
                                    <div className='takeoffnavbar-scale-menu-title'>
                                        Scale
                                    </div>

                                    <div
                                        className='default-button default-button-small'
                                        onClick={() => {
                                            handleTurnOffDrawing();
                                            setCurrentMeasurement(null);
                                            setDrawingScale(true);
                                        }}
                                    >
                                        Draw manually
                                    </div>
                                </div>

                                <div className='takeoffnavbar-scale-menu-scales'>
                                    <div className='takeoffnavbar-scale-menu-scales-left'>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(3, 64, 1, 0)}
                                        >
                                            3/64" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 32, 1, 0)}
                                        >
                                            1/32" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 16, 1, 0)}
                                        >
                                            1/16" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(3, 32, 1, 0)}
                                        >
                                            3/32" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 8, 1, 0)}
                                        >
                                            1/8" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(3, 16, 1, 0)}
                                        >
                                            3/16" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 4, 1, 0)}
                                        >
                                            1/4" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(3, 8, 1, 0)}
                                        >
                                            3/8" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 2, 1, 0)}
                                        >
                                            1/2" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(3, 4, 1, 0)}
                                        >
                                            3/4" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 1, 0)}
                                        >
                                            1" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 2, 2, 0)}
                                        >
                                            1 1/2" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(2, 1, 1, 0)}
                                        >
                                            2" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(3, 1, 1, 0)}
                                        >
                                            3" = 1' 0"
                                        </div>
                                    </div>
                                    <div className='takeoffnavbar-scale-menu-scales-right'>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 1, 0)}
                                        >
                                            1" = 1' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 2, 0)}
                                        >
                                            1" = 2' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 5, 0)}
                                        >
                                            1" = 5' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 10, 0)}
                                        >
                                            1" = 10' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 20, 0)}
                                        >
                                            1" = 20' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 30, 0)}
                                        >
                                            1" = 30' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 40, 0)}
                                        >
                                            1" = 40' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 50, 0)}
                                        >
                                            1" = 50' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 60, 0)}
                                        >
                                            1" = 60' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 70, 0)}
                                        >
                                            1" = 70' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 80, 0)}
                                        >
                                            1" = 80' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 90, 0)}
                                        >
                                            1" = 90' 0"
                                        </div>
                                        <div
                                            className='takeoffnavbar-scale-menu-scales-item'
                                            onClick={() => handleCopyScale(1, 1, 100, 0)}
                                        >
                                            1" = 100' 0"
                                        </div>
                                    </div>
                                </div>

                                {pages[pageID]?.width_inches && pages[pageID]?.height_inches &&
                                    <div className='takeoffnavbar-scale-menu-input-container'>
                                        <div className='takeoffnavbar-scale-menu-header'>
                                            <div className='takeoffnavbar-scale-menu-title'>
                                                Manual input
                                            </div>
                                            <div>
                                                {scaleNumerator && scaleDenominator && scaleFeet && scaleInches &&
                                                    <div
                                                        className='active-button active-button-small'
                                                        onClick={() => handleUpdateScale()}
                                                    >
                                                        Update
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className='takeoffnavbar-scale-menu-inputs'>
                                            <div className='takeoffnavbar-scale-menu-inputs-left'>
                                                <div className='takeoffnavbar-scale-menu-input-wrapper'>
                                                    <input
                                                        type='number'
                                                        className='takeoffnavbar-scale-menu-input'
                                                        value={scaleNumerator}
                                                        onChange={(e) => setScaleNumerator(e.target.value || null)}
                                                    />
                                                </div>
                                                <div className='takeoffnavbar-scale-menu-inputs-middle'>
                                                    /
                                                </div>
                                                <div className='takeoffnavbar-scale-menu-input-wrapper'>
                                                    <input
                                                        type='number'
                                                        className='takeoffnavbar-scale-menu-input'
                                                        value={scaleDenominator}
                                                        onChange={(e) => setScaleDenominator(e.target.value || null)}
                                                    />
                                                    <div>"</div>
                                                </div>
                                            </div>
                                            <div className='takeoffnavbar-scale-menu-inputs-middle'>
                                                =
                                            </div>
                                            <div className='takeoffnavbar-scale-menu-inputs-right'>
                                                <div className='takeoffnavbar-scale-menu-input-wrapper'>
                                                    <input
                                                        type='number'
                                                        className='takeoffnavbar-scale-menu-input'
                                                        value={scaleFeet}
                                                        onChange={(e) => setScaleFeet(e.target.value || null)}
                                                    />
                                                    <div>'</div>
                                                </div>
                                                <div className='takeoffnavbar-scale-menu-input-wrapper'>
                                                    <input
                                                        type='number'
                                                        className='takeoffnavbar-scale-menu-input'
                                                        value={scaleInches}
                                                        onChange={(e) => setScaleInches(e.target.value || null)}
                                                    />
                                                    <div>"</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {Object.values(pages)?.some(p => p.scale && p.id != pageID)
                                    ? <div>
                                        <div className='takeoffnavbar-scale-menu-header'>
                                            <div className='takeoffnavbar-scale-menu-title'>
                                                Import
                                            </div>
                                        </div>

                                        {pages && Object.values(pages)?.filter(p => p.scale && p.id != pageID).map(p =>
                                            <>
                                                <div
                                                    key={p.id}
                                                    className='takeoffnavbar-scale-menu-item'
                                                    id={'takeoffnavbar-scale-menu-item-' + p.id}
                                                    onClick={() => setScale(p)}
                                                    size={'small'}
                                                >
                                                    <div>{p.title.length > 15 ? p.title.substring(0, 15) + '...' : p.title}</div>

                                                    <div>
                                                        {p.width_inches && p.height_inches
                                                            ? p.scale * p.width / p.width_inches > 1000 || p.scale * p.width / p.width_inches < 0.01
                                                                ? (p.scale * p.width / p.width_inches).toExponential(2) + ' ft/in'
                                                                : (p.scale * p.width / p.width_inches).toFixed(2) + ' ft/in'
                                                            : p.scale > 1000 || p.scale < 0.01
                                                                ? p.scale.toExponential(2) + ' ft/px'
                                                                : p.scale.toFixed(2) + ' ft/px'
                                                        }
                                                    </div>
                                                </div>
                                                <Tooltip delayShow={1000} anchorSelect={'#takeoffnavbar-scale-menu-item-' + p.id} place='top'>
                                                    <div>
                                                        Click import scale from this page
                                                    </div>
                                                    <div>
                                                        Title: {p.title}
                                                    </div>
                                                    <div>
                                                        Scale: {p.scale > 1000 || p.scale < 0.01 ? p.scale.toExponential(2) + ' ft/px' : p.scale.toFixed(2) + ' ft/px'}
                                                    </div>
                                                </Tooltip>
                                            </>
                                        )}
                                    </div>
                                    : null
                                }
                            </div>
                        </Popup>
                    </div>
                    <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-scale-text' place='top'>
                        <div>
                            <b>
                                Set scale ({keybinds?.DrawScale?.control ? 'Ctrl + ' : ''}{keybinds?.DrawScale?.shift ? 'Shift + ' : ''}{keybinds?.DrawScale?.key})
                            </b>
                        </div>

                        <small>
                            <div>
                                Measure a length on the drawing to set the scale.
                            </div>
                            <div>
                                Click to start, click again to finish.
                            </div>
                        </small>
                    </Tooltip>

                    <div
                        className='takeoffnavbar-settings-container takeoffnavbar-settings-button'
                        id='takeoffnavbar-settings-button-globalhide'
                        onMouseEnter={() => setGlobalHide(true)}
                        onMouseLeave={() => setGlobalHide(false)}
                    >
                        <IconEyeOff size={20} stroke={1} />
                    </div>

                    <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-settings-button-globalhide' place='top-start'>
                        Hover to hide all measurements
                    </Tooltip>

                    <div className='takeoffnavbar-settings-container'>
                        <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-settings-button-settings' place='top-start'>
                            Settings
                        </Tooltip>

                        <Popup
                            trigger={open => (
                                <div
                                    id='takeoffnavbar-settings-button-settings'
                                    className='takeoffnavbar-settings-button'
                                    style={{
                                        color: open && '#006AFE',
                                    }}
                                >
                                    <IconSettings size={20} stroke={1} />
                                </div>
                            )}
                            on='click'
                            position="bottom right"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                        >
                            <div className='navdropdown-items'>
                                <a href="/settings" target="_blank" className="navdropdown-item-link">
                                    <div className="navdropdown-item">
                                        <IconSettings size={20} stroke={1} />
                                        <div>Settings</div>
                                    </div>
                                </a>

                                <a href="/keybind" target="_blank" className="navdropdown-item-link">
                                    <div className="navdropdown-item">
                                        <IconKeyboard size={20} stroke={1} />
                                        <div>Keybinds</div>
                                    </div>
                                </a>

                                <div
                                    className='navdropdown-item'
                                    onClick={() => handleDownloadPage()}
                                >
                                    <IconDownload size={20} stroke={1} /> Download Page
                                </div>

                                <div
                                    className='navdropdown-item'
                                    onClick={() => handleExportMeasurements()}
                                >
                                    <IconPhotoDown size={20} stroke={1} />
                                    <div>Export Takeoff</div>
                                    <Blocks
                                        visible={exporting}
                                        height="20"
                                        width="20"
                                        radius={10}
                                        color="#006AFE"
                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                    />
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div>
            </div>

            <div
                className='takeoffnavbar-toggle-container-right'
                style={{
                    width: rightSidebarWidth
                }}
            >
                {takeoffSettings?.ai_sidebar_location === 'right' &&
                    <div
                        className='takeoffnavbar-toggle-button'
                        id='takeoffnavbar-settings-button-ai'
                        style={takeoffSettings?.show_ai_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                        onClick={() => {
                            let newTakeoffSettings = { ...takeoffSettings };

                            if (!newTakeoffSettings?.show_ai_sidebar) {
                                newTakeoffSettings.show_ai_sidebar = true;

                                if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                    newTakeoffSettings.show_measurement_sidebar = false
                                }

                                if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.ai_sidebar_location) {
                                    newTakeoffSettings.show_pages_sidebar = false
                                }
                            } else {
                                newTakeoffSettings.show_ai_sidebar = false;
                            }

                            handleChangeTakeoffSettings(newTakeoffSettings);
                        }}
                    >
                        <div><IconRobot size={20} stroke={1} /></div>
                        <div><nobr>AI tools</nobr></div>
                    </div>
                }

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-settings-button-ai' place='top'>
                    Toggle AI sidebar ({keybinds?.ToggleAISidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleAISidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleAISidebar?.key})
                </Tooltip>

                {/*<AIPopup
                    handleUpdatePage={handleUpdatePage}
                    showAIPopup={showAIPopup}
                    setShowAIPopup={setShowAIPopup}
                    projectUUID={projectUUID}
                />*/}

                {takeoffSettings?.pages_sidebar_location === 'right' &&
                    <div
                        className='takeoffnavbar-toggle-button'
                        id='takeoffnavbar-pages-button'
                        style={takeoffSettings?.show_pages_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                        onClick={() => {
                            let newTakeoffSettings = { ...takeoffSettings };

                            if (!newTakeoffSettings?.show_pages_sidebar) {
                                newTakeoffSettings.show_pages_sidebar = true;

                                if (newTakeoffSettings?.measurement_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                    newTakeoffSettings.show_measurement_sidebar = false
                                }

                                if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.pages_sidebar_location) {
                                    newTakeoffSettings.show_ai_sidebar = false
                                }
                            } else {
                                newTakeoffSettings.show_pages_sidebar = false;
                            }

                            handleChangeTakeoffSettings(newTakeoffSettings);
                        }}

                    >
                        <div><IconLayoutGrid size={20} stroke={1} /></div>
                        <div>Pages</div>
                    </div>
                }

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-pages-button' place='top'>
                    Toggle pages sidebar ({keybinds?.TogglePagesSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.TogglePagesSidebar?.shift ? 'Shift + ' : ''}{keybinds?.TogglePagesSidebar?.key})
                </Tooltip>

                {takeoffSettings?.measurement_sidebar_location === 'right' &&
                    <div
                        id='takeoffnavbar-measurements-button'
                        className='takeoffnavbar-toggle-button'
                        style={takeoffSettings?.show_measurement_sidebar ? { backgroundColor: 'aliceblue', color: '#006AFF', zIndex: 3 } : { zIndex: 3 }}
                        onClick={() => {
                            let newTakeoffSettings = { ...takeoffSettings };

                            if (!newTakeoffSettings?.show_measurement_sidebar) {
                                newTakeoffSettings.show_measurement_sidebar = true;

                                if (newTakeoffSettings?.pages_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                    newTakeoffSettings.show_pages_sidebar = false
                                }

                                if (newTakeoffSettings?.ai_sidebar_location === newTakeoffSettings?.measurement_sidebar_location) {
                                    newTakeoffSettings.show_ai_sidebar = false
                                }
                            } else {
                                newTakeoffSettings.show_measurement_sidebar = false;
                            }

                            handleChangeTakeoffSettings(newTakeoffSettings); takeoffSettings?.show_pages_sidebar
                        }}

                    >
                        <div><IconList size={20} stroke={1} /></div>
                        <div>Takeoffs</div>
                    </div>
                }

                <Tooltip delayShow={1000} anchorSelect='#takeoffnavbar-measurements-button' place='top-start'>
                    Toggle measurements sidebar ({keybinds?.ToggleMeasurementSidebar?.control ? 'Ctrl + ' : ''}{keybinds?.ToggleMeasurementSidebar?.shift ? 'Shift + ' : ''}{keybinds?.ToggleMeasurementSidebar?.key})
                </Tooltip>
            </div>
        </div >
    )
}
