import React, { useContext, useState } from "react";
import { Layer, Stage, Line as KonvaLine, Rect, Group, Circle, Shape } from "react-konva";
import { Html, Portal } from 'react-konva-utils';
import { useSelector } from "react-redux";
import axios from "axios";

import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

import ContextMenu from "../../../components/ContextMenu";
import pSBC from "../../helper/Colors";
import { IconArrowSharpTurnRight, IconCircleMinus, IconCopy, IconEaseInControlPoint, IconTrashX, IconZoomScan } from "@tabler/icons-react";

export default function Line({ measurement }) {
    const auth = useSelector(selectAuth);

    const {
        project, setProject,
        pageID,
        pages,
        groups, setGroups,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        takeoffSettings, setTakeoffSettings,
        handleChangeTakeoffSettings,
        drawing,
        handleLineTransform,
        DeleteMeasurement,
        handleZoomToMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        setShowDetails,
        optionDown,
        handleDuplicateMeasurement,
    } = useContext(TakeoffContext);

    const [updating, setUpdating] = useState(false);

    const [start, setStart] = useState(null);
    const [dragging, setDragging] = useState(false);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [prev, setPrev] = useState(null);

    const isSelected = currentMeasurement == measurement?.id;

    const handleCreateDot = (e, index, arc) => {
        if (updating) return;

        setUpdating(true);

        const newMeasurement = {
            ...measurement,
            line_dots: [
                ...measurement.line_dots.slice(0, index + 1),
                {
                    x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                    y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                    arc: arc,
                },
                ...measurement.line_dots.slice(index + 1),
            ]
        }

        handleLineTransform(newMeasurement, false, null, () => setUpdating(false));
    }


    /*the dots in the line might have arcs, we are going to chop up the array of dots into multiple segments and store them in an array of arrays
        each time the dots change from arc to not arc or vice versa, we will create a new array in the dots array
    */

    const dots = [];
    let dotArray = [];
    let arc = measurement?.line_dots?.[0]?.arc;
    measurement?.line_dots?.forEach((dot, index) => {
        if (dot.arc !== arc) {
            if (!dot.arc) {
                let firstDot = dotArray[0];
                dotArray = [
                    {
                        ...measurement.line_dots[firstDot.index - 1],
                        index: firstDot.index - 1
                    },
                    ...dotArray,
                    {
                        ...measurement.line_dots[index],
                        index: index
                    }
                ]
            }

            dots.push(dotArray);
            dotArray = [];
            arc = dot.arc;
        }
        dotArray.push({
            ...dot,
            index: index
        });
    });

    dots.push(dotArray);

    return (
        <>
            <Html>
                {showContextMenu &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        {/*<div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Rename
                        </div>
                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Group
                        </div>
                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set height
                        </div>
                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set pitch
                        </div>*/}
                        <div
                            className='contextmenu-item'
                            onClick={() => handleDuplicateMeasurement(measurement)}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>Duplicate</div>
                        </div>

                        <div
                            className='contextmenu-item'
                            onClick={() => handleZoomToMeasurement()}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>Zoom</div>
                        </div>
                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => DeleteMeasurement(measurement)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>Delete</div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                <Group
                    draggable={isSelected}
                    onContextMenu={(e) => {
                        if (currentMeasurement !== measurement.id) {
                            setSelectedMeasurements([measurement.id]);
                        }
                        setCurrentMeasurement(measurement.id);

                        e.evt.preventDefault();
                        setContextMenuPosition({
                            x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                            y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                        })
                        setShowContextMenu(true);
                    }}
                    onMouseDown={(e) => {
                        setCurrentMeasurement(measurement.id);
                    }}
                    onMouseUp={(e) => {
                        if (optionDown) {
                            setTimeout(() => {
                                const element = document.getElementById(`measurement-${measurement.id}-name`)
                                if (element) element.click();
                            }, 100);
                        }

                        if (e.evt.metaKey) {
                            if (selectedMeasurements.find(m => m === measurement.id)) {
                                setSelectedMeasurements(selectedMeasurements.filter(m => m !== measurement.id));
                            } else {
                                setSelectedMeasurements([...selectedMeasurements, measurement.id]);
                            }
                        } else {
                            setSelectedMeasurements([measurement.id]);
                            setCurrentMeasurement(measurement.id);
                        }

                        handleChangeTakeoffSettings({
                            ...takeoffSettings,
                            show_measurement_sidebar: true,
                            show_pages_sidebar: takeoffSettings.pages_sidebar_location === takeoffSettings.measurement_sidebar_location
                                ? false : takeoffSettings.show_pages_sidebar,
                            show_ai_sidebar: takeoffSettings.ai_sidebar_location === takeoffSettings.measurement_sidebar_location
                                ? false : takeoffSettings.show_ai_sidebar,
                        })
                        setShowDetails(true);

                        setTimeout(() => {
                            const element = document.getElementById("measurement-" + measurement.id);
                            if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top > window.innerHeight)) {
                                element.scrollIntoView();
                            }
                        }, 100);

                        e.target.getStage().container().style.cursor = "pointer";
                    }}
                    onDragStart={(e) => {
                        setStart({ x: e.target.x(), y: e.target.y() });
                        setDragging(true);
                        setPrev({ ...measurement });
                    }}
                    onDragEnd={(e) => {
                        setDragging(false);
                        let dx = e.target.x() - start.x;
                        let dy = e.target.y() - start.y;

                        const newMeasurement = {
                            ...measurement,
                            line_dots: measurement.line_dots.map((point) => ({
                                ...point,
                                x: point.x + dx,
                                y: point.y + dy,
                            })),
                        }

                        e.target.x(0);
                        e.target.y(0);

                        if (updating) return;
                        setUpdating(true);
                        handleLineTransform(newMeasurement, false, prev, () => setUpdating(false));
                    }}
                >
                    {dots?.map((dotArray, index) => {
                        if (dotArray.length > 1) {
                            if (dotArray.some(dot => dot.arc)) {
                                return (
                                    <>
                                        <Shape
                                            key={index + 'line'}
                                            sceneFunc={(ctx, shape) => {
                                                ctx.beginPath();
                                                ctx.moveTo(dotArray[0].x, dotArray[0].y);

                                                for (var i = 1; i < dotArray.length - 2; i++) {
                                                    var xc = (dotArray[i].x + dotArray[i + 1].x) / 2;
                                                    var yc = (dotArray[i].y + dotArray[i + 1].y) / 2;
                                                    ctx.quadraticCurveTo(dotArray[i].x, dotArray[i].y, xc, yc);
                                                }

                                                ctx.quadraticCurveTo(
                                                    dotArray[i].x,
                                                    dotArray[i].y,
                                                    dotArray[i + 1].x,
                                                    dotArray[i + 1].y
                                                );
                                                ctx.fillStrokeShape(shape);
                                            }}
                                            strokeWidth={measurement.size / pages[pageID].zoom}
                                            stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement?.color) : measurement.color}
                                            opacity={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 1 : 0.5}
                                            shadowColor={pSBC(-0.25, measurement.color)}
                                            //shadowBlur={isSelected ? 15 / pages[pageID].zoom : 0}
                                            dash={measurement.gap ? [measurement.gap * 2, measurement.gap] : []}
                                            lineJoin="round"
                                            onMouseOut={(e) => {
                                                e.target.getStage().container().style.cursor = "default";
                                            }}
                                            onMouseUp={(e) => {
                                                e.target.getStage().container().style.cursor = "pointer";
                                            }}
                                            onMouseDown={(e) => {
                                                if (isSelected) {
                                                    e.target.getStage().container().style.cursor = "grabbing";
                                                } else {
                                                    e.target.getStage().container().style.cursor = "default";
                                                }
                                            }}
                                            onMouseOver={(e) => {
                                                if (isSelected) {
                                                    e.target.getStage().container().style.cursor = "pointer";
                                                } else {
                                                    e.target.getStage().container().style.cursor = "default";
                                                }
                                            }}
                                            perfectDrawEnabled={false}
                                        />

                                        <Shape
                                            key={index + 'shadow'}
                                            sceneFunc={(ctx, shape) => {
                                                ctx.beginPath();
                                                ctx.moveTo(dotArray[0].x, dotArray[0].y);

                                                for (var i = 1; i < dotArray.length - 2; i++) {
                                                    var xc = (dotArray[i].x + dotArray[i + 1].x) / 2;
                                                    var yc = (dotArray[i].y + dotArray[i + 1].y) / 2;
                                                    ctx.quadraticCurveTo(dotArray[i].x, dotArray[i].y, xc, yc);
                                                }

                                                // curve through the last two points
                                                ctx.quadraticCurveTo(
                                                    dotArray[i].x,
                                                    dotArray[i].y,
                                                    dotArray[i + 1].x,
                                                    dotArray[i + 1].y
                                                );
                                                ctx.fillStrokeShape(shape);
                                            }}
                                            strokeWidth={10 / pages[pageID].zoom}
                                            //shadowBlur={isSelected ? 25 / pages[pageID].zoom : 0}
                                            shadowColor={measurement.color ? measurement.color : 'lightblue'}
                                            stroke={measurement.color ? measurement.color : 'lightblue'}
                                            opacity={isSelected ? 0.3 : 0.05}
                                            lineJoin="round"
                                            onMouseOver={(e) => {
                                                e.target.getStage().container().style.cursor = "pointer";
                                            }}
                                            onMouseOut={(e) => {
                                                e.target.getStage().container().style.cursor = "default";
                                            }}
                                            perfectDrawEnabled={false}
                                        />

                                        {/*isSelected && dotArray.map((dot, i) => {
                                            if (i !== dotArray.length - 1 && i !== 0) {
                                                if (dotArray[i - 1].arc && dotArray[i + 1].arc) {
                                                    return (
                                                        <Shape
                                                            sceneFunc={(ctx, shape) => {
                                                                ctx.beginPath();
                                                                let xc1 = (dotArray[i].x + dotArray[i - 1].x) / 2;
                                                                let yc1 = (dotArray[i].y + dotArray[i - 1].y) / 2;

                                                                ctx.moveTo(xc1, yc1);

                                                                var xc = (dotArray[i].x + dotArray[i + 1].x) / 2;
                                                                var yc = (dotArray[i].y + dotArray[i + 1].y) / 2;
                                                                ctx.quadraticCurveTo(dotArray[i].x, dotArray[i].y, xc, yc);
                                                                ctx.fillStrokeShape(shape);
                                                            }}
                                                            key={i}
                                                            stroke={"blue"}
                                                            strokeWidth={10 / pages[pageID].zoom}
                                                            opacity={0.25}
                                                            onMouseEnter={(e) => {
                                                                e.target.getStage().container().style.cursor = "copy";
                                                            }}
                                                            onMouseOver={(e) => {
                                                                e.target.getStage().container().style.cursor = "copy";
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.getStage().container().style.cursor = "default";
                                                            }}
                                                            onClick={(e) => {
                                                                if (!showContextMenu && !optionDown) {
                                                                    console.log('create dot     ', i);
                                                                    handleCreateDot(e, dot.index - 1);
                                                                }
                                                            }}
                                                            perfectDrawEnabled={false}
                                                        />
                                                    )
                                                } else {
                                                    return (
                                                        <Shape
                                                            sceneFunc={(ctx, shape) => {
                                                                ctx.beginPath();
                                                                let xc1 = (dotArray[i].x + dotArray[i - 1].x) / 2;
                                                                let yc1 = (dotArray[i].y + dotArray[i - 1].y) / 2;

                                                                if (!dotArray[i - 1].arc) {
                                                                    xc1 = dotArray[i - 1].x;
                                                                    yc1 = dotArray[i - 1].y;
                                                                }

                                                                ctx.moveTo(xc1, yc1);

                                                                var xc = (dotArray[i].x + dotArray[i + 1].x) / 2;
                                                                var yc = (dotArray[i].y + dotArray[i + 1].y) / 2;

                                                                if (!dotArray[i + 1].arc) {
                                                                    xc = dotArray[i + 1].x;
                                                                    yc = dotArray[i + 1].y;
                                                                }

                                                                ctx.quadraticCurveTo(dotArray[i].x, dotArray[i].y, xc, yc);
                                                                ctx.fillStrokeShape(shape);
                                                            }}
                                                            key={i}
                                                            stroke={"blue"}
                                                            strokeWidth={10 / pages[pageID].zoom}
                                                            opacity={0.25}
                                                            onMouseEnter={(e) => {
                                                                e.target.getStage().container().style.cursor = "copy";
                                                            }}
                                                            onMouseOver={(e) => {
                                                                e.target.getStage().container().style.cursor = "copy";
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.getStage().container().style.cursor = "default";
                                                            }}
                                                            onClick={(e) => {
                                                                if (!showContextMenu && !optionDown) {
                                                                    console.log('create dot     ', i);
                                                                    handleCreateDot(e, dot.index - 1);
                                                                }
                                                            }}
                                                            perfectDrawEnabled={false}
                                                        />
                                                    )
                                                }
                                            }
                                        })*/}

                                        {isSelected && dotArray.flatMap((dot, i) => {
                                            if (i !== dotArray.length - 1) {
                                                return (
                                                    <>
                                                        <KonvaLine
                                                            key={i + 'dash' + dot.index}
                                                            points={[dot.x, dot.y, dotArray[i + 1].x, dotArray[i + 1].y]}
                                                            stroke={'gray'}
                                                            strokeWidth={2 / pages[pageID].zoom}
                                                            dash={[5 / pages[pageID].zoom, 5 / pages[pageID].zoom]}
                                                            opacity={0.8}
                                                            lineJoin="round"
                                                            perfectDrawEnabled={false}
                                                        />

                                                        <KonvaLine
                                                            key={i + 'line' + dot.index}
                                                            points={[dot.x, dot.y, dotArray[i + 1].x, dotArray[i + 1].y]}
                                                            stroke={'blue'}
                                                            strokeWidth={10 / pages[pageID].zoom}
                                                            opacity={0}
                                                            lineJoin="round"
                                                            onMouseEnter={(e) => {
                                                                if (updating) {
                                                                    e.target.getStage().container().style.cursor = "wait";
                                                                } else {
                                                                    e.target.getStage().container().style.cursor = "copy";
                                                                }
                                                            }}
                                                            onMouseOver={(e) => {
                                                                if (updating) {
                                                                    e.target.getStage().container().style.cursor = "wait";
                                                                } else {
                                                                    e.target.getStage().container().style.cursor = "copy";
                                                                }
                                                            }}
                                                            onMouseLeave={(e) => {
                                                                e.target.getStage().container().style.cursor = "default";
                                                            }}
                                                            onClick={(e) => {
                                                                if (!showContextMenu && !optionDown) {
                                                                    handleCreateDot(e, dot.index, true);
                                                                }
                                                            }}
                                                            perfectDrawEnabled={false}
                                                        />
                                                    </>
                                                )
                                            }
                                        })}
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <KonvaLine
                                            key={index + 'line'}
                                            strokeWidth={measurement.size / pages[pageID].zoom}
                                            stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement?.color) : measurement.color}
                                            opacity={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 1 : 0.5}
                                            points={dotArray.flatMap((point) => [point.x, point.y])}
                                            shadowColor={pSBC(-0.25, measurement.color)}
                                            //shadowBlur={isSelected ? 15 / pages[pageID].zoom : 0}
                                            dash={measurement.gap ? [measurement.gap * 2, measurement.gap] : []}
                                            lineJoin="round"
                                            onMouseOut={(e) => {
                                                e.target.getStage().container().style.cursor = "default";
                                            }}
                                            onMouseUp={(e) => {
                                                e.target.getStage().container().style.cursor = "pointer";
                                            }}
                                            onMouseDown={(e) => {
                                                if (isSelected) {
                                                    e.target.getStage().container().style.cursor = "grabbing";
                                                } else {
                                                    e.target.getStage().container().style.cursor = "default";
                                                }
                                            }}
                                            onMouseOver={(e) => {
                                                if (isSelected) {
                                                    e.target.getStage().container().style.cursor = "pointer";
                                                } else {
                                                    e.target.getStage().container().style.cursor = "default";
                                                }
                                            }}
                                            perfectDrawEnabled={false}
                                        />

                                        <KonvaLine
                                            key={index + 'shadow'}
                                            strokeWidth={10 / pages[pageID].zoom}
                                            //shadowBlur={isSelected ? 25 / pages[pageID].zoom : 0}
                                            shadowColor={measurement.color ? measurement.color : 'lightblue'}
                                            stroke={measurement.color ? measurement.color : 'lightblue'}
                                            opacity={isSelected ? 0.3 : 0.05}
                                            lineJoin="round"
                                            onMouseOver={(e) => {
                                                e.target.getStage().container().style.cursor = "pointer";
                                            }}
                                            onMouseOut={(e) => {
                                                e.target.getStage().container().style.cursor = "default";
                                            }}
                                            points={dotArray.flatMap((point) => [point.x, point.y])}
                                            perfectDrawEnabled={false}
                                        />

                                        {isSelected && dotArray.flatMap((dot, i) => {
                                            if (i !== dotArray.length - 1) {
                                                return (
                                                    <KonvaLine
                                                        key={i + 'line' + dot.index}
                                                        stroke={"blue"}
                                                        strokeWidth={10 / pages[pageID].zoom}
                                                        opacity={0}
                                                        onMouseEnter={(e) => {
                                                            if (updating) {
                                                                e.target.getStage().container().style.cursor = "wait";
                                                            } else {
                                                                e.target.getStage().container().style.cursor = "copy";
                                                            }
                                                        }}
                                                        onMouseOver={(e) => {
                                                            if (updating) {
                                                                e.target.getStage().container().style.cursor = "wait";
                                                            } else {
                                                                e.target.getStage().container().style.cursor = "copy";
                                                            }
                                                        }}
                                                        onMouseLeave={(e) => {
                                                            e.target.getStage().container().style.cursor = "default";
                                                        }}
                                                        points={[dot.x, dot.y, dotArray[i + 1].x, dotArray[i + 1].y]}
                                                        onClick={(e) => {
                                                            if (!showContextMenu && !optionDown) {
                                                                handleCreateDot(e, dot.index, false);
                                                            }
                                                        }}
                                                        perfectDrawEnabled={false}
                                                    />
                                                )
                                            }
                                        })}
                                    </>
                                )
                            }
                        }
                    })}

                    {/*
                    <KonvaLine
                        strokeWidth={measurement.size / pages[pageID].zoom}
                        stroke={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? pSBC(-0.8, measurement?.color) : measurement.color}
                        opacity={(isSelected || selectedMeasurements.find(m => m === measurement.id)) ? 1 : 0.5}
                        points={measurement.line_dots.flatMap((point) => [point.x, point.y])}
                        shadowColor={pSBC(-0.25, measurement.color)}
                        shadowBlur={isSelected ? 15 / pages[pageID].zoom : 0}
                        dash={measurement.gap ? [measurement.gap * 2, measurement.gap] : []}
                        lineJoin="round"
                        onMouseOut={(e) => {
                            e.target.getStage().container().style.cursor = "default";
                        }}
                        onMouseUp={(e) => {
                            e.target.getStage().container().style.cursor = "pointer";
                        }}
                        onMouseDown={(e) => {
                            if (isSelected) {
                                e.target.getStage().container().style.cursor = "grabbing";
                            } else {
                                e.target.getStage().container().style.cursor = "default";
                            }
                        }}
                        onMouseOver={(e) => {
                            if (isSelected) {
                                e.target.getStage().container().style.cursor = "pointer";
                            } else {
                                e.target.getStage().container().style.cursor = "default";
                            }
                        }}
                        perfectDrawEnabled={false}
                    />
                    <KonvaLine
                        strokeWidth={10 / pages[pageID].zoom}
                        shadowBlur={isSelected ? 25 / pages[pageID].zoom : 0}
                        shadowColor={measurement.color ? measurement.color : 'lightblue'}
                        stroke={measurement.color ? measurement.color : 'lightblue'}
                        opacity={isSelected ? 0.3 : 0.05}
                        lineJoin="round"
                        onMouseOver={(e) => {
                            e.target.getStage().container().style.cursor = "pointer";
                        }}
                        onMouseOut={(e) => {
                            e.target.getStage().container().style.cursor = "default";
                        }}
                        points={measurement.line_dots.flatMap((point) => [point.x, point.y])}
                        perfectDrawEnabled={false}
                    />

                    {isSelected && measurement.line_dots.flatMap((dot, index) => {
                        if (index !== measurement.line_dots.length - 1) {
                            return (
                                <KonvaLine
                                    key={index}
                                    stroke={"blue"}
                                    strokeWidth={10 / pages[pageID].zoom}
                                    opacity={0}
                                    onMouseEnter={(e) => {
                                        e.target.getStage().container().style.cursor = "copy";
                                    }}
                                    onMouseOver={(e) => {
                                        e.target.getStage().container().style.cursor = "copy";
                                    }}
                                    onMouseLeave={(e) => {
                                        e.target.getStage().container().style.cursor = "default";
                                    }}
                                    points={[dot.x, dot.y, measurement.line_dots[index + 1].x, measurement.line_dots[index + 1].y]}
                                    onClick={(e) => {
                                        if (!showContextMenu && !optionDown) {
                                            handleCreateDot(e, index);
                                        }
                                    }}
                                    perfectDrawEnabled={false}
                                />
                            )
                        }
                    })}

                */}
                </Group>

                {isSelected && !dragging && measurement.line_dots.flatMap((dot, index) =>
                    <LengthVertex
                        key={index + 'dot'}
                        dot={dot}
                        index={index}
                        measurement={measurement}
                        updating={updating}
                        setUpdating={setUpdating}
                    />
                )}
            </Portal>
        </>
    );
}

const LengthVertex = ({ dot, index, measurement, updating, setUpdating }) => {
    const {
        pageID,
        pages,
        groups, setGroups,
        handleLineTransform,
        updateMeasurementInState,
        DeleteMeasurement,
        handleZoomToMeasurement,
        currentMeasurement, setCurrentMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        handleDuplicateMeasurement,
    } = useContext(TakeoffContext);

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });

    const [dragging, setDragging] = useState(false);
    const [fill, setFill] = useState("white");

    const [prev, setPrev] = useState(null);

    return (
        <>
            <Html>
                {showContextMenu &&
                    <ContextMenu
                        x={contextMenuPosition.x}
                        y={contextMenuPosition.y}
                        zoom={pages[pageID].zoom}
                        showContextMenu={showContextMenu}
                        setShowContextMenu={setShowContextMenu}
                    >
                        {/*<div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Rename
                        </div>
                        <div
                            className="contextmenu-item"
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Group
                        </div>*/}
                        {index > 0 && index < measurement.line_dots.length - 1 &&
                            <div
                                className="contextmenu-item"
                                onClick={() => {
                                    const newMeasurement = {
                                        ...measurement,
                                        line_dots: [
                                            ...measurement.line_dots.slice(0, index),
                                            {
                                                ...measurement.line_dots[index],
                                                arc: !dot.arc,
                                            },
                                            ...measurement.line_dots.slice(index + 1),
                                        ]
                                    }

                                    handleLineTransform(newMeasurement, prev);
                                }}
                            >
                                {dot?.arc
                                    ? <><IconArrowSharpTurnRight size={16} stroke={1} />
                                        <div>Set vertex</div>
                                    </>
                                    : <><IconEaseInControlPoint size={16} stroke={1} />
                                        <div>Set arc</div>
                                    </>
                                }
                            </div>
                        }
                        {/*<div
                            className='contextmenu-item'
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set height
                        </div>
                        <div
                            className='contextmenu-item'
                            onClick={() => {
                                document.getElementById(`measurement-${measurement.id}`).dispatchEvent(new MouseEvent('contextmenu', { bubbles: true }));
                            }}
                        >
                            Set pitch
                        </div>*/}

                        <div
                            className='contextmenu-item'
                            onClick={() => handleDuplicateMeasurement(measurement)}
                        >
                            <IconCopy size={16} stroke={1} />
                            <div>Duplicate</div>
                        </div>

                        <div
                            className='contextmenu-item'
                            onClick={() => handleZoomToMeasurement()}
                        >
                            <IconZoomScan size={16} stroke={1} />
                            <div>Zoom</div>
                        </div>
                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => {
                                if (measurement.line_dots.length > 2) {
                                    const newMeasurement = {
                                        ...measurement,
                                        line_dots: [
                                            ...measurement.line_dots.slice(0, index),
                                            ...measurement.line_dots.slice(index + 1),
                                        ]
                                    }

                                    handleLineTransform(newMeasurement, prev);
                                }
                            }}
                        >
                            <IconCircleMinus size={16} stroke={1} />
                            <div>Delete dot</div>
                        </div>
                        <div
                            className="contextmenu-item contextmenu-item-delete"
                            onClick={() => DeleteMeasurement(measurement)}
                        >
                            <IconTrashX size={16} stroke={1} />
                            <div>Delete</div>
                        </div>
                    </ContextMenu>
                }
            </Html>

            {dot.arc &&
                <Circle
                    opacity={dragging ? 0 : 0.3}
                    fill={measurement.color ? measurement.color : 'lightblue'}
                    x={dot.x}
                    y={dot.y}
                    radius={10.0 / pages[pageID].zoom}
                    shadowColor={measurement.color ? measurement.color : 'lightblue'}
                />
            }

            <Circle
                opacity={dragging ? 0 : 0.7}
                fill={fill}
                x={dot.x}
                y={dot.y}
                radius={5.0 / pages[pageID].zoom}
                strokeWidth={2.0 / pages[pageID].zoom}
                stroke={pSBC(-0.8, measurement.color)}
                draggable
                onDragStart={(e) => {
                    setDragging(true);
                    setPrev({ ...measurement })
                }}
                onDragEnd={(e) => {
                    let new_x = e.target.x()
                    let new_y = e.target.y()

                    const newMeasurement = {
                        ...measurement,
                        line_dots: [
                            ...measurement.line_dots.slice(0, index),
                            {
                                ...measurement.line_dots[index],
                                x: new_x,
                                y: new_y,
                            },
                            ...measurement.line_dots.slice(index + 1),
                        ]
                    }

                    setDragging(false);

                    if (updating) return;
                    setUpdating(true);
                    handleLineTransform(newMeasurement, false, prev, () => setUpdating(false));
                }}
                onDragMove={(e) => {
                    const newMeasurement = {
                        ...measurement,
                        line_dots: [
                            ...measurement.line_dots.slice(0, index),
                            {
                                ...measurement.line_dots[index],
                                x: e.target.x(),
                                y: e.target.y(),
                            },
                            ...measurement.line_dots.slice(index + 1),
                        ]
                    }

                    updateMeasurementInState(newMeasurement);
                }}
                onContextMenu={(e) => {
                    if (currentMeasurement !== measurement.id) {
                        setSelectedMeasurements([measurement.id]);
                        setCurrentMeasurement(measurement.id);
                    }

                    e.evt.preventDefault();
                    setContextMenuPosition({
                        x: (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom,
                        y: (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom,
                    })
                    setShowContextMenu(true);
                    setDragging(false);
                }}
                onMouseEnter={(e) => {
                    setFill(measurement.color ? measurement.color : 'lightblue');
                    e.target.getStage().container().style.cursor = "pointer";
                }}
                onMouseOver={(e) => {
                    setFill(measurement.color ? measurement.color : 'lightblue');
                    e.target.getStage().container().style.cursor = "pointer";
                }}
                onMouseDown={(e) => {
                    setDragging(true);
                    e.target.getStage().container().style.cursor = "none";
                }}
                onMouseUp={(e) => {
                    setDragging(false);
                    e.target.getStage().container().style.cursor = "pointer";
                }}
                onMouseOut={(e) => {
                    setFill("white");
                    e.target.getStage().container().style.cursor = "default";
                }}
                perfectDrawEnabled={false}
            />
        </>
    )
}