import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, updateAuth } from '../redux/slices/authSlice';
import { useEffect, useState, useMemo, createRef } from 'react';
import { API_ROUTE } from '..';
import { axiosCurry, eventValueLens, mergeFromLens, responseDataLens } from '../utilities/utilities';
import { lensProp, compose, pipe, mergeDeepRight, over, mergeDeepLeft, isNil, andThen, view, set, toPairs, keys, map, tap, not, ifElse } from 'ramda';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

const remoteLens = lensProp('remote');
const backendLens = compose(remoteLens, lensProp('backend'));
const isAuthorizedLens = compose(backendLens, lensProp('is_authorized'));
const companiesLens = compose(backendLens, lensProp('companies'));
const contractorsLens = compose(backendLens, lensProp('contractors'));
const currentContractorLens = compose(backendLens, lensProp('current_contractor'));
const secondaryViewsLens = lensProp('secondaryViews');
const currentBillingCompanyIDLens = compose(secondaryViewsLens, lensProp('currentBillingCompanyID'));
const stripeCurrentCustomerLens = compose(remoteLens, lensProp('stripeCurrentCustomer'));
const stripeCurrentCustomerNameLens = compose(stripeCurrentCustomerLens, lensProp('name'));
const stripeCurrentCustomerEmailLens = compose(stripeCurrentCustomerLens, lensProp('email'));
const stripeCurrentCustomerAddressLens = compose(stripeCurrentCustomerLens, lensProp('address'));
const stripeCurrentCustomerCityLens = compose(stripeCurrentCustomerAddressLens, lensProp('city'));
const stripeCurrentCustomerCountryLens = compose(stripeCurrentCustomerAddressLens, lensProp('country'));
const stripeCurrentCustomerLine1Lens = compose(stripeCurrentCustomerAddressLens, lensProp('line1'));
const stripeCurrentCustomerLine2Lens = compose(stripeCurrentCustomerAddressLens, lensProp('line2'));
const stripeCurrentCustomerPostalCodeLens = compose(stripeCurrentCustomerAddressLens, lensProp('postal_code'));
const stripeCurrentCustomerStateLens = compose(stripeCurrentCustomerAddressLens, lensProp('state'));
const stripeInvoicesObjectLens = compose(remoteLens, lensProp('stripeInvoicesObject'));
const stripeInvoicesLens = compose(stripeInvoicesObjectLens, lensProp('data'));

const initialState = {
  remote: {
    backend: { is_authorized: false, companies: {}, contractors: {}, current_contractor: null },
    stripeCurrentCustomer: { name: '', email: '', address: { city: '', country: '', line1: '', line2: '', postal_code: '', state: '' } },
    stripeInvoicesObject: { data: [] },
  },
  secondaryViews: { currentBillingCompanyID: null },
};
const getContractorLens = (userID) => compose(contractorsLens, lensProp(userID));
const getContractorFirstNameLens = (userID) => compose(getContractorLens(userID), lensProp('first_name'));
const getContractorLastNameLens = (userID) => compose(getContractorLens(userID), lensProp('last_name'));
const getContractorCompanyLens = (userID) => compose(getContractorLens(userID), lensProp('company'));
const getContractorIsAdminLens = (userID) => compose(getContractorLens(userID), lensProp('is_admin'));
const getCompanyLens = (companyID) => compose(companiesLens, lensProp(companyID));
const getCompanyNameLens = (companyID) => compose(getCompanyLens(companyID), lensProp('name'));
const getCompanyTierLens = (companyID) => compose(getCompanyLens(companyID), lensProp('tier'));
const getCompanyCustomerStripeIDLens = (companyID) => compose(getCompanyLens(companyID), lensProp('customer_stripe_id'));
const getCompanyShouldOverridePaymentStatusLens = (companyID) => compose(getCompanyLens(companyID), lensProp('should_override_payment_status'));

const fullGetCurrentCompanyStripeIDLens = (state) => pipe(view(currentBillingCompanyIDLens), getCompanyCustomerStripeIDLens)(state);

const clearStripeCurrentCustomer = pipe(
  set(stripeCurrentCustomerLens)({}),
  set(stripeCurrentCustomerNameLens)(''),
  set(stripeCurrentCustomerEmailLens)(''),
  set(stripeCurrentCustomerLine1Lens)(''),
  set(stripeCurrentCustomerLine2Lens)(''),
  set(stripeCurrentCustomerCityLens)(''),
  set(stripeCurrentCustomerStateLens)(''),
  set(stripeCurrentCustomerPostalCodeLens)(''),
  set(stripeCurrentCustomerCountryLens)(''),
  set(stripeInvoicesObjectLens)({}),
  set(stripeInvoicesLens)([])
);
const closeBillingInfo = pipe(set(currentBillingCompanyIDLens)(null), clearStripeCurrentCustomer);

const SuperAdmin = () => {
  const auth = useSelector(selectAuth);

  const superAdminAPI = useMemo(() => axiosCurry(`${API_ROUTE}/api/super-admin-api/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }), [API_ROUTE, auth.token]);
  const superAdminStripeAPI = useMemo(
    () => axiosCurry(`${API_ROUTE}/api/super-admin-stripe-api/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }),
    [API_ROUTE, auth.token]
  );

  const currentContractorElement = createRef();
  const [state, setState] = useState(initialState);
  const viewState = (lens) => view(lens)(state);

  const handleSuperAdminAPIResponse = pipe(andThen(view(responseDataLens)), andThen(mergeFromLens(backendLens)), andThen(setState));

  // fetch the backend
  useEffect(() => {
    if (!isNil(auth.token)) pipe(superAdminAPI('get'), handleSuperAdminAPIResponse)(null);
  }, []);

  // fetch currently selected company's stripe information
  useEffect(() => {
    if (!viewState(currentBillingCompanyIDLens)) return;
    else if (!pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state)) {
      setState(clearStripeCurrentCustomer);
    } else {
      pipe(
        superAdminStripeAPI('post'),
        andThen(view(responseDataLens)),
        andThen(mergeFromLens(stripeCurrentCustomerLens)),
        andThen(setState)
      )({ type: 'get_customer', id: pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state) });
      pipe(
        superAdminStripeAPI('post'),
        andThen(view(responseDataLens)),
        andThen(mergeFromLens(stripeInvoicesObjectLens)),
        andThen(setState)
      )({ type: 'get_invoices_by_customer', id: pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state) });
    }
  }, [viewState(currentBillingCompanyIDLens), pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state)]);

  if (!viewState(isAuthorizedLens))
    return (
      <div className='w-screen h-screen flex flex-col justify-center items-center'>
        <span className='text-4xl'>403 Forbidden: Must be a Bobyard Admin to access this page</span>
      </div>
    );

  return (
    <>
      <div className='flex flex-col gap-8'>
        <div className='max-h-96 w-fit flex flex-col'>
          <div className='flex flex-row justify-between'>
            <span className='text-xl font-medium'>Contractors</span>
            <button className='text-xs w-24 h-auto text-white bg-blue-500' onClick={() => currentContractorElement.current.scrollIntoView({ behavior: 'smooth' })}>
              Scroll to current user
            </button>
          </div>
          <div className='overflow-scroll grow-1'>
            <table>
              <thead>
                <tr>
                  <th scope='col' className='p-2'>
                    Contactor Name
                  </th>
                  <th scope='col' className='p-2'>
                    Contractor Company
                  </th>
                  <th scope='col' className='p-2'>
                    Admin of Company?
                  </th>
                </tr>
              </thead>
              <tbody>
                {pipe(
                  viewState,
                  keys,
                  map((contractorID) => (
                    <tr
                      key={contractorID}
                      className={viewState(currentContractorLens) == contractorID ? 'bg-blue-200' : ''}
                      ref={viewState(currentContractorLens) == contractorID ? currentContractorElement : null}
                    >
                      <td className='p-2'>
                        {pipe(getContractorFirstNameLens, viewState)(contractorID)} {pipe(getContractorLastNameLens, viewState)(contractorID)}
                      </td>
                      <td className='p-2'>
                        <select
                          value={pipe(getContractorCompanyLens, viewState)(contractorID)}
                          onChange={pipe(
                            view(eventValueLens),
                            Number,
                            tap((companyID) => superAdminAPI('patch')({ type: 'change_company', contractor_id: contractorID, company_id: companyID })),
                            (companyID) => pipe(getContractorCompanyLens, (lens) => set(lens)(companyID), setState)(contractorID)
                          )}
                        >
                          {pipe(
                            viewState,
                            keys,
                            map((companyID) => (
                              <option key={companyID} value={companyID}>
                                {pipe(getCompanyNameLens, viewState)(companyID)}
                              </option>
                            ))
                          )(companiesLens)}
                        </select>
                      </td>
                      <td className='p-2'>
                        <input
                          type='checkbox'
                          checked={pipe(getContractorIsAdminLens, viewState)(contractorID)}
                          onChange={() =>
                            pipe(
                              getContractorIsAdminLens,
                              viewState,
                              not,
                              tap((is_admin) => superAdminAPI('patch')({ type: 'modify_contractors', changes: { [contractorID]: { is_admin } } })),
                              set(getContractorIsAdminLens(contractorID)),
                              setState
                            )(contractorID)
                          }
                        />
                      </td>
                    </tr>
                  ))
                )(contractorsLens)}
              </tbody>
            </table>
          </div>
        </div>
        <div className='max-h-96 w-fit flex flex-col'>
          <div className='font-medium text-xl'>Companies</div>
          <div className='overflow-scroll grow-1'>
            <table>
              <thead>
                <tr>
                  <th scope='col' className='p-2'>
                    Company Name
                  </th>
                  <th scope='col' className='p-2'>
                    Payment Tier
                  </th>
                  <th scope='col' className='p-2'>
                    Has Stripe customer been setup?
                  </th>
                  <th scope='col' className='p-2'>
                    Overriding payment status?
                  </th>
                  <th scope='col' className='p-2'>
                    Stripe Billing
                  </th>
                </tr>
              </thead>
              <tbody>
                {pipe(
                  viewState,
                  keys,
                  map((companyID) => (
                    <tr key={companyID}>
                      <td className='p-2'>{pipe(getCompanyNameLens, viewState)(companyID)}</td>
                      <td className='p-2'>
                        <select
                          value={pipe(getCompanyTierLens, viewState)(companyID)}
                          onChange={pipe(
                            view(eventValueLens),
                            tap((tier) => superAdminAPI('patch')({ type: 'modify_companies', changes: { [companyID]: { tier } } })),
                            (tier) => pipe(getCompanyTierLens, (lens) => set(lens)(tier), setState)(companyID)
                          )}
                        >
                          <option value='free'>Free</option>
                          <option value='paid'>Paid</option>
                        </select>
                      </td>
                      <td className='p-2'>
                        {pipe(
                          getCompanyCustomerStripeIDLens,
                          viewState,
                          ifElse(
                            isNil,
                            () => <span className='bg-red-500'>NO</span>,
                            () => <span className='bg-green-500'>YES</span>
                          )
                        )(companyID)}
                      </td>
                      <td className='p-2'>
                        <input
                          checked={pipe(getCompanyShouldOverridePaymentStatusLens, viewState)(companyID)}
                          type='checkbox'
                          onChange={() =>
                            pipe(
                              getCompanyShouldOverridePaymentStatusLens,
                              viewState,
                              not,
                              tap((should_override_payment_status) => superAdminAPI('patch')({ type: 'modify_companies', changes: { [companyID]: { should_override_payment_status } } })),
                              set(getCompanyShouldOverridePaymentStatusLens(companyID)),
                              setState
                            )(companyID)
                          }
                        />
                      </td>
                      <td className='p-2' key={companyID}>
                        <button onClick={() => pipe(set(currentBillingCompanyIDLens), setState)(companyID)}>see billing info</button>
                      </td>
                    </tr>
                  ))
                )(companiesLens)}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal show={pipe(viewState, isNil, not)(currentBillingCompanyIDLens)} onHide={() => setState(closeBillingInfo)}>
        <ModalHeader>Billing info for {pipe(viewState, getCompanyNameLens, viewState)(currentBillingCompanyIDLens)}</ModalHeader>
        <ModalBody className='flex flex-col gap-8'>
          <div className=' border-solid border border-black flex flex-col'>
            <div className='text-2xl bg-gray-200'>Customer Billing Info</div>
            <div>
              Name: <input value={viewState(stripeCurrentCustomerNameLens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerNameLens), setState)} />
            </div>
            <div>
              Email: <input value={viewState(stripeCurrentCustomerEmailLens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerEmailLens), setState)} />
            </div>
            <div>
              Line 1: <input value={viewState(stripeCurrentCustomerLine1Lens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerLine1Lens), setState)}></input>
            </div>
            <div>
              Line 2: <input value={viewState(stripeCurrentCustomerLine2Lens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerLine2Lens), setState)}></input>
              <div>
                City: <input value={viewState(stripeCurrentCustomerCityLens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerCityLens), setState)}></input>
              </div>
              <div>
                State: <input value={viewState(stripeCurrentCustomerStateLens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerStateLens), setState)}></input>
              </div>
              <div>
                Postal Code: <input value={viewState(stripeCurrentCustomerPostalCodeLens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerPostalCodeLens), setState)}></input>
              </div>
              <div>
                Country (2-digit ISO): <input value={viewState(stripeCurrentCustomerCountryLens)} onChange={pipe(view(eventValueLens), set(stripeCurrentCustomerCountryLens), setState)}></input>
              </div>
            </div>
            {!!pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state) && <JsonView collapsed={true} src={viewState(stripeCurrentCustomerLens)} />}
            <div className='flex flex-row gap-2 justify-end bg-gray-200'>
              {!!pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state) && (
                <>
                  <button
                    className='bg-blue-500'
                    onClick={() =>
                      superAdminStripeAPI('patch')({
                        type: 'modify_customer',
                        properties: viewState(stripeCurrentCustomerLens),
                        id: pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state),
                      })
                    }
                  >
                    update customer info
                  </button>
                  <button
                    className='bg-red-500'
                    onClick={() => pipe(superAdminAPI('post'), handleSuperAdminAPIResponse)({ type: 'delete_stripe_customer', company_id: viewState(currentBillingCompanyIDLens) })}
                  >
                    delete customer
                  </button>
                </>
              )}
              {!pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state) && (
                <button
                  className='bg-blue-500'
                  onClick={() =>
                    pipe(
                      superAdminAPI('post'),
                      handleSuperAdminAPIResponse
                    )({
                      type: 'create_stripe_customer',
                      company_id: viewState(currentBillingCompanyIDLens),
                      properties: viewState(stripeCurrentCustomerLens),
                    })
                  }
                >
                  create customer
                </button>
              )}
            </div>
          </div>
          {pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state) && (
            <div className=' border-solid border border-black flex flex-col'>
              <div className='text-2xl bg-gray-200'>Invoices</div>
              <div className='flex flex-col gap-2'>
                {pipe(
                  viewState,
                  map((invoice) => {
                    const invoiceID = invoice.id;
                    const invoiceStatus = invoice.status;
                    return (
                      <div className='border-4 border-solid border-black'>
                        <div>ID: {invoiceID}</div>
                        <div>Status: {invoiceStatus}</div>
                        <div>Subtotal: {invoice.subtotal}</div>
                        <div>Amount Due: {invoice.amount_due}</div>
                        <div>Amount Paid: {invoice.amount_paid}</div>
                        <div>Amount Remaining: {invoice.amount_remaining}</div>
                        <div>Due Date: {invoice.due_date}</div>

                        <JsonView collapsed={true} src={invoice} />
                        <div className='flex flex-row gap-2 justify-end'>
                          {invoiceStatus == 'draft' && (
                            <button
                              className='bg-red-500'
                              disabled={invoiceStatus != 'draft'}
                              onClick={() =>
                                pipe(
                                  superAdminStripeAPI('post'),
                                  andThen(view(responseDataLens)),
                                  andThen(mergeFromLens(stripeInvoicesObjectLens)),
                                  andThen(setState)
                                )({ type: 'delete_invoice', id: invoiceID })
                              }
                            >
                              delete invoice
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })
                )(stripeInvoicesLens)}
              </div>
              <div className='flex flex-row gap-2 justify-end bg-gray-200'>
                <button
                  className='bg-blue-500'
                  onClick={() => {
                    pipe(
                      superAdminStripeAPI('post'),
                      andThen(view(responseDataLens)),
                      andThen(mergeFromLens(stripeInvoicesObjectLens)),
                      andThen(setState)
                    )({ type: 'create_invoice', id: pipe(fullGetCurrentCompanyStripeIDLens, viewState)(state) });
                  }}
                >
                  create invoice
                </button>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SuperAdmin;
