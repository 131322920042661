import React, { useCallback, useEffect, useState } from 'react';

import {
    Label,
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ReferenceArea,
    ResponsiveContainer,
} from 'recharts';
import DefaultButton from '../../components/DefaultButton';

export default function Graph({ dataIn, names, title, allowCumulative }) {
    const [data, setData] = useState([]);
    const [dataName, setDataName] = useState(names?.[0])

    const [cumulative, setCumulative] = useState(false);

    const [refAreaLeft, setRefAreaLeft] = useState('');
    const [refAreaRight, setRefAreaRight] = useState('');
    const [left, setLeft] = useState('dataMin');
    const [right, setRight] = useState('dataMax');
    const [top, setTop] = useState(10000);
    const [bottom, setBottom] = useState(0);

    useEffect(() => {
        if (dataIn && names) {
            if (dataIn && !data.length) {
                setData(JSON.parse(JSON.stringify(dataIn)));
            }

            let bestName = names[0];

            names.forEach(n => {
                if (dataIn.reduce((max, item) => Math.max(max, item[n]), 1) > dataIn.reduce((max, item) => Math.max(max, item[bestName]), 1)) {
                    bestName = n;
                }
            })

            setDataName(bestName);
            //setTop(Math.round(dataIn[0][bestName] * 1.1) + 1)
            setTop(Math.round(dataIn.reduce((max, item) => Math.max(max, item[bestName]), 1) * 1.1));
        }
    }, [dataIn, names]);

    const getAxisYDomain = (from, to, offset) => {
        const refData = data.slice(from - 1, to);
        let [bottom, top] = [refData[0][0], refData[0][0]];

        let bestName = names[0];

        names.forEach(n => {
            if (refData.reduce((max, item) => Math.max(max, item[n]), 1) > refData.reduce((max, item) => Math.max(max, item[bestName]), 1)) {
                bestName = n;
            }
        })

        setDataName(bestName)

        return [0, Math.round(dataIn.reduce((max, item) => Math.max(max, item[bestName]), 1) * 1.1)];
    };

    const zoom = () => {
        if (refAreaLeft === refAreaRight || refAreaRight === '') {
            setRefAreaLeft('');
            setRefAreaRight('');
            return;
        }

        let [refAreaLeftTemp, refAreaRightTemp] = [refAreaLeft, refAreaRight];

        // xAxis domain
        if (refAreaLeft > refAreaRight) {
            [refAreaLeftTemp, refAreaRightTemp] = [refAreaRight, refAreaLeft];
        }

        // yAxis domain
        const [bottomTemp, topTemp] = getAxisYDomain(refAreaLeftTemp, refAreaRightTemp, 1);

        setData(data.slice());
        setRefAreaLeft('');
        setRefAreaRight('');
        setLeft(refAreaLeftTemp);
        setRight(refAreaRightTemp);
        setBottom(bottomTemp);
        setTop(topTemp);
    }

    const zoomOut = () => {
        setData(data.slice());
        setRefAreaLeft('');
        setRefAreaRight('');
        setLeft('dataMin');
        setRight('dataMax');
        setTop(Math.round(dataIn.reduce((max, item) => Math.max(max, item[dataName]), 1) * 1.1));
        setBottom(0);
    }

    return (
        <div className="report-graph-container">
            <div className='report-graph-header'>
                <div className='report-graph-header-title'>
                    {title}
                </div>

                {/*<DefaultButton
                    text="Zoom Out"
                    handleClick={zoomOut}
                />*/}

                {allowCumulative &&
                    <DefaultButton
                        text={cumulative ? "Cumulative" : "Normal"}
                        handleClick={() => {
                            if (cumulative) {
                                setData(JSON.parse(JSON.stringify(dataIn)));
                            } else {
                                const DataInCopy = JSON.parse(JSON.stringify(dataIn));
                                let newData = DataInCopy.slice();

                                let totals = { ...newData[0] };
                                newData[0] = { ...newData[0] }

                                for (var i = 1; i < newData.length; i++) {
                                    for (var name of names) {
                                        totals[name] += newData[i][name];
                                        newData[i][name] = totals[name];
                                    }
                                }

                                setData(newData.slice());
                            }

                            setCumulative(prev => !prev);
                        }}
                    />
                }
            </div>

            <ResponsiveContainer width="100%" height={200}>
                <LineChart
                    data={data}
                    //onMouseDown={(e) => setRefAreaLeft(e.activeLabel)}
                    //onMouseMove={(e) => refAreaLeft && setRefAreaRight(e.activeLabel)}
                    //onMouseUp={zoom}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        allowDataOverflow={false}
                        dataKey="id"
                        //domain={[left, right]}
                        type="number"
                        reversed={true}
                        allowDecimals={false}
                    />
                    <YAxis
                        allowDataOverflow={false}
                        dataKey={dataName}
                        //domain={[bottom, top]}
                        allowDecimals={false}
                        type="number"
                        yAxisId="1"
                    />
                    <Tooltip />

                    {names?.map(n =>
                        <Line
                            key={n}
                            yAxisId="1"
                            type="monotone"
                            dataKey={n}
                            stroke={n.includes('inference') ? "#006aef" : "#8884d8"}
                            strokeWidth={2}
                            activeDot={{ r: 8 }}
                        />
                    )}

                    {/*refAreaLeft && refAreaRight ? (
                        <ReferenceArea
                            yAxisId="1"
                            x1={refAreaLeft}
                            x2={refAreaRight}
                            strokeOpacity={0.3}
                        />
                    ) : null*/}
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}