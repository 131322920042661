import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

import { selectAuth, updateAuth } from '../../redux/slices/authSlice';

import "../styles/Company.css";

import { API_ROUTE } from '../../index';
import DashboardNavbar from '../../components/DashboardNavbar';
import ProfileSidebar from '../Profile/ProfileSidebar';
import { useParams } from 'react-router-dom';
import ActiveButton from '../../components/ActiveButton';
import NotFoundPage from '../../components/NotFoundPage';

export default function JoinCompany() {
  const dispatch = useDispatch();
  const params = useParams();
  const inviteLink = params.inviteLink;

  const auth = useSelector(selectAuth);

  const [company, setCompany] = useState(null);
  const [contractor, setContractor] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (inviteLink && auth?.user?.id) {
      axios({
        method: 'get',
        url: `${API_ROUTE}/api/join-company/`,
        params: {
          inviteLink: inviteLink,
          userID: auth?.user?.id
        },
        headers: {
          "Content-Type": "application/json"
        }
      })
        .then((response) => {
          console.log(response);

          if (response.data?.status === 'error') {
            setError(response.data.error);
          } else if (response.data?.status === 'registered') {
            setCompany(response.data.company);
            setContractor(response.data.contractor);
          } else if (response.data?.status === 'joined') {
            window.location.assign('/dashboard');
          } else {
            setCompany(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [inviteLink, auth]);

  const handleJoin = () => {
    axios({
      method: 'put',
      url: `${API_ROUTE}/api/contractors/${contractor?.id}/`,
      data: {
        ...contractor,
        company: company?.id,
        is_admin: false,
      },
      headers: {
        "Authorization": `Token ${auth?.token}`,
        "Content-Type": "application/json"
      }
    })
      .then((response) => {
        console.log(response);

        dispatch(updateAuth({
          user: auth.user,
          contractor: {
            ...auth.contractor,
            company: company.id,
            is_admin: false,
          },
          token: auth.token,
        }));

        window.location.assign('/dashboard');
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (error) {
    return (
      <NotFoundPage />
    )
  }

  return (
    <>
      <DashboardNavbar auth={auth} />
      <div className='profile-container'>
        <div className='profile-header'>
          <div className='profile-title'>
            Join company on Bobyard
          </div>

        </div>

        <div>
          (Note, you can only be in one company at a time. If you join a new company, you will leave your current company.)
        </div>


        <div className='profile-details-container'>
          Name: {company?.name}
          <br></br>
          <br></br>
        </div>

        <div>
          <ActiveButton
            text="Join"
            handleClick={() => handleJoin()}
          />
        </div>
      </div>
    </>
  );
}

