import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TakeoffContext } from "../../helper/Context";

import { Modal } from "react-bootstrap";
import axios from "axios";

import { selectAuth } from "../../../redux/slices/authSlice";
import { API_ROUTE } from "../../../index";
import DefaultButton from "../../../components/DefaultButton";
import ActiveButton from "../../../components/ActiveButton";

export default function ScaleModal({
    inches, setInches,
    feet, setFeet,
    scaleStart,
    scaleEnd,
    handleSaveScale,
    handleCancelScale,
    showScaleModal, setShowScaleModal,
}) {
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                if (feet || inches) {
                    handleSaveScale();
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [feet, inches]);

    return (
        <Modal show={showScaleModal} onHide={() => setShowScaleModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Enter Scale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    The line you drew is <b>{(Math.sqrt(Math.pow(scaleStart.x - scaleEnd.x, 2) + Math.pow(scaleStart.y - scaleEnd.y, 2))).toFixed(0)}</b> pixels long. Please give the length of the corresponding line.
                </div>

                <div className='takeoff-scalemodal-inputs'>
                    <div>
                        <div>
                            <b>Feet</b>
                        </div>

                        <input
                            type="number"
                            className='takeoff-scalemodal-input'
                            placeholder='Feet'
                            value={feet}
                            onChange={(e) => setFeet(e.target.value)}
                            min={0}
                        />
                    </div>

                    <div>
                        <div>
                            <b>Inches</b>
                        </div>

                        <input
                            type="number"
                            className='takeoff-scalemodal-input'
                            placeholder='Inches'
                            value={inches}
                            onChange={(e) => setInches(e.target.value)}
                            min={0}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DefaultButton
                    text='Cancel'
                    handleClick={() => handleCancelScale()}
                />

                {feet || inches ?
                    <ActiveButton
                        text='Save'
                        handleClick={() => handleSaveScale()}
                    />
                : null}
            </Modal.Footer>
        </Modal>
    );
}