import { useDraggable } from "@dnd-kit/core";
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import RectangleMeasurement from './RectangleMeasurement';
import PolygonMeasurement from './PolygonMeasurement';
import LineMeasurement from './LineMeasurement';
import CountMeasurement from './CountMeasurement';
import CircleMeasurement from './CircleMeasurement';

export default function Measurement({ measurement, showModal, setShowModal }) {
    if (!measurement) return null;

    /*const { attributes, listeners, setNodeRef, transform } = useDraggable({
        id: measurement.id,
    });

    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: 'measurement-' + measurement.id });

    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    } : undefined;

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };*/

    return (
        <>
            {measurement.type === 'rectangle' &&
                <RectangleMeasurement key={measurement.id} measurement={measurement} showModal={showModal} setShowModal={setShowModal} />
            }
            {measurement.type === 'polygon' &&
                <PolygonMeasurement key={measurement.id} measurement={measurement} showModal={showModal} setShowModal={setShowModal} />
            }
            {measurement.type === 'line' &&
                <LineMeasurement key={measurement.id} measurement={measurement} showModal={showModal} setShowModal={setShowModal} />
            }
            {measurement.type === 'count' &&
                <CountMeasurement key={measurement.id} measurement={measurement} showModal={showModal} setShowModal={setShowModal} />
            }
            {measurement.type === 'circle' &&
                <CircleMeasurement key={measurement.id} measurement={measurement} showModal={showModal} setShowModal={setShowModal} />
            }
        </>
    )
}