import { lensPath, pipe, view, set, isNil } from 'ramda';
import { useState } from 'react';
import { useEffect } from 'react';
import { eventValueLens } from '../Lenses';
import { SketchPicker } from 'react-color';
import Popup from 'reactjs-popup';

const initialState = { value: '' };
const valueLens = lensPath(['value']);

const ColorField = ({ onBlur = () => {}, onClick = () => {}, value }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(set(valueLens)(isNil(value) ? '#000000' : value));
  }, [value]);
  return <SketchPicker className="my-4" disableAlpha={true} color={view(valueLens)(state)} onChangeComplete={({ hex }) => onBlur(hex)} onChange={({ hex }) => setState(set(valueLens)(hex))} />;
};

export default ColorField;
