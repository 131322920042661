import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import PropTypes from 'prop-types';

import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';

import { selectAuth, updateAuth } from '../redux/slices/authSlice';

import { API_ROUTE } from '../index';

import "./styles/Login.css";

export default function Login({ prevPath }) {
  const dispatch = useDispatch();

  const auth = useSelector(selectAuth);

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState("");

  const routeChange = () => {
    let path = '/';
    if (prevPath != null && prevPath.pathname) { //!null if you want to redirect to desired url entered before login in
      path = prevPath.pathname;
    }
    else {
      path = '/dashboard';
    }

    window.location.assign(path);
  }

  useEffect(() => {
    if (auth?.user?.username) {
      routeChange();
    }
  }, [auth, prevPath]);

  const handleSubmit = (e) => {
    e.preventDefault();
    var form_data = { username: email, password: password };

    axios({
      method: 'post',
      url: `${API_ROUTE}/api-token-auth/`,
      data: form_data,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((tokenresponse) => {
        axios({
          method: 'post',
          url: `${API_ROUTE}/api/login/`,
          data: {
            'token': tokenresponse.data.token,
            'email': email
          },
          headers: { 'Content-Type': 'application/json' },
        })
          .then((response) => {
            console.log(response.data);

            dispatch(updateAuth({
              user: response.data.user,
              contractor: response.data.contractor,
              token: tokenresponse.data.token,
            }));

            routeChange(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setError("Incorrect email or password.");
        console.log(error);

        setEmail("");
        setPassword("");
      });
  }

  return (
    <div className="login-wrapper">
      <a href="/">
        <div className='login-logo-container'>
          <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png"} className='login-logo-image' />
        </div>
      </a>

      <div
        className='login-title'
      >Sign in to Bobyard</div>

      <div className="login-container">
        <div className="error">{error}</div>
        <input
          className='inputlogin'
          value={email}
          onChange={e => setEmail(e.target.value)}
          type="email"
          placeholder="Email"
        />
        <input
          value={password}
          onChange={e => setPassword(e.target.value)}
          type="password"
          className='inputlogin'
          placeholder="Password"
        />
      </div>

      <button
        type="submit"
        className={email && password
          ? "active-button login-button"
          : "default-button login-button login-button-disabled"
        }
        disabled={!(email && password)}
        onClick={(e) => handleSubmit(e)}
      >
        Login
      </button>

      <div className='login-footer-container'>
        <div>
          Don't have an account? <a className='register-link' href="/register">Sign up</a>
        </div>

        <div>
          Forgot your password? <a className='register-link' href="/reset-password-request">Reset password</a>
        </div>
      </div>

      {/*<div className='support-footer-container'>
        <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/logo.png"} className='homepage-footer-logoimg' />
        <div>
            <div>Venture backed</div>
            <div>Bobyard, Inc 2023</div>
        </div>  
      </div>*/}
    </div>
  )
}
