import { useState } from "react";
import "./styles/Demo2.css"
import LandingNavbar from "./LandingNavbar";

export default function Meeting() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <div className='demo2-container'>
            <div className='landing4-content'>
                <LandingNavbar />
            </div>
            
            <iframe
                src="https://calendly.com/michaelding/bobyard"
                className="demo2-iframe"
            ></iframe>
        </div>
    )
}