import { lensPath, compose } from 'ramda';
const searchLens = lensPath(['search']);
const searchTermLens = compose(searchLens, lensPath(['searchTerm']));
const stateLens = compose(searchLens, lensPath(['state']));
const countyLens = compose(searchLens, lensPath(['county']));
const zipcodeLens = compose(searchLens, lensPath(['zipcode']));
const pageLens = lensPath(['page']);
const limitLens = compose(searchLens, pageLens, lensPath(['limit']));
const offsetLens = compose(searchLens, pageLens, lensPath(['offset']));
const coordinateLens = compose(searchLens, lensPath(['coordinate']));
const latLens = compose(coordinateLens, lensPath(['lat']));
const lngLens = compose(coordinateLens, lensPath(['lng']));
const onRateSelectedLens = lensPath(['onRateSelected']);

//{
//   search: {
//     state: 'California',
//     county: 'San Francisco County',
//     zipcode: '94582',
//     searchTerm: '',
//     page: { limit: 3, offset: 0 }, //note: offset state is always 0 (never modified), just exists for the sake of the query
//     coordinate: { lat: '', lng: '' },
//   },
// onRateSelected: ({unitCost, laborCost}) => some kind of function here
// }

export {onRateSelectedLens, searchLens, searchTermLens, stateLens, countyLens, zipcodeLens, limitLens, offsetLens, latLens, lngLens };
