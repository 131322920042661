import { pipe, andThen, forEach, reduce, tap } from 'ramda';
import { PDFDocument } from 'pdf-lib';

const andThenAll = (callback) => (promises) => {
  return Promise.all(promises).then(callback);
};

const getPDFDocFromURL = pipe(
  fetch,
  andThen((res) => res.arrayBuffer()),
  andThen(PDFDocument.load)
);

const mergePDFs = async (pdf1, pdf2) => {
  const copiedPages = await pdf1.copyPages(pdf2, pdf2.getPageIndices());
  copiedPages.forEach((page) => pdf1.addPage(page));
  return pdf1;
};

const getBlobFromByteArray = (byte) => new Blob([byte], { type: 'application/pdf' });

const saveBlob = (name) => (blob) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  link.click();
  link.remove();
  return blob;
};

const saveByteArray = (reportName) => (byte) => {
  const blob = new Blob([byte], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  const fileName = reportName;
  link.download = fileName;
  link.click();
};

export { andThenAll, getPDFDocFromURL, mergePDFs, saveByteArray, getBlobFromByteArray, saveBlob };
