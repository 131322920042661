import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Arc, Shape } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import { API_ROUTE } from "../../../index";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import pSBC from "../../helper/Colors";

export default function LineConstructor({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        setDrawingLine,
        groups, setGroups,
        currentGroup,
        selectedGroups,
        createMeasurement,
        createMeasurements,
        history, setHistory,
        currentHistory, setCurrentHistory,
        settings,
        measurements, setMeasurements,
        handleChangeFilter,
    } = useContext(TakeoffContext);

    const [points, setPoints] = useState([]);
    const [nextPoint, setNextPoint] = useState(null);
    const [pointsHistory, setPointsHistory] = useState([]);

    const [showRectangle, setShowRectangle] = useState(false);
    const [rectangleOrientation, setRectangleOrientation] = useState();

    const [showArc, setShowArc] = useState(false);
    const [arcOrientation, setArcOrientation] = useState();
    const [arcAngle, setArcAngle] = useState();

    const [showLine, setShowLine] = useState(false);

    const addLine = (line) => {
        handleChangeFilter('include_length', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'line',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                'hide': false,
                'size': 2,
                ['line_dots']: [...line],
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/line/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup || null,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'dots': line,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                //createMeasurement(response.data, false, tempName);
                createMeasurements(response.data, tempName);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                if (points.length < 2) return;
                addLine(points);
                setPoints([]);
                setNextPoint(null);
                setShowArc(false);
                setShowRectangle(false);
                setShowLine(false);
            }

            if (e.key === "Delete" || e.key === "Backspace") {
                if (points.length > 0) {
                    e.stopPropagation();
                    let mostRecentDot = points[points.length - 1];
                    setPoints(prev => prev.slice(0, -1));
                    setPointsHistory(prev => [...prev, mostRecentDot]);
                }
            } else if (e.key === "Tab") {
                if (pointsHistory.length > 0) {
                    e.stopPropagation();
                    let mostRecentDot = pointsHistory[pointsHistory.length - 1];
                    setPoints(prev => [...prev, mostRecentDot]);
                    setPointsHistory(prev => prev.slice(0, -1));
                }
            } else if (e.key === "a" || e.key === "A") {
                e.stopPropagation();
                console.log(e);
                setPoints(prev => {
                    let newPoints = [...prev];
                    if (newPoints.length < 2) return newPoints;
                    newPoints[newPoints.length - 2] = { ...newPoints[newPoints.length - 2], arc: !newPoints[newPoints.length - 2].arc };
                    return newPoints;
                })
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [points, pointsHistory]);

    const dots = [];
    let dotArray = [];
    let arc = points?.[0]?.arc;
    points?.forEach((dot, index) => {
        if (dot.arc !== arc) {
            if (!dot.arc) {
                let firstDot = dotArray[0];
                dotArray = [
                    {
                        ...points[firstDot.index - 1],
                        index: firstDot.index - 1
                    },
                    ...dotArray,
                    {
                        ...points[index],
                        index: index
                    }
                ]
            }

            dots.push(dotArray);
            dotArray = [];
            arc = dot.arc;
        }
        dotArray.push({
            ...dot,
            index: index
        });
    });

    dots.push(dotArray);

    return (
        <>
            {points.length > 0 &&
                <Line
                    strokeWidth={2 / pages[pageID].zoom}
                    stroke={pSBC(-0.8, groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3')}
                    opacity={0.5}
                    lineJoin="round"
                    points={points.flatMap((point) => [point.x, point.y]).concat([nextPoint?.x, nextPoint?.y])}
                />
            }

            {dots?.map((dotArray, index) => {
                if (dotArray.length > 1) {
                    if (dotArray.some(dot => dot.arc)) {
                        return (
                            <>
                                <Shape
                                    key={index + 'line'}
                                    sceneFunc={(ctx, shape) => {
                                        ctx.beginPath();
                                        ctx.moveTo(dotArray[0].x, dotArray[0].y);

                                        for (var i = 1; i < dotArray.length - 2; i++) {
                                            var xc = (dotArray[i].x + dotArray[i + 1].x) / 2;
                                            var yc = (dotArray[i].y + dotArray[i + 1].y) / 2;
                                            ctx.quadraticCurveTo(dotArray[i].x, dotArray[i].y, xc, yc);
                                        }

                                        ctx.quadraticCurveTo(
                                            dotArray[i].x,
                                            dotArray[i].y,
                                            dotArray[i + 1].x,
                                            dotArray[i + 1].y
                                        );
                                        ctx.fillStrokeShape(shape);
                                    }}
                                    strokeWidth={2 / pages[pageID].zoom}
                                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                                    opacity={0.5}
                                    lineJoin="round"
                                    closed={true}
                                    perfectDrawEnabled={false}
                                />
                            </>
                        )
                    } else {
                        return (
                            <>
                                <Line
                                    key={index + 'line'}
                                    strokeWidth={2 / pages[pageID].zoom}
                                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                                    opacity={0.0}
                                    lineJoin="round"
                                    closed={true}
                                    points={dotArray.flatMap((point) => [point.x, point.y])}
                                    perfectDrawEnabled={false}
                                />
                            </>
                        )
                    }
                }
            })}

            {showLine && points.length > 0 &&
                <Line
                    strokeWidth={1 / pages[pageID].zoom}
                    dash={[5, 5]}
                    points={[points[points.length - 1].x - 100, points[points.length - 1].y, points[points.length - 1].x + 100, points[points.length - 1].y]}
                    stroke={pSBC(-0.8, groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3')}
                />
            }

            {showRectangle && points.length > 0 &&
                <Rect
                    x={points[points.length - 1].x}
                    y={points[points.length - 1].y}
                    width={10 / pages[pageID].zoom}
                    height={10 / pages[pageID].zoom}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    strokeWidth={1 / pages[pageID].zoom}
                    opacity={0.5}
                    rotation={rectangleOrientation}
                />
            }

            {showArc && points.length > 0 &&
                <Arc
                    x={points[points.length - 1].x}
                    y={points[points.length - 1].y}
                    innerRadius={0}
                    outerRadius={10 / pages[pageID].zoom}
                    fill={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
                    strokeWidth={1 / pages[pageID].zoom}
                    opacity={0.5}
                    angle={arcAngle}
                    rotation={arcOrientation}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+length.svg') 8 24, auto"}
                //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"}
                onDblClick={(e) => {
                    if (points.length <= 2) return;
                    addLine(points.slice(0, -1));
                    setPoints([]);
                    setNextPoint(null);
                    setShowArc(false);
                    setShowRectangle(false);
                    setShowLine(false);
                }}
                onClick={(e) => {
                    if (nextPoint) {
                        setPoints(prev => prev.concat({ x: nextPoint?.x, y: nextPoint?.y, arc: false }));
                    } else {
                        let x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        let y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setPoints(prev => prev.concat({ x: x, y: y, arc: false }));
                    }
                    setPointsHistory([]);
                    setShowRectangle(false);
                }}
                onMouseMove={(e) => {
                    setShowRectangle(false);
                    setShowArc(false);
                    setShowLine(false);

                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    if (points.length < 1 || !settings.angle_snap) {
                        setNextPoint({
                            x: x,
                            y: y
                        });
                        return;
                    }

                    const angleSnap = settings.angle_snap_distance;

                    const angle = Math.atan2(y - points[points.length - 1].y, x - points[points.length - 1].x) * 180 / Math.PI;
                    const x2 = x - points[points.length - 1].x;
                    const y2 = y - points[points.length - 1].y;
                    const hypotenuse = Math.sqrt(x2 * x2 + y2 * y2);
                    const angle3 = Math.round((Math.atan2(y2, x2) * 180 / Math.PI) / 45) * 45;

                    if (settings?.angle_snap_90 && Math.abs(angle) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(0);
                        setNextPoint({ x, y: points[points.length - 1].y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 90) < angleSnap || Math.abs(angle + 270) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(90);
                        setNextPoint({ x: points[points.length - 1].x, y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 180) < angleSnap || Math.abs(angle + 180) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(180);
                        setNextPoint({ x, y: points[points.length - 1].y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_90 && Math.abs(angle - 270) < angleSnap || Math.abs(angle + 90) < angleSnap) {
                        setShowRectangle(true);
                        setRectangleOrientation(270);
                        setNextPoint({ x: points[points.length - 1].x, y });
                        setShowLine(true);
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 45) < angleSnap || Math.abs(angle + 315) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(0);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 135) < angleSnap || Math.abs(angle + 225) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(180 - 45);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 225) < angleSnap || Math.abs(angle + 135) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(180);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (settings?.angle_snap_45 && Math.abs(angle - 315) < angleSnap || Math.abs(angle + 45) < angleSnap) {
                        setShowArc(true);
                        setArcOrientation(-45);
                        setArcAngle(45);
                        setShowLine(true);
                        setNextPoint({ x: points[points.length - 1].x + hypotenuse * Math.cos(angle3 * Math.PI / 180), y: points[points.length - 1].y + hypotenuse * Math.sin(angle3 * Math.PI / 180) });
                    } else if (points.length > 1) {

                        //constant variables needed for calculations
                        const prev_x1 = points[points.length - 1].x - points[points.length - 2].x;
                        const prev_y1 = points[points.length - 1].y - points[points.length - 2].y;
                        const prev_x2 = x - points[points.length - 1].x;
                        const prev_y2 = y - points[points.length - 1].y;
                        const prev_hypotenuse1 = Math.sqrt(prev_x1 * prev_x1 + prev_y1 * prev_y1);
                        const prev_hypotenuse2 = Math.sqrt(prev_x2 * prev_x2 + prev_y2 * prev_y2);
                        const prev_angle1 = Math.atan2(prev_y1, prev_x1) * 180 / Math.PI;
                        const prev_angle2 = Math.atan2(prev_y2, prev_x2) * 180 / Math.PI;
                        const prev_angle3 = Math.round((Math.atan2(prev_y2, prev_x2) * 180 / Math.PI) / 45) * 45;

                        //snap to 90, 180, 270, 360, 45, 135, 225, 315 of the angle made between (next point, previous point) and (previous point, previous previous point)
                        if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2) < angleSnap || Math.abs(prev_angle1 - prev_angle2 - 360) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 360) < angleSnap) {
                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(prev_angle1 * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(prev_angle1 * Math.PI / 180) });
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 90) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 270) < angleSnap) {
                            let angle = prev_angle1 + 270;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowRectangle(true);
                            setRectangleOrientation(angle - 90);
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 180) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 180) < angleSnap) {
                            setNextPoint({ x, y });
                        } else if (settings?.angle_snap_90_prev && Math.abs(prev_angle1 - prev_angle2 - 270) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 90) < angleSnap) {
                            let angle = prev_angle1 + 90;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowRectangle(true);
                            setRectangleOrientation(angle);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 45) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 315) < angleSnap) {
                            let angle = prev_angle1 + 315;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle - 135);
                            setArcAngle(180 - 45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 135) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 225) < angleSnap) {
                            let angle = prev_angle1 + 225;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle - 45);
                            setArcAngle(45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 225) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 135) < angleSnap) {
                            let angle = prev_angle1 + 135;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle);
                            setArcAngle(45);
                        } else if (settings?.angle_snap_45_prev && Math.abs(prev_angle1 - prev_angle2 - 315) < angleSnap || Math.abs(prev_angle1 - prev_angle2 + 45) < angleSnap) {
                            let angle = prev_angle1 + 45;
                            if (angle > 360) angle -= 360;
                            if (angle < 0) angle += 360;

                            setNextPoint({ x: points[points.length - 1].x + prev_hypotenuse2 * Math.cos(angle * Math.PI / 180), y: points[points.length - 1].y + prev_hypotenuse2 * Math.sin(angle * Math.PI / 180) });

                            setShowArc(true);
                            setArcOrientation(angle);
                            setArcAngle(180 - 45);
                        } else {
                            setNextPoint({ x, y });
                        }
                    } else {
                        setNextPoint({ x, y });
                    }
                }}
            />

            {points.map((point, index) => (
                <LineVertex
                    key={index}
                    points={points}
                    point={point}
                    setPoints={setPoints}
                    index={index}
                    pageID={pageID}
                    pages={pages}
                    setPages={setPages}
                    groups={groups}
                    currentGroup={currentGroup}
                    nextPoint={nextPoint}
                    setNextPoint={setNextPoint}
                />
            ))}
        </>
    );
}

function LineVertex({
    points,
    point,
    setPoints,
    index,
    pageID,
    pages,
    setPages,
    groups,
    currentGroup,
    nextPoint,
    setNextPoint,
}) {
    const [mouseHover, setMouseHover] = useState(false);

    return (
        <Rect
            key={index}
            x={point.x - 5 / pages[pageID].zoom}
            y={point.y - 5 / pages[pageID].zoom}
            width={10 / pages[pageID].zoom}
            height={10 / pages[pageID].zoom}
            fill={mouseHover ? '#9DD9F3' : 'white'}
            stroke={groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3'}
            strokeWidth={1 / pages[pageID].zoom}
            opacity={0.5}
            draggable={true}
            onDragMove={(e) => {
                const newPoints = [...points];
                newPoints[index] = {
                    ...points[index],
                    x: e.target.x() + 5 / pages[pageID].zoom,
                    y: e.target.y() + 5 / pages[pageID].zoom,
                };
                setPoints(newPoints);
            }}
            onMouseEnter={(e) => {
                e.target.getStage().container().style.cursor = "pointer";
                setMouseHover(true)
            }}
            onMouseLeave={() => setMouseHover(false)}
        />
    )
}
