import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';


import "./styles/Logout.css";
import DefaultButton from '../components/DefaultButton';
import { removeAuth } from '../redux/slices/authSlice';

export default function Logout() {
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();

    dispatch(removeAuth());
  }

  return(
    <div className="logout-wrapper">
      <div>
        <div className='logout-title'>Are you sure you want to log out?</div>
        <DefaultButton 
          text='Logout'
          handleClick={handleLogout}
        />
      </div>
    </div>
  )
}