import { Modal } from 'react-bootstrap';

export default function DeleteModal({ show, onHide, handleDelete, selectedIDs }) {
    const groupCount = new Set(selectedIDs.filter(item => item.includes('group'))).size;
    const entryCount = new Set(selectedIDs.filter(item => item.includes('entry'))).size;
    const assemblyEntryCount = new Set(selectedIDs.filter(item => item.includes('assembly'))).size;

    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header closeButton>
                <div className="database-columns-modal-title">
                    Delete Items
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className="database-columns-modal-body">
                    <div className="database-columns-modal-body-text">
                        Are you sure you want to delete these items? {selectedIDs.find(item => item.includes('group')) ? 'This will also delete all items within the selected groups.' : ''}
                    </div>

                    <div>
                        You currently have selected:
                        <div className="database-columns-modal-body-selected">{groupCount} group{groupCount > 1 || groupCount === 0 ? 's' : ''}</div>
                        <div className="database-columns-modal-body-selected">{entryCount} {entryCount > 1 || entryCount === 0 ? 'entry' : 'entries'}</div>
                        <div className="database-columns-modal-body-selected">{assemblyEntryCount} assembly {assemblyEntryCount > 1 || assemblyEntryCount === 0 ? 'entry' : 'entries'}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="delete-button"
                    onClick={handleDelete}
                >
                    Delete
                </button>
                <button
                    className="default-button"
                    onClick={onHide}
                >
                    Cancel
                </button>
            </Modal.Footer>
        </Modal>
    )
}