import ExcellentExport from 'excellentexport';
import ColorFieldPopup from './components/ColorFieldPopup';
import { getTreeNodeChildrenLens, getTreeNodeNameLens, getTreeNodeTypeLens, nodeNameLens } from './Lenses';
import autoTable from 'jspdf-autotable';
import axios from 'axios';
import {
  applySpec,
  equals,
  isNil,
  join,
  lensIndex,
  lensPath,
  lensProp,
  map,
  mergeAll,
  over,
  pipe,
  replace,
  set,
  split,
  toString,
  view,
  sort,
  indexOf,
  reject,
  addIndex,
  juxt,
  toLower,
  any,
  defaultTo,
  concat,
  andThen,
} from 'ramda';
import jsPDF from 'jspdf';
import {
  EstimateCheckboxField,
  EstimateHintedNumericField,
  EstimateInapplicableElement,
  EstimateNumericElement,
  EstimateNumericField,
  EstimateOverridableNumericField,
  EstimateSelectField,
  EstimateTextElement,
  EstimateTextField,
} from './components/styledcomponents';
import { UOM_CONVERSIONS, UOM_Dependencies, UOM_Display, UOM_Measured_Default } from '../takeoff/helper/UnitConversions';

const axiosCurry = (url) => (headers) => (method) => (data) =>
  axios({
    method,
    url,
    headers,
    data,
  });

const restColumns = [
  'page_title',
  'description',
  'internal_notes',
  'use_group_color',
  'color',
  'count',
  'length',
  'area',
  'uom_measured',
  'uom',
  'quantity1',
  'quantity2',
  'pitch',
  'unit_amount',
  'material_vendor',
  'material_cost',
  'material_cost_extra',
  'material_tax',
  'material_shipping',
  'material_unit_subtotal',
  'material_subtotal',
  'material_markup',
  'material_unit_total',
  'material_total',
  'labor_amount',
  'labor_cost',
  'labor_unit_subtotal',
  'labor_subtotal',
  'labor_markup',
  'labor_unit_total',
  'labor_total',
  'subcontractor_unit_cost',
  'subcontractor_subtotal',
  'subcontractor_markup',
  'subcontractor_unit_total',
  'subcontractor_total',
  'unit_cost',
  'unit_cost_markup',
  'unit_cost_total',
  'subtotal',
  'subtotal_markup',
  'total',
  'cost',
  'profit',
  'margin',
  'total_labor_hours',
  'crew_size',
  'labor_days',
];

const ColumnTypes = {
  name: 'text',
  page_title: 'text',
  description: 'text',
  internal_notes: 'text',
  use_group_color: 'boolean',
  color: 'color',
  count: 'number',
  length: 'number',
  area: 'number',
  uom_measured: 'text',
  uom: 'dropdown',
  quantity1: 'number',
  quantity2: 'text',
  pitch: 'number',
  unit_amount: 'number',
  material_vendor: 'text',
  material_cost_extra: 'number',
  material_cost: 'number',
  material_tax: 'number',
  material_shipping: 'number',
  material_unit_subtotal: 'number',
  material_subtotal: 'number',
  material_markup: 'number',
  material_unit_total: 'number',
  material_total: 'number',
  labor_amount: 'number',
  labor_cost: 'number',
  labor_unit_subtotal: 'number',
  labor_subtotal: 'number',
  labor_markup: 'number',
  labor_unit_total: 'number',
  labor_total: 'number',
  subcontractor_unit_cost: 'number',
  subcontractor_subtotal: 'number',
  subcontractor_markup: 'number',
  subcontractor_unit_total: 'number',
  subcontractor_total: 'number',
  unit_cost: 'number',
  unit_cost_markup: 'number',
  unit_cost_total: 'number',
  subtotal: 'number',
  subtotal_markup: 'number',
  total: 'number',
  cost: 'number',
  profit: 'number',
  margin: 'number',
  total_labor_hours: 'number',
  crew_size: 'number',
  labor_days: 'number',
};

const ColumnLabels = {
  name: 'Name',
  page_title: 'Page Title',
  description: 'Description',
  internal_notes: 'Internal Notes',
  use_group_color: 'Use Group Color',
  color: 'Color',
  count: 'Count (ea)',
  length: 'Length (ft)',
  area: 'Area (ft²)',
  uom_measured: 'UOM Measured',
  uom: 'UOM',
  quantity1: 'Quantity 1',
  quantity2: 'Quantity 2',
  pitch: 'Pitch (in/ft)',
  unit_amount: 'Unit Amount',
  material_vendor: 'Material Vendor',
  material_cost: 'Material Cost ($)',
  material_tax: 'Material Tax (%)',
  material_shipping: 'Material Shipping (%)',
  material_unit_subtotal: 'Material Unit Subtotal ($)',
  material_subtotal: 'Material Subtotal ($)',
  material_markup: 'Material Markup (%)',
  material_cost_extra: 'Material Cost Extra ($)',
  material_unit_total: 'Material Unit Total ($)',
  material_total: 'Material Total ($)',
  labor_amount: 'Labor Amount (hrs)',
  labor_cost: 'Labor Cost ($/hr)',
  labor_unit_subtotal: 'Labor Unit Subtotal ($)',
  labor_subtotal: 'Labor Subtotal ($)',
  labor_markup: 'Labor Markup (%)',
  labor_unit_total: 'Labor Unit Total ($)',
  labor_total: 'Labor Total ($)',
  subcontractor_unit_cost: 'Subcontractor Unit Cost ($)',
  subcontractor_subtotal: 'Subcontractor Subtotal ($)',
  subcontractor_markup: 'Subcontractor Unit Markup (%)',
  subcontractor_unit_total: 'Subcontractor Unit Total ($)',
  subcontractor_total: 'Subcontractor Total ($)',
  unit_cost: 'Unit Cost ($)',
  unit_cost_markup: 'Unit Cost Markup (%)',
  unit_cost_total: 'Unit Cost Total ($)',
  subtotal: 'Subtotal ($)',
  subtotal_markup: 'Subtotal Markup (%)',
  total: 'Total Price ($)',
  cost: 'Total Cost ($)',
  profit: 'Profit ($)',
  margin: 'Margin (%)',
  total_labor_hours: 'Total Labor Hours',
  crew_size: 'Crew Size',
  labor_days: 'Labor Days',
};

const ColumnDescriptions = {
  name: 'Name of the item',
  page_title: 'Title of the page the corresponding measurement is on',
  description: 'Description of the item',
  internal_notes: 'Internal notes on the item',
  use_group_color: "Item will use its parent's color",
  color: 'Color of the measurement',
  count: 'If the measurement type is count, the number of dots',
  length: 'If the measurement type is line, the length of the line',
  area: 'If the measurement type is a rectangle, polygon, or circle, the area of the shape',
  uom_measured: 'Unit of measurement for the measurement (conversion from uom_measured)',
  uom: 'Unit of measurement for the item (conversion to uom)',
  quantity1: 'Quantity of the item (used for UOM conversion)',
  quantity2: 'Quantity of the item (used for UOM conversion)',
  unit_amount: 'Quantity of the item',
  material_vendor: 'Vendor of the material',
  material_cost: 'Material cost per unit',
  material_tax: 'Tax on the material',
  material_shipping: 'Shipping cost of the material',
  material_unit_subtotal: 'Subtotal of material unit cost (material cost + material extra cost) * (1 + material tax) * (1 + material shipping)',
  material_subtotal: 'Subtotal of the material (material unit subtotal * unit amount)',
  material_markup: 'Markup on the material',
  material_cost_extra: 'Extra material cost per unit',
  material_unit_total: 'Total of material unit cost (material unit subtotal * (1 + material markup))',
  material_total: 'Total cost of the material (material unit total * unit amount)',
  labor_amount: 'Amount of labor per unit',
  labor_cost: 'Cost of labor per hour',
  labor_unit_subtotal: 'Subtotal of labor unit cost (labor cost * labor amount)',
  labor_subtotal: 'Subtotal of the labor (labor unit subtotal * unit amount)',
  labor_markup: 'Markup on the labor',
  labor_unit_total: 'Total of labor unit cost (labor unit subtotal * (1 + labor markup))',
  labor_total: 'Total cost of the labor (labor unit total * unit amount)',
  subcontractor_unit_cost: 'Cost of the subcontractor',
  subcontractor_subtotal: 'Subtotal of the subcontractor (subcontractor unit cost * unit amount)',
  subcontractor_markup: 'Markup on the subcontractor',
  subcontractor_unit_total: 'Total unit cost of the subcontractor (subcontractor unit cost * (1 + subcontractor markup))',
  subcontractor_total: 'Total cost of the subcontractor (subcontractor unit total * unit amount)',
  unit_cost: 'Total cost of the unit (material total + labor total + subcontractor total)',
  unit_cost_markup: 'Markup on the unit',
  unit_cost_total: 'Total cost of the unit (unit cost * (1 + unit cost markup))',
  subtotal: 'Subtotal of the unit (material total + labor total + subcontractor total)',
  subtotal_markup: 'Markup on the subtotal',
  total: 'Total price (subtotal * (1 + markup))',
  cost: 'Total cost (material total + labor total + subcontractor total)',
  profit: 'Profit (total - cost)',
  margin: 'Margin (profit / total)',
  total_labor_hours: 'Total labor hours',
  crew_size: 'Size of the crew',
  labor_days: 'Number of days of labor (total labor hours / crew size)',
};

const getColumnHeader = (column) => ColumnLabels[column];
const getColumnDescription = (column) => ColumnDescriptions[column];
const getIsNumeric = (column) => (nodeID) => ColumnTypes[column] == 'number';
const headLens = lensIndex(0);
const addCommas = pipe(split('.'), over(headLens)(replace(/\B(?=(\d{3})+(?!\d))/g)(',')), join('.'));
const numToStr = (num) => (isNil(num) ? '' : pipe(toString, addCommas)(num));
const numToStr2Places = (num) => (isNil(num) ? '' : pipe((num) => num.toFixed(2), addCommas)(num));

const generateSortFunction = (getPrimaryValue) => (column) => (id1, id2) => {
  const value1 = getPrimaryValue(column)(id1);
  const value2 = getPrimaryValue(column)(id2);
  if (isNil(value1)) return -1;
  if (isNil(value2)) return 1;
  return value1 < value2 ? -1 : 1;
};

const getDirectionalizedSortFunction = (isAscending) => (sortFunction) => (id1, id2) => isAscending ? sortFunction(id1, id2) : sortFunction(id2, id1);

const dfs = (getChildren) => (nodeID) => {
  const children = getChildren(nodeID);
  return [nodeID, ...children.flatMap(dfs(getChildren))];
};

const getDepths = (getChildren) => (startDepth) => (nodeID) => {
  const children = getChildren(nodeID);
  return mergeAll([{ [nodeID]: startDepth }, ...children.map(getDepths(getChildren)(startDepth + 1))]);
};

const sortNodeChildren = (sortFunction) => (node) => {
  const childrenLens = lensProp('children');
  return view(childrenLens)(node) ? over(childrenLens)(sort(sortFunction))(node) : node;
};

const getDepthFromDepthDict = (depthDict) => (nodeID) => depthDict[nodeID] || 0;

const dndNodeIDLens = lensPath(['id']);
const dndNodeCanHaveChildrenLens = lensPath(['canHaveChildren']);
const dndNodeChildrenLens = lensPath(['children']);
const assembleDndTreeFromTreeData = (getChildren) => (nodeID) =>
  pipe(set(dndNodeIDLens)(nodeID), set(dndNodeChildrenLens)(pipe(getChildren, map(assembleDndTreeFromTreeData(getChildren)))(nodeID)))({});
const applyToDndNodeSubtree = (f) => (dndNode) => {
  const appliedToChildren = view(dndNodeChildrenLens)(dndNode).map(applyToDndNodeSubtree(f));
  return f(set(dndNodeChildrenLens)(appliedToChildren)(dndNode));
};
const populateCanHaveChildren = (getIsGroup) => (dndNode) => set(dndNodeCanHaveChildrenLens)(getIsGroup(view(dndNodeIDLens)(dndNode)))(dndNode);

const getIndexOfNodeIDFromAssembledDndTree = (nodeID) => (assembledDndTree) => {
  const currLevelIndex = pipe(map(view(dndNodeIDLens)), indexOf(nodeID))(assembledDndTree);
  if (currLevelIndex != -1) return currLevelIndex;
  const subtreeLevelIndex = pipe(map(view(dndNodeChildrenLens)), reject(isNil), map(getIndexOfNodeIDFromAssembledDndTree(nodeID)), reject(equals(-1)))(assembledDndTree)[0];
  return isNil(subtreeLevelIndex) ? currLevelIndex : subtreeLevelIndex;
};

const safeReject = (f) => (arr) => arr ? reject(f)(arr) : arr;
const mapIndexed = addIndex(map);

const getAllBetweenElements = (elem1) => (elem2) => (arr) => {
  const elem1Index = arr.indexOf(elem1);
  const elem2Index = arr.indexOf(elem2);
  const startIndex = Math.min(elem1Index, elem2Index);
  const endIndex = Math.max(elem1Index, elem2Index);
  if (startIndex == -1 || endIndex == -1) return [];
  return arr.slice(startIndex, endIndex + 1);
};

const getHighestAncestor = (getChildren) => (ancestorSet) => (nodeID) => {
  if (ancestorSet.has(nodeID)) return [nodeID];
  return getChildren(nodeID).flatMap(getHighestAncestor(getChildren)(ancestorSet));
};

const getFieldType = (column) => (nodeID) => ColumnTypes[column];

const fullGetIsShown = (getIsColumnShownItem) => (getIsColumnShownGroup) => (getNodeType) => (column) => (nodeID) => {
  if (getNodeType(nodeID) == 'group') return getIsColumnShownGroup(column);
  return getIsColumnShownItem(column);
};

const fullGetRoutedField = (getIsShown) => (getValue) => (getIsCalculated) => (getIsApplicable) => (getIsEditable) => (getFieldType) => (column) => (nodeID) => {
  if (!getIsApplicable(column)(nodeID) || !getIsShown(column)(nodeID)) return EstimateInapplicableElement;
  if (column == 'quantity1') return EstimateHintedNumericField;
  if (column == 'quantity2') {
    if ((getValue('uom_measured')(nodeID) || UOM_Measured_Default[getValue('type')(nodeID)]) == 'ft2' && getValue('uom')(nodeID) == 'ea') return EstimateSelectField;
    return EstimateHintedNumericField;
  }
  const fieldType = getFieldType(column)(nodeID);
  if (getIsEditable(column)(nodeID)) {
    if (fieldType == 'number' && getIsCalculated(column)(nodeID)) return EstimateOverridableNumericField;
    if (fieldType == 'number' && !getIsCalculated(column)(nodeID)) return EstimateNumericField;
    if (fieldType == 'color') return ColorFieldPopup;
    if (fieldType == 'dropdown') return EstimateSelectField;
    if (fieldType == 'boolean') return EstimateCheckboxField;
    return EstimateTextField;
  }
  if (fieldType == 'number') return EstimateNumericElement;
  return EstimateTextElement;
};

const fullGetRoutedFieldChildren = (getIsApplicable) => (getValue) => (getPrimaryValue) => (column) => (nodeID) => {
  const uomMeasured = getPrimaryValue('uom_measured')(nodeID);
  const uom = getPrimaryValue('uom')(nodeID);
  if (column == 'uom') {
    return Object.keys(UOM_CONVERSIONS[uomMeasured] || {}).map((uom) => (
      <option value={uom} key={uom}>
        {UOM_Display[uom]}
      </option>
    ));
  }
  if (column == 'quantity1' && getIsApplicable(column)(nodeID))
    return <div className='flex flex-row items-center h-full min-w-0 p-1 font-light text-gray-darkish text-nowrap'>{UOM_Dependencies[uomMeasured][uom][1].quantity1}</div>;
  if (column == 'quantity2' && getIsApplicable(column)(nodeID)) {
    if ((getValue('uom_measured')(nodeID) || UOM_Measured_Default[getValue('type')(nodeID)]) == 'ft2' && getValue('uom')(nodeID) == 'ea')
      return (
        <>
          <option value={0}>Fill with squares</option>
          <option value={1}>Fill with triangles</option>
        </>
      );
    return <div className='flex flex-row items-center h-full min-w-0 p-1 font-light text-gray-darkish text-nowrap'>{UOM_Dependencies[uomMeasured][uom][2].quantity2}</div>;
  }
};

//this function returns null for inapplicable values
const getIsFieldIncludedFromSets = (getNodeType) => (topLevelIDSet) => (leafIDSet) => (nodeID) => (column) => {
  const type = getNodeType(nodeID);
  if (column == 'labor_hours') return leafIDSet.has(nodeID);
  if (!getIsCalculated(type)(column) || column == 'subtotal' || column == 'unit_amount') return null;
  if (column == 'total') return topLevelIDSet.has(nodeID);
  return leafIDSet.has(nodeID);
};

const getPDFCellValueFromGetters = (getIsShown) => (getDepth) => (getNodeType) => (getIsApplicable) => (getPrimaryValue) => (nodeID) => (column) => {
  const primaryValue = getPrimaryValue(column)(nodeID);
  const content = getIsApplicable(column)(nodeID) && getIsShown(column)(nodeID) ? (isNil(primaryValue) ? '' : getIsNumeric(column)(nodeID) ? getFormattedDecimal(primaryValue) : primaryValue) : '--';
  const styles = {
    fillColor: getNodeType(nodeID) == 'group' ? '#f0f8ff' : '#ffffff',
    fontSize: 7,
    cellPadding: { top: 2, right: 2, bottom: 2, left: column == 'name' ? 2 + 2 * getDepth(nodeID) : 2 },
  };
  return { content, styles };
};

const fullGenerateJSPDFTable = (getIsExpandable) => (getIsExpanded) => (getPDFCellValue) => (columns) => (ids) => {
  const head = [columns.map(getColumnHeader)];
  const headStyles = { fontSize: 7, fillColor: '#eeeeee', textColor: '#000000', fontStyle: 'bold' };
  const body = ids.map((nodeID) =>
    columns.map((column) => {
      if (column == 'name' && getIsExpandable(nodeID)) {
        if (getIsExpanded(nodeID)) return { content: 'v ' + getPDFCellValue(nodeID)(column).content, styles: getPDFCellValue(nodeID)(column).styles };
        return { content: '>  ' + getPDFCellValue(nodeID)(column).content, styles: getPDFCellValue(nodeID)(column).styles };
      }
      return getPDFCellValue(nodeID)(column);
    })
  );
  const doc = new jsPDF();
  autoTable(doc, { head, body, headStyles, theme: 'grid' });
  return doc;
};

const getTableCellValueFromGetters = (getIsShown) => (getIsNumeric) => (getIsCalculated) => (getDepth) => (getNodeType) => (getIsApplicable) => (getPrimaryValue) => (nodeID) => (column) => {
  const primaryValue = getPrimaryValue(column)(nodeID);
  if (typeof primaryValue == 'number') return getFormattedDecimal(primaryValue).toString();
  if (column == 'name') return '> '.repeat(getDepth(nodeID)) + primaryValue;
  if (!getIsApplicable(column)(nodeID) || !getIsShown(column)(nodeID)) return '--';
  if (isNil(primaryValue)) return '';
  return primaryValue;
};

const fullHandleTableExport = (getTableCellValue) => (columns) => (ids) => (type) => {
  const headers = columns.map(getColumnHeader);
  const rows = ids.map((nodeID) => columns.map(getTableCellValue(nodeID)));
  const tableArray = [headers, ...rows];
  const element = document.createElement('a');
  ExcellentExport.convert({ anchor: element, filename: `estimate_table.${type}`, format: type }, [{ name: 'Sheet 1', from: { array: tableArray } }]);
  element.setAttribute('download', `estimate_table.${type}`);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

const getTableExportElement = (type) => (table) => {
  const element = document.createElement('a');
  ExcellentExport.convert({ anchor: element, filename: `estimate_table`, format: type }, [{ name: 'Sheet 1', from: { array: table } }]);
  return element;
};

const getBlobFromURL = (mimeType) =>
  pipe(
    fetch,
    andThen((r) => r.blob()),
    andThen((blob) => blob.slice(0, blob.size, mimeType))
  );

const getFormattedDecimal = (num) => Math.round(num * 100) / 100;

const fullGetIsNodeMatching = (getString) => (query) => pipe(getString, defaultTo(''), toLower, (str) => str.includes(query.toLowerCase()));

const fullGetAncestors = (getNodeGroup) => (nodeID) => concat([nodeID])(getNodeGroup(nodeID) ? fullGetAncestors(getNodeGroup)(getNodeGroup(nodeID)) : []);

const DEFAULT_COLOR = '#9DD9F3';

export {
  DEFAULT_COLOR,
  addCommas,
  applyToDndNodeSubtree,
  assembleDndTreeFromTreeData,
  axiosCurry,
  dfs,
  fullGetAncestors,
  fullGetIsNodeMatching,
  fullGetIsShown,
  fullGetRoutedField,
  fullGetRoutedFieldChildren,
  fullGenerateJSPDFTable,
  generateSortFunction,
  getAllBetweenElements,
  getColumnDescription,
  getColumnHeader,
  getDepthFromDepthDict,
  getDepths,
  getDirectionalizedSortFunction,
  getFieldType,
  getFormattedDecimal,
  getHighestAncestor,
  getIndexOfNodeIDFromAssembledDndTree,
  getIsFieldIncludedFromSets,
  getIsNumeric,
  getPDFCellValueFromGetters,
  getTableCellValueFromGetters,
  fullHandleTableExport,
  headLens,
  mapIndexed,
  numToStr,
  numToStr2Places,
  populateCanHaveChildren,
  restColumns,
  safeReject,
  sortNodeChildren,
  getTableExportElement,
  getBlobFromURL,
};
