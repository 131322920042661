import React, { useContext, useState } from "react";
import { TakeoffContext } from "../../helper/Context";
import { useSelector } from "react-redux";

import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { Html } from "react-konva-utils";
import axios from "axios";

import { Modal } from 'react-bootstrap';

import { selectAuth } from "../../../redux/slices/authSlice";
import { API_ROUTE } from "../../../index";

import ScaleModal from "./ScaleModal";

export default function ScaleConstructor({}) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        setDrawingScale
    } = useContext(TakeoffContext);

    const [scaleStart, setScaleStart] = useState(null);
    const [scaleEnd, setScaleEnd] = useState(null);

    const [feet, setFeet] = useState(0);
    const [inches, setInches] = useState(0);

    const [showScaleModal, setShowScaleModal] = useState(false);

    const handleSaveScale = () => {
        const scalePixels = Math.sqrt(Math.pow(scaleStart.x - scaleEnd.x, 2) + Math.pow(scaleStart.y - scaleEnd.y, 2))
        const scaleFeet = (feet ? parseFloat(feet) : 0) + (inches ? parseFloat(inches) / 12 : 0);

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            data: {
                project: pages[pageID].project,
                scale: scaleFeet / scalePixels,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
        .then((response) => {
            console.log(response);
        })
        .catch((error) => {
            console.log(error);
        })
        
        setPages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                scale: scaleFeet / scalePixels,
            }
        }))

        setShowScaleModal(false);
        setScaleStart(null);
        setScaleEnd(null);
        setDrawingScale(false);
    }

    return (
        <>
            <Html>
                {showScaleModal &&
                    <ScaleModal
                        inches={inches}
                        setInches={setInches}
                        feet={feet}
                        setFeet={setFeet}
                        scaleStart={scaleStart}
                        scaleEnd={scaleEnd}
                        showScaleModal={showScaleModal}
                        setShowScaleModal={setShowScaleModal}
                        handleSaveScale={handleSaveScale}
                        handleCancelScale={() => {
                            setScaleStart(null);
                            setScaleEnd(null);
                            setShowScaleModal(false);
                            setDrawingScale(false);
                        }}
                    />
                }
            </Html>

            <Line
                strokeWidth={2 / pages[pageID].zoom}
                stroke="#006AFF"
                opacity={0.7}
                lineJoin="round"
                points={scaleStart && scaleEnd ? [scaleStart.x, scaleStart.y, scaleEnd.x, scaleEnd.y] : []}
            />

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+scale.svg') 8 24, auto"}
                //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"} 
                onClick={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    if(scaleStart){
                        setScaleEnd({ x: x, y: y });
                        setShowScaleModal(true);
                    }else{
                        setScaleStart({ x: x, y: y });
                    }
                }}
                onMouseMove={(e) => {
                    if(scaleStart){
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setScaleEnd({ x, y });    
                    }
                }}
            />
        </>
    );
}