import { Group, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "../../helper/Context";
import { Html, Portal } from "react-konva-utils";

export default function AnnotationText({ annotation }) {
    const {
        annotations, setAnnotations,
        handleCreateAnnotation,
        handleUpdateAnnotation,
        pages, pageID,
        setEditingMeasurement,
        drawing,
        currentMeasurement, setCurrentMeasurement,
        selectedAnnotations, setSelectedAnnotations,
        currentAnnotation, setCurrentAnnotation,
        selectedMeasurements, setSelectedMeasurements,
    } = useContext(TakeoffContext);

    const shapeRef = useRef();
    const transformerRef = useRef();

    const isSelected = (currentAnnotation === annotation.id && !drawing && !currentMeasurement);

    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        if (isSelected) {
            transformerRef.current.nodes([shapeRef.current]);
            transformerRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setIsEditing(false);
                setEditingMeasurement(false);
                setCurrentAnnotation(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleTransformEnd = (e) => {
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        node.scaleX(1);
        node.scaleY(1);

        handleUpdateAnnotation({
            ...annotation,
            text: {
                ...annotation.text,
                x: node.x(),
                y: node.y(),
                width: Math.max(node.width() * scaleX),
                height: Math.max(node.height() * scaleY),
            }
        });
    }

    return (
        <>
            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                {isEditing
                    ? <Html>
                        <textarea
                            style={{
                                position: 'absolute',
                                top: annotation.text.y,
                                left: annotation.text.x,
                                width: (annotation.text.width) || 200,
                                height: (annotation.text.height) || 200,
                                fontSize: annotation.size + 'px',
                                fontFamily: annotation.text.font_family,
                                fontStyle: annotation.text.font_style,
                                backgroundColor: annotation.fill_color,
                                color: annotation.stroke_color,
                                transformOrigin: 'top left',
                                //transform scale it to match the zoom
                                //transform: `scale(${1 / pages[pageID].zoom})`,
                                backgroundColor: 'transparent',
                                overflow: 'scroll',
                            }}
                            value={annotation.text.text}
                            onChange={(e) => {
                                setAnnotations(({
                                    ...annotations,
                                    [annotation.id]: {
                                        ...annotation,
                                        text: {
                                            ...annotation.text,
                                            text: e.target.value
                                        }
                                    }
                                }));
                            }}
                            onBlur={() => {
                                handleUpdateAnnotation(annotations[annotation.id]);
                                setIsEditing(false);
                                setEditingMeasurement(false);
                            }}
                            autoFocus
                        />
                    </Html>
                    : <Text
                        x={annotation.text.x}
                        y={annotation.text.y}
                        fontSize={annotation.size}
                        fontFamily={annotation.text.font_family}
                        fontStyle={annotation.text.font_style}
                        fill={annotation.stroke_color}
                        backgroundColor={annotation.fill_color}
                        text={annotation.text.text}
                        width={annotation.text.width || 200}
                        height={annotation.text.height || 100}
                    />
                }

                <Rect
                    ref={shapeRef}
                    onDblClick={() => setIsEditing(true)}
                    onClick={(e) => {
                        if (e.evt.metaKey) {
                            if (selectedAnnotations.includes(annotation.id)) {
                                setSelectedAnnotations(selectedAnnotations.filter((id) => id !== annotation.id));
                            } else {
                                setSelectedAnnotations([...selectedAnnotations, annotation.id]);
                            }
                        } else {
                            setSelectedAnnotations([annotation.id]);
                            setCurrentAnnotation(annotation.id)
                        }

                        setCurrentMeasurement(null);
                        setSelectedMeasurements([]);
                    }}

                    x={annotation.text.x}
                    y={annotation.text.y}
                    width={annotation.text.width || 200}
                    height={annotation.text.height || 100}
                    fill={annotation.fill_color}
                    opacity={isSelected || selectedAnnotations.includes(annotation.id) ? 0.7 : 0.5}

                    draggable={isSelected && !isEditing && !drawing}
                    onDragStart={() => {
                        transformerRef.current.nodes([shapeRef.current]);
                    }}
                    onDragMove={(e) => {
                        setAnnotations(({
                            ...annotations,
                            [annotation.id]: {
                                ...annotation,
                                text: {
                                    ...annotation.text,
                                    x: e.target.x(),
                                    y: e.target.y(),
                                }
                            }
                        }));
                    }}
                    onDragEnd={(e) => {
                        console.log('drag end');
                        handleUpdateAnnotation({
                            ...annotation,
                            text: {
                                ...annotation.text,
                                x: e.target.x(),
                                y: e.target.y(),
                            }
                        });
                    }}
                    onTransformEnd={handleTransformEnd}
                    strokeScaleEnabled={false}
                    perfectDrawEnabled={false}
                />

                {isSelected &&
                    <Transformer
                        ref={transformerRef}
                        flipEnabled={false}
                        rotateEnabled={false}
                        keepRatio={false}
                    />
                }
            </Portal>
        </>
    )
}