import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { TakeoffContext } from './helper/Context';

import './styles/TakeoffSidebar.css';

import MeasurementSidebar from './components/MeasurementSidebar';
import PagesSidebar from './components/PagesSidebar';
import AISidebar from './components/AISidebar';

export default function TakeoffSidebar({ }) {
    const {
        takeoffSettings,
    } = useContext(TakeoffContext);

    const [measurementSidebarWidth, setShowMeasurementsSidebarWidth] = useState(takeoffSettings?.measurement_sidebar_width || null);
    const [pagesSidebarWidth, setShowPagesSidebarWidth] = useState(takeoffSettings?.pages_sidebar_width || null);
    const [aiSidebarWidth, setShowAISidebarWidth] = useState(takeoffSettings?.ai_sidebar_width || null);

    const [currentAITab, setCurrentAITab] = useState('count');

    useEffect(() => {
        if (takeoffSettings) {
            setShowMeasurementsSidebarWidth(takeoffSettings?.measurement_sidebar_width);
            setShowPagesSidebarWidth(takeoffSettings?.pages_sidebar_width);
            setShowAISidebarWidth(takeoffSettings?.ai_sidebar_width);
        }
    }, [takeoffSettings]);

    if (!takeoffSettings && !measurementSidebarWidth && !pagesSidebarWidth && !aiSidebarWidth) return null;

    return (
        <>
            {takeoffSettings?.show_pages_sidebar &&
                <PagesSidebar
                    sidebarWidth={pagesSidebarWidth}
                    setSidebarWidth={setShowPagesSidebarWidth}
                />
            }

            {takeoffSettings?.show_measurement_sidebar &&
                <MeasurementSidebar 
                    sidebarWidth={measurementSidebarWidth}
                    setSidebarWidth={setShowMeasurementsSidebarWidth}
                />
            }

            {takeoffSettings?.show_ai_sidebar &&
                <AISidebar 
                    sidebarWidth={aiSidebarWidth}
                    setSidebarWidth={setShowAISidebarWidth}
                    currentAITab={currentAITab}
                    setCurrentAITab={setCurrentAITab}
                />
            }
        </>
    )
}