import { verticalListSortingStrategy } from '@dnd-kit/sortable';
export const customListSortingStrategy = (isValid) => {
  const sortingStrategy = ({ activeIndex, activeNodeRect, index, rects, overIndex }) => {
    if (isValid(activeIndex, overIndex)) {
      return verticalListSortingStrategy({
        activeIndex,
        activeNodeRect,
        index,
        rects,
        overIndex,
      });
    }
    return null;
  };
  return sortingStrategy;
};
