import React, { useState } from 'react';

import "./styles/ActiveButton.css";

export default function ActiveButton({ text, handleClick, size }) {
    return (
        <button 
            className={`active-button active-button-${size}`}
            onClick={handleClick}
        >
            {text}
        </button>
    );
}
