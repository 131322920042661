import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/slices/authSlice";
import { API_ROUTE, PROXYURL } from "../..";
import { Blocks } from "react-loader-spinner";
import { useEffect, useState } from "react";
import { IconCopy, IconEye, IconHighlight, IconRobot, IconStar, IconTrashX } from "@tabler/icons-react";
import { Tooltip } from "react-tooltip";
import Popup from "reactjs-popup";
import { Modal } from "react-bootstrap";
import DeleteButton from "../../components/DeleteButton";
import DefaultButton from "../../components/DefaultButton";

export default function ProjectDetailsPage({ page, files, setFiles, shiftAnchor, setShiftAnchor, setShowPagePreview, setCurrentPage, zoom }) {
    const auth = useSelector(selectAuth);

    const [loaded, setLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [title, setTitle] = useState(page.title);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if ((e.key === 'Enter' || e.key === 'Return') && document.activeElement.tagName === 'INPUT' && showModal) {
                document.activeElement.blur();
            }

            if (e.key === 'Escape' && showModal) {
                document.activeElement.blur();
                setShowModal(false);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [showModal]);

    const handleClick = (e) => {
        e.stopPropagation();

        if (e.shiftKey) {
            console.log('shift click');

            if (shiftAnchor === null) {
                setShiftAnchor(page.page_number);
            } else {
                let newIncluded = false;

                if (Object.values(files[page.parent_file].pages).filter((p) => p.page_number >= Math.min(shiftAnchor, page.page_number) && p.page_number <= Math.max(shiftAnchor, page.page_number)).some((p) => !p.is_included)) {
                    newIncluded = true;
                }

                setFiles(prev => ({
                    ...prev,
                    [page.parent_file]: {
                        ...prev[page.parent_file],
                        pages: {
                            ...prev[page.parent_file].pages,
                            ...Object.values(files[page.parent_file].pages).filter((p) => p.page_number >= Math.min(shiftAnchor, page.page_number) && p.page_number <= Math.max(shiftAnchor, page.page_number)).map((p) => ({
                                ...p,
                                is_included: newIncluded
                            })).reduce((acc, curr) => {
                                acc[curr.id] = curr;
                                return acc;
                            }, {})
                        }
                    }
                }))

                setShiftAnchor(null);

                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/project-details-toggle-pages/`,
                    data: {
                        pages: Object.values(files[page.parent_file].pages).filter((p) => p.page_number >= Math.min(shiftAnchor, page.page_number) && p.page_number <= Math.max(shiftAnchor, page.page_number)).map((p) => p.id),
                        is_included: newIncluded
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        'Content-Type': 'application/json'
                    }
                })
                    .then((response) => {
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }
        } else {
            setFiles(prev => ({
                ...prev,
                [page.parent_file]: {
                    ...prev[page.parent_file],
                    pages: {
                        ...prev[page.parent_file].pages,
                        [page.id]: {
                            ...prev[page.parent_file].pages[page.id],
                            is_included: !prev[page.parent_file].pages[page.id].is_included
                        }
                    }
                }
            }))

            axios({
                method: 'put',
                url: `${API_ROUTE}/api/pages/${page.id}/`,
                data: {
                    parent_file: page.parent_file,
                    project: page.project,
                    is_included: !page.is_included,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => {
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const handleDuplicatePage = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/duplicate-page/`,
            data: {
                page: page.id,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log(response);

                setFiles(prev => ({
                    ...prev,
                    [page.parent_file]: {
                        ...prev[page.parent_file],
                        pages: {
                            ...prev[page.parent_file].pages,
                            [response.data.page.id]: {
                                ...response.data.page,
                            }
                        }
                    }
                }))
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleSavePage = (newPage) => {
        setFiles(prev => ({
            ...prev,
            [newPage.parent_file]: {
                ...prev[newPage.parent_file],
                pages: {
                    ...prev[newPage.parent_file].pages,
                    [newPage.id]: {
                        ...newPage,
                    }
                }
            }
        }))

        axios({
            method: "put",
            url: `${API_ROUTE}/api/pages/${page.id}/`,
            data: {
                project: newPage.project,
                starred: newPage.starred,
                title: newPage.title,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                "Content-Type": "application/json",
            },
        })
            .then(res => {
                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeletePage = () => {
        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/pages/${page.id}/`,
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then(response => {
                console.log(response);

                setFiles(prev => {
                    let newFiles = { ...prev };
                    delete newFiles[page.parent_file].pages[page.id];

                    return newFiles;
                });

                setShowDeleteModal(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div
            className='projectdetails-page-container'
            onClick={(e) => handleClick(e)}
            onContextMenu={(e) => {
                e.preventDefault();
                setShowModal(true);
            }}
            style={{
                borderColor: page.is_included && loaded && '#006AFF',
            }}
        >
            {loaded ? null : <Blocks
                visible={true}
                height="40"
                width="40"
                color="#006AFE"
                ariaLabel="blocks-loading"
                radius="10"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
            />}

            <img
                id={'projectdetails-page-thumbnail' + page.id}
                className="projectdetails-page-thumbnail"
                style={{
                    display: loaded ? 'block' : 'none'
                }}
                src={page.thumbnail}
                onLoad={() => setLoaded(true)}
            />

            {!loaded ? null : <div
                id={'projectdetails-page-status' + page.id}
                className='projectdetails-page-status-icon'
                style={{
                    backgroundColor: page.is_included && '#006AFF'
                }}
            >
            </div>}

            {!loaded
                ? null
                : page.ai_prepped
                    ? <div
                        id={'projectdetails-page-aiprepped' + page.id}
                        className='projectdetails-page-aiprepped'
                    >
                        <IconRobot size={20} stroke={1} />
                    </div>
                    : null
            }

            {!loaded ? null : <div
                id={'projectdetails-page-measurements' + page.id}
                className='projectdetails-page-measurements'
            >
                {page.measurements}
            </div>
            }

            {!loaded ? null : <div
                id={'projectdetails-page-view' + page.id}
                className='projectdetails-page-view'
                onClick={(e) => {
                    e.stopPropagation();
                    setCurrentPage(page.id);
                    setShowPagePreview(true);
                }}
            >
                <IconEye />
            </div>}


            <Popup
                trigger={
                    <div
                        style={{
                            position: 'absolute',
                            top: '20px',
                            right: `${zoom}px`,
                            width: '0px',
                            height: '0px',
                            color: 'transparent',
                        }}
                    >
                        &nbsp;
                    </div>
                }
                open={showModal}
                on=''
                onClose={() => {
                    setShowModal(false);

                    if (title !== page.title) {
                        handleSavePage({
                            ...page,
                            title,
                        });
                    }
                }}
                position={'bottom right'}
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                arrow={false}
                contentStyle={{
                    width: '188px',
                    padding: '0px',
                }}
            >
                <div className="pagessidebar-page-options-container">
                    <div className="pagessidebar-page-options-section-name">
                        <div>
                            <input
                                type="text"
                                className="pagessidebar-page-options-name-input"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => {
                                    handleSavePage({
                                        ...page,
                                        title,
                                    });
                                }}
                            />
                        </div>
                        <div className="pagessidebar-page-options-section-icon">
                            <IconHighlight size={15} stroke={1} />
                        </div>
                    </div>

                    {/*<div
                        className="pagessidebar-page-options-section"
                        onClick={() => handleSavePage({
                            ...p,
                            starred: !page.starred,
                        })}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconStar size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Star
                        </div>
                    </div>*/}

                    <div
                        className="pagessidebar-page-options-section"
                        onClick={() => handleDuplicatePage()}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconCopy size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Duplicate
                        </div>
                    </div>

                    <div
                        className="pagessidebar-page-options-section-delete"
                        onClick={() => setShowDeleteModal(true)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Delete
                        </div>
                    </div>
                </div>
            </Popup>

            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                size='small'
            >
                <Modal.Header>
                    <Modal.Title>
                        <div className="deletemeasurementmodal-title">
                            Delete page
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Are you sure you want to delete <b>{page.title}</b> and all its measurements?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <DeleteButton
                        handleClick={() => handleDeletePage()}
                    >
                        Delete
                    </DeleteButton>
                    <DefaultButton
                        handleClick={() => setShowDeleteModal(false)}
                    >
                        Cancel
                    </DefaultButton>
                </Modal.Footer>
            </Modal>

            {!loaded ? null : <div
                className='projectdetails-page-name'
                style={{
                    maxWidth: `${zoom - 4}px`,
                }}
            >
                {page.title}
            </div>}

            <div
                style={{
                    zIndex: 9999,
                }}
            >
                <Tooltip anchorSelect={'#projectdetails-page-aiprepped' + page.id} delayShow={1000} place='top' positionStrategy='fixed' style={{ zIndex: 99999 }}>
                    Page has been AI prepped
                </Tooltip>

                <Tooltip anchorSelect={'#projectdetails-page-thumbnail' + page.id} delayShow={1000} place='top' positionStrategy='fixed' style={{ zIndex: 99999 }}>
                    <div>{page.is_included
                        ? 'Click to exclude'
                        : 'Click to include for takeoff'}
                    </div>
                    {files[page?.parent_file]?.pages && Object.keys(files[page.parent_file].pages).length > 1 ?
                        <div>
                            Shift click 2 pages to toggle pages in between
                        </div>
                        : null}
                </Tooltip>

                <Tooltip anchorSelect={'#projectdetails-page-status' + page.id} delayShow={1000} place='top' positionStrategy='fixed' style={{ zIndex: 99999 }}>
                    {page.is_included
                        ? 'Click to exclude'
                        : 'Click to include for takeoff'}
                </Tooltip>

                <Tooltip anchorSelect={'#projectdetails-page-view' + page.id} delayShow={1000} place='top' positionStrategy='fixed' style={{ zIndex: 99999 }}>
                    View page
                </Tooltip>

                <Tooltip anchorSelect={'#projectdetails-page-measurements' + page.id} delayShow={1000} place='top' positionStrategy='fixed' style={{ zIndex: 99999 }}>
                    {page.measurements} measurement{page.measurements === 1 ? '' : 's'} on this page
                </Tooltip>
            </div>
        </div>
    )
}