const InputTypes = {
    'name': 'text',
    'description': 'text',
    'internal_notes': 'text',
    'unit_amount': 'number',
    'material_vendor': 'text',
    'material_cost': 'number',
    'material_cost_extra': 'number',
    'material_tax': 'number',
    'material_shipping': 'number',
    'material_unit_subtotal': 'number',
    'material_subtotal': 'number',
    'material_markup': 'number',
    'material_unit_total': 'number',
    'material_total': 'number',
    'labor_amount': 'number',
    'labor_cost': 'number',
    'labor_unit_subtotal': 'number',
    'labor_subtotal': 'number',
    'labor_markup': 'number',
    'labor_unit_total': 'number',
    'labor_total': 'number',
    'subcontractor_unit_cost': 'number',
    'subcontractor_subtotal': 'number',
    'subcontractor_markup': 'number',
    'subcontractor_unit_total': 'number',
    'subcontractor_total': 'number',
    'unit_cost': 'number',
    'unit_cost_markup': 'number',
    'unit_cost_total': 'number',
    'subtotal': 'number',
    'subtotal_markup': 'number',
    'total': 'number',
    'cost': 'number',
    'profit': 'number',
    'margin': 'number',
    'total_labor_hours': 'number',
    'crew_size': 'number',
    'labor_days': 'number',
}

const InputCalculated = {
    'name': false,
    'description': false,
    'internal_notes': false,
    'unit_amount': false,
    'material_vendor': false,
    'material_cost': false,
    'material_cost_extra': false,
    'material_tax': false,
    'material_shipping': false,
    'material_unit_subtotal': true,
    'material_subtotal': true,
    'material_markup': false,
    'material_unit_total': true,
    'material_total': true,
    'labor_amount': false,
    'labor_cost': false,
    'labor_unit_subtotal': true,
    'labor_subtotal': true,
    'labor_markup': false,
    'labor_unit_total': true,
    'labor_total': true,
    'subcontractor_unit_cost': false,
    'subcontractor_subtotal': true,
    'subcontractor_markup': false,
    'subcontractor_unit_total': true,
    'subcontractor_total': true,
    'unit_cost': true,
    'unit_cost_markup': false,
    'unit_cost_total': true,
    'subtotal': true,
    'subtotal_markup': false,
    'total': true,
    'cost': true,
    'profit': true,
    'margin': true,
    'total_labor_hours': true,
    'crew_size': false,
    'labor_days': true,
}

const InputEditable = {
    'name': true,
    'description': true,
    'internal_notes': true,
    'unit_amount': true,
    'material_vendor': true,
    'material_cost': true,
    'material_cost_extra': true,
    'material_tax': true,
    'material_shipping': true,
    'material_unit_subtotal': false,
    'material_subtotal': false,
    'material_markup': true,
    'material_unit_total': true,
    'material_total': false,
    'labor_amount': true,
    'labor_cost': true,
    'labor_unit_subtotal': false,
    'labor_subtotal': false,
    'labor_markup': true,
    'labor_unit_total': true,
    'labor_total': false,
    'subcontractor_unit_cost': true,
    'subcontractor_subtotal': false,
    'subcontractor_markup': true,
    'subcontractor_unit_total': true,
    'subcontractor_total': false,
    'unit_cost': true,
    'unit_cost_markup': true,
    'unit_cost_total': false,
    'subtotal': false,
    'subtotal_markup': true,
    'total': true,
    'cost': false,
    'profit': false,
    'margin': false,
    'total_labor_hours': false,
    'crew_size': true,
    'labor_days': false,
}

const ColumnLabels = {
    name: 'Name',
    page_title: 'Page Title',
    description: 'Description',
    internal_notes: 'Internal Notes',
    color: 'Color',
    count: 'Count (ea)',
    length: 'Length (ft)',
    area: 'Area (ft²)',
    uom_measured: 'UOM Measured',
    uom: 'UOM',
    quantity1: 'Quantity 1',
    quantity2: 'Quantity 2',
    unit_amount: 'Unit Amount',
    material_vendor: 'Material Vendor',
    material_cost: 'Material Cost ($)',
    material_cost_extra: 'Material Cost Extra ($)',
    material_tax: 'Material Tax (%)',
    material_shipping: 'Material Shipping (%)',
    material_unit_subtotal: 'Material Unit Subtotal ($)',
    material_subtotal: 'Material Subtotal ($)',
    material_markup: 'Material Markup (%)',
    material_unit_total: 'Material Unit Total ($)',
    material_total: 'Material Total ($)',
    labor_amount: 'Labor Amount (hrs)',
    labor_cost: 'Labor Cost ($/hr)',
    labor_unit_subtotal: 'Labor Unit Subtotal ($)',
    labor_subtotal: 'Labor Subtotal ($)',
    labor_markup: 'Labor Markup (%)',
    labor_unit_total: 'Labor Unit Total ($)',
    labor_total: 'Labor Total ($)',
    subcontractor_unit_cost: 'Subcontractor Unit Cost ($)',
    subcontractor_subtotal: 'Subcontractor Subtotal ($)',
    subcontractor_markup: 'Subcontractor Markup (%)',
    subcontractor_unit_total: 'Subcontractor Unit Total ($)',
    subcontractor_total: 'Subcontractor Total ($)',
    unit_cost: 'Unit Cost ($)',
    unit_cost_markup: 'Unit Cost Markup (%)',
    unit_cost_total: 'Unit Cost Total ($)',
    subtotal: 'Subtotal ($)',
    subtotal_markup: 'Subtotal Markup (%)',
    total: 'Total Price ($)',
    cost: 'Total Cost ($)',
    profit: 'Profit ($)',
    margin: 'Margin (%)',
    total_labor_hours: 'Total Labor Hours',
    crew_size: 'Crew Size',
    labor_days: 'Labor Days',
};

const ColumnDescriptions = {
    name: 'Name of the item',
    page_title: 'Title of the page the corresponding measurement is on',
    description: 'Description of the item',
    internal_notes: 'Internal notes about the item',
    color: 'Color of the measurement',
    count: 'If the measurement type is count, the number of dots',
    length: 'If the measurement type is line, the length of the line',
    area: 'If the measurement type is a rectangle, polygon, or circle, the area of the shape',
    uom_measured: 'Unit of measurement for the measurement',
    uom: 'Unit of measurement for the item',
    quantity1: 'Quantity of the item',
    quantity2: 'Quantity of the item',
    unit_amount: 'Quantity of the item',
    material_vendor: 'Vendor of the material',
    material_cost: 'Material cost per unit',
    material_cost_extra: 'Extra material cost per unit',
    material_tax: 'Tax on the material',
    material_shipping: 'Shipping cost of the material',
    material_unit_subtotal: 'Subtotal of material unit cost',
    material_subtotal: 'Subtotal of material cost',
    material_markup: 'Markup on the material',
    material_unit_total: 'Total of material unit cost',
    material_total: 'Total of material cost',
    labor_amount: 'Amount of labor per unit',
    labor_cost: 'Cost of labor per hour',
    labor_unit_subtotal: 'Subtotal of labor unit cost',
    labor_subtotal: 'Subtotal of labor cost',
    labor_markup: 'Markup on the labor',
    labor_unit_total: 'Total of labor unit cost',
    labor_total: 'Total of labor cost',
    subcontractor_unit_cost: 'Cost of the subcontractor',
    subcontractor_subtotal: 'Subtotal of the subcontractor',
    subcontractor_markup: 'Markup on the subcontractor',
    subcontractor_unit_total: 'Total unit cost of the subcontractor',
    subcontractor_total: 'Total cost of the subcontractor',
    unit_cost: 'Total cost of the unit',
    unit_cost_markup: 'Markup on the unit',
    unit_cost_total: 'Total cost of the unit',
    subtotal: 'Subtotal of the unit',
    subtotal_markup: 'Markup on the subtotal',
    total: 'Total cost of the unit',
    cost: 'Cost of the unit',
    profit: 'Profit of the unit',
    margin: 'Margin of the unit',
    total_labor_hours: 'Total labor hours',
    crew_size: 'Size of the crew',
    labor_days: 'Number of days of labor',
};

const InputCalculations = {
    'material_unit_subtotal': ({ material_cost, material_cost_extra, material_tax, material_shipping }) => +((material_cost + material_cost_extra) * (1 + material_tax / 100) * (1 + material_shipping / 100)).toFixed(2),
    'material_subtotal': ({ material_unit_subtotal, unit_amount }) => +(material_unit_subtotal * unit_amount).toFixed(2),
    'material_unit_total': ({ material_unit_subtotal, material_markup }) => +(material_unit_subtotal * (1 + material_markup / 100)).toFixed(2),
    'material_total': ({ material_unit_total, unit_amount }) => +(material_unit_total * unit_amount).toFixed(2),
    'labor_unit_subtotal': ({ labor_amount, labor_cost }) => +(labor_amount * labor_cost).toFixed(2),
    'labor_subtotal': ({ labor_unit_subtotal, unit_amount }) => +(labor_unit_subtotal * unit_amount).toFixed(2),
    'labor_unit_total': ({ labor_unit_subtotal, labor_markup }) => +(labor_unit_subtotal * (1 + labor_markup / 100)).toFixed(2),
    'labor_total': ({ labor_unit_total, unit_amount }) => +(labor_unit_total * unit_amount).toFixed(2),
    'subcontractor_subtotal': ({ subcontractor_unit_cost, unit_amount }) => +(subcontractor_unit_cost * unit_amount).toFixed(2),
    'subcontractor_unit_total': ({ subcontractor_unit_cost, subcontractor_markup }) => +(subcontractor_unit_cost * (1 + subcontractor_markup / 100)).toFixed(2),
    'subcontractor_total': ({ subcontractor_unit_total, unit_amount }) => +(subcontractor_unit_total * unit_amount).toFixed(2),
    'unit_cost': ({ material_unit_total, labor_unit_total, subcontractor_unit_total }) => +(material_unit_total + labor_unit_total + subcontractor_unit_total).toFixed(2),
    'unit_cost_total': ({ unit_cost, unit_cost_markup }) => +(unit_cost * (1 + unit_cost_markup / 100)).toFixed(2),
    'subtotal': ({ unit_cost_total, unit_amount }) => +(unit_cost_total * unit_amount).toFixed(2),
    'total': ({ subtotal, subtotal_markup }) => +(subtotal * (1 + subtotal_markup / 100)).toFixed(2),
    'cost': ({ material_unit_subtotal, labor_unit_subtotal, subcontractor_unit_cost, unit_cost, unit_amount }) => unit_cost ? +(unit_cost * unit_amount).toFixed(2) : +((material_unit_subtotal + labor_unit_subtotal + subcontractor_unit_cost) * unit_amount).toFixed(2),
    'profit': ({ total, cost }) => +(total - cost).toFixed(2),
    'margin': ({ profit, total }) => +(profit / total * 100).toFixed(2),
    'total_labor_hours': ({ labor_amount, unit_amount }) => +(labor_amount * unit_amount).toFixed(2),
    'labor_days': ({ total_labor_hours, crew_size }) => +(total_labor_hours / (crew_size * 8)).toFixed(2),
}

const calculatedFieldsDependencies = {
    'unit_amount': [],
    'material_unit_subtotal': ['material_cost', 'material_cost_extra', 'material_tax', 'material_shipping'],
    'material_subtotal': ['material_unit_subtotal', 'unit_amount'],
    'material_unit_total': ['material_unit_subtotal', 'material_markup'],
    'material_total': ['material_unit_total', 'unit_amount'],
    'labor_unit_subtotal': ['labor_amount', 'labor_cost'],
    'labor_subtotal': ['labor_unit_subtotal', 'unit_amount'],
    'labor_unit_total': ['labor_unit_subtotal', 'labor_markup'],
    'labor_total': ['labor_unit_total', 'unit_amount'],
    'subcontractor_subtotal': ['subcontractor_unit_cost', 'unit_amount'],
    'subcontractor_unit_total': ['subcontractor_unit_cost', 'subcontractor_markup'],
    'subcontractor_total': ['subcontractor_unit_total', 'unit_amount'],
    'unit_cost': ['material_unit_total', 'labor_unit_total', 'subcontractor_unit_total'],
    'unit_cost_total': ['unit_cost', 'unit_cost_markup'],
    'subtotal': ['unit_cost_total', 'unit_amount'],
    'total': ['subtotal', 'subtotal_markup'],
    'cost': ['material_unit_subtotal', 'labor_unit_subtotal', 'subcontractor_unit_cost', 'unit_cost', 'unit_amount'],
    'profit': ['total', 'cost'],
    'margin': ['profit', 'total'],
    'total_labor_hours': ['labor_amount', 'unit_amount'],
    'labor_days': ['total_labor_hours', 'crew_size'],
}

const GroupFields = {
    'name': true,
    'description': true,
    'internal_notes': true,
    'unit_amount': false,
    'material_vendor': false,
    'material_cost': false,
    'material_cost_extra': false,
    'material_tax': false,
    'material_shipping': false,
    'material_unit_subtotal': false,
    'material_subtotal': false,
    'material_markup': false,
    'material_unit_total': false,
    'material_total': false,
    'labor_amount': false,
    'labor_cost': false,
    'labor_unit_subtotal': false,
    'labor_subtotal': false,
    'labor_markup': false,
    'labor_unit_total': false,
    'labor_total': false,
    'subcontractor_unit_cost': false,
    'subcontractor_subtotal': false,
    'subcontractor_markup': false,
    'subcontractor_unit_total': false,
    'subcontractor_total': false,
    'unit_cost': false,
    'unit_cost_markup': false,
    'unit_cost_total': false,
    'subtotal': false,
    'subtotal_markup': false,
    'total': false,
    'cost': false,
    'profit': false,
    'margin': false,
    'total_labor_hours': false,
    'crew_size': false,
    'labor_days': false,
}

const AssemblyFields = {
    'name': true,
    'description': true,
    'internal_notes': true,
    'unit_amount': true,
    'material_vendor': true,
    'material_cost': true,
    'material_cost_extra': true,
    'material_tax': true,
    'material_shipping': true,
    'material_unit_subtotal': true,
    'material_subtotal': true,
    'material_markup': true,
    'material_unit_total': true,
    'material_total': true,
    'labor_amount': true,
    'labor_cost': true,
    'labor_unit_subtotal': true,
    'labor_subtotal': true,
    'labor_markup': true,
    'labor_unit_total': true,
    'labor_total': true,
    'subcontractor_unit_cost': true,
    'subcontractor_subtotal': true,
    'subcontractor_markup': true,
    'subcontractor_unit_total': true,
    'subcontractor_total': true,
    'unit_cost': true,
    'unit_cost_markup': true,
    'unit_cost_total': true,
    'subtotal': true,
    'subtotal_markup': true,
    'total': true,
    'cost': true,
    'profit': true,
    'margin': true,
    'total_labor_hours': true,
    'crew_size': true,
    'labor_days': true,
}

const AssemblyCalculatedFields = {
    'name': false,
    'description': false,
    'unit_amount': false,
    'unit_amount': false,
    'material_vendor': false,
    'material_cost': true,
    'material_cost_extra': false,
    'material_tax': false,
    'material_shipping': false,
    'material_unit_subtotal': true,
    'material_subtotal': true,
    'material_markup': false,
    'material_unit_total': true,
    'material_total': true,
    'labor_amount': true,
    'labor_cost': false,
    'labor_unit_subtotal': true,
    'labor_subtotal': true,
    'labor_markup': false,
    'labor_unit_total': true,
    'labor_total': true,
    'subcontractor_unit_cost': false,
    'subcontractor_subtotal': true,
    'subcontractor_markup': false,
    'subcontractor_unit_total': true,
    'subcontractor_total': true,
    'unit_cost': true,
    'unit_cost_markup': false,
    'unit_cost_total': true,
    'subtotal': true,
    'subtotal_markup': false,
    'total': true,
    'cost': true,
    'profit': true,
    'margin': true,
    'total_labor_hours': true,
    'crew_size': false,
    'labor_days': true,
}

const AssemblyCalculations = {
    'material_cost': ({ children }) => +children.reduce((acc, child) => acc + child.unit_amount * child.material_cost, 0).toFixed(2),
    'material_unit_subtotal': ({ material_cost, material_cost_extra, material_tax, material_shipping }) => +((material_cost + material_cost_extra) * (1 + material_tax / 100) * (1 + material_shipping / 100)).toFixed(2),
    'material_subtotal': ({ material_unit_subtotal, unit_amount }) => +(material_unit_subtotal * unit_amount).toFixed(2),
    'material_unit_total': ({ material_unit_subtotal, material_markup }) => +(material_unit_subtotal * (1 + material_markup / 100)).toFixed(2),
    'material_total': ({ material_unit_total, unit_amount }) => +(material_unit_total * unit_amount).toFixed(2),
    'labor_amount': ({ children }) => +children.reduce((acc, child) => acc + child.unit_amount * child.labor_amount, 0).toFixed(2),
    'labor_unit_subtotal': ({ labor_amount, labor_cost }) => +(labor_amount * labor_cost).toFixed(2),
    'labor_subtotal': ({ labor_unit_subtotal, unit_amount }) => +(labor_unit_subtotal * unit_amount).toFixed(2),
    'labor_unit_total': ({ labor_unit_subtotal, labor_markup }) => +(labor_unit_subtotal * (1 + labor_markup / 100)).toFixed(2),
    'labor_total': ({ labor_unit_total, unit_amount }) => +(labor_unit_total * unit_amount).toFixed(2),
    'subcontractor_subtotal': ({ subcontractor_unit_cost, unit_amount }) => +(subcontractor_unit_cost * unit_amount).toFixed(2),
    'subcontractor_unit_total': ({ subcontractor_unit_cost, subcontractor_markup }) => +(subcontractor_unit_cost * (1 + subcontractor_markup / 100)).toFixed(2),
    'subcontractor_total': ({ subcontractor_unit_total, unit_amount }) => +(subcontractor_unit_total * unit_amount).toFixed(2),
    'unit_cost': ({ material_unit_total, labor_unit_total, subcontractor_unit_total }) => +(material_unit_total + labor_unit_total + subcontractor_unit_total).toFixed(2),
    'unit_cost_total': ({ unit_cost, unit_cost_markup }) => +(unit_cost * (1 + unit_cost_markup / 100)).toFixed(2),
    'subtotal': ({ unit_cost_total, unit_amount }) => +(unit_cost_total * unit_amount).toFixed(2),
    'total': ({ subtotal, subtotal_markup }) => +(subtotal * (1 + subtotal_markup / 100)).toFixed(2),
    'cost': ({ material_unit_subtotal, labor_unit_subtotal, subcontractor_unit_cost, unit_cost, unit_amount }) => unit_cost ? +(unit_cost * unit_amount).toFixed(2) : +((material_unit_subtotal + labor_unit_subtotal + subcontractor_unit_cost) * unit_amount).toFixed(2),
    'profit': ({ total, cost }) => +(total - cost).toFixed(2),
    'margin': ({ profit, total }) => +(profit / total * 100).toFixed(2),
    'total_labor_hours': ({ labor_amount, unit_amount }) => +(labor_amount * unit_amount).toFixed(2),
    'labor_days': ({ total_labor_hours, crew_size }) => +(total_labor_hours / (crew_size * 8)).toFixed(2),
}

const AssemblyCalculationsDependencies = {
    'material_cost': ['children.unit_amount', 'children.material_cost'],
    'material_unit_subtotal': ['material_cost', 'material_cost_extra', 'material_tax', 'material_shipping'],
    'material_subtotal': ['material_unit_subtotal', 'unit_amount'],
    'material_unit_total': ['material_unit_subtotal', 'material_markup'],
    'material_total': ['material_unit_total', 'unit_amount'],
    'labor_amount': ['children.unit_amount', 'children.labor_amount'],
    'labor_unit_subtotal': ['labor_amount', 'labor_cost'],
    'labor_subtotal': ['labor_unit_subtotal', 'unit_amount'],
    'labor_unit_total': ['labor_unit_subtotal', 'labor_markup'],
    'labor_total': ['labor_unit_total', 'unit_amount'],
    'subcontractor_subtotal': ['subcontractor_unit_cost', 'unit_amount'],
    'subcontractor_unit_total': ['subcontractor_unit_cost', 'subcontractor_markup'],
    'subcontractor_total': ['subcontractor_unit_total', 'unit_amount'],
    'unit_cost': ['material_unit_total', 'labor_unit_total', 'subcontractor_unit_total'],
    'unit_cost_total': ['unit_cost', 'unit_cost_markup'],
    'subtotal': ['unit_cost_total', 'unit_amount'],
    'total': ['subtotal', 'subtotal_markup'],
    'cost': ['material_unit_subtotal', 'labor_unit_subtotal', 'subcontractor_unit_cost', 'unit_cost', 'unit_amount'],
    'profit': ['total', 'cost'],
    'margin': ['profit', 'total'],
    'total_labor_hours': ['labor_amount', 'unit_amount'],
    'labor_days': ['total_labor_hours', 'crew_size'],
}

const AssemblyEntryFields = {
    'name': true,
    'description': true,
    'internal_notes': true,
    'unit_amount': true,
    'material_vendor': true,
    'material_cost': true,
    'material_cost_extra': false,
    'material_tax': false,
    'material_shipping': false,
    'material_unit_subtotal': false,
    'material_subtotal': false,
    'material_markup': false,
    'material_unit_total': false,
    'material_total': false,
    'labor_amount': true,
    'labor_cost': false,
    'labor_unit_subtotal': false,
    'labor_subtotal': false,
    'labor_markup': false,
    'labor_unit_total': false,
    'labor_total': false,
    'subcontractor_unit_cost': false,
    'subcontractor_subtotal': false,
    'subcontractor_markup': false,
    'subcontractor_unit_total': false,
    'subcontractor_total': false,
    'unit_cost': false,
    'unit_cost_markup': false,
    'unit_cost_total': false,
    'subtotal': false,
    'subtotal_markup': false,
    'total': false,
    'cost': false,
    'profit': false,
    'margin': false,
    'total_labor_hours': false,
    'crew_size': false,
    'labor_days': false,
}

const CalculateValue = (columnID, data, assemblyEntries) => {
    if (!data) return 0.00;

    if (data[columnID] !== null && data[columnID] !== undefined) {
        if (!data[columnID]) {
            return 0.00;
        }

        return data[columnID];
    }

    const dependencies = data.type === 'assembly' ? AssemblyCalculationsDependencies[columnID] : calculatedFieldsDependencies[columnID];

    if (!dependencies || dependencies?.length === 0) {
        return 0.00;
    }

    const calculatedValues = {};

    dependencies.forEach((dependency) => {
        if (dependency.includes('children.')) {

        }
        if (!dependency.includes('children.')) {
            calculatedValues[dependency] = CalculateValue(dependency, data, assemblyEntries);
        }
    })

    if (dependencies.some((dependency) => dependency.includes('children.'))) {
        let childrenValues = [];

        Object.values(assemblyEntries).filter((entry) => {
            if (String(data.id).includes('entry')) {
                return entry.assembly == data.id.split('-')[1]
            } else {
                return entry.assembly === data.id
            }
        }).forEach((entry) => {
            let child = {}

            dependencies.filter((dependency) => dependency.includes('children.')).forEach((dependency) => {
                child[dependency.split('.')[1]] = entry[dependency.split('.')[1]] || 0.00
            })

            childrenValues.push(child)
        })

        console.log(childrenValues)
        calculatedValues['children'] = childrenValues;
    }

    if (columnID === 'cost') {
        calculatedValues['unit_cost'] = data['unit_cost']
    }

    console.log(calculatedValues)

    return data.type === 'assembly' ? AssemblyCalculations[columnID](calculatedValues) : InputCalculations[columnID](calculatedValues);
}


export {
    InputTypes,
    InputCalculated,
    InputEditable,
    InputCalculations,
    calculatedFieldsDependencies,
    CalculateValue,
    GroupFields,
    ColumnLabels,
    ColumnDescriptions,
    AssemblyFields,
    AssemblyCalculatedFields,
    AssemblyCalculations,
    AssemblyEntryFields,
}