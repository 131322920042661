import React, { useState } from 'react';

import "./styles/DeleteButton.css";

export default function DeleteButton({ text, handleClick, children }) {
    return (
        <button 
            className='delete-button'
            onClick={handleClick}
        >
            {text}
            {children}
        </button>
    );
}
