import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { IconArrowLeft, IconArrowRight, IconDownload, IconRobot, IconTrashX, IconUpload, IconZoomIn, IconZoomOut } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useDropzone } from "react-dropzone";
import TextareaAutosize from 'react-textarea-autosize';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import ReactPanZoom from 'react-image-pan-zoom-rotate';

import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';

import ProjectDetailsNavbar from './components/ProjectDetailsNavbar';
import DeleteButton from '../components/DeleteButton';
import DefaultButton from '../components/DefaultButton';
import SidebarFile from './components/SidebarFile';

import './styles/ProjectDetails.css';
import ProjectDetailsPage from './components/ProjectDetailsPage';
import { Blocks } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';
import ProjectDetailsModal from './ProjectDetailsModal';
import NotFoundPage from '../components/NotFoundPage';

export default function ProjectDetails({ }) {
    const params = useParams();
    const projectUUID = params.projectUUID;

    const auth = useSelector(selectAuth);

    const [project, setProject] = useState(null);

    const [loading, setLoading] = useState(true);
    const [validProject, setValidProject] = useState(true);
    const [files, setFiles] = useState(null);

    const [currentPage, setCurrentPage] = useState(null);
    const [currentFile, setCurrentFile] = useState(null);

    const [zoom, setZoom] = useState(200);
    const [shiftAnchor, setShiftAnchor] = useState(null);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPagePreview, setShowPagePreview] = useState(false);
    const [showProjectDetails, setShowProjectDetails] = useState(false);

    const [uploadProgress, setUploadProgress] = useState({});

    const onDrop = async (acceptedFiles) => {
        console.log(acceptedFiles);

        for (let i = 0; i < acceptedFiles.length; i++) {
            const file = acceptedFiles[i];
            if (!file) return null;

            const presignedData = (await axios({
                method: 'post',
                url: `${API_ROUTE}/api/aws-presigned-headers-api/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
                data: { filename: file.name.replace(/[^a-zA-Z0-9.]/g, ' ') }
            })).data;

            //console.log(presignedData);

            const formData = new FormData();
            Object.keys(presignedData.fields).forEach((field) => formData.append(field, presignedData.fields[field]));
            formData.append('file', file);

            const key = presignedData.fields.key;

            toast.info((`Uploading ${file.name.replace(/[^a-zA-Z0-9.]/g, ' ')}...`), {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: 1 / 100,
                theme: "light",
                toastId: key
            })

            const onUploadProgress = (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //console.log(percentCompleted, file.name);

                toast.update(key, {
                    progress: 0.35 * percentCompleted / 100 + 0.01,
                });
            }

            const awsFileUploadPromise = axios.post(presignedData.url, formData, { 'Content-Type': 'multipart/form-data', ...presignedData.fields, onUploadProgress }).then(() => {
                return presignedData.fields.key;
            });

            //console.log(awsFileUploadPromise);

            const djangoFileCreationPromise = awsFileUploadPromise.then((key) => axios({
                method: 'post',
                url: `${API_ROUTE}/api/project-files/`,
                data: {
                    key: key,
                    name: file.name.replace(/[^a-zA-Z0-9.]/g, ' '),
                    projectUUID: projectUUID
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                }
            }))

            //console.log(djangoFileCreationPromise);
        }
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png', '.jpg', '.jpeg'],
            'application/pdf': ['.pdf']
        }
    })

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        `${WEBSOCKET_ROUTE}/file-upload-consumer/${projectUUID}/`,
        {
            heartbeat: {
                message: 'ping',
                returnMessage: 'pong',
                timeout: 60000, // 1 minute, if no response is received, the connection will be closed
                interval: 25000, // every 25 seconds, a ping message will be sent
            },
            onMessage: (e) => {
                const data = JSON.parse(e.data);
                console.log(data);

                if (data.type === 'project_details_updates') {
                    if (data.is_upload_complete) {
                        axios({
                            method: 'get',
                            url: `${API_ROUTE}/api/project-details/`,
                            params: {
                                'file_id': data.file_id
                            },
                            headers: {
                                'Authorization': `Token ${auth.token}`,
                                "Content-Type": "application/json"
                            },
                        })
                            .then((response) => {
                                console.log(response);

                                setFiles(prev => ({
                                    ...prev,
                                    [data.file_id]: response.data
                                }));
                            })
                            .catch((error) => {
                                console.log(error);
                            });

                        toast.done(data.key);
                    } else if (Number(data.progress) === -1) {
                        setUploadProgress(prev => ({
                            ...prev,
                            [data.key]: prev[data.key] + 1
                        }));

                        toast.update(data.key, { progress: Math.min(Math.max(0, 0.5 + 0.5 * (uploadProgress[data.key] + 1 - 50) / data.page_count), 0.99) });
                    } else {
                        setUploadProgress(prev => ({
                            ...prev,
                            [data.key]: data.progress
                        }));

                        toast.update(data.key, { progress: data.progress / 100 });
                    }
                }
            },
            onClose: (e) => {
                console.log(e);
            },
            shouldReconnect: (closeEvent) => true,
            onOpen: (e) => {
                console.log(e);
            },
        }
    );

    useEffect(() => {
        if (auth.token && !files) {
            axios({
                method: 'post',
                url: `${API_ROUTE}/api/project-details/`,
                data: {
                    'projectUUID': projectUUID
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setLoading(false);
                    setFiles(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (auth.token && !project) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/projects/`,
                params: {
                    'projectUUID': projectUUID
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setProject(response.data[0]);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, projectUUID]);

    const handleDelete = (fileID) => {
        setFiles(prev => {
            let newFiles = { ...prev };
            delete newFiles[fileID];
            return newFiles;
        })
        setCurrentFile(null);
        setShowDeleteModal(false);

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/files/${fileID}/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUpload = async (e) => {
        //console.log(e.target.files);

        for (let i = 0; i < e.target.files.length; i++) {
            const file = e.target.files[i];
            if (!file) return null;

            const presignedData = (await axios({
                method: 'post',
                url: `${API_ROUTE}/api/aws-presigned-headers-api/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
                data: { filename: file.name.replace(/[^a-zA-Z0-9.]/g, ' ') }
            })).data;

            //console.log(presignedData);

            const formData = new FormData();
            Object.keys(presignedData.fields).forEach((field) => formData.append(field, presignedData.fields[field]));
            formData.append('file', file);

            const key = presignedData.fields.key;

            toast.info((`Uploading ${file.name.replace(/[^a-zA-Z0-9.]/g, ' ')}...`), {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: 1 / 100,
                theme: "light",
                toastId: key
            })

            const onUploadProgress = (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                //console.log(percentCompleted, file.name);

                toast.update(key, {
                    progress: 0.35 * percentCompleted / 100 + 0.01,
                });
            }

            const awsFileUploadPromise = axios.post(presignedData.url, formData, { 'Content-Type': 'multipart/form-data', ...presignedData.fields, onUploadProgress }).then(() => {
                return presignedData.fields.key;
            });

            //console.log(awsFileUploadPromise);

            const djangoFileCreationPromise = awsFileUploadPromise.then((key) => axios({
                method: 'post',
                url: `${API_ROUTE}/api/project-files/`,
                data: {
                    key: key,
                    name: file.name.replace(/[^a-zA-Z0-9.]/g, ' '),
                    projectUUID: projectUUID
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                }
            }))

            //console.log(djangoFileCreationPromise);
        }

        e.target.value = null;
    }

    if (project && !auth?.contractor?.bobyard_admin && auth?.contractor?.company !== project?.company) {
        return (
            <NotFoundPage />
        )
    }

    if (loading) {
        return (
            <>
                <div className='projectdetailsnavbar-container'>
                    <div className="shrink-0">
                        <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='h-10' />
                    </div>

                    <div>
                        <Skeleton height={20} width={200} />
                    </div>

                    <div>
                        <Skeleton height={20} width={200} />
                    </div>

                    <div className='projectdetailsnavbar-profile-picture'>
                        <img
                            src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                            className='object-cover w-10 h-10 rounded-full'
                        />
                    </div>
                </div>

                <div className='projectdetails-container'>
                    <div className='projectdetails-sidebar'>
                        <div className='projectdetails-files-loading'>
                            <Skeleton height={30} />

                            <br></br>
                            <Skeleton count={1.8} />

                            <br></br>
                            <Skeleton count={5} />

                            <br></br>
                            <Skeleton count={10.9} />

                            <br></br>
                            <Skeleton count={9.6} />
                        </div>
                    </div>

                    <div className='projectdetails-content'>
                        <div className='projectdetails-content-loading'>
                            <Blocks
                                visible={true}
                                height="60"
                                width="60"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                radius="10"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <ProjectDetailsNavbar auth={auth} projectUUID={projectUUID} project={project} setShowProjectDetails={setShowProjectDetails}
                pageSelect={Object.values(files).reduce((acc, file) => acc + Object.values(file.pages).filter((page) => page.is_included).length, 0)}
            />

            <ToastContainer
                className={'projectdetail-toastcontainer'}
                position="top-right"
            />

            {showProjectDetails && project &&
                <ProjectDetailsModal
                    project={project}
                    setProject={setProject}
                    showProjectDetails={showProjectDetails}
                    setShowProjectDetails={setShowProjectDetails}
                />
            }

            {showDeleteModal && files && currentFile &&
                <DeleteFileConfirmation
                    showDeleteModal={showDeleteModal}
                    setShowDeleteModal={setShowDeleteModal}
                    handleDelete={handleDelete}
                    currentFile={currentFile}
                    files={files}
                />
            }

            {showPagePreview && files && currentPage &&
                <PagePreview
                    showPagePreview={showPagePreview}
                    setShowPagePreview={setShowPagePreview}
                    files={files}
                    setFiles={setFiles}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    currentFile={currentFile}
                />
            }

            <div className='projectdetails-container'>
                <div className='projectdetails-sidebar'>
                    <div className='projectdetails-sidebar-header'>
                        <div className='projectdetails-sidebar-header-title'>
                            Files
                        </div>

                        <input
                            className='projectdetails-upload-input'
                            id='upload-file'
                            type='file'
                            multiple
                            accept='.pdf,.png,.jpg,.jpeg'
                            onChange={(e) => handleUpload(e)}
                        />

                        <button
                            id='projectdetails-upload-icon'
                            className='projectdetails-upload-button'
                            onClick={() => document.getElementById('upload-file').click()}
                        >
                            <IconUpload
                                size={28}
                            />
                        </button>

                        <Tooltip anchorSelect='#projectdetails-upload-icon' delayShow={1000}>
                            Upload file
                        </Tooltip>
                    </div>

                    <div className='projectdetails-files-container'>
                        {loading
                            ? <div className='projectdetails-loading'>
                                <Blocks
                                    visible={true}
                                    height="60"
                                    width="60"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    radius="10"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            </div>
                            : (files && Object.values(files)?.map((file) =>
                                <SidebarFile
                                    key={file.id}
                                    file={file}
                                    currentFile={currentFile}
                                    setCurrentFile={setCurrentFile}
                                    setShowDeleteModal={setShowDeleteModal}
                                />
                            ))
                        }
                    </div>
                </div>

                <div className='projectdetails-content'>
                    {currentFile
                        ? <div
                            className='projectdetails-pages-container'
                            style={{
                                gridTemplateColumns: `repeat(auto-fill, minmax(${zoom}px, 1fr))`
                            }}
                        >
                            {Object.values(files[currentFile].pages).sort((a, b) => a.page_number - b.page_number).map((page) =>
                                <ProjectDetailsPage
                                    key={page.id}
                                    page={page}
                                    files={files}
                                    setFiles={setFiles}
                                    shiftAnchor={shiftAnchor}
                                    setShiftAnchor={setShiftAnchor}
                                    setShowPagePreview={setShowPagePreview}
                                    setCurrentPage={setCurrentPage}
                                    zoom={zoom}
                                />
                            )}
                        </div>
                        : <div
                            className='projectdetails-upload-placeholder'
                            onClick={() => document.getElementById('upload-file').click()}
                            {...getRootProps()}
                            style={{
                                cursor: 'pointer',
                                border: isDragActive && '2px dashed #006AFF',
                                backgroundColor: isDragActive && 'aliceblue'
                            }}
                        >
                            <input
                                {...getInputProps()}
                            />

                            <div className='projectdetails-upload-placeholder-title'>
                                <div>
                                    <IconUpload size={100} />
                                </div>
                            </div>
                            <div
                                className='projectdetails-upload-placeholder-body'
                                style={{
                                    color: isDragActive && '#006AFF !important'
                                }}
                            >
                                {isDragActive
                                    ? <>Drop the files here</>
                                    : <>Drag & drop, or click to select</>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>

            {files &&
                <InfoPopup
                    files={files}
                    currentFile={currentFile}
                    setCurrentFile={setCurrentFile}
                    setFiles={setFiles}
                    setZoom={setZoom}
                />
            }
        </>
    )
}

function InfoPopup({ files, currentFile, setCurrentFile, setFiles, setZoom }) {
    const auth = useSelector(selectAuth);

    const handleToggleAll = (isIncluded) => {
        setFiles(prev => ({
            ...prev,
            [currentFile]: {
                ...prev[currentFile],
                pages: Object.values(prev[currentFile].pages).reduce((acc, page) => {
                    acc[page.id] = {
                        ...page,
                        is_included: isIncluded
                    }
                    return acc;
                }, {})
            }
        }));

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/project-details-toggle-pages/`,
            data: {
                pages: Object.values(files[currentFile].pages).map((page) => page.id),
                is_included: isIncluded
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className='projectdetails-infopopup-container'>
            <div className='projectdetails-infopopup-page-count'>
                {Object.values(files).reduce((acc, file) => acc + Object.values(file.pages).filter((page) => page.is_included).length, 0)
                    ? <><b>{Object.values(files).reduce((acc, file) => acc + Object.values(file.pages).filter((page) => page.is_included).length, 0)}</b> included in all files</>
                    : <b>Click on pages to include for takeoff</b>
                }
            </div>

            {currentFile &&
                <div className='projectdetails-infopopup-zoom'>
                    <div
                        className='projectdetails-pages-header-button'
                        onClick={() => {
                            setZoom(prev => Math.min(400, prev + prev / 4));
                        }}
                    >
                        <IconZoomIn size={20} stroke={1} />
                        <div>Zoom in</div>
                    </div>
                    <div
                        className='projectdetails-pages-header-button'
                        onClick={() => {
                            setZoom(prev => Math.max(150, prev - prev / 4));
                        }}
                    >
                        <IconZoomOut size={20} stroke={1} />
                        <div>
                            Zoom out
                        </div>
                    </div>
                </div>
            }

            {currentFile &&
                (Object.values(files[currentFile].pages).every((page) => page.is_included)
                    ? <div
                        className='projectdetails-infopopup-selectall'
                        onClick={() => handleToggleAll(false)}
                    >
                        <div>Deselect entire file</div>

                        <div
                            className='projectdetails-infopopup-status-icon'
                            style={{
                                backgroundColor: '#006AFF'
                            }}
                        >
                        </div>
                    </div>
                    : <div
                        className='projectdetails-infopopup-selectall'
                        onClick={() => handleToggleAll(true)}
                    >
                        <div>Select entire file</div>

                        <div className='projectdetails-infopopup-status-icon'>
                            &nbsp;
                        </div>
                    </div>
                )
            }
        </div>
    )
}

function PagePreview({ showPagePreview, setShowPagePreview, files, setFiles, currentPage, setCurrentPage, currentFile }) {
    const auth = useSelector(selectAuth);

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        //console.log('loading');
    }, [currentPage]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'ArrowLeft') {
                if (files[currentFile].pages[currentPage].page_number > 1) {
                    setCurrentPage(Object.values(files[currentFile].pages).filter((p) => p.page_number < files[currentFile].pages[currentPage].page_number).sort((a, b) => b.page_number - a.page_number)[0].id);
                } else {
                    setCurrentPage(Object.values(files[currentFile].pages).sort((a, b) => b.page_number - a.page_number)[0].id);
                }
            } else if (e.key === 'ArrowRight') {
                if (files[currentFile].pages[currentPage].page_number < Object.values(files[currentFile].pages).sort((a, b) => b.page_number - a.page_number)[0].page_number) {
                    setCurrentPage(Object.values(files[currentFile].pages).filter((p) => p.page_number > files[currentFile].pages[currentPage].page_number).sort((a, b) => a.page_number - b.page_number)[0].id);
                } else {
                    setCurrentPage(Object.values(files[currentFile].pages).sort((a, b) => a.page_number - b.page_number)[0].id);
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [currentPage, files, currentFile]);

    const handleTogglePage = (pageID, isIncluded) => {
        let page = files[currentFile].pages[pageID];

        setFiles(prev => ({
            ...prev,
            [page.parent_file]: {
                ...prev[page.parent_file],
                pages: {
                    ...prev[page.parent_file].pages,
                    [page.id]: {
                        ...prev[page.parent_file].pages[page.id],
                        is_included: !prev[page.parent_file].pages[page.id].is_included
                    }
                }
            }
        }))

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${page.id}/`,
            data: {
                parent_file: page.parent_file,
                project: page.project,
                is_included: isIncluded,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response.data);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    return (
        < Modal
            show={showPagePreview}
            onHide={() => setShowPagePreview(false)
            }
            size='xl'
        >
            <Modal.Header
                style={{
                    padding: '1%',
                    paddingLeft: '2%',
                    paddingRight: '2%',
                }}
            >
                <div className="projectdetails-pagepreview-title">
                    <div className='projectdetails-pagepreview-title-pagenumber'>
                        {files[currentFile]?.pages[currentPage]?.title
                            ? files[currentFile]?.pages[currentPage]?.title
                            : <>
                                Page #{files[currentFile]?.pages[currentPage]?.page_number}
                            </>
                        }
                    </div>

                    {/*<PreviewImageZoomControls />*/}

                    <div className='projectdetails-pagepreview-navigation'>
                        <div
                            id='projectdetails-pagepreview-navigation-button-left'
                            className='projectdetails-pagepreview-navigation-button'
                            onClick={() => {
                                if (files[currentFile].pages[currentPage].page_number > 1) {
                                    setCurrentPage(Object.values(files[currentFile].pages).filter((p) => p.page_number < files[currentFile].pages[currentPage].page_number).sort((a, b) => b.page_number - a.page_number)[0].id);
                                } else {
                                    setCurrentPage(Object.values(files[currentFile].pages).sort((a, b) => b.page_number - a.page_number)[0].id);
                                }
                            }}
                        >
                            <IconArrowLeft size={24} />
                        </div>

                        <Tooltip anchorSelect='#projectdetails-pagepreview-navigation-button-left' delayShow={1000} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                            Previous page (Left Arrow)
                        </Tooltip>

                        <div
                            id='projectdetails-pagepreview-navigation-button-right'
                            className='projectdetails-pagepreview-navigation-button'
                            onClick={() => {
                                if (files[currentFile].pages[currentPage].page_number < Object.values(files[currentFile].pages).sort((a, b) => b.page_number - a.page_number)[0].page_number) {
                                    setCurrentPage(Object.values(files[currentFile].pages).filter((p) => p.page_number > files[currentFile].pages[currentPage].page_number).sort((a, b) => a.page_number - b.page_number)[0].id);
                                } else {
                                    setCurrentPage(Object.values(files[currentFile].pages).sort((a, b) => a.page_number - b.page_number)[0].id);
                                }
                            }}
                        >
                            <IconArrowRight size={24} />
                        </div>

                        <Tooltip anchorSelect='#projectdetails-pagepreview-navigation-button-right' delayShow={1000} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                            Next page (Right Arrow)
                        </Tooltip>
                    </div>

                    {files[currentFile]?.pages[currentPage]?.is_included
                        ? <div
                            id='projectdetails-pagepreview-title-toggle-exclude'
                            className='projectdetails-pagepreview-title-toggle'
                            onClick={() => handleTogglePage(currentPage, !files[currentFile].pages[currentPage].is_included)}
                        >
                            <div>Exclude</div>

                            <div
                                className='projectdetails-infopopup-status-icon'
                                style={{
                                    backgroundColor: '#006AFF'
                                }}
                            >
                            </div>
                        </div>
                        : <div
                            id='projectdetails-pagepreview-title-toggle-include'
                            className='projectdetails-pagepreview-title-toggle'
                            onClick={() => handleTogglePage(currentPage, !files[currentFile].pages[currentPage].is_included)}
                        >
                            <div>Include</div>

                            <div className='projectdetails-infopopup-status-icon'>
                                &nbsp;
                            </div>
                        </div>
                    }

                    <Tooltip anchorSelect='#projectdetails-pagepreview-title-toggle-include' delayShow={1000} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                        Include page in takeoff
                    </Tooltip>

                    <Tooltip anchorSelect='#projectdetails-pagepreview-title-toggle-exclude' delayShow={1000} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                        Exclude page from takeoff
                    </Tooltip>

                    <div>
                        {files?.[currentFile]?.pages[currentPage]?.ai_prepped
                            ? <IconRobot size={20} stroke={1.2} />
                            : null
                        }
                    </div>

                    <div>
                        {files?.[currentFile]?.pages?.[currentPage]?.measurements
                            ? <nobr>
                                {files?.[currentFile]?.pages?.[currentPage]?.measurements} measurement{files?.[currentFile]?.pages?.[currentPage]?.measurements === 1 ? '' : 's'}
                            </nobr>
                            : <nobr>
                                0 measurements
                            </nobr>
                        }
                    </div>

                    {/*<div className='projectdetails-pagepreview-title-filename'>
                        {files[currentFile]?.name}
                    </div>*/}
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='projectdetails-pagepreview-image-container'>
                    <ReactPanZoom
                        alt={files[currentFile]?.pages[currentPage]?.name}
                        image={files[currentFile]?.pages[currentPage]?.thumbnail}
                    />
                </div>

                {/*<div className='projectdetails-pagepreview-loading'>
                        {loaded ? null : <Blocks
                            visible={true}
                            height="40"
                            width="40"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />}
                    </div>*/}

                {/*<img
                        className='projectdetails-pagepreview-image'
                        style={{
                            //display: loaded ? 'block' : 'none'
                        }}
                        src={files[currentFile]?.pages[currentPage]?.thumbnail}
                        alt={files[currentFile]?.pages[currentPage]?.name}
                        onLoad={() => {
                            setLoaded(true);
                            //console.log('loaded');
                        }}
                    />*/}
            </Modal.Body>
        </Modal >
    )
}

function DeleteFileConfirmation({ showDeleteModal, setShowDeleteModal, handleDelete, currentFile, files }) {
    return (
        <Modal
            show={showDeleteModal}
            onHide={() => setShowDeleteModal(false)}
            size='small'
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="deletemeasurementmodal-title">
                        Delete File
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Are you sure you want to delete <b>{files?.[currentFile]?.name}</b> and all its associated pages & measurements?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DeleteButton
                    handleClick={() => handleDelete(currentFile)}
                >
                    Delete
                </DeleteButton>
                <DefaultButton
                    handleClick={() => setShowDeleteModal(false)}
                >
                    Cancel
                </DefaultButton>
            </Modal.Footer>
        </Modal>
    )
}