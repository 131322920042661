
import { useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { Rect, Stage, Layer, Image } from 'react-konva';
import { Html } from "react-konva-utils";
import useImage from 'use-image';
import axios from 'axios';

import { IconArrowsMove, IconDirectionArrows, IconWindowMaximize, IconWindowMinimize, IconX } from '@tabler/icons-react';

import { TakeoffContext } from './helper/Context';

import './styles/MiniMap.css';

import { API_ROUTE } from '..';
import { selectAuth } from '../redux/slices/authSlice';
import DefaultButton from '../components/DefaultButton';
import ButtonHoverInfo from '../components/ButtonHoverInfo';

export default function MiniMap({ url }) {
    const auth = useSelector(selectAuth)

    const {
        previewURL, setPreviewURL,
        pageID,
        pages, setPages,
        project, setProject,
        takeoffSettings,
        showMiniMap, setShowMiniMap,
    } = useContext(TakeoffContext);

    const [image] = useImage(url);

    const WIDTH = 200;
    const ratio = 200 / pages[pageID].width;
    const HEIGHT = pages[pageID].height * ratio;

    const [dragging, setDragging] = useState(false)
    const [rel, setRel] = useState(null)
    const [pos, setPos] = useState({
        x: project?.minimap_x
            ? project.minimap_x
            : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left'
                ? document.getElementsByClassName('pagessidebar').offsetWidth + 20
                : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left'
                    ? document.getElementsByClassName('aisidebar').offsetWidth + 20
                    : takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left'
                        ? document.getElementsByClassName('measurementsidebar').offsetWidth + 20
                        : '',
        y: project?.minimap_y ? project.minimap_y : ''
    })

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }
    }, [dragging])

    const onMouseDown = (e) => {
        if (e.button !== 0) return
        let temp;

        if (takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left'
            || takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left'
            || takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left') {
            temp = document.getElementsByClassName('takeoff-minimap-container-sidebar')[0].getBoundingClientRect()
        } else {
            temp = document.getElementsByClassName('takeoff-minimap-container')[0].getBoundingClientRect()
        }

        setDragging(true)
        setRel({
            x: e.pageX - temp.left,
            y: e.pageY - temp.top
        })
    }

    const onMouseUp = (e) => {
        if (dragging) {
            axios({
                method: 'PUT',
                url: `${API_ROUTE}/api/projects/${project.id}/`,
                data: {
                    author: project.author,
                    company: project.company,
                    minimap_x: e.pageX - rel.x,
                    minimap_y: e.pageY - rel.y,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    console.log(res);

                    setProject(prev => ({
                        ...prev,
                        minimap_x: e.pageX - rel.x,
                        minimap_y: e.pageY - rel.y,
                    }));
                })
                .catch(err => {
                    console.log(err);
                })
        }

        setDragging(false)
    }

    const onMouseMove = (e) => {
        if (!dragging) return

        //dont allow to drag outside of the screen, can drag left, right, up and down
        if (e.pageX - rel.x < 0) {
            setPos(prev => ({
                ...prev,
                x: '0px',
            }))
        } else if (e.pageX - rel.x > window.innerWidth - WIDTH) {
            setPos(prev => ({
                ...prev,
                x: window.innerWidth - WIDTH + 'px',
            }))
        } else {
            setPos(prev => ({
                ...prev,
                x: e.pageX - rel.x + 'px',
            }))
        }

        if (e.pageY - rel.y < window.innerHeight / 100 * 6) {
            setPos(prev => ({
                ...prev,
                y: '4vh'
            }))
        } else if (e.pageY - rel.y > window.innerHeight - HEIGHT) {
            setPos(prev => ({
                ...prev,
                y: window.innerHeight - HEIGHT + 'px',
            }))
        } else {
            setPos(prev => ({
                ...prev,
                y: e.pageY - rel.y + 'px',
            }))
        }
    }

    const handleReset = (e) => {
        e.stopPropagation();
        setPos({
            x: '',
            y: '',
        })

        axios({
            method: 'PUT',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                minimap_x: null,
                minimap_y: null,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res);

                setProject(prev => ({
                    ...prev,
                    minimap_x: '',
                    minimap_y: '',
                }));
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDragEnd = (e, minimap) => {
        let newPosition = {
            x: -e.target.x() * pages[pageID].zoom * pages[pageID].width / WIDTH,
            y: -e.target.y() * pages[pageID].zoom * pages[pageID].height / HEIGHT,
        };

        if (minimap) {
            newPosition = {
                x: -e.evt.layerX / ratio * pages[pageID].zoom + window.innerWidth / 2,
                y: -e.evt.layerY / ratio * pages[pageID].zoom + window.innerHeight / 2,
            };
        }

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pages/${pageID}/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                project: pages[pageID].project,
                position_x: newPosition.x,
                position_y: newPosition.y,
                zoom: pages[pageID].zoom,
            }
        })
            .then(res => {
                console.log(res);

                setPages(prev => ({
                    ...prev,
                    [pageID]: {
                        ...prev[pageID],
                        position_x: newPosition.x,
                        position_y: newPosition.y,
                    }
                }));
            })
            .catch(err => {
                console.log(err);
            })
    }

    if (pages?.[pageID]?.width * pages?.[pageID]?.zoom < window.innerWidth * 1.5 && pages?.[pageID]?.height * pages?.[pageID]?.zoom < window.innerHeight * 1.5) {
        return null;
    }

    if (!showMiniMap) {
        return (
            <div className={"takeoff-minimap-expand"}
                style={{
                    left: pos.x
                        ? pos.x
                        : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left'
                            ? document.getElementById('pagessidebar')?.offsetWidth + 5
                            : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left'
                                ? document.getElementById('aisidebar')?.offsetWidth + 5
                                : takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left'
                                    ? document.getElementById('measurementsidebar')?.offsetWidth + 5
                                    : 5,
                }}
            >
                <ButtonHoverInfo
                    message={'Show minimap'}
                    top={-35}
                    left={0}
                    size={'small'}
                >
                    <DefaultButton
                        handleClick={() => setShowMiniMap(true)}
                        size={'small'}
                    >
                        <IconWindowMaximize size={20} stroke={1} />
                    </DefaultButton>
                </ButtonHoverInfo>
            </div>
        )
    }

    if (takeoffSettings?.show_measurement_sidebar && !document.getElementById('measurementsidebar')
        || takeoffSettings?.show_pages_sidebar && !document.getElementById('pagessidebar')
        || takeoffSettings?.show_ai_sidebar && !document.getElementById('aisidebar')) {
        setTimeout(() => {
            setPos({ x: pos.x, y: pos.y });
        }, 50);
    }

    return (
        <div
            className={"takeoff-minimap-container"}
            style={{
                flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                left: pos.x
                    ? pos.x
                    : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left'
                        ? document.getElementById('pagessidebar')?.offsetWidth + 5
                        : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left'
                            ? document.getElementById('aisidebar')?.offsetWidth + 5
                            : takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left'
                                ? document.getElementById('measurementsidebar')?.offsetWidth + 5
                                : 5,
                top: pos.y ? pos.y : '',
            }}
        >
            <Stage
                width={WIDTH}
                height={HEIGHT}
            >
                <Layer>
                    <Image
                        image={image}
                        width={WIDTH}
                        height={HEIGHT}
                        onMouseOver={(e) => {
                            e.target.getStage().container().style.cursor = 'crosshair';
                        }}
                        onMouseOut={(e) => {
                            e.target.getStage().container().style.cursor = 'default';
                        }}
                        onClick={(e) => {
                            handleDragEnd(e, true);
                        }}
                    />
                </Layer>
                <Layer>
                    <Rect
                        x={-pages[pageID].position_x / pages[pageID].zoom * WIDTH / pages[pageID].width}
                        y={-pages[pageID].position_y / pages[pageID].zoom * HEIGHT / pages[pageID].height}
                        width={window.innerWidth / pages[pageID].zoom * WIDTH / pages[pageID].width}
                        height={window.innerHeight / pages[pageID].zoom * HEIGHT / pages[pageID].height}
                        fill='lightblue'
                        opacity={0.3}
                        stroke="#006aef"
                        strokeWidth={1}
                        draggable={true}
                        onMouseOver={(e) => {
                            e.target.getStage().container().style.cursor = 'move';
                        }}
                        onMouseOut={(e) => {
                            e.target.getStage().container().style.cursor = 'default';
                        }}
                        onDragMove={(e) => {
                            setPages(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev[pageID],
                                    position_x: -e.target.x() * pages[pageID].zoom * pages[pageID].width / WIDTH,
                                    position_y: -e.target.y() * pages[pageID].zoom * pages[pageID].height / HEIGHT,
                                }
                            }));
                        }}
                        onDragEnd={(e) => {
                            setPages(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev[pageID],
                                    position_x: -e.target.x() * pages[pageID].zoom * pages[pageID].width / WIDTH,
                                    position_y: -e.target.y() * pages[pageID].zoom * pages[pageID].height / HEIGHT,
                                }
                            }));

                            handleDragEnd(e);
                        }}
                        dragBoundFunc={(pos) => {
                            if (pos.x < 0 - window.innerWidth / pages[pageID].zoom * WIDTH / pages[pageID].width + 20) {
                                pos.x = 0 - window.innerWidth / pages[pageID].zoom * WIDTH / pages[pageID].width + 20;
                            }

                            if (pos.x > WIDTH - 20) {
                                pos.x = WIDTH - 20;
                            }

                            if (pos.y < 0 - window.innerHeight / pages[pageID].zoom * HEIGHT / pages[pageID].height + 20) {
                                pos.y = 0 - window.innerHeight / pages[pageID].zoom * HEIGHT / pages[pageID].height + 20;
                            }

                            if (pos.y > HEIGHT - 20) {
                                pos.y = HEIGHT - 20;
                            }

                            return pos;
                        }}
                    />
                </Layer>
                <Layer>
                    <Html>
                        <div className='takeoff-minimap-minimize'>
                            <ButtonHoverInfo
                                message={'Hide minimap'}
                                top={20}
                                left={0}
                                size={'small'}
                            >
                                <DefaultButton
                                    handleClick={() => setShowMiniMap(false)}
                                    size={'small'}
                                >
                                    <IconX size={15} stroke={1} />
                                </DefaultButton>
                            </ButtonHoverInfo>

                            {/*<div
                                onMouseDown={onMouseDown}
                            >
                                <ButtonHoverInfo
                                    message={'Move minimap'}
                                    top={20}
                                    left={0}
                                    size={'small'}
                                >
                                    <DefaultButton
                                        size={'small'}
                                    >
                                        <IconArrowsMove size={15} stroke={1} />
                                    </DefaultButton>
                                </ButtonHoverInfo>
                    </div>*/}

                            {pos.x && pos.y ?
                                <ButtonHoverInfo
                                    message={'Reset position'}
                                    top={20}
                                    left={0}
                                    size={'small'}
                                >
                                    <DefaultButton
                                        size={'small'}
                                        handleClick={(e) => handleReset(e)}
                                    >
                                        <IconWindowMinimize size={15} stroke={1} />
                                    </DefaultButton>
                                </ButtonHoverInfo>
                                : null}
                        </div>
                    </Html>
                </Layer>
            </Stage>
        </div>
    )
}