import clsx from 'clsx';
import React, { forwardRef } from 'react';

import './FolderTreeItemWrapper.css';

function flattenParents(parent) {
  if (!parent) return [];
  return [...flattenParents(parent.parent), parent];
}

export const FolderTreeItemWrapper = forwardRef((props, ref) => {
  const {
    clone,
    depth,
    disableSelection,
    disableInteraction,
    disableSorting,
    ghost,
    handleProps,
    indentationWidth,
    indicator,
    collapsed,
    onCollapse,
    onRemove,
    item,
    wrapperRef,
    style,
    isLast,
    parent,
    hideCollapseButton,
    childCount,
    manualDrag,
    showDragHandle,
    disableCollapseOnItemClick,
    className,
    contentClassName,
    isOver,
    isOverParent,
    ...rest
  } = props;
  const flattenedParents = flattenParents(parent);
  return (
    <li
      {...rest}
      className={clsx(
        'estimate-dnd-sortable-tree_folder_wrapper',
        clone && 'estimate-dnd-sortable-tree_folder_clone',
        ghost && 'estimate-dnd-sortable-tree_folder_ghost',
        disableSelection && 'estimate-dnd-sortable-tree_folder_disable-selection',
        disableInteraction && 'estimate-dnd-sortable-tree_folder_disable-interaction',
        className
      )}
      ref={wrapperRef}
      style={{
        ...style,
      }}
    >
      {flattenedParents.map((item) => (
        <div key={item.id} className={item.isLast ? 'estimate-dnd-sortable-tree_folder_line-last' : 'estimate-dnd-sortable-tree_folder_line'} />
      ))}
      <div className={isLast ? 'estimate-dnd-sortable-tree_folder_line-to_self-last' : 'estimate-dnd-sortable-tree_folder_line-to_self'} />
      {manualDrag && showDragHandle && !disableSorting && <div className={'estimate-dnd-sortable-tree_folder_handle'} {...handleProps} />}
      {!manualDrag && !hideCollapseButton && !!onCollapse && !!childCount && (
        <button
          onClick={(e) => {
            e.preventDefault();
            onCollapse?.();
          }}
          className={clsx('estimate-dnd-sortable-tree_folder_tree-item-collapse_button', collapsed && 'estimate-dnd-sortable-tree_folder_tree-item-collapse_button-collapsed')}
        />
      )}
      <div
        className={clsx('estimate-dnd-sortable-tree_folder_tree-item', contentClassName)}
        ref={ref}
        {...(manualDrag ? undefined : handleProps)}
        onClick={disableCollapseOnItemClick ? undefined : onCollapse}
      >
        {props.children}
      </div>
    </li>
  );
});
