import { batch, useSelector } from 'react-redux';
import { selectAuth } from '../redux/slices/authSlice';
import { useMemo, useState, useEffect } from 'react';
import { axiosCurry, eventValueLens, mergeFromLens, repsonseLens, responseDataLens, responseStatusLens } from '../utilities/utilities';
import { API_ROUTE, STRIPE_PUBLIC_KEY } from '..';
import { andThen, compose, identity, lensProp, map, otherwise, pipe, view, set, tap, isNil } from 'ramda';
import { Badge, Button, Modal, Table } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AddressElement } from '@stripe/react-stripe-js';
import { CustomerProfiles } from 'aws-sdk';
import { IconMailFast } from '@tabler/icons-react';
import DashboardNavbar from '../components/DashboardNavbar';
import ProfileSidebar from '../settings/Profile/ProfileSidebar';
import Skeleton from 'react-loading-skeleton';
import DefaultButton from '../components/DefaultButton';

const DEFAULT_COUNTRY = 'US';

//TODO: find a better place to put this
//TODO: also, this is the test key, we need to use the live key in production?????

const formatStripeDate = (timestamp) => {
  const adjusted = timestamp * 1000;
  const dateObj = new Date(adjusted);
  const yyyy = dateObj.getFullYear();
  const dd = String(dateObj.getDate()).padStart(2, '0');
  const mm = String(dateObj.getMonth() + 1).padStart(2, '0');
  return `${yyyy}-${mm}-${dd}`;
};
const statusVariantDict = { open: 'primary', paid: 'success', uncollectible: 'danger', void: 'warning', draft: 'info' };
const getBadgeStatusVariant = (status) => statusVariantDict[status] || 'secondary';

const remoteLens = lensProp('remote');
const statusLens = compose(remoteLens, lensProp('status'));
const dataLens = compose(remoteLens, lensProp('data'));
const invoicesListLens = compose(dataLens, lensProp('invoices_list'));
const invoiceItemsDictLens = compose(dataLens, lensProp('invoice_items_dict'));
const customerLens = compose(dataLens, lensProp('customer'));
const customerNameLens = compose(customerLens, lensProp('name'));
const customerEmailLens = compose(customerLens, lensProp('email'));
const customerAddressLens = compose(customerLens, lensProp('address'));
const customerAddressLine1Lens = compose(customerAddressLens, lensProp('line1'));
const customerAddressLine2Lens = compose(customerAddressLens, lensProp('line2'));
const customerAddressCityLens = compose(customerAddressLens, lensProp('city'));
const customerAddressStateLens = compose(customerAddressLens, lensProp('state'));
const customerAddressPostalCodeLens = compose(customerAddressLens, lensProp('postal_code'));
const customerCountryLens = compose(customerAddressLens, lensProp('country'));

const editorLens = lensProp('editor');
const isEditorOpenLens = compose(editorLens, lensProp('isOpen'));
const isEditorAddressCompleteLens = compose(editorLens, lensProp('isAddressComplete'));
const editorCustomerLens = compose(editorLens, lensProp('customer'));
const editorEmailLens = compose(editorCustomerLens, lensProp('email'));
const editorAddressLens = compose(editorCustomerLens, lensProp('address'));

const initialState = {
  remote: { status: null, data: { invoices_list: [], invoice_items_dict: {}, customer: { name: null, email: null, address: { country: DEFAULT_COUNTRY } } } },
  editor: { isOpen: false, isAddressComplete: false, customer: { email: null, address: {} } },
};

const openEditor = pipe(set(isEditorOpenLens)(true), (state) => set(editorEmailLens)(view(customerEmailLens)(state))(state));
const closeEditor = pipe(set(isEditorOpenLens)(false), set(isEditorAddressCompleteLens)(false), set(editorAddressLens)({}));

const Billing = () => {
  const auth = useSelector(selectAuth);
  const [state, setState] = useState(initialState);
  const viewState = (lens) => view(lens)(state);

  const billingAPI = useMemo(() => axiosCurry(`${API_ROUTE}/api/billing-api/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }), [API_ROUTE, auth.token]);
  useEffect(() => {
    pipe(billingAPI('get'), otherwise(view(repsonseLens)), andThen(mergeFromLens(remoteLens)), andThen(setState))();
  }, []);

  const stripe = useMemo(() => (STRIPE_PUBLIC_KEY ? loadStripe(STRIPE_PUBLIC_KEY) : null), [STRIPE_PUBLIC_KEY]);

  return (
    <>
      <div>
        <DashboardNavbar auth={auth} contractor={auth?.contractor} />
        <ProfileSidebar current={'billing'} />
        <div className='profile-container'>
          {pipe(viewState, isNil)(statusLens) && <Skeleton className='w-full h-full' />}

          {viewState(statusLens) == 403 && (
            <div className='flex flex-col items-center justify-center w-full h-full'>
              <span className='text-4xl'>Forbidden: You must be an administrator of your company to access this page</span>
            </div>
          )}

          {viewState(statusLens) == 200 && (
            <>
              <div className='company-header'>
                <div className='profile-title'>Billing</div>
                <DefaultButton handleClick={() => setState(openEditor)}>{viewState(customerAddressLens) ? 'Edit' : 'Add'} billing info</DefaultButton>
              </div>
              <div className='profile-details-row'>
                {viewState(customerAddressLens) && (
                  <>
                    <div className='profile-details-item'>
                      <div className='profile-details-name'>Name/Organization</div>
                      <div>
                        <div className='profile-details'>{viewState(customerNameLens)}</div>
                      </div>
                    </div>
                    <div className='profile-details-item'>
                      <div className='profile-details-name'>Email</div>
                      <div>
                        <div className='profile-details'>{viewState(customerEmailLens)}</div>
                      </div>
                    </div>
                    <div className='profile-details-item'>
                      <div className='profile-details-name'>Address</div>
                      <div className='profile-details'>
                        <div>{viewState(customerAddressLine1Lens)}</div>
                        <div>{viewState(customerAddressLine2Lens)}</div>
                        <div>
                          {viewState(customerAddressCityLens)}, {viewState(customerAddressStateLens)} {viewState(customerAddressPostalCodeLens)}
                        </div>
                        <div>{viewState(customerCountryLens)}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className='company-details-container'>
                <div className='company-details-header'>
                  <div className='profile-details-title'>Invoices</div>
                </div>

                <div className='company-details-invites'>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className='whitespace-nowrap'>Status</th>
                        <th className='whitespace-nowrap'>Amt Due</th>
                        <th className='whitespace-nowrap'>Amt Paid</th>
                        <th className='whitespace-nowrap'>Amt Left</th>
                        <th className='whitespace-nowrap'>Invoice #</th>
                        <th className='whitespace-nowrap'>Due</th>
                        <th className='whitespace-nowrap'>Created</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {pipe(
                        viewState,
                        map(({ status, id, currency, amount_due, amount_paid, amount_remaining, number, due_date, created }) => {
                          const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency }).format;
                          return (
                            <tr key={id}>
                              <td>
                                <Badge bg={getBadgeStatusVariant(status)}>{status}</Badge>
                              </td>
                              <td>{currencyFormatter(amount_due / 100)}</td>
                              <td>{currencyFormatter(amount_paid / 100)}</td>
                              <td>{currencyFormatter(amount_remaining / 100)}</td>
                              <td>{number}</td>
                              <td className='whitespace-nowrap'>{formatStripeDate(due_date)}</td>
                              <td className='whitespace-nowrap'>{formatStripeDate(created)}</td>
                              <td>
                                <DefaultButton handleClick={() => billingAPI('post')({ type: 'resend_invoice', id })} className='flex flex-row gap-1'>
                                  Resend <IconMailFast />
                                </DefaultButton>
                              </td>
                            </tr>
                          );
                        })
                      )(invoicesListLens)}
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <Modal show={viewState(isEditorOpenLens)} onHide={() => setState(closeEditor)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Billing Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='flex flex-col mb-3'>
            <label className='block mb-1 text-sm font-normal text-gray-800'>Email</label>
            <input
              value={viewState(editorEmailLens)}
              onChange={pipe(view(eventValueLens), set(editorEmailLens), setState)}
              className='block p-2.5 text-gray-800 border border-solid rounded-md shadow-xs w-100'
            />
          </div>
          <Elements stripe={stripe}>
            <AddressElement
              options={{ mode: 'billing', defaultValues: viewState(customerAddressLens) ? viewState(customerLens) : undefined }}
              onChange={({ complete, value }) => {
                setState(pipe(set(isEditorAddressCompleteLens)(complete), mergeFromLens(editorCustomerLens)(value)));
              }}
            />
          </Elements>
        </Modal.Body>
        <Modal.Footer>
          <DefaultButton handleClick={() => setState(closeEditor)}>Cancel</DefaultButton>
          <DefaultButton
            variant='primary'
            disabled={!viewState(isEditorAddressCompleteLens)}
            handleClick={() => {
              pipe(
                viewState,
                (customer) => billingAPI('patch')({ type: 'modify_customer', properties: { ...customer } }),
                andThen((newRemote) => mergeFromLens(remoteLens)(newRemote)(state)),
                andThen(closeEditor),
                andThen(setState)
              )(editorCustomerLens);
            }}
          >
            Update
          </DefaultButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Billing;
