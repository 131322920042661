import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import axios from 'axios';

import { useSelector, useDispatch } from 'react-redux';

import { selectAuth, updateAuth } from '../redux/slices/authSlice';

import { API_ROUTE } from '../index';

export default function ResetPasswordRequest() {
    const auth = useSelector(selectAuth);

    const [email, setEmail] = useState();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    useEffect(() => {
        if (auth?.user?.username) {
            window.location.assign('/dashboard');
        }
    }, [auth])

    const handleSubmit = (e) => {
        e.preventDefault();

        setError("");
        setSuccess("");

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/password_reset/`,
            data: {
                'email': email
            },
            headers: { 'Content-Type': 'application/json' },
        })
            .then((response) => {
                console.log(response);

                setSuccess("If the email exists in our system, you will receive an email with instructions to reset your password.");
            })
            .catch((error) => {
                console.log(error);

                setEmail("");
                setError("The email you entered does not exist in our system.");
            });
    }

    return (
        <div className="login-wrapper">
            <a href="/">
                <div className='login-logo-container'>
                    <img src={"https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png"} className='login-logo-image' />
                </div>
            </a>

            <div className='login-title'>
                Reset Password
            </div>

            <div className="login-container">
                <div className="error">{error}</div>
                <input
                    className='inputlogin'
                    onChange={e => setEmail(e.target.value)}
                    type="email"
                    placeholder="Email"
                />
            </div>

            {success
                ? <div className="success">{success}</div>
                : <button
                    type="submit"
                    className={email
                        ? "active-button login-button"
                        : "default-button login-button login-button-disabled"
                    }
                    disabled={!email}
                    onClick={(e) => handleSubmit(e)}
                >
                    Send email
                </button>
            }
        </div>
    )
}