import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

import {
    IconZoomIn, IconZoomOut, IconArrowsMinimize, IconZoomScan,
    IconRectangle, IconPolygon, IconTimeline, IconCircles, IconRulerMeasure,
    IconCaretUp, IconCaretDown, IconCaretLeft, IconCaretRight,
    IconDirectionArrows, IconCut, IconPolygonOff, IconSquareOff, IconArrowBackUp, IconArrowForwardUp, IconHome, IconArrowsMove, IconPlus, IconMinus, IconCirclePlus, IconCircleMinus, IconWindowMinimize, IconRotate2, IconRotateClockwise2, IconCircleDot, IconScissors,
    IconEraser,
    IconCircleSquare,
    IconArrowUpRightCircle,
    IconCircle,
} from '@tabler/icons-react';

import './styles/TakeoffToolbar.css';

import { TakeoffContext } from './helper/Context';
import { selectAuth } from '../redux/slices/authSlice';

import { API_ROUTE } from '../index';
import ButtonHoverInfo from '../components/ButtonHoverInfo';
import DefaultButton from '../components/DefaultButton';
import DraggableToolbar from './components/sidebar/DraggableToolbar';
import CustomIcon from '../components/CustomIcon';
import { Tooltip } from 'react-tooltip';
import { IconTrashX } from '@tabler/icons-react';
import { Blocks } from 'react-loader-spinner';
import { IconTextSize } from '@tabler/icons-react';

export default function TakeoffToolbar({ }) {
    const auth = useSelector(selectAuth);

    const {
        project, setProject,
        pageID,
        drawing,
        takeoffSettings,
        handleUpdateProject,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        drawingCount, drawingLine, drawingPolygon, drawingRectangle, drawingScale, drawingCircle,
        setDrawingCount, setDrawingLine, setDrawingPolygon, setDrawingRectangle, setDrawingCircle,
        setDrawingScale,
        cuttingPolygonRect, setCuttingPolygonRect,
        cuttingRectangleRect, setCuttingRectangleRect,
        cuttingPolygon, setCuttingPolygon,
        cuttingRectangle, setCuttingRectangle,
        splittingPolygon, setSplittingPolygon,
        splittingRectangle, setSplittingRectangle,
        handleZoom, handleResetZoom, handleZoomToMeasurement,
        history,
        handleUndo, handleRedo,
        changingHistory, setChangingHistory,
        addingCount, setAddingCount,
        deletingCount, setDeletingCount,
        keybinds,
        setHintingSAM,
        selectedMeasurements, setSelectedMeasurements,
        showDeleteMeasurementModal, setShowDeleteMeasurementModal,
        editingMeasurement,
        setAIAutoClassifying, setAIAutoFinding,
        setAIAutoCountRect,
        setAIAutoCountPoly,
        handleTurnOffDrawing,
        erasingCount, setErasingCount,
        handleCreateAnnotation,
        drawingAnnotationArrow, setDrawingAnnotationArrow,
        drawingAnnotationRectangle, setDrawingAnnotationRectangle,
        drawingAnnotationText, setDrawingAnnotationText,
        currentAnnotation, 
        selectedAnnotations,
        setShowDeleteAnnotationModal,
    } = useContext(TakeoffContext);

    const [showMoveToolbar, setShowMoveToolbar] = useState(false);

    const [showAnnotationOptions, setShowAnnotationOptions] = useState(false);

    const [dragging, setDragging] = useState(false)
    const [rel, setRel] = useState(null)
    const [pos, setPos] = useState({ x: project?.toolbar_x ? project?.toolbar_x : '', y: project?.toolbar_y ? project?.toolbar_y : '' })

    useEffect(() => {
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)

        return () => {
            document.removeEventListener('mousemove', onMouseMove)
            document.removeEventListener('mouseup', onMouseUp)
        }
    }, [dragging, pos])

    const onMouseDown = (e) => {
        if (e.button !== 0) return

        var pos = document.getElementsByClassName('takeofftoolbar-container')[0].getBoundingClientRect()
        setDragging(true)

        console.log(e.pageX, e.pageY, pos.left, pos.top)
        setRel({
            x: e.pageX - pos.left,
            y: e.pageY - pos.top
        })
    }

    const onMouseUp = (e) => {
        if (dragging) {
            axios({
                method: 'PUT',
                url: `${API_ROUTE}/api/projects/${project.id}/`,
                data: {
                    author: project.author,
                    company: project.company,
                    toolbar_x: pos.x,
                    toolbar_y: pos.y,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    console.log(res);

                    setProject(prev => ({
                        ...prev,
                        toolbar_x: pos.x,
                        toolbar_y: pos.y,
                    }));
                })
                .catch(err => {
                    console.log(err);
                })
        }

        setDragging(false)
    }

    const onMouseMove = (e) => {
        if (!dragging) return

        if (project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom') {
            if (e.pageX - rel.x < 0) {
                setPos(prev => ({
                    ...prev,
                    x: 1,
                }))
            } else if (e.pageX - rel.x > (showMoveToolbar ? window.innerWidth - 400 : window.innerWidth - 340)) {
                setPos(prev => ({
                    ...prev,
                    x: showMoveToolbar ? window.innerWidth - 400 : window.innerWidth - 340,
                }))
            } else {
                setPos(prev => ({
                    ...prev,
                    x: e.pageX - rel.x,
                }))
            }

            if (e.pageY - rel.y < window.innerHeight / 100 * 6) {
                setPos(prev => ({
                    ...prev,
                    y: window.innerHeight / 100 * 6,
                }))
            } else if (e.pageY - rel.y > window.innerHeight - 40) {
                setPos(prev => ({
                    ...prev,
                    y: window.innerHeight - 40,
                }))
            } else {
                setPos(prev => ({
                    ...prev,
                    y: e.pageY - rel.y,
                }))
            }
        } else {
            if (e.pageX - rel.x < 0) {
                setPos(prev => ({
                    ...prev,
                    x: 1,
                }))
            } else if (e.pageX - rel.x > window.innerWidth - 50) {
                setPos(prev => ({
                    ...prev,
                    x: window.innerWidth - 50,
                }))
            } else {
                setPos(prev => ({
                    ...prev,
                    x: e.pageX - rel.x,
                }))
            }

            if (e.pageY - rel.y < window.innerHeight / 100 * 6) {
                setPos(prev => ({
                    ...prev,
                    y: window.innerHeight / 100 * 6,
                }))
            } else if (e.pageY - rel.y > (showMoveToolbar ? window.innerHeight - 320 : window.innerHeight - 255)) {
                setPos(prev => ({
                    ...prev,
                    y: showMoveToolbar ? window.innerHeight - 320 : window.innerHeight - 255,
                }))
            } else {
                setPos(prev => ({
                    ...prev,
                    y: e.pageY - rel.y,
                }))
            }
        }
    }

    const handleReset = (e) => {
        e.stopPropagation();
        setPos({
            x: null,
            y: null,
        })

        axios({
            method: 'PUT',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                toolbar_x: null,
                toolbar_y: null,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res);

                setProject(prev => ({
                    ...prev,
                    toolbar_y: null,
                    toolbar_y: null,
                }));
            })
            .catch(err => {
                console.log(err);
            })
    }

    const CutRectangleRectMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Cut rectangle rect ({keybinds?.CutRectangle?.control ? 'Ctrl + ' : ''}{keybinds?.CutRectangle?.shift ? 'Shift + ' : ''}{keybinds?.CutRectangle?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Draw negative rectangle to cut out.
            </div>
        </>
    )

    const CutRectangleMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Cut rectangle ({keybinds?.CutRectangle?.control ? 'Ctrl + ' : ''}{keybinds?.CutRectangle?.shift ? 'Shift + ' : ''}{keybinds?.CutRectangle?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Draw negative polygon to cut out.
            </div>
        </>
    )

    const SplitRectangleMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Split rectangle ({keybinds?.SplitRectangle?.control ? 'Ctrl + ' : ''}{keybinds?.SplitRectangle?.shift ? 'Shift + ' : ''}{keybinds?.SplitRectangle?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Click two points outside rectangle, 'Enter' to split.
            </div>
        </>
    )

    const RectangleMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Add rectangle ({keybinds?.DrawRectangle?.control ? 'Ctrl + ' : ''}{keybinds?.DrawRectangle?.shift ? 'Shift + ' : ''}{keybinds?.DrawRectangle?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Click to start. Click again to finish.
            </div>
        </>
    )

    const CutPolygonRectMeassage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Cut with rectangles ({keybinds?.CutPolygon?.control ? 'Ctrl + ' : ''}{keybinds?.CutPolygon?.shift ? 'Shift + ' : ''}{keybinds?.CutPolygon?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Draw negative rectangles to cut out.
            </div>
        </>
    )

    const CutPolygonMeassage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Cut with polygons ({keybinds?.CutPolygon?.control ? 'Ctrl + ' : ''}{keybinds?.CutPolygon?.shift ? 'Shift + ' : ''}{keybinds?.CutPolygon?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Draw negative polygons to cut out.
            </div>
        </>
    )

    const SplitPolygonMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Split polygon ({keybinds?.SplitPolygon?.control ? 'Ctrl + ' : ''}{keybinds?.SplitPolygon?.shift ? 'Shift + ' : ''}{keybinds?.SplitPolygon?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Click two points outside polygon, 'Enter' to split.
            </div>
        </>
    )

    const CircleMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Add circle ({keybinds?.DrawCircle?.control ? 'Ctrl + ' : ''}{keybinds?.DrawCircle?.shift ? 'Shift + ' : ''}{keybinds?.DrawCircle?.key})
            </div>

            <div className='takeofftoolbar-hover-message-container'>
                <div className='takeofftoolbar-hover-message-body'>
                    Click to place center. Click again to finish.
                </div>
            </div>
        </>
    )

    const PolygonMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Add polygon ({keybinds?.DrawPolygon?.control ? 'Ctrl + ' : ''}{keybinds?.DrawPolygon?.shift ? 'Shift + ' : ''}{keybinds?.DrawPolygon?.key})
            </div>

            <div className='takeofftoolbar-hover-message-container'>

                <div className='takeofftoolbar-hover-message-body'>
                    Click to start & place points. "Enter" or click origin to finish.
                </div>

                <div className='takeofftoolbar-hover-message-body'>
                    After completion, click a point to remove. Click line to add a point.
                </div>
            </div>
        </>
    )

    const LineMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Add line ({keybinds?.DrawLength?.control ? 'Ctrl + ' : ''}{keybinds?.DrawLength?.shift ? 'Shift + ' : ''}{keybinds?.DrawLength?.key})
            </div>

            <div className='takeofftoolbar-hover-message-container'>

                <div className='takeofftoolbar-hover-message-body'>
                    Click to start & place points. "Enter" or double click to finish.
                </div>

                <div className='takeofftoolbar-hover-message-body'>
                    After completion, click a point to remove. Click line to add a point.
                </div>
            </div>
        </>
    )

    const CountMessage = (
        <>
            <div className='takeofftoolbar-hover-message-title'>
                Add count ({keybinds?.DrawCount?.control ? 'Ctrl + ' : ''}{keybinds?.DrawCount?.shift ? 'Shift + ' : ''}{keybinds?.DrawCount?.key})
            </div>

            <div className='takeofftoolbar-hover-message-body'>
                Click to place points. Press "Enter" to finish.
            </div>
        </>
    )

    const getClassName = () => {
        let className = 'takeofftoolbar-container';

        if ((takeoffSettings?.pages_sidebar_location === 'left' && takeoffSettings?.show_pages_sidebar)
            || (takeoffSettings?.measurement_sidebar_location === 'left' && takeoffSettings?.show_measurement_sidebar)
            || (takeoffSettings?.ai_sidebar_location === 'left' && takeoffSettings?.show_ai_sidebar)
        ) {
            if ((takeoffSettings?.pages_sidebar_location === 'right' && takeoffSettings?.show_pages_sidebar)
                || (takeoffSettings?.measurement_sidebar_location === 'right' && takeoffSettings?.show_measurement_sidebar)
                || (takeoffSettings?.ai_sidebar_location === 'right' && takeoffSettings?.show_ai_sidebar)
            ) {
                className += ' takeofftoolbar-container-' + project?.toolbar_position + '-pages-sidebar';
            } else {
                className += ' takeofftoolbar-container-' + project?.toolbar_position + '-pages';
            }
        } else {
            if ((takeoffSettings?.pages_sidebar_location === 'right' && takeoffSettings?.show_pages_sidebar)
                || (takeoffSettings?.measurement_sidebar_location === 'right' && takeoffSettings?.show_measurement_sidebar)
                || (takeoffSettings?.ai_sidebar_location === 'right' && takeoffSettings?.show_ai_sidebar)
            ) {
                className += ' takeofftoolbar-container-' + project?.toolbar_position + '-sidebar';
            } else {
                className += ' takeofftoolbar-container-' + project?.toolbar_position;
            }
        }

        return className;
    }

    if (takeoffSettings?.show_measurement_sidebar && !document.getElementById('measurementsidebar')
        || takeoffSettings?.show_pages_sidebar && !document.getElementById('pagessidebar')
        || takeoffSettings?.show_ai_sidebar && !document.getElementById('aisidebar')) {
        setTimeout(() => {
            setPos({ x: pos.x, y: pos.y });
        }, 50);
    }

    const right = !pos.x
        ? project?.toolbar_position === 'right'
            ? (takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'right' && document.getElementById('measurementsidebar')
                ? document.getElementById('measurementsidebar').offsetWidth * 1.01 + 5
                : takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'right' && document.getElementById('pagessidebar')
                    ? document.getElementById('pagessidebar').offsetWidth * 1.01 + 5
                    : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'right' && document.getElementById('aisidebar')
                        ? document.getElementById('aisidebar').offsetWidth * 1.01 + 5
                        : ''
            )
            : ''
        : ''

    return (
        <div
            className={getClassName()}
            style={{
                flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                right: right,
                left: pos.x
                    ? pos.x + 'px'
                    : project?.toolbar_position === 'left' &&
                    (takeoffSettings?.show_pages_sidebar && takeoffSettings?.pages_sidebar_location === 'left'
                        ? document.getElementById('pagessidebar')?.offsetWidth + 5
                        : takeoffSettings?.show_measurement_sidebar && takeoffSettings?.measurement_sidebar_location === 'left'
                            ? document.getElementById('measurementsidebar')?.offsetWidth + 5
                            : takeoffSettings?.show_ai_sidebar && takeoffSettings?.ai_sidebar_location === 'left'
                                ? document.getElementById('aisidebar')?.offsetWidth + 5
                                : ''),
                top: pos.y ? pos.y + 'px' : '',
            }}
        >
            {currentMeasurement && (measurements[currentMeasurement]?.type === 'count' || measurements[currentMeasurement]?.type === 'polygon' || measurements[currentMeasurement]?.type === 'rectangle') ?
                <div
                    className='takeofftoolbar-container-section'
                    style={{
                        flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                    }}
                >
                    {currentMeasurement && measurements[currentMeasurement].type === 'count' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-addcount'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!addingCount) {
                                        setAddingCount(true);
                                    }
                                }}
                                //disabled={drawing}
                                size={'small'}
                                style={{ backgroundColor: addingCount ? '#006aef' : '', color: addingCount ? 'white' : '' }}
                                active={addingCount}
                            >
                                <IconCirclePlus size={20} stroke={1} />
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-addcount'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {`Add count (${keybinds?.AddCount?.control ? 'Ctrl + ' : ''}${keybinds?.AddCount?.shift ? 'Shift + ' : ''}${keybinds?.AddCount?.key})`}
                            </Tooltip>
                        </>
                        : null}

                    {currentMeasurement && measurements[currentMeasurement].type === 'count' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-deletecount'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!deletingCount) {
                                        setDeletingCount(true);
                                    }
                                }}
                                //disabled={drawing}
                                size={'small'}
                                style={{ backgroundColor: deletingCount ? '#006aef' : '', color: deletingCount ? 'white' : '' }}
                                active={deletingCount}
                            >
                                <IconCircleMinus size={20} stroke={1} />
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-deletecount'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {`Delete count (${keybinds?.DeleteCount?.control ? 'Ctrl + ' : ''}${keybinds?.DeleteCount?.shift ? 'Shift + ' : ''}${keybinds?.DeleteCount?.key})`}
                            </Tooltip>
                        </>
                        : null}

                    {currentMeasurement && measurements[currentMeasurement].type === 'polygon' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-cutpolygon'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!cuttingPolygon) {
                                        setCuttingPolygon(true);
                                    }
                                }}
                                //disabled={drawing}
                                size={'small'}
                                style={cuttingPolygon ? { backgroundColor: '#006aef', color: 'white' } : {}}
                                active={cuttingPolygon}
                            >
                                <CustomIcon src={cuttingPolygon
                                    ? 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/active_cutpoly.svg'
                                    : 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/cutpoly.svg'} />
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-cutpolygon'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {CutPolygonMeassage}
                            </Tooltip>
                        </>
                        : null}

                    {currentMeasurement && measurements[currentMeasurement].type === 'rectangle' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-cutrectangle'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!cuttingRectangle) {
                                        setCuttingRectangle(true);
                                    }
                                }}
                                //disabled={drawing}
                                size={'small'}
                                style={cuttingRectangle ? { backgroundColor: '#006aef', color: 'white' } : {}}
                                active={cuttingRectangle}
                            >
                                <CustomIcon src={cuttingRectangle
                                    ? 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/active_cutpoly.svg'
                                    : 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/cutpoly.svg'} />
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-cutrectangle'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {CutRectangleMessage}
                            </Tooltip>
                        </>
                        : null}

                    {currentMeasurement && measurements[currentMeasurement].type === 'polygon' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-cutpolygonrect'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!cuttingPolygonRect) {
                                        setCuttingPolygonRect(true);
                                    }
                                }}
                                size={'small'}
                                style={cuttingPolygonRect ? { backgroundColor: '#006aef', color: 'white' } : {}}
                                active={cuttingPolygonRect}
                            >
                                <CustomIcon src={cuttingPolygonRect
                                    ? 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/active_cutrect.svg'
                                    : 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/cutrect.svg'} />
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-cutpolygonrect'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {CutPolygonRectMeassage}
                            </Tooltip>
                        </>
                        : null}

                    {currentMeasurement && measurements[currentMeasurement].type === 'rectangle' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-cutrectanglerect'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!cuttingRectangleRect) {
                                        setCuttingRectangleRect(true);
                                    }
                                }}
                                //disabled={drawing}
                                size={'small'}
                                style={cuttingRectangleRect ? { backgroundColor: '#006aef', color: 'white' } : {}}
                                active={cuttingRectangleRect}
                            >
                                <CustomIcon src={cuttingRectangleRect
                                    ? 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/active_cutrect.svg'
                                    : 'https://bobyard-public-images.s3.us-west-2.amazonaws.com/cutrect.svg'} />
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-cutrectanglerect'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {CutRectangleRectMessage}
                            </Tooltip>
                        </>
                        : null}

                    {currentMeasurement && measurements[currentMeasurement].type === 'polygon' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-splitpolygon'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!splittingPolygon) {
                                        setSplittingPolygon(true);
                                    }
                                }}
                                //disabled={drawing}
                                size={'small'}
                                style={splittingPolygon ? { backgroundColor: '#006aef', color: 'white' } : {}}
                                active={splittingPolygon}
                            >
                                <IconPolygonOff size={20} stroke={1} />
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-splitpolygon'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {SplitPolygonMessage}
                            </Tooltip>
                        </>
                        : null}

                    {currentMeasurement && measurements[currentMeasurement].type === 'rectangle' ?
                        <>
                            <div
                                className='takeofftoolbar-button'
                                id='takeofftoolbar-button-splitrectangle'
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    if (!splittingRectangle) {
                                        setSplittingRectangle(true);
                                    }
                                }}
                                //disabled={drawing}
                                size={'small'}
                                style={splittingRectangle ? { backgroundColor: '#006aef', color: 'white' } : {}}
                                active={splittingRectangle}
                            >
                                <CustomIcon>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        width="18px"
                                        height="18px"
                                        viewBox="0 0 15 15"
                                        color={splittingRectangle ? 'white' : 'black'}
                                        stroke='1'
                                        fill='none'
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <defs />
                                        <g>
                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rectangle" width="13" height="13" viewBox="0 0 22 22" stroke-width="1" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M3 5m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                            </svg>
                                            <path
                                                d="M 13.64 13.64 L 0 0"
                                                fill="none"
                                                stroke="currentColor"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="0.6"
                                                stroke-miterlimit="10"
                                                pointer-events="stroke"
                                            />
                                        </g>
                                    </svg>
                                </CustomIcon>
                            </div>

                            <Tooltip
                                className='tooltip'
                                anchorSelect='#takeofftoolbar-button-splitrectangle'
                                delayShow={1000}
                                place={
                                    project?.toolbar_position === 'left'
                                        ? 'right-start'
                                        : project?.toolbar_position === 'right'
                                            ? 'left-start'
                                            : project?.toolbar_position === 'top'
                                                ? 'bottom-start'
                                                : 'top-start'
                                }
                                positionStrategy='fixed'
                                style={{ zIndex: 10000 }}>
                                {SplitRectangleMessage}
                            </Tooltip>
                        </>
                        : null}
                </div>
                : null}

            <div
                className='takeofftoolbar-container-section'
                style={{
                    flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                }}
            >
                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-drawrectangle'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setCurrentMeasurement(null);

                        if (!drawingRectangle) {
                            setDrawingRectangle(true);
                        }
                    }}
                    //disabled={drawing}
                    style={drawingRectangle ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconRectangle size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-drawrectangle'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    {RectangleMessage}
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-drawpolygon'
                    onClick={() => {
                        handleTurnOffDrawing();

                        if (!drawingPolygon) {
                            setDrawingPolygon(true);
                        }
                    }}
                    //disabled={drawing}
                    style={drawingPolygon ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconPolygon size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-drawpolygon'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    {PolygonMessage}
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-drawcircle'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setCurrentMeasurement(null);

                        if (!drawingCircle) {
                            setDrawingCircle(true);
                        }
                    }}
                    //disabled={drawing}
                    style={drawingCircle ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconCircleDot size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-drawcircle'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    {CircleMessage}
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-drawline'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setCurrentMeasurement(null);

                        if (!drawingLine) {
                            setDrawingLine(true);
                        }
                    }}
                    //disabled={drawing}
                    size={'small'}
                    style={drawingLine ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconTimeline size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-drawline'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    {LineMessage}
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-drawcount'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setCurrentMeasurement(null);

                        if (!drawingCount) {
                            setDrawingCount(true);
                        }
                    }}
                    //disabled={drawing}
                    size={'small'}
                    style={drawingCount ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconCircles size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-drawcount'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    {CountMessage}
                </Tooltip>
            </div>

            <div
                className='takeofftoolbar-container-section'
                style={{
                    flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                }}
            >
                {/*<div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-drawscale'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setCurrentMeasurement(null);

                        if (!drawingScale) {
                            setDrawingScale(true);
                        }
                    }}
                    //disabled={drawing}
                    size={'small'}
                    style={drawingScale ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconRulerMeasure size={20} stroke={1} />
                </div>*/}

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-drawscale'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}
                >
                    <div>
                        <b>
                            Set scale ({keybinds?.DrawScale?.control ? 'Ctrl + ' : ''}{keybinds?.DrawScale?.shift ? 'Shift + ' : ''}{keybinds?.DrawScale?.key})
                        </b>
                    </div>

                    <small>
                        <div>
                            Measure a length on the drawing to set the scale.
                        </div>
                        <div>
                            Click to start, click again to finish.
                        </div>
                    </small>
                </Tooltip>

                <div
                    className='takeofftoolbar-button-delete'
                    id='takeofftoolbar-count-eraser-button'
                    onClick={() => {
                        handleTurnOffDrawing();

                        if (!erasingCount) {
                            setErasingCount(true);
                        }
                    }}
                    size={'small'}
                    style={erasingCount ? { backgroundColor: '#ff0000', color: 'white' } : {}}
                >
                    <IconEraser size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-count-eraser-button'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}
                >
                    <div><b>Count Eraser ({keybinds?.EraseCount?.control ? 'Ctrl + ' : ''}{keybinds?.EraseCount?.shift ? 'Shift + ' : ''}{keybinds?.EraseCount?.key})</b></div>
                    <div>
                        Draw a rectangle, delete all dots inside.
                    </div>
                </Tooltip>

                {currentMeasurement || selectedMeasurements?.length > 0 || currentAnnotation || selectedAnnotations?.length > 0 ?
                    <>
                        <div
                            className='takeofftoolbar-button-delete'
                            id='takeofftoolbar-button-delete'
                            onClick={() => {
                                if ((currentMeasurement || selectedMeasurements.length > 0) && !editingMeasurement && !drawingRectangle && !drawingPolygon && !drawingCount && !drawingLine && !drawingCircle && !cuttingPolygon && !cuttingRectangle && !splittingPolygon && !splittingRectangle && !cuttingPolygonRect && !cuttingRectangleRect) {
                                    setShowDeleteMeasurementModal(true);
                                } else if (currentAnnotation || selectedAnnotations.length > 0) {
                                    setShowDeleteAnnotationModal(true);
                                }
                            }}
                            size={'small'}
                        >
                            <IconTrashX size={20} stroke={1} />
                        </div>

                        <Tooltip
                            className='tooltip'
                            anchorSelect='#takeofftoolbar-button-delete'
                            delayShow={1000}
                            place={
                                project?.toolbar_position === 'left'
                                    ? 'right-start'
                                    : project?.toolbar_position === 'right'
                                        ? 'left-start'
                                        : project?.toolbar_position === 'top'
                                            ? 'bottom-start'
                                            : 'top-start'
                            }
                            positionStrategy='fixed'
                            style={{ zIndex: 10000 }}>
                            Delete current selection
                        </Tooltip>
                    </>
                    : null
                }
            </div>

            {history?.length > 0 ?
                <div
                    className='takeofftoolbar-container-section'
                    style={{
                        flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                    }}
                >
                    <div
                        className='takeofftoolbar-button'
                        id='takeofftoolbar-button-undo'
                        onClick={() => {
                            if (!changingHistory) {
                                handleUndo();
                            }
                        }}
                        size={'small'}
                    //style={changingHistory ? { backgroundColor: '#006aef', color: 'white' } : {}}
                    >
                        {changingHistory
                            ? <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                radius="10"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                            : <IconArrowBackUp size={20} stroke={1} />
                        }
                    </div>

                    <Tooltip
                        className='tooltip'
                        anchorSelect='#takeofftoolbar-button-undo'
                        delayShow={1000}
                        place={
                            project?.toolbar_position === 'left'
                                ? 'right-start'
                                : project?.toolbar_position === 'right'
                                    ? 'left-start'
                                    : project?.toolbar_position === 'top'
                                        ? 'bottom-start'
                                        : 'top-start'
                        }
                        positionStrategy='fixed'
                        style={{ zIndex: 10000 }}>
                        Undo ({keybinds?.Undo?.control ? 'Ctrl + ' : ''}{keybinds?.Undo?.shift ? 'Shift + ' : ''}{keybinds?.Undo?.key})
                    </Tooltip>

                    <div
                        className='takeofftoolbar-button'
                        id='takeofftoolbar-button-redo'
                        onClick={() => {
                            if (!changingHistory) {
                                handleRedo();
                            }
                        }}
                        size={'small'}
                    //style={changingHistory ? { backgroundColor: '#006aef', color: 'white' } : {}}
                    >
                        {changingHistory
                            ? <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                radius="2"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                            : <IconArrowForwardUp size={20} stroke={1} />
                        }
                    </div>

                    <Tooltip
                        className='tooltip'
                        anchorSelect='#takeofftoolbar-button-redo'
                        delayShow={1000}
                        place={
                            project?.toolbar_position === 'left'
                                ? 'right-start'
                                : project?.toolbar_position === 'right'
                                    ? 'left-start'
                                    : project?.toolbar_position === 'top'
                                        ? 'bottom-start'
                                        : 'top-start'
                        }
                        positionStrategy='fixed'
                        style={{ zIndex: 10000 }}>
                        Redo ({keybinds?.Redo?.control ? 'Ctrl + ' : ''}{keybinds?.Redo?.shift ? 'Shift + ' : ''}{keybinds?.Redo?.key})
                    </Tooltip>
                </div>
                : ''}

            <div
                className='takeofftoolbar-container-section'
                style={{
                    flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                }}
            >
                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-zoomin'
                    onClick={() => handleZoom(1.2)}
                    size={'small'}
                >
                    <IconZoomIn size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-zoomin'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    Zoom in ({keybinds?.ZoomIn?.control ? 'Ctrl + ' : ''}{keybinds?.ZoomIn?.shift ? 'Shift + ' : ''}{keybinds?.ZoomIn?.key})
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-zoomout'
                    onClick={() => handleZoom(0.8)}
                    size={'small'}
                >
                    <IconZoomOut size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-zoomout'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    Zoom out ({keybinds?.ZoomOut?.control ? 'Ctrl + ' : ''}{keybinds?.ZoomOut?.shift ? 'Shift + ' : ''}{keybinds?.ZoomOut?.key})
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-resetzoom'
                    onClick={() => handleResetZoom()}
                    size={'small'}
                >
                    <IconHome size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-resetzoom'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    Reset zoom ({keybinds?.ZoomReset?.control ? 'Ctrl + ' : ''}{keybinds?.ZoomReset?.shift ? 'Shift + ' : ''}{keybinds?.ZoomReset?.key})
                </Tooltip>

                {currentMeasurement &&
                    (measurements[currentMeasurement]?.type === 'rectangle'
                        || measurements[currentMeasurement]?.type === 'polygon' && measurements[currentMeasurement]?.polygon_dots.length > 0
                        || measurements[currentMeasurement]?.type === 'line' && measurements[currentMeasurement]?.line_dots.length > 0
                        || measurements[currentMeasurement]?.type === 'count' && measurements[currentMeasurement]?.count_dots.length > 0
                        || measurements[currentMeasurement]?.type === 'circle'
                    ) &&
                    <>
                        <div
                            className='takeofftoolbar-button'
                            id='takeofftoolbar-button-zoomtomeasurement'
                            onClick={() => {
                                if (!drawing) {
                                    handleZoomToMeasurement()
                                }
                            }}
                            size={'small'}
                        >
                            <IconZoomScan size={20} stroke={1} />
                        </div>

                        <Tooltip
                            className='tooltip'
                            anchorSelect='#takeofftoolbar-button-zoomtomeasurement'
                            delayShow={1000}
                            place={
                                project?.toolbar_position === 'left'
                                    ? 'right-start'
                                    : project?.toolbar_position === 'right'
                                        ? 'left-start'
                                        : project?.toolbar_position === 'top'
                                            ? 'bottom-start'
                                            : 'top-start'
                            }
                            positionStrategy='fixed'
                            style={{ zIndex: 10000 }}>
                            Zoom to measurement ({keybinds?.ZoomToMeasurement?.control ? 'Ctrl + ' : ''}{keybinds?.ZoomToMeasurement?.shift ? 'Shift + ' : ''}{keybinds?.ZoomToMeasurement?.key})
                        </Tooltip>
                    </>
                }
            </div>

            <div
                className='takeofftoolbar-container-section'
                style={{
                    flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                }}
            >
                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-createannotation-text'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setDrawingAnnotationText(true);
                    }}
                    size={'small'}
                    style={drawingAnnotationText ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconTextSize size={20} stroke={1} />
                </div>

                <Tooltip className='tooltip' anchorSelect='#takeofftoolbar-button-createannotation-text' delayShow={1000} place={project?.toolbar_position === 'left' ? 'right-start' : project?.toolbar_position === 'right' ? 'left-start' : project?.toolbar_position === 'top' ? 'bottom-start' : 'top-start'} positionStrategy='fixed' style={{ zIndex: 10000 }}>
                    Create text annotation ({keybinds?.DrawAnnotationText?.control ? 'Ctrl + ' : ''}{keybinds?.DrawAnnotationText?.shift ? 'Shift + ' : ''}{keybinds?.DrawAnnotationText?.key})
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-createannotation-arrow'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setDrawingAnnotationArrow(true);
                    }}
                    size={'small'}
                    style={drawingAnnotationArrow ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconArrowUpRightCircle size={20} stroke={1} />
                </div>

                <Tooltip className='tooltip' anchorSelect='#takeofftoolbar-button-createannotation-arrow' delayShow={1000} place={project?.toolbar_position === 'left' ? 'right-start' : project?.toolbar_position === 'right' ? 'left-start' : project?.toolbar_position === 'top' ? 'bottom-start' : 'top-start'} positionStrategy='fixed' style={{ zIndex: 10000 }}>
                    Create arrow annotation ({keybinds?.DrawAnnotationArrow?.control ? 'Ctrl + ' : ''}{keybinds?.DrawAnnotationArrow?.shift ? 'Shift + ' : ''}{keybinds?.DrawAnnotationArrow?.key})
                </Tooltip>

                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-createannotation-circle'
                    onClick={() => {
                        handleTurnOffDrawing();
                        setDrawingAnnotationRectangle(true);
                    }}
                    size={'small'}
                    style={drawingAnnotationRectangle ? { backgroundColor: '#006aef', color: 'white' } : {}}
                >
                    <IconRectangle size={20} stroke={1} />
                </div>

                <Tooltip className='tooltip' anchorSelect='#takeofftoolbar-button-createannotation-circle' delayShow={1000} place={project?.toolbar_position === 'left' ? 'right-start' : project?.toolbar_position === 'right' ? 'left-start' : project?.toolbar_position === 'top' ? 'bottom-start' : 'top-start'} positionStrategy='fixed' style={{ zIndex: 10000 }}>
                    Create rectangle annotation ({keybinds?.DrawAnnotationRectangle?.control ? 'Ctrl + ' : ''}{keybinds?.DrawAnnotationRectangle?.shift ? 'Shift + ' : ''}{keybinds?.DrawAnnotationRectangle?.key})
                </Tooltip>
            </div>

            {/*<div
                className='takeofftoolbar-container-section'
                style={{
                    flexDirection: project?.toolbar_position === 'top' || project?.toolbar_position === 'bottom' ? 'row' : 'column',
                }}
                onMouseDown={onMouseDown}
                onContextMenu={(e) => {
                    e.preventDefault();
                    setShowMoveToolbar(prev => !prev)
                }}
            >
                <div
                    className='takeofftoolbar-button'
                    id='takeofftoolbar-button-move'
                    size={'small'}
                >
                    <IconArrowsMove size={20} stroke={1} />
                </div>

                <Tooltip
                    className='tooltip'
                    anchorSelect='#takeofftoolbar-button-move'
                    delayShow={1000}
                    place={project?.toolbar_position === 'left'
                        ? 'right-start'
                        : project?.toolbar_position === 'right'
                            ? 'left-start'
                            : project?.toolbar_position === 'top'
                                ? 'bottom-start'
                                : 'top-start'
                    }
                    positionStrategy='fixed'
                    style={{ zIndex: 10000 }}>
                    <div>
                        <b>
                            Move toolbar
                        </b>
                    </div>
                    <div>
                        Drag to move toolbar.
                    </div>
                    <div>
                        Right click to pin.
                    </div>
                </Tooltip>

                {pos.x && pos.y ?
                    <>
                        <div
                            className='takeofftoolbar-button'
                            id='takeofftoolbar-button-reset'
                            onClick={(e) => handleReset(e)}
                            size={'small'}
                        >
                            <IconWindowMinimize size={20} stroke={1} />
                        </div>

                        <Tooltip
                            className='tooltip'
                            anchorSelect='#takeofftoolbar-button-reset'
                            delayShow={1000}
                            place={project?.toolbar_position === 'left'
                                ? 'right-start'
                                : project?.toolbar_position === 'right'
                                    ? 'left-start'
                                    : project?.toolbar_position === 'top'
                                        ? 'bottom-start'
                                        : 'top-start'
                            }
                            positionStrategy='fixed'
                            style={{ zIndex: 10000 }}>
                            Reset position
                        </Tooltip>
                    </>
                    : ''}

                {showMoveToolbar &&
                    <>
                        {project?.toolbar_position !== 'left' &&
                            <>
                                <div
                                    className='takeofftoolbar-button'
                                    id='takeofftoolbar-button-pinleft'
                                    onClick={(e) => {
                                        handleReset(e);
                                        handleUpdateProject({
                                            ...project,
                                            toolbar_position: 'left',
                                        })
                                        setShowMoveToolbar(false);
                                    }}
                                    size={'small'}
                                >
                                    <IconCaretLeft size={20} stroke={1} />
                                </div>

                                <Tooltip
                                    className='tooltip'
                                    anchorSelect='#takeofftoolbar-button-pinleft'
                                    delayShow={1000}
                                    place={
                                        project?.toolbar_position === 'left'
                                            ? 'right-start'
                                            : project?.toolbar_position === 'right'
                                                ? 'left-start'
                                                : project?.toolbar_position === 'top'
                                                    ? 'bottom-start'
                                                    : 'top-start'
                                    }
                                    positionStrategy='fixed'
                                    style={{ zIndex: 10000 }}>
                                    Pin toolbar to left
                                </Tooltip>
                            </>
                        }

                        {project?.toolbar_position !== 'right' &&
                            <>
                                <div
                                    className='takeofftoolbar-button'
                                    id='takeofftoolbar-button-pinright'
                                    onClick={(e) => {
                                        handleReset(e);
                                        handleUpdateProject({
                                            ...project,
                                            toolbar_position: 'right',
                                        })
                                        setShowMoveToolbar(false);
                                    }}
                                    size={'small'}
                                >
                                    <IconCaretRight size={20} stroke={1} />
                                </div>

                                <Tooltip
                                    className='tooltip'
                                    anchorSelect='#takeofftoolbar-button-pinright'
                                    delayShow={1000}
                                    place={
                                        project?.toolbar_position === 'left'
                                            ? 'right-start'
                                            : project?.toolbar_position === 'right'
                                                ? 'left-start'
                                                : project?.toolbar_position === 'top'
                                                    ? 'bottom-start'
                                                    : 'top-start'
                                    }
                                    positionStrategy='fixed'
                                    style={{ zIndex: 10000 }}>
                                    Pin toolbar to right
                                </Tooltip>
                            </>
                        }

                        {project?.toolbar_position !== 'top' &&
                            <>
                                <div
                                    className='takeofftoolbar-button'
                                    id='takeofftoolbar-button-pintop'
                                    onClick={(e) => {
                                        handleReset(e);
                                        handleUpdateProject({
                                            ...project,
                                            toolbar_position: 'top',
                                        })
                                        setShowMoveToolbar(false);
                                    }}
                                    size={'small'}
                                >
                                    <IconCaretUp size={20} stroke={1} />
                                </div>

                                <Tooltip
                                    className='tooltip'
                                    anchorSelect='#takeofftoolbar-button-pintop'
                                    delayShow={1000}
                                    place={
                                        project?.toolbar_position === 'left'
                                            ? 'right-start'
                                            : project?.toolbar_position === 'right'
                                                ? 'left-start'
                                                : project?.toolbar_position === 'top'
                                                    ? 'bottom-start'
                                                    : 'top-start'
                                    }
                                    positionStrategy='fixed'
                                    style={{ zIndex: 10000 }}>
                                    Pin toolbar to top
                                </Tooltip>
                            </>
                        }

                        {project?.toolbar_position !== 'bottom' &&
                            <>
                                <div
                                    className='takeofftoolbar-button'
                                    id='takeofftoolbar-button-pinbottom'
                                    onClick={(e) => {
                                        handleReset(e);
                                        handleUpdateProject({
                                            ...project,
                                            toolbar_position: 'bottom',
                                        })
                                        setShowMoveToolbar(false);
                                    }}
                                    size={'small'}
                                >
                                    <IconCaretDown size={20} stroke={1} />
                                </div>

                                <Tooltip
                                    className='tooltip'
                                    anchorSelect='#takeofftoolbar-button-pinbottom'
                                    delayShow={1000}
                                    place={
                                        project?.toolbar_position === 'left'
                                            ? 'right-start'
                                            : project?.toolbar_position === 'right'
                                                ? 'left-start'
                                                : project?.toolbar_position === 'top'
                                                    ? 'bottom-start'
                                                    : 'top-start'
                                    }
                                    positionStrategy='fixed'
                                    style={{ zIndex: 10000 }}>
                                    Pin toolbar to bottom
                                </Tooltip>
                            </>
                        }
                    </>
                }
            </div>*/}
        </div >
    )
}