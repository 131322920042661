import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core"
import { CSS } from "@dnd-kit/utilities"
import { arrayMove, SortableContext } from "@dnd-kit/sortable"

import {
  buildTree,
  findItemDeep,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty
} from "./utilities.js"
// import { sortableTreeKeyboardCoordinates } from './keyboardCoordinates';
import { SortableTreeItem } from "./SortableTreeItem.js"
import { customListSortingStrategy } from "./SortingStrategy.js"
import { IconCaretRightFilled, IconCircleDot, IconCircles, IconPolygon, IconRectangle, IconTimeline } from "@tabler/icons-react"
import { MeasurementSideBarContext, TakeoffContext } from "../helper/Context.js"
import axios from "axios"
import { useSelector } from "react-redux"
import { selectAuth } from "../../redux/slices/authSlice.js"
import { API_ROUTE } from "../../index.js"

const defaultPointerSensorOptions = {
  activationConstraint: {
    distance: 3
  }
}

export const dropAnimationDefaultConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5
        })
      }
    ]
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing
    })
  }
}

export function SortableTree({
  items,
  indicator,
  indentationWidth = 20,
  onItemsChanged,
  TreeItemComponent,
  pointerSensorOptions,
  disableSorting,
  dropAnimation,
  dndContextProps,
  handleDragEndAddOn,
  sortableProps,
  keepGhostInPlace,
  canRootHaveChildren,
  disableTree,
  ...rest
}) {
  const auth = useSelector(selectAuth);

  const {
    measurements, groups,
    project,
    selectedDots,
    selectedMeasurements,
    selectedGroups,
    shiftDown,
    controlDown,
    takeoffSettings,
  } = useContext(TakeoffContext);

  const {
    draggingID, setDraggingID,
    overGroup, setOverGroup,
    draggingCount, setDraggingCount,
    handleDragCountEnd,
  } = useContext(MeasurementSideBarContext);

  const [activeId, setActiveId] = useState(null)
  const [overId, setOverId] = useState(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [currentPosition, setCurrentPosition] = useState(null)

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items)
    const collapsedItems = flattenedTree.reduce(
      (acc, { children, collapsed, id }) =>
        collapsed && children?.length ? [...acc, id] : acc,
      []
    )

    const result = removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    )
    return result
  }, [activeId, items])
  const projected = getProjection(
    flattenedItems,
    activeId,
    overId,
    offsetLeft,
    indentationWidth,
    keepGhostInPlace ?? false,
    canRootHaveChildren
  )
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft
  })
  // const [coordinateGetter] = useState(() =>
  //   sortableTreeKeyboardCoordinates(sensorContext, indentationWidth)
  // );
  const sensors = useSensors(
    // useSensor(KeyboardSensor, {
    //   coordinateGetter,
    // })
    useSensor(
      PointerSensor,
      pointerSensorOptions ?? defaultPointerSensorOptions
    )
  )

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [
    flattenedItems
  ])
  const activeItem = activeId
    ? flattenedItems.find(({ id }) => id === activeId)
    : null

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft
    }
  }, [flattenedItems, offsetLeft])

  const itemsRef = useRef(items)
  itemsRef.current = items
  const handleRemove = useCallback(
    id => {
      const item = findItemDeep(itemsRef.current, id)
      onItemsChanged(removeItem(itemsRef.current, id), {
        type: "removed",
        item
      })
    },
    [onItemsChanged]
  )

  const handleCollapse = useCallback(
    function handleCollapse(id) {
      if (shiftDown || controlDown) {
        return
      }

      const item = findItemDeep(itemsRef.current, id)
      onItemsChanged(
        setProperty(itemsRef.current, id, "collapsed", value => {
          return !value
        }),
        {
          type: item.collapsed ? "collapsed" : "expanded",
          item: item
        }
      )

      axios({
        method: 'post',
        url: `${API_ROUTE}/api/takeoff-contractor-group-settings/`,
        data: {
          contractor_id: auth.contractor.id,
          project_id: project.id,
          group_id: item.id.split('-')[1],
          is_expanded: !item.collapsed,
        },
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then(res => {
          console.log(res)
        })
        .catch(err => {
          console.log(err)
        })
    },
    [onItemsChanged]
  )

  const announcements = useMemo(
    () => ({
      onDragStart({ active }) {
        return `Picked up ${active.id}.`
      },
      onDragMove({ active, over }) {
        return getMovementAnnouncement("onDragMove", active.id, over?.id)
      },
      onDragOver({ active, over }) {
        return getMovementAnnouncement("onDragOver", active.id, over?.id)
      },
      onDragEnd({ active, over }) {
        return getMovementAnnouncement("onDragEnd", active.id, over?.id)
      },
      onDragCancel({ active }) {
        return `Moving was cancelled. ${active.id} was dropped in its original position.`
      }
    }),
    []
  )

  const strategyCallback = useCallback(() => {
    return !!projected
  }, [projected])

  useEffect(() => {
    if (projected?.parentId && overGroup !== projected.parentId) {
      setOverGroup(projected.parentId)
    } else if (!projected?.parentId && overGroup !== null) {
      setOverGroup(null)
    }
  }, [projected])

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={disableSorting ? undefined : sensors}
      modifiers={indicator ? modifiersArray : undefined}
      collisionDetection={closestCenter}
      // measuring={measuring}
      onDragStart={disableSorting ? undefined : handleDragStart}
      onDragMove={disableSorting ? undefined : handleDragMove}
      onDragOver={disableSorting ? undefined : handleDragOver}
      onDragEnd={(e) => {
        if (!disableSorting) {
          handleDragEnd(e)
          handleDragEndAddOn ? handleDragEndAddOn(e) : null
        }
      }}
      onDragCancel={disableSorting ? undefined : handleDragCancel}
      autoScroll={{ layoutShiftCompensation: false }}
      {...dndContextProps}
    >
      <SortableContext
        items={sortedIds}
        strategy={
          disableSorting
            ? undefined
            : customListSortingStrategy(strategyCallback)
        }
        disabled={disableTree}
      >
        {flattenedItems.map(item => {
          return (
            <SortableTreeItem
              {...rest}
              key={item.id}
              id={item.id}
              item={item}
              childCount={item.children?.length}
              depth={
                item.id === activeId && projected && !keepGhostInPlace
                  ? projected.depth
                  : item.depth
              }
              indentationWidth={indentationWidth}
              indicator={indicator}
              collapsed={Boolean(item.collapsed && item.children?.length)}
              onCollapse={item.children?.length ? handleCollapse : undefined}
              onRemove={handleRemove}
              isLast={
                item.id === activeId && projected
                  ? projected.isLast
                  : item.isLast
              }
              parent={
                item.id === activeId && projected
                  ? projected.parent
                  : item.parent
              }
              TreeItemComponent={TreeItemComponent}
              disableSorting={disableSorting}
              sortableProps={sortableProps}
              keepGhostInPlace={keepGhostInPlace}
              projected={projected}
            />
          )
        })}
        {createPortal(
          <DragOverlay
            dropAnimation={
              dropAnimation === undefined
                ? dropAnimationDefaultConfig
                : dropAnimation
            }
          >
            {(activeId && activeItem) || (draggingID && draggingID.includes("dot-"))
              ? <div className="measurementsidebar-dragging-overlay">
                {draggingID.includes("dot-")
                  ? selectedDots && selectedDots.length > 1 && selectedDots.find(d => Number(d.id) === Number(draggingID.split('-')[1])) &&
                  <div className="measurementsidebar-dragging-overlay-dots">
                    Dragging {new Set(selectedDots.map(d => d.id)).size} dots
                  </div>
                  : activeItem.id.includes("measurement-")
                    ? selectedMeasurements.find(m => m === Number(activeId.split('-')[1])) && selectedMeasurements.length > 1
                      ? <>Dragging {selectedMeasurements.length} measurements {selectedGroups.length > 1 && `& ${selectedGroups.length} groups`}</>
                      : <>
                        <div>
                          {measurements[activeItem.id.split("-")[1]].type === "rectangle"
                            ? <IconRectangle size={15} style={{ color: measurements[activeItem.id.split("-")[1]].color }} />
                            : measurements[activeItem.id.split("-")[1]].type === "polygon"
                              ? <IconPolygon size={15} style={{ color: measurements[activeItem.id.split("-")[1]].color }} />
                              : measurements[activeItem.id.split("-")[1]].type === "cirlce"
                                ? <IconCircleDot size={15} style={{ color: measurements[activeItem.id.split("-")[1]].color }} />
                                : measurements[activeItem.id.split("-")[1]].type === "line"
                                  ? <IconTimeline size={15} style={{ color: measurements[activeItem.id.split("-")[1]].color }} />
                                  : <IconCircles size={15} style={{ color: measurements[activeItem.id.split("-")[1]].color }} />
                          }
                        </div>
                        <div>
                          {measurements[activeItem.id.split("-")[1]].name?.length > 20
                            ? measurements[activeItem.id.split("-")[1]].name.slice(0, 20) + "..."
                            : measurements[activeItem.id.split("-")[1]].name}
                        </div>
                      </>
                    : draggingID.includes("group-") && selectedGroups.find(g => g === Number(draggingID.split('-')[1])) && selectedGroups.length > 1
                      ? <>Dragging {selectedGroups.filter((g, i) => selectedGroups.indexOf(g) === i).length} groups {selectedMeasurements.filter((m, i) => selectedMeasurements.indexOf(m) === i).length > 1 && `& ${selectedMeasurements.filter((m, i) => selectedMeasurements.indexOf(m) === i).length} measurements`}</>
                      : draggingID.includes("placeholder")
                        ? <></>
                        : <>
                          <div>
                            <IconCaretRightFilled size={15} style={{ color: groups[activeItem.id.split("-")[1]].color }} />
                          </div>
                          <div>
                            {groups[activeItem.id.split("-")[1]].name?.length > 20
                              ? groups[activeItem.id.split("-")[1]].name.slice(0, 20) + "..."
                              : groups[activeItem.id.split("-")[1]].name}
                          </div>
                        </>
                }
              </div>
              : null
            }
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext >
  )

  function handleDragStart({ active: { id: activeId } }) {
    if (activeId.includes('placeholder')) {
      setActiveId(null)
      setDraggingID(null)
      setDraggingCount(false)
      setOverId(null)
      resetState()
      return
    }

    if (activeId.includes('dot')) {
      setDraggingCount(true);
      setDraggingID(activeId);
      document.body.style.setProperty("cursor", "grabbing")
      return
    }

    setActiveId(activeId)
    setDraggingID(activeId)
    setOverId(activeId)

    const activeItem = flattenedItems.find(({ id }) => id === activeId)

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId
      })
    }

    document.body.style.setProperty("cursor", "grabbing")
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }) {
    resetState()

    console.log(active, over)

    if (active?.id.includes('dot') && over?.id.includes('count')) {
      setDraggingCount(false);
      handleDragCountEnd(active.id, over.id);
      document.body.style.setProperty("cursor", "")
      return
    }

    if (projected && over && !active.id.includes('dot')) {
      const { depth, parentId } = projected
      if (keepGhostInPlace && over.id === active.id) return
      const clonedItems = flattenTree(items)
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id)
      const activeTreeItem = clonedItems[activeIndex]
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId }
      const draggedFromParent = activeTreeItem.parent
      let sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const activeID = active.id.split("-")[1]

      if ((active.id.includes('measurement') && selectedMeasurements.find(m => m === Number(activeID))) || (active.id.includes('group') && selectedGroups.find(g => g === Number(activeID)))) {
        for (const selectedM of selectedMeasurements.filter(m => {
          if (selectedGroups.length === 0) return true
          let paths = measurements[m].path.split("/")
          return !selectedGroups.find(g => paths.includes(`group-${g}`))
        })
          .filter(m => m !== Number(activeID))) {
          const selectedMIndex = sortedItems.findIndex(({ id }) => id === `measurement-${selectedM}`)
          const selectedMItem = sortedItems[selectedMIndex]
          sortedItems[selectedMIndex] = { ...selectedMItem, depth, parentId }
          sortedItems = arrayMove(sortedItems, selectedMIndex, overIndex)
        }

        for (const selectedG of selectedGroups.filter(selected_g => {
          if (selectedGroups.length <= 1) return true
          let paths = groups[selected_g].path.split("/").filter(p => p !== `group-${selected_g}`)
          return !selectedGroups.find(g => paths?.includes(`group-${g}`))
        })
          .filter(g => g !== Number(activeID))) {
          const selectedGIndex = sortedItems.findIndex(({ id }) => id === `group-${selectedG}`)
          const selectedGItem = sortedItems[selectedGIndex]
          sortedItems[selectedGIndex] = { ...selectedGItem, depth, parentId }
          sortedItems = arrayMove(sortedItems, selectedGIndex, overIndex)
        }
      }

      const newItems = buildTree(sortedItems)
      const newActiveItem = sortedItems.find(x => x.id === active.id)
      const currentParent = newActiveItem.parentId
        ? sortedItems.find(x => x.id === newActiveItem.parentId)
        : null
      // removing setTimeout leads to an unwanted scrolling
      // Use case:
      //   There are a lot of items in a tree (so that the scroll exists).
      //   You take the node from the bottom and move it to the top
      //   Without `setTimeout` when you drop the node the list gets scrolled to the bottom.
      setTimeout(() =>
        onItemsChanged(newItems, {
          type: "dropped",
          draggedItem: newActiveItem,
          draggedFromParent: draggedFromParent,
          droppedToParent: currentParent
        })
      )
    }
  }

  function handleDragCancel() {
    let sidebar = document.getElementsByClassName('measurementsidebar-groups-container')[0];
    sidebar.scrollLeft = 0;
    resetState()
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setDraggingID(null)
    setOffsetLeft(0)
    setCurrentPosition(null)

    document.body.style.setProperty("cursor", "")
  }

  function getMovementAnnouncement(eventName, activeId, overId) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId
          })
        }
      }

      const clonedItems = flattenTree(items)
      const overIndex = clonedItems.findIndex(({ id }) => id === overId)
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId)
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const previousItem = sortedItems[overIndex - 1]

      let announcement
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved"
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested"

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1]
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
        } else {
          let previousSibling = previousItem
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId = previousSibling.parentId
            previousSibling = sortedItems.find(({ id }) => id === parentId)
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
          }
        }
      }

      return announcement
    }

    return
  }
}

const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25
  }
}
const modifiersArray = [adjustTranslate]
