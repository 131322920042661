import './styles/CustomIcon.css'

export default function CustomIcon({ src, color, style, children }) {
    return (
        <div className="custom-icon">
            {children
                ? children
                : <div
                    className="custom-icon-img"
                    style={style}
                >
                    <img src={src} color={color} />
                </div>
            }
        </div >
    )
}