import { useSelector } from 'react-redux';
import './styles/ErrorBoundary.css';
import emailjs from '@emailjs/browser';
import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, errorInfo) {
        //console.log(error, errorInfo);
        this.setState({ hasError: true });

        if (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1') {
            emailjs.send('service_h495bf7', 'template_n26vkul', {
                url: window.location.href,
                user: this.props.auth.user.id,
                time: new Date().toLocaleString(),
                contractor_email: this.props.auth.user.email,
                error: JSON.stringify(error, Object.getOwnPropertyNames(error))
            }, 'YKosJcsU9_433zfUR')
                .then((response) => {
                    console.log(response);
                }, (err) => {
                    console.error(err);
                });
        }
    }

    render() {
        if (this.state.hasError && (window.location.hostname !== 'localhost' && window.location.hostname !== '127.0.0.1')) {
            return (
                <div className="error-boundary-container">
                    <div className="error-boundary-header">
                        <div className="error-boundary-title">
                            Something went wrong.
                        </div>

                        <div className="error-boundary-header-message">
                            Try <a className='error-boundary-link' href="">refreshing</a> the page or going back to the <a className='error-boundary-link' href="/dashboard">dashboard</a>.
                        </div>
                    </div>

                    <div className="error-boundary-content">
                        <div className="error-boundary-message">
                            We are sorry for the inconvenience! An email has been sent to Michael with the error details.
                        </div>

                        <div className="error-boundary-message">
                            If the problem persists, please contact your dedicated support enginner.
                        </div>

                        <br></br>

                        <div className="error-boundary-message">
                            Feel free to text Michael at: 408-510-2396
                        </div>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorBoundary;