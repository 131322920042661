import axios from 'axios';
import { lensPath, lensProp, over, mergeDeepLeft, F, isEmpty, isNil, pipe, map, when, is, ifElse, either, reject, toPairs, view, set, prop, includes, addIndex, reduce } from 'ramda';

const axiosCurry = (url) => (headers) => (method) => (data) =>
  axios({
    method,
    url,
    headers,
    data,
  });

const repsonseLens = lensProp('response');
const responseDataLens = lensProp('data');
const responseStatusLens = lensProp('status');
const eventValueLens = lensPath(['target', 'value']);
const mergeFromLens = (lens) => (replacementObj) => over(lens)(mergeDeepLeft(replacementObj));
const removeNulls = pipe(map(when(is(Object), (v) => removeNulls(v))), reject(ifElse(is(String), F, either(isEmpty, isNil))));
const uploadFormData = (url) => (formData) => (onUploadProgress) => axios.post(url, formData, { onUploadProgress });
const formDataFromObj = (obj) => {
  const formData = new FormData();
  pipe(
    toPairs,
    map(([k, v]) => formData.append(k, v))
  )(obj);
  return formData;
};

const reverseView = (obj) => (lens) => view(lens)(obj);
const reverseSet = (obj) => (value) => (lens) => set(lens)(value)(obj);
const reverseOver = (obj) => (fn) => (lens) => over(lens)(fn)(obj);
const reverseProp = (obj) => (attr) => prop(attr)(obj);
const reverseIncludes = (list) => (val) => includes(val)(list);
const mapIndexed = addIndex(map);
const reduceIndexed = addIndex(reduce);

export {
  axiosCurry,
  repsonseLens,
  responseDataLens,
  responseStatusLens,
  eventValueLens,
  mergeFromLens,
  removeNulls,
  uploadFormData,
  formDataFromObj,
  reverseView,
  reverseSet,
  reverseOver,
  reverseProp,
  reverseIncludes,
  mapIndexed,
  reduceIndexed,
};
