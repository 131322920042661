import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "../../helper/Context";

import "../../styles/SortSettings.css"
import axios from "axios";
import { API_ROUTE } from "../../..";
import { selectAuth } from "../../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import { Blocks } from "react-loader-spinner";


export default function SortSettings({ setShowSortSettings }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        project, setProject,
        measurements, setMeasurements,
        groups, setGroups,
        tree, setTree,
        currentMeasurement, setCurrentMeasurement,
        handleChangeFilter,
        changingFilter, setChangingFilter,
        takeoffSettings, setTakeoffSettings,
    } = useContext(TakeoffContext);

    const [sorting, setSorting] = useState(false);

    const filterSettingsRef = useRef(null);

    const handleDocumentClick = (e) => {
        if (filterSettingsRef.current && !filterSettingsRef.current.contains(e.target)) {
            setTimeout(() => {
                setShowSortSettings(false);
            }, 100);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Escape") {
            setShowSortSettings(false);
            e.stopPropagation();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleDocumentClick);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const handleClick = (type) => {
        setSorting(true);

        setTakeoffSettings(prev => ({
            ...prev,
            'sort': type,
        }))

        axios({
            method: 'PUT',
            url: `${API_ROUTE}/api/takeoff/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                'settingsID': takeoffSettings?.id,
                'type': type,
                'pageID': pageID,
            }
        })
            .then(res => {
                console.log(res);

                if (Object.keys(measurements).length > 250) {
                    //window.location.reload();
                    setTree(res.data.tree);
                    setSorting(false);
                } else {
                    setTree(res.data.tree);
                    setSorting(false);
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    return (
        <div
            className="measurementsidebar-filtersettings-container"
            ref={filterSettingsRef}
        >
            <div className="measurementsidebar-filtersettings-header">
                Sort settings
            </div>

            <div className="measurementsidebar-sortsettings-content">
                <div
                    className={takeoffSettings?.sort === "custom"
                        ? "measurementsidebar-sortsettings-content-item measurementsidebar-sortsettings-content-item-active"
                        : "measurementsidebar-sortsettings-content-item"}
                    onClick={() => handleClick("custom")}
                >
                    <div>Custom</div>

                    {sorting &&
                        <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    }
                </div>

                <div
                    className={takeoffSettings?.sort === "name-up" || takeoffSettings?.sort === "name-down"
                        ? "measurementsidebar-sortsettings-content-item measurementsidebar-sortsettings-content-item-active"
                        : "measurementsidebar-sortsettings-content-item"}
                    onClick={() => {
                        if (takeoffSettings?.sort === "name-up") {
                            handleClick("name-down");
                        } else {
                            handleClick("name-up");
                        }
                    }}
                >
                    <div>Name {takeoffSettings?.sort === "name-up" ? "▲" : takeoffSettings?.sort === "name-down" ? "▼" : ""}</div>

                    {sorting &&
                        <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    }
                </div>

                <div
                    className={takeoffSettings?.sort === "type-up" || takeoffSettings?.sort === "type-down"
                        ? "measurementsidebar-sortsettings-content-item measurementsidebar-sortsettings-content-item-active"
                        : "measurementsidebar-sortsettings-content-item"}
                    onClick={() => {
                        if (takeoffSettings?.sort === "type-up") {
                            handleClick("type-down");
                        } else {
                            handleClick("type-up");
                        }
                    }}
                >
                    <div>Type {takeoffSettings?.sort === "type-up" ? "▲" : takeoffSettings?.sort === "type-down" ? "▼" : ""}</div>

                    {sorting &&
                        <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    }
                </div>

                <div
                    className={takeoffSettings?.sort === "date-up" || takeoffSettings?.sort === "date-down"
                        ? "measurementsidebar-sortsettings-content-item measurementsidebar-sortsettings-content-item-active"
                        : "measurementsidebar-sortsettings-content-item"}
                    onClick={() => {
                        if (takeoffSettings?.sort === "date-up") {
                            handleClick("date-down");
                        } else {
                            handleClick("date-up");
                        }
                    }}
                >
                    <div>Date {takeoffSettings?.sort === "date-up" ? "▲" : takeoffSettings?.sort === "date-down" ? "▼" : ""}</div>

                    {sorting &&
                        <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    }
                </div>
            </div>
        </div>
    )
}