import React, { useContext, useEffect, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";
import axios from "axios";

import { API_ROUTE } from "../../../index";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import pSBC from "../../helper/Colors";
import { Portal } from "react-konva-utils";

const polygonClipping = require('polygon-clipping')

export default function RectangleSpliter({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        setDrawingLine,
        groups, setGroups,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        createMeasurement,
        handlePolygonTransform,
        splittingRectangle, setSplittingRectangle,
        splittingPolygon, setSplittingPolygon,
        history, setHistory,
        currentHistory, setCurrentHistory,
    } = useContext(TakeoffContext);

    const [points, setPoints] = useState([]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Enter") {
                if (points.length >= 2) {
                    handleSplit();
                    setPoints([]);
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [points]);

    const handleCreatePolygon = (poly) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/polygon/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': measurements[currentMeasurement].group || null,
                'type': 'polygon',
                'color': measurements[currentMeasurement].color,
                'dots': poly.polygon_dots,
                'sub_polygons': poly.sub_polygons || null,
                'quantity1': measurements[currentMeasurement].quantity1,
                'quantity2': measurements[currentMeasurement].quantity2,
                'uom': measurements[currentMeasurement].uom,
                'offset': measurements[currentMeasurement].offset,
            },
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${auth.token}`
            }
        })
            .then((res) => {
                console.log(res);

                createMeasurement(res.data, true);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const rectToPoly = (m) => {
        let x = m.rectangle.x;
        let y = m.rectangle.y;
        let width = m.width;
        let height = m.height;

        return [[
            [x, y],
            [x + width, y],
            [x + width, y + height],
            [x, y + height],
        ]]
    }

    const handleSplit = () => {
        let measurement = measurements[currentMeasurement];

        let polygon = rectToPoly(measurement);

        const differenceA = polygonClipping.difference(
            polygon,
            (points.length === 2 ? [getRectangle(1)] : [getPolygon()])
        );

        const differenceB = points.length === 2
            ? polygonClipping.difference(
                polygon,
                [[...getRectangle(-1)]]
            )
            : polygonClipping.intersection(
                polygon,
                [getPolygon()]
            );

        let tempHistory = [...history];
        let tempCurrentHistory = currentHistory;

        if (differenceA.length && differenceB.length) {
            if (differenceA[0].length > 1) {
                let sub_polygons = {}

                differenceA[0].slice(1).forEach((poly, i) => {
                    sub_polygons[i] = {
                        id: i,
                        polygon_dots: poly.slice(1).map((dot, j) => ({
                            id: j,
                            x: dot[0],
                            y: dot[1],
                        })),
                    }
                })

                tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                tempHistory.push({
                    action: "edit",
                    previous: measurement,
                    current: {
                        ...measurement,
                        type: "polygon",
                        polygon_dots: differenceA[0][0].slice(1).map((dot, i) => ({
                            id: i,
                            x: dot[0],
                            y: dot[1],
                        })),
                        sub_polygons: sub_polygons,
                    },
                });
                tempCurrentHistory++;

                handlePolygonTransform({
                    ...measurement,
                    type: "polygon",
                    polygon_dots: differenceA[0][0].slice(1).map((dot, i) => ({
                        id: i,
                        x: dot[0],
                        y: dot[1],
                    })),
                    sub_polygons: sub_polygons,
                }, null, true);

                setSplittingRectangle(false);
                setSplittingPolygon(true);
            } else {
                tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                tempHistory.push({
                    action: "edit",
                    previous: measurement,
                    current: {
                        ...measurement,
                        type: "polygon",
                        polygon_dots: differenceA[0][0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                        sub_polygons: {},
                    },
                });
                tempCurrentHistory++;

                handlePolygonTransform({
                    ...measurement,
                    type: "polygon",
                    polygon_dots: differenceA[0][0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                    sub_polygons: {},
                }, null, true);

                setSplittingRectangle(false);
                setSplittingPolygon(true);
            }

            if (differenceA.length > 1) {
                differenceA.slice(1).forEach((poly) => {
                    if (poly.length > 1) {
                        let sub_polygons = {}

                        poly.slice(1).forEach((poly, i) => {
                            sub_polygons[i] = {
                                id: i,
                                polygon_dots: poly.slice(1).map((dot, j) => ({
                                    id: j,
                                    x: dot[0],
                                    y: dot[1],
                                })),
                            }
                        })

                        tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                        tempHistory.push({
                            action: "add",
                            previous: null,
                            current: {
                                ...measurement,
                                type: "polygon",
                                polygon_dots: poly[0].slice(1).map((dot, i) => ({
                                    id: i,
                                    x: dot[0],
                                    y: dot[1],
                                })),
                                sub_polygons: sub_polygons,
                            },
                        });
                        tempCurrentHistory++;

                        handleCreatePolygon({
                            polygon_dots: poly[0].slice(1).map((dot, i) => ({
                                id: i,
                                x: dot[0],
                                y: dot[1],
                            })),
                            sub_polygons: sub_polygons,
                        });
                    } else {
                        tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                        tempHistory.push({
                            action: "add",
                            previous: null,
                            current: {
                                ...measurement,
                                type: "polygon",
                                polygon_dots: poly[0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                                sub_polygons: {},
                            },
                        });
                        tempCurrentHistory++;

                        handleCreatePolygon({
                            polygon_dots: poly[0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                            sub_polygons: {},
                        });
                    }
                })
            }

            if (differenceB[0].length > 1) {
                let sub_polygons = {}

                differenceB[0].slice(1).forEach((poly, i) => {
                    sub_polygons[i] = {
                        id: i,
                        polygon_dots: poly.slice(1).map((dot, j) => ({
                            id: j,
                            x: dot[0],
                            y: dot[1],
                        })),
                    }
                })

                tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                tempHistory.push({
                    action: "add",
                    previous: null,
                    current: {
                        ...measurement,
                        type: "polygon",
                        polygon_dots: differenceB[0][0].slice(1).map((dot, i) => ({
                            x: dot[0],
                            y: dot[1],
                        })),
                        sub_polygons: sub_polygons,
                    },
                });
                tempCurrentHistory++;

                handleCreatePolygon({
                    polygon_dots: differenceB[0][0].slice(1).map((dot, i) => ({
                        x: dot[0],
                        y: dot[1],
                    })),
                    sub_polygons: sub_polygons,
                })
            } else {
                tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                tempHistory.push({
                    action: "add",
                    previous: null,
                    current: {
                        ...measurement,
                        type: "polygon",
                        polygon_dots: differenceB[0][0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                        sub_polygons: {},
                    },
                });
                tempCurrentHistory++;

                handleCreatePolygon({
                    polygon_dots: differenceB[0][0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                    sub_polygons: {},
                })
            }

            if (differenceB.length > 1) {
                differenceB.slice(1).forEach((poly) => {
                    if (poly.length > 1) {
                        let sub_polygons = {}

                        poly.slice(1).forEach((poly, i) => {
                            sub_polygons[i] = {
                                id: i,
                                polygon_dots: poly.slice(1).map((dot, j) => ({
                                    id: j,
                                    x: dot[0],
                                    y: dot[1],
                                })),
                            }
                        })

                        tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                        tempHistory.push({
                            action: "add",
                            previous: null,
                            current: {
                                ...measurement,
                                type: "polygon",
                                polygon_dots: poly[0].slice(1).map((dot, i) => ({
                                    id: i,
                                    x: dot[0],
                                    y: dot[1],
                                })),
                                sub_polygons: sub_polygons,
                            },
                        });
                        tempCurrentHistory++;

                        handleCreatePolygon({
                            polygon_dots: poly[0].slice(1).map((dot, i) => ({
                                id: i,
                                x: dot[0],
                                y: dot[1],
                            })),
                            sub_polygons: sub_polygons,
                        });
                    } else {
                        tempHistory = tempHistory.slice(0, tempCurrentHistory + 1);

                        tempHistory.push({
                            action: "add",
                            previous: null,
                            current: {
                                ...measurement,
                                type: "polygon",
                                polygon_dots: poly[0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                                sub_polygons: {},
                            },
                        });
                        tempCurrentHistory++;

                        handleCreatePolygon({
                            polygon_dots: poly[0].slice(1).map((point) => ({ x: point[0], y: point[1] })),
                            sub_polygons: {},
                        });
                    }
                })
            }

            console.log(tempHistory);
            console.log(tempCurrentHistory);

            setHistory(tempHistory);
            setCurrentHistory(tempCurrentHistory);
        }
    }

    const getRectangle = (offset) => {
        const slope = (points[1].y - points[0].y) / (points[1].x - points[0].x);
        const yIntercept = points[0].y - (slope * points[0].x);

        let x1 = 0;
        let y1 = slope * x1 + yIntercept;
        let x2 = pages[pageID].width;
        let y2 = slope * x2 + yIntercept;

        let x3 = 0;
        let y3 = slope * x3 + yIntercept + 1000000 * offset;
        let x4 = pages[pageID].width;
        let y4 = slope * x4 + yIntercept + 1000000 * offset;

        return [[x1, y1], [x2, y2], [x3, y3], [x4, y4]];
    }

    const getPolygon = () => {
        let polygon = getPoints()

        let poly = [];
        for (let i = 0; i < polygon.length; i += 2) {
            poly.push([polygon[i], polygon[i + 1]]);
        }

        return poly;
    }

    const getPoints = () => {
        if (points.length < 2) return [];

        if (points.length === 2) {
            const slope = (points[1].y - points[0].y) / (points[1].x - points[0].x);
            const yIntercept = points[0].y - (slope * points[0].x);

            let x1 = 0;
            let y1 = slope * x1 + yIntercept;
            let x2 = pages[pageID].width;
            let y2 = slope * x2 + yIntercept;

            return [x1, y1, x2, y2];
        }

        //we get an array of points, the first point extends forever, and so does the last point

        //slop between 1st and 2nd point
        const slopeA = (points[1].y - points[0].y) / (points[1].x - points[0].x);
        const yInterceptA = points[0].y - (slopeA * points[0].x);

        //slop between 2nd to last and last point
        const slopeB = (points[points.length - 1].y - points[points.length - 2].y) / (points[points.length - 1].x - points[points.length - 2].x);
        const yInterceptB = points[points.length - 2].y - (slopeB * points[points.length - 2].x);

        return [
            ...(points[0].x < points[1].x ? [0, slopeA * 0 + yInterceptA] : [pages[pageID].width, slopeA * pages[pageID].width + yInterceptA]),
            //0, slopeA * 0 + yInterceptA,
            //pages[pageID].width, slopeA * pages[pageID].width + yInterceptA,
            ...points.map((point) => [point.x, point.y]).flat(),

            ...(points[points.length - 1].x < points[points.length - 2].x ? [0, slopeB * 0 + yInterceptB] : [pages[pageID].width, slopeB * pages[pageID].width + yInterceptB]),
            //0, slopeB * 0 + yInterceptB,
            //pages[pageID].width, slopeB * pages[pageID].width + yInterceptB,
        ]
    }

    return (
        <Portal
            selector={'.selection-layer'}
            enabled={true}
        >
            {points.length > 1 ?
                <Line
                    strokeWidth={2 / pages[pageID].zoom}
                    stroke="#FF0000"
                    dash={[5 / pages[pageID].zoom, 5 / pages[pageID].zoom]}
                    lineCap="round"
                    opacity={1}
                    lineJoin="round"
                    points={getPoints()}
                />
                : null}

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/split+rectangle.svg') 8 24, auto"}
                //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"}
                onClick={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    setPoints([...points, { x: x, y: y }]);
                }}
            />

            {points.map((point, i) =>
                <Circle
                    key={i}
                    x={point.x}
                    y={point.y}
                    radius={5 / pages[pageID].zoom}
                    fill="#FF0000"
                    stroke="#FF0000"
                    strokeWidth={1 / pages[pageID].zoom}
                    draggable={true}
                    onDragMove={(e) => {
                        let newPoints = [...points];
                        newPoints[i] = { x: e.target.x(), y: e.target.y() };
                        setPoints(newPoints);
                    }}
                    onMouseEnter={(e) => e.target.getStage().container().style.cursor = "pointer"}
                    onMouseLeave={(e) => e.target.getStage().container().style.cursor = "default"}
                    onClick={(e) => {
                        let newPoints = [...points];
                        newPoints.splice(i, 1);
                        setPoints(newPoints);
                    }}
                />
            )}
        </Portal>
    );
}