import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';

import { selectAuth } from '../../redux/slices/authSlice';

import "../styles/Report.css";

import { API_ROUTE } from '../../index';
import DashboardNavbar from '../../components/DashboardNavbar';
import ProfileSidebar from '../Profile/ProfileSidebar';
import Graph from './Graph';
import ActiveButton from '../../components/ActiveButton';
import { Blocks } from 'react-loader-spinner';
import { IconChartHistogram } from '@tabler/icons-react';

export default function ReportPage() {
    const auth = useSelector(selectAuth);

    const [projects, setProjects] = useState(null);
    const [contractors, setContractors] = useState(null);
    const [data, setData] = useState(null);

    const [scope, setScope] = useState('company');
    const [timeline, setTimeline] = useState('week');

    const [project, setProject] = useState(null);
    const [contractor, setContractor] = useState(null);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (auth.contractor && auth.token && !contractors) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/report/`,
                params: {
                    'companyID': auth.contractor.company,
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setContractors(response.data.contractors);
                    setProjects(response.data.projects);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, contractors]);

    const GetReport = () => {
        setData(null);
        setLoading(true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/report/`,
            data: {
                'companyID': auth.contractor.company,
                'timeline': timeline || 'week',
                'project': project || null,
                'contractor': contractor || null,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            <DashboardNavbar auth={auth} />
            <ProfileSidebar current={'report'} />
            <div className='report-container'>
                <div className='report-title'>
                    Report
                </div>

                <div className='report-settings'>
                    <div>
                        <div className='report-settings-title'>
                            Filter
                        </div>

                        <div className='report-settings-select-container'>
                            <Select
                                className='report-settings-select'
                                classNamePrefix={'report-settings-select'}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isFocused ? '#F0F8FF' : 'white',
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#EEEEEE',
                                        primary: '#006AFF',
                                    },
                                })}
                                defaultValue={{ value: 'company', label: 'Company' }}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                name="Filter"
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={[
                                    { value: 'company', label: 'Company' },
                                    { value: 'project', label: 'Project' },
                                    { value: 'user', label: 'User' },
                                ]}
                                onChange={(e) => {
                                    setScope(e.value);

                                    if (e.value === 'company') {
                                        setProject(null);
                                        setContractor(null);
                                    } else if (e.value === 'project') {
                                        setContractor(null);
                                        setProject(projects[0].id);
                                    } else if (e.value === 'user') {
                                        setProject(null);
                                        setContractor(contractors[0].id);
                                    }
                                }}
                            />

                            {scope === 'project' &&
                                <Select
                                    className='report-settings-select'
                                    classNamePrefix={'report-settings-select'}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isFocused ? '#F0F8FF' : 'white',
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#EEEEEE',
                                            primary: '#006AFF',
                                        },
                                    })}
                                    defaultValue={{ value: projects[0].id, label: projects[0].title }}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={false}
                                    name="Project"
                                    onChange={(e) => {
                                        setProject(e.value);
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={[
                                        ...(projects && projects.map((p) => {
                                            return { value: p.id, label: p.title }
                                        }))
                                    ]}
                                />
                            }

                            {scope === 'user' &&
                                <Select
                                    className='report-settings-select'
                                    classNamePrefix={'report-settings-select'}
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: state.isFocused ? '#F0F8FF' : 'white',
                                        }),
                                    }}
                                    theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#EEEEEE',
                                            primary: '#006AFF',
                                        },
                                    })}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={false}
                                    isRtl={false}
                                    isSearchable={false}
                                    name="User"
                                    value={{ value: contractors[0].id, label: contractors[0].email }}
                                    onChange={(e) => {
                                        setContractor(e.value);
                                    }}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                    options={[
                                        ...(contractors && contractors.map((c) => {
                                            return { value: c.id, label: c.email }
                                        }))
                                    ]}
                                />
                            }
                        </div>
                    </div>

                    <div>
                        <div className='report-settings-title'>
                            Timeline
                        </div>

                        <div>
                            <Select
                                className='report-settings-select'
                                classNamePrefix={'report-settings-select'}
                                styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        backgroundColor: state.isFocused ? '#F0F8FF' : 'white',
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary25: '#EEEEEE',
                                        primary: '#006AFF',
                                    },
                                })}
                                value={{ value: timeline, label: timeline.charAt(0).toUpperCase() + timeline.slice(1) }}
                                onChange={(e) => setTimeline(e.value)}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={false}
                                isRtl={false}
                                isSearchable={false}
                                name="Timeline"
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                                options={[
                                    { value: 'day', label: 'Day' },
                                    { value: 'week', label: 'Week' },
                                    { value: 'month', label: 'Month' },
                                    { value: 'year', label: 'Year' },
                                ]}
                            />
                        </div>
                    </div>

                    <div className='report-settings-header-item'>
                        <button
                            className='active-button report-settings-button'
                            onClick={() => GetReport()}
                        >
                            <div>
                                <IconChartHistogram size={20} stroke={2} />
                            </div>
                            <div>
                                Generate Report
                            </div>
                        </button>
                    </div>
                </div>

                {loading &&
                    <div className='report-loading'>
                        <Blocks
                            visible={true}
                            height="60"
                            width="60"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="20"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        <div>Loading...</div>
                    </div>
                }

                {data && !loading &&
                    <div className='report-graphs'>
                        {/*<Graph dataIn={data} names={['time_saved']} title={'Time saved'} allowCumulative={true} />*/}

                        <Graph dataIn={[...data]} names={['dots_inferenced', 'dots_created']} title={'Dots'} allowCumulative={true} />
                        {/*<Graph dataIn={data} names={['inference_time', 'creation_time']} title={'Total time'} allowCumulative={true} />*/}
                        {/*<Graph dataIn={data} names={['creation_time_avg', 'inference_time_avg']} title={'Speed'} allowCumulative={false} />*/}

                        <Graph dataIn={data} names={['measurements_created', 'measurements_inferenced']} title={'Measurements'} allowCumulative={true} />

                        <Graph dataIn={[...data]} names={['pages_uploaded', 'pages_takenoff', 'pages_prepped']} title={'Pages'} allowCumulative={true} />
                    </div>
                }
            </div>
        </>
    )
}