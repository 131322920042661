import { useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE } from '../../..';
import { selectAuth } from '../../../redux/slices/authSlice';
import { TakeoffContext } from '../../helper/Context';

import '../../styles/AIPopup.css';

import DefaultButton from '../../../components/DefaultButton';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { IconRobot } from '@tabler/icons-react';
import { Modal } from 'react-bootstrap';

export default function AISidebarLength({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        setMeasurements, setGroups,
        setTree,
        setPageGroups, setUngroupedPages,
        hintingSAM, setHintingSAM,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        hintingAutoFindDW, setHintingAutoFindDW,
        hintingAutoMeasure, setHintingAutoMeasure,
        hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
        AIMessages, setAIMessages,
        currentGroup,
        createMeasurements,
        createMeasurement,
        setAIAutoCountRect,
        setAIAutoCountPoly,
        autoNameAllPages, setAutoNameAllPages,
        showLegendsPopup, setShowLegendsPopup,
        AILegends,
        autoCountReference, setAutoCountReference,
        autoCountLocation, setAutoCountLocation,
        AIAutoCountExample, setAIAutoCountExample,
        handleTurnOffDrawing,
        AIAutoMeasureLengthRect, setAIAutoMeasureLengthRect,
        hintingLength, setHintingLength,
    } = useContext(TakeoffContext);

    const handleAutoMeasureRoomPerim = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-measure-room-perim/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handlePrepLength = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/ai-get-pipes/`,
            data: {
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleCreateLengths = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/create-pipe-lengths/`,
            data: {
                'page': pageID,
                'location': 'all',
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleHintLength = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/sam-roads-inference/`,
            data: {
                page: pageID,
                x: 200 / pages[pageID].zoom,
                y: 200 / pages[pageID].zoom,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                axios({
                    method: 'post',
                    url: `${API_ROUTE}/api/count/`,
                    data: {
                        'userID': auth.user.id,
                        'pageID': pageID,
                        'group': currentGroup,
                        'dots': [...response.data.map((dot) => ({
                            x: dot[1],
                            y: dot[0],
                        }))],
                        'time': 0.01,
                    },
                    headers: {
                        'Authorization': `Token ${auth.token}`,
                        "Content-Type": "application/json"
                    },
                })
                    .then((response) => {
                        console.log(response);

                        createMeasurement(response.data, true);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className='aisidebar-length-container'>
            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Room perimeters
                    </div>
                </div>
                <div className='navbar-aipopup-group-body'>
                    {AIMessages?.[pageID]?.autoSegRoomsPerim?.running
                        ? <div className="navbar-aipopup-group-item">
                            Automatic
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : <>
                            <div
                                id='navbar-aipopup-item-auto-measure-room-perim'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev[pageID],
                                            'autoSegRoomsPerim': {
                                                'progress': 1 / 100,
                                                'show': true,
                                                'running': true,
                                            }
                                        }
                                    }));
                                    handleAutoMeasureRoomPerim();
                                }}
                            >
                                Automatic
                            </div>

                            <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim'>
                                Automatically measure perimeter of all rooms
                            </Tooltip>
                        </>
                    }

                    {AIMessages?.[pageID]?.autoSegRoomsPerimCrop?.running
                        ? <div className="navbar-aipopup-group-item">
                            Crop
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : hintingAutoMeasurePerim?.[pageID]?.show
                            ? <div
                                id='navbar-aipopup-item-auto-measure-room-perim-hints-active'
                                className="navbar-aipopup-group-item-active"
                                onClick={() => {
                                    handleTurnOffDrawing();
                                }}
                            >
                                Crop
                            </div>
                            : <div
                                id='navbar-aipopup-item-auto-measure-room-perim-hints'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    handleTurnOffDrawing();

                                    setHintingAutoMeasurePerim(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev[pageID],
                                            show: true,
                                        }
                                    }))
                                }}
                            >
                                Crop
                            </div>
                    }

                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim-hints-active'>
                        <div>Draw a rectangular crop to auto measure perimeters for</div>
                        <div>Click to escape</div>
                    </Tooltip>

                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-measure-room-perim-hints'>
                        Draw a rectangular crop to auto measure perimeters for
                    </Tooltip>
                </div>
            </div>

            <hr className='navbar-aipopup-hr'></hr>

            {/*<div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Run lengths
                    </div>
                </div>
                <div className='navbar-aipopup-group-body'>
                    {hintingLength
                        ? <div
                            id='navbar-aipopup-item-hint-sam-active'
                            className='navbar-aipopup-group-item-active'
                            onClick={() => {
                                handleTurnOffDrawing();
                            }}
                        >
                            Hint length
                        </div>
                        : <div
                            id='navbar-aipopup-item-hint-sam'
                            className="navbar-aipopup-group-item"
                            onClick={() => {
                                handleTurnOffDrawing();

                                setHintingLength(true);
                                setCurrentMeasurement(null);
                            }}
                        >
                            Hint length
                        </div>
                    }
                </div>
            </div>*/}

            {/*pages[pageID]?.ai_prepped_length
                ? <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Run lengths
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        <div
                            className="navbar-aipopup-group-item"
                            onClick={() => handleCreateLengths()}
                        >
                            Whole page
                        </div>

                        <div
                            className="navbar-aipopup-group-item"
                            onClick={() => setAIAutoMeasureLengthRect(prev => ({
                                ...prev,
                                [pageID]: {
                                    ...prev[pageID],
                                    start: null,
                                    end: null,
                                    isComplete: false,
                                    show: true,
                                }
                            }))}
                        >
                            Rectangle
                        </div>
                    </div>
                </div>
                : <div className='navbar-aipopup-group'>
                    <div className='navbar-aipopup-group-header'>
                        <div className='navbar-aipopup-group-title'>
                            Prep line lengths
                        </div>
                    </div>
                    <div className='navbar-aipopup-group-body'>
                        <div
                            className="navbar-aipopup-group-item"
                            onClick={() => handlePrepLength()}
                        >
                            Prep
                        </div>
                    </div>
                </div>
            */}
        </div>
    )
}