import { useContext } from "react";

import { TakeoffContext } from "../../helper/Context";

import Page from "./Page";
import ListPage from "./Components/ListPage";

export default function PageList({ search, filterStarred }) {
    const {
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
    } = useContext(TakeoffContext);

    return (
        <div className="pagessidebar-body-list">
            {pages && Object.values(pages)?.sort((a, b) => a.id - b.id).filter(p => {
                if (filterStarred && !p.starred) return false;
                if (search && !p.title.toLowerCase().includes(search.toLowerCase())) return false;
                if (p) return true;
                return false;
            }).map(p => (
                <ListPage key={p.id} p={p} useGrid={false} useNames={false} useList={true} disabled={true} />
            ))}
        </div>
    )
}