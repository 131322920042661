import { useContext, useEffect, useRef } from "react";

import { TakeoffContext } from "../../helper/Context";

import "../../styles/FilterSettings.css"
import axios from "axios";
import { API_ROUTE } from "../../..";
import { selectAuth } from "../../../redux/slices/authSlice";
import { useSelector } from "react-redux";
import { Blocks } from "react-loader-spinner";


export default function FilterSettings({ setShowFilterSettings }) {
    const auth = useSelector(selectAuth);

    const {
        project, setProject,
        measurements, setMeasurements,
        groups, setGroups,
        tree, setTree,
        currentMeasurement, setCurrentMeasurement,
        handleChangeFilter,
        changingFilter, setChangingFilter,
    } = useContext(TakeoffContext);

    const filterSettingsRef = useRef(null);

    const handleDocumentClick = (e) => {
        if (filterSettingsRef.current && !filterSettingsRef.current.contains(e.target) && e.target.id !== 'measurementsidebar-header-button-filter') {
            setTimeout(() => {
                setShowFilterSettings(false);
            }, 100);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Escape") {
            setShowFilterSettings(false);
            e.stopPropagation();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleDocumentClick);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div
            className="measurementsidebar-filtersettings-container"
            ref={filterSettingsRef}
        >
            <div className="measurementsidebar-filtersettings-header">
                Filter settings
            </div>

            <div className="measurementsidebar-filtersettings-body">
                <div className="measurementsidebar-filtersettings-item">
                    All pages

                    {changingFilter
                        ? <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        : <input
                            type="checkbox"
                            className="measurementsidebar-filtersettings-item-input"
                            checked={project?.include_all_pages}
                            onChange={(e) => handleChangeFilter('include_all_pages', e.target.checked)}
                        />
                    }
                </div>

                <div className="measurementsidebar-filtersettings-item">
                    Count

                    {changingFilter
                        ? <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        : <input
                            type="checkbox"
                            className="measurementsidebar-filtersettings-item-input"
                            checked={project?.include_count}
                            onChange={(e) => handleChangeFilter('include_count', e.target.checked)}
                        />
                    }
                </div>

                <div className="measurementsidebar-filtersettings-item">
                    Length

                    {changingFilter
                        ? <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        : <input
                            type="checkbox"
                            className="measurementsidebar-filtersettings-item-input"
                            checked={project?.include_length}
                            onChange={(e) => handleChangeFilter('include_length', e.target.checked)}
                        />
                    }
                </div>

                <div className="measurementsidebar-filtersettings-item">
                    Area

                    {changingFilter
                        ? <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        : <input
                            type="checkbox"
                            className="measurementsidebar-filtersettings-item-input"
                            checked={project?.include_area}
                            onChange={(e) => handleChangeFilter('include_area', e.target.checked)}
                        />
                    }
                </div>

                <div className="measurementsidebar-filtersettings-item">
                    Volume

                    {changingFilter
                        ? <Blocks
                            visible={true}
                            height="18"
                            width="18"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        : <input
                            type="checkbox"
                            className="measurementsidebar-filtersettings-item-input"
                            checked={project?.include_volume}
                            onChange={(e) => handleChangeFilter('include_volume', e.target.checked)}
                        />
                    }
                </div>
            </div>
        </div>
    )
}