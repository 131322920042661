import { createSlice } from '@reduxjs/toolkit'

export const authSlice = createSlice({
    name: 'auth',
    initialState: {},
    reducers: {
        updateAuth: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                user: {...action.payload.user},
                contractor: {...action.payload.contractor},
                token: action.payload.token,
            }
        },
        removeAuth: (state) => {
            return {}
        },
    },
})

export const { updateAuth, removeAuth } = authSlice.actions

export const selectAuth = (state) => state.auth

export default authSlice.reducer
