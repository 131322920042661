import axios from 'axios';
import { lensPath, compose, set, view, pipe, over, concat, lensIndex, isNil } from 'ramda';
import { useState, useEffect } from 'react';
import { API_ROUTE } from '../index';
import { selectAuth } from '../redux/slices/authSlice';
import { useSelector } from 'react-redux';
import { axiosCurry } from '../utilities/utilities';
import { searchTermLens, searchLens, stateLens, countyLens, zipcodeLens, limitLens, offsetLens, onRateSelectedLens } from './onebuildLenses';

const sourcesQuery = `query sources($input: SourceSearchInput!) {
    sources(input: $input) {
      nodes {
        state
        county
        csiDivision
        csiDivisionNumber
        csiDivisionName
        csiSection
        csiTitle
        nahbDivision
        nahbDivisionDescription
        nahbCode
        nahbCodeDescription
        categoryPath
        properties {
          name
          value
          uom
          quantity
        }
        description
        imagesUrls
        nestedSources {
          parentId
          quantity
          seqNum
          formula
          id
          sourceType
          name
          uom
          inputUOM
          outputUOM
          materialRateUsdCents
          laborRateUsdCents
          burdenedLaborRateUsdCents
          productionRate
          calculatedUnitRateUsdCents
          imagesUrls
          description
          externalProductUrl
          csiDivision
          csiSection
          csiTitle
        }
        knownUoms {
          uom
          materialRateUsdCents
          laborRateUsdCents
          burdenedLaborRateUsdCents
          productionRate
          calculatedUnitRateUsdCents
        }
        id
        sourceType
        name
        uom
        inputUOM
        outputUOM
        materialRateUsdCents
        laborRateUsdCents
        burdenedLaborRateUsdCents
        laborSourceId
        laborName
        productionRate
        calculatedUnitRateUsdCents
        externalProductUrl
      }
      pageInfo {
        hasNextPage
      }
      dataLocation {
        countyName
        stateName
      }
      totalCount
    }
  }`;

const initialState = {
  nodes: [],
  totalCount: 0,
  isLaborIncluded: false,
};

const nodesLens = lensPath(['nodes']);
const totalCountLens = lensPath(['totalCount']);
const isLaborIncludedLens = lensPath(['isLaborIncluded']);

const responseSourcesLens = lensPath(['data', 'data', 'sources']);
const responseNodeLens = compose(responseSourcesLens, nodesLens);
const responseTotalCountLens = compose(responseSourcesLens, totalCountLens);

const nodeNameLens = lensPath(['name']);
const nodeImagesUrlsLens = lensPath(['imagesUrls']);
const nodeHeadImageUrlLens = compose(nodeImagesUrlsLens, lensIndex(0));
const nodeUOMLens = lensPath(['uom']);
const nodeMaterialRateUsdCentsLens = lensPath(['materialRateUsdCents']);
const nodeLaborRateUsdCentsLens = lensPath(['laborRateUsdCents']);
const nodeSourceTypeLens = lensPath(['sourceType']);

const setNodes = (nodes) => set(nodesLens)(nodes);
const setNodesFromResponse = (response) => setNodes(view(responseNodeLens)(response));
const concatNodes = (nodes) => (state) => set(nodesLens)(concat(view(nodesLens)(state))(nodes))(state);
const concatNodesFromResponse = (response) => concatNodes(view(responseNodeLens)(response));
const setTotalCount = (count) => set(totalCountLens)(count);
const setTotalCountFromResponse = (response) => setTotalCount(view(responseTotalCountLens)(response));
const toggleIsLaborIncluded = over(isLaborIncludedLens)((val) => !val);

const OneBuildPopup = (props) => {
  const [state, setState] = useState(initialState);
  const auth = useSelector(selectAuth);
  const oneBuildSearch = axiosCurry(`${API_ROUTE}/api/onebuild-api/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' })('post');
  const currentResultsCount = view(nodesLens)(state)?.length;

  useEffect(() => {
    oneBuildSearch({
      variables: { input: view(searchLens)(props) },
      query: sourcesQuery,
    }).then((res) => setState(pipe(setNodesFromResponse(res), setTotalCountFromResponse(res))));
  }, [view(searchLens)(props)]);
  return (
    <div className='flex flex-col pt-2 pb-2 rounded shadow-lg w-96'>
      {!isNil(view(nodesLens)(state)) && view(totalCountLens)(state) != 0 && (
        <div className='flex flex-row min-h-0 gap-2 pl-2 pr-2'>
          <input value={view(isLaborIncludedLens)(state)} type='checkbox' id='include-labor-checkbox' onChange={() => pipe(toggleIsLaborIncluded, setState)(state)} />
          <label htmlFor='include-labor-checkbox'>Include Labor Cost</label>
        </div>
      )}
      {(isNil(view(nodesLens)(state)) || view(totalCountLens)(state) == 0) && <div className='p-2 text-sm'>No results found</div>}
      {!isNil(view(nodesLens)(state)) && view(totalCountLens)(state) != 0 && (
        <div className='flex flex-col min-h-0 overflow-y-scroll grow'>
          {view(nodesLens)(state).map((node) => {
            return (
              <div key={node.id} className='flex flex-row items-center h-16 gap-4 pt-2 pb-2 rounded hover:bg-gray-revell'>
                <img src={view(nodeHeadImageUrlLens)(node)} alt={view(nodeNameLens)(node)} className='object-contain w-20 ml-4 border rounded h-14 shrink-0 border-gray-darkish' />
                <div className='flex flex-col justify-between min-w-0 gap-2 ml-4 grow'>
                  <div className='min-h-0 text-xs text-nowrap overflow-clip text-ellipsis'>{view(nodeNameLens)(node)}</div>
                  <div className='flex flex-row min-h-0 gap-4 text-2xs text-gray-darkish'>
                    <div>
                      ${view(nodeMaterialRateUsdCentsLens)(node) / 100}/{view(nodeUOMLens)(node)}
                    </div>
                    {view(isLaborIncludedLens)(state) && (
                      <div>
                        Labor: ${view(nodeLaborRateUsdCentsLens)(node) / 100}/{view(nodeSourceTypeLens)(node) == 'ASSEMBLY' ? view(nodeUOMLens)(node) : 'hr'}
                      </div>
                    )}
                  </div>
                </div>
                <div className='mr-2'>
                  <button
                    className='text-lg font-light'
                    onClick={() =>
                      view(onRateSelectedLens)(props)({
                        laborCost: view(isLaborIncludedLens)(state) ? view(nodeLaborRateUsdCentsLens)(node) / 100 : null,
                        unitCost: view(nodeMaterialRateUsdCentsLens)(node) / 100,
                      })
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {currentResultsCount < view(totalCountLens)(state) && (
        <div className='flex flex-row justify-around min-h-0 p-2'>
          <button
            onClick={() =>
              oneBuildSearch({
                variables: { input: pipe(set(offsetLens)(currentResultsCount), view(searchLens))(props) },
                query: sourcesQuery,
              }).then((res) => pipe(concatNodesFromResponse(res), setState)(state))
            }
            className='text-xs font-medium'
          >
            <u>Load More</u>
          </button>
        </div>
      )}
    </div>
  );
};
export default OneBuildPopup;
