import React, { useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { getIsOverParent, iOS } from './utilities';

const animateLayoutChanges = ({ isSorting, isDragging }) => (isSorting || isDragging ? false : true);

const SortableTreeItemNotMemoized = function SortableTreeItem({ id, depth, isLast, TreeItemComponent, parent, disableSorting, sortableProps, keepGhostInPlace, ...props }) {
  const { attributes, isDragging, isSorting, listeners, setDraggableNodeRef, setDroppableNodeRef, transform, transition, isOver, over } = useSortable({
    id,
    animateLayoutChanges,
    disabled: disableSorting,
    ...sortableProps,
  });
  const isOverParent = useMemo(() => !!over?.id && getIsOverParent(parent, over.id), [over?.id]);
  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition ?? undefined,
  };
  const localCollapse = useMemo(() => {
    if (!props.onCollapse) return undefined;
    return () => props.onCollapse?.(props.item.id);
  }, [props.item.id, props.onCollapse]);

  const localRemove = useMemo(() => {
    if (!props.onRemove) return undefined;

    return () => props.onRemove?.(props.item.id);
  }, [props.item.id, props.onRemove]);
  return (
    <TreeItemComponent
      {...props}
      ref={setDraggableNodeRef}
      wrapperRef={setDroppableNodeRef}
      style={keepGhostInPlace ? undefined : style}
      depth={depth}
      ghost={isDragging}
      disableSelection={iOS}
      disableInteraction={isSorting}
      isLast={isLast}
      parent={parent}
      handleProps={{
        ...attributes,
        ...listeners,
      }}
      onCollapse={localCollapse}
      onRemove={localRemove}
      disableSorting={disableSorting}
      isOver={isOver}
      isOverParent={isOverParent}
    />
  );
};

export const SortableTreeItem = React.memo(SortableTreeItemNotMemoized);
