import { IconCheckbox, IconSquare, IconSquareCheckFilled, IconTablePlus } from '@tabler/icons-react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import Popup from 'reactjs-popup';

export default function ColumnsModal({ columns, handleColumnToggle, handleColumnToggleAll }) {
    const [showColumnsModal, setShowColumnsModal] = useState(false);

    return (
        <>
            <Tooltip anchorSelect="#database-navbar-columns-setting" place="top" delayShow={1000} positionStrategy="fixed" style={{ zIndex: 1000 }}>
                Toggle columns
            </Tooltip>

            <Popup
                trigger={open => (
                    <div
                        id="database-navbar-columns-setting"
                        className="database-navbar-item"
                        onClick={() => {
                            setShowColumnsModal(true);
                        }}
                    >
                        <IconTablePlus size={20} />
                    </div>
                )}
                on=''
                open={showColumnsModal}
                onClose={() => setShowColumnsModal(false)}
                position="bottom right"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                    width: '500px',
                }}
            >
                <div className="database-columns-modal-body">
                    <div className="database-columns-modal-header">
                        <div className="database-columns-modal-title">
                            Columns
                        </div>
                        <div className="database-columns-modal-options">
                            <div
                                className="database-columns-modal-options-item"
                                onClick={() => handleColumnToggleAll(true)}
                            >
                                Include all
                            </div>
                            <div
                                className="database-columns-modal-options-item"
                                onClick={() => handleColumnToggleAll(false)}
                            >
                                Exclude all
                            </div>
                        </div>
                    </div>

                    <div className="database-columns-modal-container">
                        {columns && Object.keys(columns).filter(c => c !== 'name').map((column, index) =>
                            <div
                                key={index}
                                className="database-columns-modal-item"
                                onClick={() => handleColumnToggle(column)}
                            >
                                {columns[column]
                                    ? <div className="database-columns-modal-item-icon">
                                        <IconSquareCheckFilled size={20} />
                                    </div>
                                    : <div className="database-columns-modal-item-icon">
                                        <IconSquare size={20} />
                                    </div>
                                }
                                <div className="database-columns-modal-item-text">
                                    {column.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Popup>
        </>
    )
}