import { useState, useEffect } from 'react';
import "./styles/Landing4.css";
import axios from 'axios';

import { IconBrandLinkedin } from '@tabler/icons-react';
import LandingNavbar from './LandingNavbar';

export default function PilotLanding() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <div className='landing4-container'>
            <div className='landing4-background'>
                <img
                    className='landing4-background-image'
                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Landingpage-render-final.gif'
                />
            </div>

            <div className='landing4-content'>
                <LandingNavbar />

                <div className='landing4-body'>
                    <div className='landing4-mobile-render'>
                        <img
                            className='landing4-mobile-render-image'
                            src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/Landingpage-render-final.gif'
                        />
                    </div>

                    <div className='landing4-body-main'>
                        <div className='landing4-body-title'>
                            Bobyard Pilots
                        </div>
                        <div className='landing4-body-subtitle'>
                            Thank you for being a Bobyard design partner. Your advice, perspective, and expertise are what makes everything we do possible. We are excited to have you on board!
                        </div>
                    </div>
                    <div className='landing4-body-connect'>
                        <div className='landing4-body-connect-title'>
                            We would love to hear from you!
                        </div>

                        <div className='landing4-body-subtitle'>
                            If you have any feedback or questions, please grab a time to chat with us.
                        </div>

                        <div className='landing4-body-connect-button'>
                            <a href='/meeting'>
                                <button className='landing4-header-menu-button'>
                                    Book meeting
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className='landing4-body-backedby'>
                        <div className='landing4-body-backedby-title'>
                            Backed by
                        </div>
                        <div className='landing4-body-backedby-logos'>
                            <div>
                                <img
                                    className='landing4-body-backedby-primary-logo-image'
                                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-primary-logo.png'
                                />
                            </div>
                            <div>
                                <img
                                    className='landing4-body-backedby-pear-logo-image'
                                    src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-pear-logo.png'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
