import { useEffect, useState } from 'react';

import { IconDownload, IconTrashX, IconUpload } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';

import AWS from 'aws-sdk';
import axios from 'axios';
import { Blocks } from 'react-loader-spinner';
import { saveAs } from 'file-saver';

export default function SidebarFile({ file, currentFile, setCurrentFile, setShowDeleteModal }) {
    const [hover, setHover] = useState(false);

    const [downloading, setDownloading] = useState(false);

    const openFileNewTab = (fileIn) => {
        setDownloading(true);
        
        axios({
            url: fileIn.file,
            method: 'GET',
            responseType: 'blob',
        })
            .then((response) => {
                console.log(response);

                const file = new Blob([response.data], { type: response.data.type });
                saveAs(file, fileIn.name);

                setDownloading(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div
            className='projectdetails-file-container'
            onClick={() => setCurrentFile(file.id)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{
                backgroundColor: file.id === currentFile && 'aliceblue',
            }}
        >
            <div
                id={'projectdetails-file-name' + file.id}
                className='projectdetails-file-name'
                style={{
                    color: file.id === currentFile ? '#006AFF' : '#000000',
                }}
            >
                {file.name}
            </div>

            {file.name.length > 30 &&
                <Tooltip anchorSelect={'#projectdetails-file-name' + file.id} delayShow={1000} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                    {file.name}
                </Tooltip>
            }

            {hover && (
                <div className='projectdetails-file-options-container'>
                    {downloading
                        ? <div className='projectdetails-file-download'>
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : <div
                            id={'projectdetails-file-download-icon' + file.id}
                            className='projectdetails-file-download'
                            onClick={(e) => {
                                e.stopPropagation();
                                openFileNewTab(file);
                            }}
                        >
                            <IconDownload />
                        </div>
                    }

                    <Tooltip anchorSelect={'#projectdetails-file-download-icon' + file.id} delayShow={1000} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                        Download original file
                    </Tooltip>

                    <div
                        id={'projectdetails-file-delete-icon' + file.id}
                        className='projectdetails-file-delete'
                        onClick={(e) => {
                            e.stopPropagation();
                            setCurrentFile(file.id);
                            setShowDeleteModal(true);
                        }}
                    >
                        <IconTrashX />
                    </div>

                    <Tooltip anchorSelect={'#projectdetails-file-delete-icon' + file.id} delayShow={1000} positionStrategy='fixed' style={{ zIndex: 99999 }}>
                        Delete file
                    </Tooltip>
                </div>
            )}
        </div>
    )
}