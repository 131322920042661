import { useSelector } from "react-redux";
import { selectAuth } from "../../redux/slices/authSlice";
import { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { API_ROUTE } from "../..";

import { useParams } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import NumericField from "../components/NumericField";

import {
    FolderTreeItemWrapper,
    SimpleTreeItemWrapper,
    SortableTree,
    TreeItemComponentProps,
    TreeItems,
} from 'dnd-kit-sortable-tree';
import { IconArrowsMaximize, IconCaretDown, IconCaretRight, IconFolder, IconFolderOpen, IconReceipt2, IconSearch, IconSquarePlus, IconX } from "@tabler/icons-react";
import { Resizable } from "react-resizable";
import { VendorQuoteContext } from "./context";
import { EmptyCircleIcon, EmptyCircleIconBlue, RevertCircleIcon } from "../../database/components/Icons";
import { Tooltip } from "react-tooltip";
import { IconArrowsMinimize } from "@tabler/icons-react";
import { Blocks } from "react-loader-spinner";

export default function VendorQuotes({ projectUUID, items, handleSetMaterialCost, handleSetVendorName }) {
    //const params = useParams();
    //const projectUUID = params.projectUUID;

    const auth = useSelector(selectAuth);

    const [vendors, setVendors] = useState(null);
    const [measurements, setMeasurements] = useState(null);
    const [groups, setGroups] = useState(null);
    const [tree, setTree] = useState(null);
    const [nameWidth, setNameWidth] = useState(300);

    const [activeID, setActiveID] = useState(null);
    const [hoverID, setHoverID] = useState(null);

    const [search, setSearch] = useState('');
    const [searching, setSearching] = useState(false);

    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        if (auth.token && showPopup) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/vendor-quotes/`,
                params: {
                    'userID': auth.user.id,
                    'projectUUID': projectUUID
                },
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setVendors(response.data.vendors);
                    setMeasurements(response.data.measurements);
                    setGroups(response.data.groups);
                    setTree(response.data.tree);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth, showPopup]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter' || e.key === 'Escape' || e.key === 'Return' || e.key === 'Tab') {
                if (document.activeElement.tagName === 'INPUT') {
                    document.activeElement.blur();
                } else {
                    setActiveID(null);
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    const handleSearch = () => {
        setSearching(true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/vendor-quotes/`,
            data: {
                projectUUID: projectUUID,
                search: search,
            },
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => {
                console.log(response);

                setTree(response.data);
                setSearching(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleCreateVendor = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/vendor/`,
            data: {
                'projectUUID': projectUUID,
                'name': 'New Vendor'
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setVendors({
                    ...vendors,
                    [response.data.id]: response.data,
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUpdateQuote = (quote, price) => {
        console.log(quote, price);

        setVendors({
            ...vendors,
            [quote.vendor]: {
                ...vendors[quote.vendor],
                quotes: {
                    ...vendors[quote.vendor].quotes,
                    [quote.measurement]: {
                        ...quote,
                        price: price,
                    }
                }
            }
        });

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/vendorquotes/${quote.id}/`,
            data: {
                'vendor': quote.vendor,
                'measurement': quote.measurement,
                'price': price,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleResizeVendor = (vendorID, width) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/vendors/${vendorID}/`,
            data: {
                'project': vendors[vendorID].project,
                'width': width,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const updateVendorName = (vendorID, name) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/vendors/${vendorID}/`,
            data: {
                'project': vendors[vendorID].project,
                'name': name,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleImportQuote = (vendorID, entryID) => {
        const materialCost = (vendors[vendorID].quotes[entryID.split('-')[1]].price)
        handleSetMaterialCost(entryID)(materialCost)

        const vendorName = vendors[vendorID].name
        handleSetVendorName(entryID)(vendorName)
    }

    const handleToggleAll = (collapsed) => {
        let oldTree = JSON.stringify(tree);

        if (collapsed) {
            oldTree = oldTree.replace(/"collapsed":true/g, '"collapsed":false');
        } else {
            oldTree = oldTree.replace(/"collapsed":false/g, '"collapsed":true');
        }

        setTree(JSON.parse(oldTree));
    }

    const visibleIDs = useMemo(() => {
        if (!tree) return [];

        const flattened = [];

        const dfs = (node) => {
            flattened.push(node.id);

            if (node.children && !node.collapsed) {
                node.children.forEach((child, i) => {
                    dfs(child);
                });
            }
        }

        tree.forEach((node, i) => {
            dfs(node);
        })

        return flattened;
    }, [tree]);

    const hasCollapsed = useMemo(() => {
        if (!tree) return false;
        return String(JSON.stringify(tree)).includes(`"collapsed":true`);
    }, [tree]);

    const hasExpanded = useMemo(() => {
        if (!tree) return false;
        return String(JSON.stringify(tree)).includes(`"collapsed":false`);
    }, [tree]);

    return (
        <>
            <div
                id="database-navbar-columns-setting"
                className="database-navbar-item"
                onClick={() => {
                    setShowPopup(true);
                }}
            >
                <IconReceipt2 size={20} />
            </div>

            <Modal
                show={showPopup}
                onHide={() => setShowPopup(false)}
                centered
                size="xl"
                dialogClassName="vendorquotes-modal-dialog"
                contentClassName="vendorquotes-modal-content"
            >
                <Modal.Header
                    style={{
                        padding: '5px 20px',
                    }}
                >
                    <div className="vendorquotes-header">
                        <div className="vendorquotes-title">
                            <IconReceipt2 size={20} /> Vendor Quotes
                        </div>

                        <div className='database-navbar-search'>
                            {searching
                                ? <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                                : <IconSearch size={20} />
                            }

                            <div className='database-navbar-search-container'>
                                <input
                                    id='database-navbar-search'
                                    type='text'
                                    placeholder='Search...'
                                    className='database-navbar-search-input'
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onBlur={(e) => handleSearch(search)}
                                />

                                <div
                                    id='database-navbar-search-clear'
                                    className='database-navbar-search-clear'
                                    onClick={() => {
                                        setSearch('');
                                        handleSearch('');
                                    }}
                                >
                                    <IconX size={20} />
                                </div>
                            </div>
                        </div>

                        <div className="vendorquotes-buttons">
                            <div
                                className={"vendorquotes-close-button " + (!hasCollapsed ? 'vendorquotes-button-disabled' : '')}
                                onClick={() => {
                                    if (hasCollapsed) {
                                        handleToggleAll(true);
                                    }
                                }}
                            >
                                <IconArrowsMaximize stroke={1} size={20} />
                                Expand all
                            </div>

                            <div
                                className={"vendorquotes-close-button " + (!hasExpanded ? 'vendorquotes-button-disabled' : '')}
                                onClick={() => {
                                    if (hasExpanded) {
                                        handleToggleAll(false);
                                    }
                                }}
                            >
                                <IconArrowsMinimize stroke={1} size={20} />
                                Collapse all
                            </div>

                            <div
                                className="vendorquotes-close-button"
                                onClick={() => handleCreateVendor()}
                            >
                                <IconSquarePlus stroke={1} size={20} /> Create vendor
                            </div>
                        </div>
                    </div>

                    <div
                        className="vendorquotes-close-button"
                        onClick={() => setShowPopup(false)}
                    >
                        <IconX size={20} />
                    </div>
                </Modal.Header>
                <div className="vendorquotes-body">
                    {(tree && vendors && visibleIDs)
                        ? <ScrollSync>
                            <div className="vendor-quotes-columns">
                                <div
                                    className="database-column database-sidebar vendorquotes-column"
                                    style={{
                                        width: nameWidth + 'px',
                                    }}
                                >
                                    <Resizable
                                        axis="x"
                                        handle={
                                            <div
                                                className="database-column-resize-handle"
                                                onClick={(e) => e.stopPropagation()}
                                                onDoubleClick={(e) => setNameWidth(300)}
                                                onContextMenu={(e) => {
                                                    e.preventDefault();
                                                    setNameWidth(300);
                                                }}
                                            >
                                                &nbsp;
                                            </div>
                                        }
                                        minConstraints={[300, 0]}
                                        maxConstraints={[1000, Infinity]}
                                        height={Infinity}
                                        width={nameWidth}
                                        onResize={(e, data) => {
                                            setNameWidth(data.size.width);
                                        }}
                                        onResizeStop={(e, data) => {
                                        }}
                                    >
                                        <div
                                            className="database-column-header"
                                            style={{
                                                width: nameWidth + 'px',
                                                height: '42px',
                                            }}
                                        >
                                            Name
                                        </div>
                                    </Resizable>

                                    <ScrollSyncPane>
                                        <div className="vendorquotes-sidebar-content">
                                            <VendorQuoteContext.Provider
                                                value={{
                                                    activeID,
                                                    hoverID,
                                                    setHoverID,
                                                }}
                                            >
                                                <SortableTree
                                                    items={tree}
                                                    onItemsChanged={(newTree, { type, item, draggedItem, draggedFromParent, droppedToParent }) => setTree(newTree)}
                                                    TreeItemComponent={TreeItem}
                                                    disableSorting={true}
                                                    pointerSensorOptions={{
                                                        'activationConstraint': {
                                                            'distance': 10,
                                                        }
                                                    }}
                                                />
                                            </VendorQuoteContext.Provider>
                                        </div>
                                    </ScrollSyncPane>
                                </div>

                                {vendors && Object.values(vendors)?.map((vendor) =>
                                    <div
                                        key={vendor.id}
                                        className="database-column vendorquotes-column"
                                        style={{
                                            width: vendor.width ? vendor.width + 'px' : '200px',
                                        }}
                                    >
                                        <VendorName
                                            vendor={vendor}
                                            vendors={vendors}
                                            setVendors={setVendors}
                                            updateVendorName={updateVendorName}
                                            handleResizeVendor={handleResizeVendor}
                                        />

                                        <ScrollSyncPane>
                                            <div className="vendorquotes-column-content">
                                                {visibleIDs?.map((entryID) =>
                                                    <VendorQuote
                                                        key={entryID}
                                                        entryID={entryID}
                                                        vendor={vendor}
                                                        vendors={vendors}
                                                        setVendors={setVendors}
                                                        handleUpdateQuote={handleUpdateQuote}
                                                        setActiveID={setActiveID}
                                                        activeID={activeID}
                                                        hoverID={hoverID}
                                                        setHoverID={setHoverID}
                                                        handleImportQuote={handleImportQuote}
                                                        selected={items[entryID].material_cost
                                                            && items[entryID].material_cost === vendor.quotes[entryID.split('-')[1]]?.price
                                                            && items[entryID].material_vendor === vendor.name
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </ScrollSyncPane>
                                    </div>
                                )}
                            </div>
                        </ScrollSync >
                        : null
                    }
                </div>
            </Modal >
        </>
    )
}

const VendorQuote = ({ entryID, vendor, vendors, setVendors, handleUpdateQuote, setActiveID, activeID, hoverID, setHoverID, handleImportQuote, selected }) => {
    const [hover, setHover] = useState(false);

    let is_min = Object.values(vendors).reduce((acc, vendor) => {
        if (vendor.quotes[entryID.split('-')[1]]?.price > 0) {
            if (acc === 0) {
                return vendor.quotes[entryID.split('-')[1]].price;
            } else {
                return Math.min(acc, vendor.quotes[entryID.split('-')[1]].price);
            }
        } else {
            return acc;
        }
    }, 0) === vendor.quotes[entryID.split('-')[1]]?.price

    return (
        <>
            <div
                key={entryID}
                className={"database-entry "}
                onMouseEnter={() => {
                    setHoverID(entryID);
                    setHover(true);
                }}
                onMouseLeave={() => {
                    setHoverID(null)
                    setHover(false);
                }}
                style={{
                    backgroundColor: entryID === activeID
                        ? 'aliceblue'
                        : hoverID === entryID
                            ? '#EEEEEE'
                            : '#F8F8F8',
                }}
            >
                {entryID.includes('group')
                    ? <>&nbsp;</>
                    : <NumericField
                        className={"database-entry-field " + ((is_min || entryID.includes('measurement') && hover) ? 'database-entry-field-calculated' : '')}
                        onBlur={(value) => {
                            handleUpdateQuote(vendor?.quotes?.[entryID.split('-')[1]], value);
                        }}
                        onClick={(e) => {
                            setActiveID(entryID);
                        }}
                        value={vendor?.quotes?.[entryID.split('-')[1]]?.price}
                        placeholder={0.00}
                    />
                }

                {selected
                    ? <div
                        id={"database-entry-calculated-icon-" + entryID + "-" + vendor.id}
                        className="database-entry-calculated-icon">
                        <RevertCircleIcon />
                    </div>
                    : (is_min || entryID.includes('measurement') && hover)
                        ? <div
                            id={"database-entry-calculated-icon-" + entryID + "-" + vendor.id}
                            className="database-entry-calculated-icon vendor-calculated-icon"
                            onClick={() => handleImportQuote(vendor.id, entryID)}
                        >
                            {is_min
                                ? <EmptyCircleIconBlue />
                                : <EmptyCircleIcon />
                            }
                        </div>
                        : null
                }
            </div>

            <Tooltip anchorSelect={"#database-entry-calculated-icon-" + entryID + "-" + vendor.id} delayShow={1000} positionStrategy="fixed" style={{ zIndex: 99999 }} place="top-end">
                <div>
                    {selected
                        ? <>Cost imported</>
                        : <>Import material cost</>
                    }
                </div>
                <div>
                    {is_min
                        ? 'Lowest price'
                        : null
                    }
                </div>
            </Tooltip>
        </>
    )
}

const VendorName = ({ vendor, vendors, setVendors, updateVendorName, handleResizeVendor }) => {
    const [editing, setEditing] = useState(false);

    return (
        <Resizable
            axis="x"
            handle={
                <div
                    className="database-column-resize-handle"
                    onClick={(e) => e.stopPropagation()}
                    onDoubleClick={(e) => handleResizeVendor(vendor.id, 200)}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        handleResizeVendor(vendor.id, 200);
                    }}
                >
                    &nbsp;
                </div>
            }
            minConstraints={[100, 0]}
            maxConstraints={[1000, Infinity]}
            height={Infinity}
            width={vendor.width || 200}
            onResize={(e, data) => {
                setVendors({
                    ...vendors,
                    [vendor.id]: {
                        ...vendor,
                        width: data.size.width,
                    }
                });
            }}
            onResizeStop={(e, data) => {
                handleResizeVendor(vendor.id, Math.max(100, data.size.width))
            }}
        >
            {editing
                ? <div
                    className="vendorquotes-column-header-input-container"
                    style={{
                        width: vendor.width ? vendor.width + 'px' : '200px',
                    }}
                >
                    <input
                        type="text"
                        value={vendor.name}
                        className="vendorquotes-column-header-input"
                        style={{
                            width: vendor.width ? vendor.width + 'px' : '200px',
                        }}
                        onChange={(e) => {
                            setVendors({
                                ...vendors,
                                [vendor.id]: {
                                    ...vendor,
                                    name: e.target.value,
                                }
                            });
                        }}
                        onBlur={() => {
                            setEditing(false);
                            updateVendorName(vendor.id, vendor.name);
                        }}
                        autoFocus
                    />
                </div>
                : <div
                    className="vendorquotes-column-header"
                    style={{
                        width: vendor.width ? vendor.width + 'px' : '200px',
                    }}
                    onClick={() => {
                        setEditing(true);
                    }}
                >
                    {vendor.name}
                </div>
            }
        </Resizable>
    )
}

const TreeItem = forwardRef((props, ref) => {
    const {
        activeID,
        hoverID,
        setHoverID,
    } = useContext(VendorQuoteContext);

    return (
        <FolderTreeItemWrapper
            {...props} ref={ref}
            manualDrag={true}
            showDragHandle={true}
            hideCollapseButton={true}
            style={{
                backgroundColor: activeID === props.item.id
                    ? 'aliceblue'
                    : hoverID === props.item.id
                        ? '#EEEEEE'
                        : '#F8F8F8',
                marginTop: '0px',
                marginBottom: '0px',
                height: '40px',
            }}
        >
            <div
                className="database-tree-entry"
                style={{
                    height: '30px',
                }}
                onMouseEnter={() => setHoverID(props.item.id)}
                onMouseLeave={() => setHoverID(null)}
            >
                <div
                    className={"database-tree-entry-icon " + (!props.item.id.includes('group') ? 'database-tree-entry-icon-disabled' : '')}
                    id={"database-tree-entry-icon-" + props.item.id}
                >
                    {props.item.id.includes('group') &&
                        (props.collapsed
                            ? <IconFolder size={18} />
                            : <IconFolderOpen size={18} />
                        )
                    }
                </div>

                <div className="vendorquotes-tree-entry-name">
                    {props.item.name || <>--</>}
                </div>
            </div>
        </FolderTreeItemWrapper>
    );
});
