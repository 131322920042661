function lineIntersectsLine(line1, line2) {
    const x1 = line1.start.x;
    const y1 = line1.start.y;
    const x2 = line1.end.x;
    const y2 = line1.end.y;

    const x3 = line2.start.x;
    const y3 = line2.start.y;
    const x4 = line2.end.x;
    const y4 = line2.end.y;

    const det = (x2 - x1) * (y4 - y3) - (x4 - x3) * (y2 - y1);

    if (det === 0) {
        return false;
    } else {
        const lambda = ((y4 - y3) * (x4 - x1) + (x3 - x4) * (y4 - y1)) / det;
        const gamma = ((y1 - y2) * (x4 - x1) + (x2 - x1) * (y4 - y1)) / det;
        return (0 < lambda && lambda < 1) && (0 < gamma && gamma < 1);
    }
}

export function PointInPolygon(point, polygon) {
    let inside = false;
    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        const xi = polygon[i].x;
        const yi = polygon[i].y;
        const xj = polygon[j].x;
        const yj = polygon[j].y;

        const intersect = ((yi > point.y) !== (yj > point.y)) && (point.x < ((xj - xi) * (point.y - yi) / (yj - yi)) + xi);
        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
}

function PolygonInPolygon(polygon1, polygon2) {
    for (let i = 0; i < polygon1.length; i++) {
        if (!PointInPolygon(polygon1[i], polygon2)) {
            return false;
        }
    }

    return true;
}


//returns true if the two polygons intersect, false otherwise
//polygon1 and polygon2 are arrays of points (x, y)
function PolyPolyIntersection(polygon1, polygon2) {
    //for every edge in polygon1, check if it intersects with any edge in polygon2
    for (let i = 0; i < polygon1.length; i++) {
        for (let j = 0; j < polygon2.length; j++) {
            if (lineIntersectsLine({ start: polygon1[i], end: polygon1[(i + 1) % polygon1.length] }, { start: polygon2[j], end: polygon2[(j + 1) % polygon2.length] })) {
                return true;
            }
        }
    }

    //if polygon1 is entirely inside polygon2, or vice versa, return true
    if (PolygonInPolygon(polygon1, polygon2) || PolygonInPolygon(polygon2, polygon1)) {
        return true;
    }

    return false;
}

function RectToPolygon(rectangle) {
    let x = rectangle.rectangle.x;
    let y = rectangle.rectangle.y;
    let width = rectangle.width;
    let height = rectangle.height;

    return [{ x: x, y: y }, { x: x + width, y: y }, { x: x + width, y: y + height }, { x: x, y: y + height }];
}

export function MergeablePolygon(measurements, selectedMeasurements, currentMeasurement) {
    selectedMeasurements = selectedMeasurements.filter(m => m !== currentMeasurement);

    for (let i = 0; i < selectedMeasurements.length; i++) {
        if (MeasurementsIntersect(measurements[currentMeasurement], measurements[selectedMeasurements[i]])) {
            return true;
        }
    }

    return false;
}

export function MeasurementsIntersect(shape1, shape2) {
    if (shape1.id === shape2.id || shape1.group !== shape2.group) {
        return false;
    }

    if (shape1.type === 'polygon' && shape2.type === 'polygon') {
        return PolyPolyIntersection(shape1.polygon_dots, shape2.polygon_dots);
    } else if (shape1.type === 'polygon' && shape2.type === 'rectangle') {
        return PolyPolyIntersection(shape1.polygon_dots, RectToPolygon(shape2));
    } else if (shape1.type === 'rectangle' && shape2.type === 'polygon') {
        return PolyPolyIntersection(RectToPolygon(shape1), shape2.polygon_dots);
    } else if (shape1.type === 'rectangle' && shape2.type === 'rectangle') {
        return PolyPolyIntersection(RectToPolygon(shape1), RectToPolygon(shape2));
    }

    return false;
}

export function ClosestPointToPolygon(point, polygon){
    //find the closest point on the polygon to the given point

    //for each edge in the polygon, find the closest point on the edge to the given point
    let minDist = Infinity;
    let closestPoint = null;

    for (let i = 0; i < polygon.length; i++) {
        let edge = { start: polygon[i], end: polygon[(i + 1) % polygon.length] };
        let closest = ClosestPointToLine(point, edge);

        let dist = Math.sqrt((point.x - closest.x) ** 2 + (point.y - closest.y) ** 2);

        if (dist < minDist) {
            minDist = dist;
            closestPoint = closest;
        }
    }

    return closestPoint;
}

export function ClosestPointToLine(point, line) {
    //find the closest point on the line to the given point
    let x1 = line.start.x;
    let y1 = line.start.y;
    let x2 = line.end.x;
    let y2 = line.end.y;

    let x3 = point.x;
    let y3 = point.y;

    let u = ((x3 - x1) * (x2 - x1) + (y3 - y1) * (y2 - y1)) / ((x2 - x1) ** 2 + (y2 - y1) ** 2);

    let x = x1 + u * (x2 - x1);
    let y = y1 + u * (y2 - y1);

    return { x: x, y: y };
}