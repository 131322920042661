import { TakeoffContext } from "../../helper/Context";
import { useContext } from "react";

import { API_ROUTE } from "../../..";
import { Modal } from "react-bootstrap";

import axios from "axios";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../redux/slices/authSlice";
import DeleteButton from "../../../components/DeleteButton";
import DefaultButton from "../../../components/DefaultButton";

export default function DeleteGroupModal({
    showDeleteGroupModal, setShowDeleteGroupModal,
}) {
    const auth = useSelector(selectAuth)

    const {
        deleteGroup, setDeleteGroup,
        pageID,
        setMeasurements,
        groups, setGroups,
        setTree,
        currentGroup, setCurrentGroup,
    } = useContext(TakeoffContext);

    const handleDeleteGroup = () => {
        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/group/`,
            data: {
                page: pageID,
                id: deleteGroup,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
        })
            .then((res) => {
                console.log(res);

                setShowDeleteGroupModal(false);
                setMeasurements(res.data.measurements);
                setGroups(res.data.groups);
                setTree(res.data.tree);

                if (currentGroup === deleteGroup) {
                    setCurrentGroup(null);
                }

                setDeleteGroup(null);
            })
            .catch((err) => {
                console.log(err)
            })
    }


    return (
        <Modal
            show={showDeleteGroupModal}
            onHide={() => setShowDeleteGroupModal(false)}
            size='small'
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="deletemeasurementmodal-title">
                        Delete Group
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Are you sure you want to delete {groups[deleteGroup].name} and all of its subgroups and measurements?
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DeleteButton
                    handleClick={() => handleDeleteGroup()}
                >
                    Delete All
                </DeleteButton>
                <DefaultButton
                    handleClick={() => setShowDeleteGroupModal(false)}
                >
                    Cancel
                </DefaultButton>
            </Modal.Footer>
        </Modal>
    )
}