import { useContext, useState } from "react";
import { useDroppable } from '@dnd-kit/core';
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

import { IconCaretDownFilled, IconCaretRightFilled, IconSettings } from "@tabler/icons-react"

import { API_ROUTE } from "../../../..";

import { TakeoffContext } from "../../../helper/Context";
import { selectAuth } from "../../../../redux/slices/authSlice";

import DefaultIconButton from "../../../../components/DefaultIconButton"
import DeleteButton from "../../../../components/DeleteButton";
import Page from "../Page";

export default function PageGridUngrouped({
    showPageGroups,
    setShowPageGroups,
    search,
    filterStarred,
    handleGroupPage,
    draggingPage,
    selectedPages, setSelectedPages,
    handleClickPage,
}) {
    const {
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages,
    } = useContext(TakeoffContext);

    const { isOver, setNodeRef } = useDroppable({
        id: -1,
    });

    const [showGear, setShowGear] = useState(false);

    return (
        <div
            className="pagessidebar-group"
            ref={setNodeRef}
            style={{
                backgroundColor: isOver && draggingPage
                    ? '#f0f8ff'
                    : '',
            }}
        >
            <div
                className="pagessidebar-group-header"
                onClick={(e) => {


                    setShowPageGroups({ ...showPageGroups, ['ungrouped']: !showPageGroups['ungrouped'] })
                }}
                onMouseEnter={() => setShowGear(true)}
                onMouseLeave={() => setShowGear(false)}
                style={{
                    color: isOver && draggingPage
                        ? '#006aef'
                        : ungroupedPages?.length
                            ? ''
                            : '#a9a9a9',
                    backgroundColor: showGear
                        ? '#EEEEEE'
                        : ''
                }}
            >
                <div>
                    {showPageGroups['ungrouped']
                        ? <IconCaretDownFilled size={15} stroke={1} />
                        : <IconCaretRightFilled size={15} stroke={1} />
                    }
                </div>
                <div className="pagessidebar-group-title">
                    {'Ungrouped'}
                </div>
                <div>
                </div>
            </div>

            {showPageGroups['ungrouped'] &&
                <div
                    className="pagessidebar-body-grid"
                >
                    {pages && ungroupedPages?.length ? ungroupedPages?.sort((a, b) => a - b).filter(p => {
                        if (filterStarred && !pages[p].starred) return false;
                        if (search && !pages[p].title.toLowerCase().includes(search.toLowerCase())) return false;
                        return true;
                    }).map(p => (
                        <Page
                            key={p}
                            p={pages[p]}
                            useGrid={true}
                            useNames={false}
                            useList={false}
                            handleGroupPage={handleGroupPage}
                            handleClickPage={handleClickPage}
                            selectedPages={selectedPages}
                        />
                    )) : null}
                </div>
            }
        </div >
    )
}

