import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import { Portal } from "react-konva-utils";

export default function AILegendSelector({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        localizationLegends, setLocalizationLegends,
        currentLegend, setCurrentLegend,
        populatingLegend, setPopulatingLegend,
    } = useContext(TakeoffContext);

    const handlePopulateWithReference = (legend) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/populate-virtual-legend/`,
            data: {
                'page': pageID,
                'method': 'existing',
                'legend': legend.id,
                'virtual_legend': currentLegend,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setPopulatingLegend({
                    'message': '',
                    'progress': 0,
                    'show': true,
                    'running': true,
                    'id': null,
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {localizationLegends?.filter(legend => String(legend.page) === String(pageID))?.map(legend =>
                <Rect
                    key={legend.id}
                    x={Math.min(legend.x1, legend.x2)}
                    y={Math.min(legend.y1, legend.y2)}
                    width={Math.abs(legend.x1 - legend.x2)}
                    height={Math.abs(legend.y1 - legend.y2)}
                    fill={'#9DD9F3'}
                    stroke={'#006AFE'}
                    opacity={0.5}
                    onMouseEnter={(e) => {
                        e.target.getStage().container().style.cursor = 'pointer';
                        console.log('enter');
                    }}
                    onMouseLeave={(e) => {
                        e.target.getStage().container().style.cursor = 'default';
                    }}
                    onClick={(e) => {
                        e.cancelBubble = true;
                        handlePopulateWithReference(legend);
                    }}
                />
            )}
        </>
    );
}