import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image, Arrow } from "react-konva";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../../index";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";

export default function AnnotationArrowConstructor({ }) {
    const {
        pageID,
        pages,
        handleCreateAnnotation,
    } = useContext(TakeoffContext);

    const [drawing, setDrawing] = useState(false);

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);

    return (
        <>
            {(drawing && start && end) &&
                <Arrow
                    x={start.x}
                    y={start.y}
                    points={[0, 0, end.x - start.x, end.y - start.y]}
                    fill={"#006aff"}
                    stroke={"#006aff"}
                    strokeWidth={10 / pages[pageID].zoom}
                    opacity={0.5}
                />
            }

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"}
                onMouseUp={(e) => {
                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        handleCreateAnnotation({
                            type: "arrow",
                            data: {
                                x1: start.x,
                                y1: start.y,
                                x2: x - start.x,
                                y2: y - start.y,
                            }
                        })
                        setStart(null);
                        setEnd(null);
                        setDrawing(false);
                    } else {
                        setDrawing(true);
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setStart({ x: x, y: y });
                    }
                }}
                onMouseMove={(e) => {
                    if (drawing) {
                        const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                        const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                        setEnd({ x: x, y: y });
                    }
                }}
            />
        </>
    );
}