import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import { API_ROUTE } from "../../../index";

export default function CircleConstructor({ countDots, setCountDots, countHistory, setCountHistory }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        selectedGroups,
        createMeasurement,
        createMeasurements,
        currentHistory, setCurrentHistory,
        history, setHistory,
        setDrawingCircle,
        setMeasurements,
        handleChangeFilter,
    } = useContext(TakeoffContext);

    const [center, setCenter] = useState({ x: 0, y: 0 });
    const [outerPoint, setOuterPoint] = useState(null);
    const [radius, setRadius] = useState(0);

    const addCircle = (circle) => {
        handleChangeFilter('include_area', true);

        let tempName = 'temp' + uuidv4();

        setMeasurements(prev => ({
            ...prev,
            [tempName]: {
                'type': 'circle',
                'page': pageID,
                'color': groups[currentGroup]?.color ? groups[currentGroup]?.color : '#9DD9F3',
                ['circle']: {
                    x: circle.x,
                    y: circle.y,
                    radius: circle.radius,
                },
            }
        }))

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/circle/`,
            data: {
                'userID': auth.user.id,
                'pageID': pageID,
                'group': currentGroup ? currentGroup : null,
                'groups': [...(currentGroup ? new Set([currentGroup, ...selectedGroups]) : new Set(selectedGroups))],
                'x': circle.x,
                'y': circle.y,
                'radius': circle.radius,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);
                
                //createMeasurement(response.data, false, tempName);
                
                createMeasurements(response.data, tempName);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {center.x && center.y && radius ?
                <Circle
                    x={center.x}
                    y={center.y}
                    radius={radius}
                    fill={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                    opacity={0.3}
                />
                : null}

            {center.x && center.y ?
                <Circle
                    x={center.x}
                    y={center.y}
                    radius={5 / pages[pageID].zoom}
                    fill={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                />
                : null}

            {center.x && center.y && outerPoint ?
                <Line
                    strokeWidth={2 / pages[pageID].zoom}
                    points={[center.x, center.y, outerPoint.x, outerPoint.y]}
                    stroke={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                />
                : null}

            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseEnter={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"}
                //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"}
                onMouseMove={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    if (center.x) {
                        setOuterPoint({ x, y });
                        setRadius(Math.sqrt(Math.pow(x - center.x, 2) + Math.pow(y - center.y, 2)));
                    }
                }}
                onMouseUp={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    if (!center.x && !center.y) {
                        setCenter({ x, y });
                    } else {
                        addCircle({
                            x: center.x,
                            y: center.y,
                            radius: Math.sqrt(Math.pow(x - center.x, 2) + Math.pow(y - center.y, 2)),
                        });
                        setCenter({ x: 0, y: 0 });
                        setOuterPoint(null);
                        setRadius(0);
                    }
                }}
            />
        </>
    );
}