import React from 'react';

import './styles/Switch.css';

export default function Switch({ isOn, handleToggle, onColor }) {
    return (
        <div
            onClick={handleToggle}
            className="switch-container"
        >
            <input
                checked={isOn}
                onChange={handleToggle}
                className="switch-checkbox"
                type="checkbox"
            />
            <label
                style={{ background: isOn && onColor }}
                className="switch-label"
            >
                <span className='switch-button' />
            </label>
        </div>
    );
};