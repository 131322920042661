import { useEffect } from "react";

import "./styles/ContextMenu.css";

export default function ContextMenu({ x, y, zoom, showContextMenu, setShowContextMenu, children }) {
    useEffect(() => {
        const handleClick = () => setShowContextMenu(false);

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);

    return (
        <div
            className='contextmenu-container'
            style={{
                top: y,
                left: x,
                transform: `scale(${1/zoom})`,
            }}
        >
            <div className="contextmenu">
                {children}
            </div>
        </div>
    )
}