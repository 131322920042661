import { useEffect, useState } from "react"

export default function DetailsNavbarInput({ keyID, children }) {
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        const handleClick = e => {
            if (selected && !document.getElementById(keyID + '-container').contains(e.target)) {
                setSelected(false);
            }
        }

        const handleKeyDown = e => {
            if (selected && (e.key === 'Escape' || e.key === 'Enter' || e.key === 'Return' || e.key === 'enter' || e.key === 'return')) {
                e.stopPropagation();
                setSelected(false);
                document.activeElement.blur();
            }
        }

        document.addEventListener('click', handleClick);
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('click', handleClick);
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [keyID, selected])

    return (
        <div
            id={keyID + '-container'}
            className='detailsnavbar-row'
            style={{
                border: selected && '1px solid #006aff',
                backgroundColor: selected && 'aliceblue',
            }}
            onClick={() => {
                setSelected(true);

                const element = document.getElementById(String(keyID));
                if (element) {
                    element.click();
                    element.focus();
                }
            }}
        >
            {children}
        </div>
    )
}