import { SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconFolder, IconFolderOpen, IconMenu2 } from '@tabler/icons-react';
import { forwardRef, useContext, useEffect, useState } from 'react';
import TextField from '../components/TextField';
import { map, prop } from 'ramda';
import { ProjectFileNodeTreeContext } from './Utilities';
import { FolderTreeItemWrapper } from './tree/ui/folder/FolderTreeItemWrapper';

const StyledRowContainer = forwardRef((props, ref) => (
  <div {...props} className={`${props.className}`} ref={ref}>
    {props.children}
  </div>
));

const StyledSortableRow = ({ children, className, id, style, onClick = () => { }, onContextMenu = () => { }, disabled = false }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id, disabled });

  const combinedStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...style,
  };

  return (
    <StyledRowContainer ref={setNodeRef} style={combinedStyle} className={`${className}`} onClick={onClick} onContextMenu={onContextMenu}>
      {!disabled ?
        <div className='files-row-drag-handle'>
          <IconMenu2 size={20} stroke={1} {...listeners} {...attributes} />
        </div>
        : <>&nbsp;</>}
      {children}
    </StyledRowContainer>
  );
};

const LargeTextField = ({ onBlur, value, className }) => (
  <TextField className={`flex-grow overflow-hidden text-sm font-light overflow-ellipsis whitespace-nowrap outline-none ${className}`} onBlur={onBlur} value={value} />
);

const LargeTextElement = ({ children, className }) => <div className={`flex-grow overflow-hidden text-sm font-light overflow-ellipsis whitespace-nowrap ${className}`}>{children}</div>;

const StyledTreeRow = React.forwardRef((props, ref) => {
  const { getChildren, currentNode } = useContext(ProjectFileNodeTreeContext);

  return (
    <FolderTreeItemWrapper
      {...props} ref={ref}
      manualDrag={true}
      showDragHandle={true}
      hideCollapseButton={true}
    >
      <div className={'files-projectfiles-item ' + (currentNode == props.item.id ? ' files-projectfiles-item-selected' : '')}>
        {props.childCount !== undefined ? (
          <div className='files-projectfiles-item-icon'>
            {props.collapsed ? <IconFolder size={20} /> : <IconFolderOpen size={20} />}
          </div>
        ) : <>&nbsp;</>}

        {getChildren(props.item.id)}
      </div>
    </FolderTreeItemWrapper>
  );
});

const ContextMenuInput = ({ value, onBlur, className }) => {
  const [tempValue, setTempValue] = useState(value);

  useEffect(() => {
    if (value && value !== tempValue) {
      setTempValue(value);
    }
  }, [value]);

  return (
    <input
      type="text"
      className={className}
      value={tempValue}
      onChange={e => {
        setTempValue(e.target.value)
        e.stopPropagation();
      }}
      onBlur={() => onBlur(tempValue)}
    />
  )
}

export { StyledSortableRow, LargeTextField, LargeTextElement, StyledRowContainer, StyledTreeRow, ContextMenuInput };
