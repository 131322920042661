import axios from "axios";
import { forwardRef, useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { API_ROUTE } from "../../..";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../redux/slices/authSlice";
import { TakeoffContext } from "../../helper/Context";
import { FolderTreeItemWrapper, SortableTree } from "dnd-kit-sortable-tree";
import { IconArrowsMaximize, IconArrowsMinimize, IconCaretDown, IconCaretRight, IconCopy, IconFolder, IconFolderDown, IconFolderOpen, IconFolders, IconReceipt2, IconX } from "@tabler/icons-react";
import { Tooltip } from "react-tooltip";
import { createContext } from "react";
import { IconSearch } from "@tabler/icons-react";
import { Blocks } from "react-loader-spinner";

const ImportGroupModalContext = createContext({});

export default function ImportGroupModal({ showImportGroupModal, setShowImportGroupModal, projectUUID }) {
    const auth = useSelector(selectAuth);

    const {
        project,
        pageID,
        setTree,
        setGroups,
    } = useContext(TakeoffContext);

    const [databaseGroups, setDatabaseGroups] = useState([]);
    const [searching, setSearching] = useState(false);
    const [search, setSearch] = useState('');

    useEffect(() => {
        axios({
            method: 'get',
            url: `${API_ROUTE}/api/database-groups/`,
            params: {
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setDatabaseGroups(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, [showImportGroupModal]);

    const handleSearch = (search) => {
        setSearching(true);

        axios({
            method: 'get',
            url: `${API_ROUTE}/api/database-groups/`,
            params: {
                search: search,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setDatabaseGroups(response.data);
                setSearching(false);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleImportGroup = (groupID) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/import-database-groups/`,
            data: {
                'project': project.id,
                'page': pageID,
                'group': groupID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setTree(response.data.tree);
                setGroups(prev => ({
                    ...prev,
                    [response.data.group.id]: response.data.group,
                }));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleImportGroups = (groupID) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/import-database-groups/`,
            data: {
                'project': project.id,
                'page': pageID,
                'group': groupID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            },
        })
            .then((response) => {
                console.log(response);

                setTree(response.data.tree);
                setGroups(response.data.groups);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleToggleAll = (collapsed) => {
        let oldTree = JSON.stringify(databaseGroups);

        if (collapsed) {
            oldTree = oldTree.replace(/"collapsed":true/g, '"collapsed":false');
        } else {
            oldTree = oldTree.replace(/"collapsed":false/g, '"collapsed":true');
        }

        setDatabaseGroups(JSON.parse(oldTree));
    }

    const hasCollapsed = useMemo(() => {
        if (!databaseGroups) return false;
        return String(JSON.stringify(databaseGroups)).includes(`"collapsed":true`);
    }, [databaseGroups]);

    const hasExpanded = useMemo(() => {
        if (!databaseGroups) return false;
        return String(JSON.stringify(databaseGroups)).includes(`"collapsed":false`);
    }, [databaseGroups]);

    return (
        <Modal
            show={showImportGroupModal}
            onHide={() => setShowImportGroupModal(false)}
            size='m'
            centered
        >
            <Modal.Header
                style={{
                    padding: '0px',
                }}
            >
                <div className="importgroupmodal-header">
                    <div className="vendorquotes-title">
                        <IconFolderDown size={20} /> Import groups
                    </div>


                    <div
                        className="vendorquotes-close-button"
                        id="importgroupmodal-close"
                        onClick={() => setShowImportGroupModal(false)}
                    >
                        <IconX size={20} />
                    </div>

                    <Tooltip delayShow={1000} anchorSelect='#importgroupmodal-close' place="bottom" positionStrategy="fixed">
                        Close modal
                    </Tooltip>
                </div>
            </Modal.Header>

            <div className="importgroupmodal-buttons">
                <div className="importgroupmodal-expand">
                    <div
                        className={"vendorquotes-close-button " + (!hasCollapsed ? 'vendorquotes-button-disabled' : '')}
                        id="importgroupmodal-expand-all"
                        onClick={() => {
                            if (hasCollapsed) {
                                handleToggleAll(true);
                            }
                        }}
                    >
                        <IconArrowsMaximize stroke={1} size={20} />
                    </div>

                    <Tooltip delayShow={1000} anchorSelect='#importgroupmodal-expand-all' place="bottom" positionStrategy="fixed">
                        Expand all groups
                    </Tooltip>

                    <div
                        className={"vendorquotes-close-button " + (!hasExpanded ? 'vendorquotes-button-disabled' : '')}
                        id="importgroupmodal-collapse-all"
                        onClick={() => {
                            if (hasExpanded) {
                                handleToggleAll(false);
                            }
                        }}
                    >
                        <IconArrowsMinimize stroke={1} size={20} />
                    </div>

                    <Tooltip delayShow={1000} anchorSelect='#importgroupmodal-collapse-all' place="bottom" positionStrategy="fixed">
                        Collapse all groups
                    </Tooltip>
                </div>

                <div className='importgroupmodal-search-container'>
                    {searching
                        ? <Blocks
                            visible={true}
                            height="20"
                            width="20"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                        : <IconSearch size={20} />
                    }

                    <div className='database-navbar-search-container'>
                        <input
                            id='database-navbar-search'
                            type='text'
                            placeholder='Search...'
                            className='database-navbar-search-input'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onBlur={(e) => handleSearch(search)}
                        />

                        <div
                            id='database-navbar-search-clear'
                            className='database-navbar-search-clear'
                            onClick={() => {
                                setSearch('');
                                handleSearch('');
                            }}
                        >
                            <IconX size={20} />
                        </div>

                        <Tooltip delayShow={1000} anchorSelect='#database-navbar-search-clear' place="bottom" positionStrategy="fixed">
                            Clear search
                        </Tooltip>
                    </div>
                </div>
            </div>

            <Modal.Body>
                <div className='measurementsidebar-importgroupmodal-container'>
                    <ImportGroupModalContext.Provider
                        value={{
                            handleImportGroup,
                            handleImportGroups,
                        }}
                    >
                        <SortableTree
                            items={databaseGroups}
                            onItemsChanged={(newTree, { type, item, draggedItem, draggedFromParent, droppedToParent }) => setDatabaseGroups(newTree)}
                            TreeItemComponent={TreeItem}
                            disableSorting={true}
                            pointerSensorOptions={{
                                'activationConstraint': {
                                    'distance': 10,
                                }
                            }}
                        />
                    </ImportGroupModalContext.Provider>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal >
    )
}

const TreeItem = forwardRef((props, ref) => {
    const {
        handleImportGroup,
        handleImportGroups,
    } = useContext(ImportGroupModalContext);

    return (
        <FolderTreeItemWrapper
            {...props} ref={ref}
            manualDrag={true}
            showDragHandle={true}
            hideCollapseButton={true}
        >
            <div
                className="database-tree-entry"
                style={{
                    height: '30px',
                }}
            >
                <div
                    className={"importgroupmodal-icon"}
                    id={"importgroupmodal-expand-icon-" + props.item.id}
                >
                    {props.item.id.includes('group') &&
                        (props.collapsed
                            ? <IconFolder size={18} />
                            : <IconFolderOpen size={18} />
                        )
                    }
                </div>

                <Tooltip delayShow={1000} anchorSelect={'#importgroupmodal-expand-icon-' + props.item.id} place="bottom" positionStrategy="fixed">
                    {props.collapsed
                        ? <>Expand group</>
                        : <>Collapse group</>
                    }
                </Tooltip>

                <div
                    className="importgroupmodal-icon"
                    id={"importgroupmodal-import-icon-" + props.item.id}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleImportGroup(props.item.id.split('-')[1]);
                    }}
                >
                    <IconFolderDown size={18} />
                </div>

                <Tooltip delayShow={1000} anchorSelect={'#importgroupmodal-import-icon-' + props.item.id} place="bottom" positionStrategy="fixed">
                    Import only this group
                </Tooltip>

                <div
                    className="importgroupmodal-icon"
                    id={"importgroupmodal-copy-icon-" + props.item.id}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleImportGroups(props.item.id.split('-')[1]);
                    }}
                >
                    <IconFolders size={18} />
                </div>

                <Tooltip delayShow={1000} anchorSelect={'#importgroupmodal-copy-icon-' + props.item.id} place="bottom" positionStrategy="fixed">
                    Import this and all nested groups
                </Tooltip>

                <div
                    className="vendorquotes-tree-entry-name"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {props.item.name || <>--</>}
                </div>
            </div>
        </FolderTreeItemWrapper>
    );
});
