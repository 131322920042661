import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, updateAuth } from '../redux/slices/authSlice';
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ROUTE } from "..";

export default function BobyardAdmin() {
    const dispatch = useDispatch();
    const auth = useSelector(selectAuth);

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        if (auth?.token && auth?.contractor?.bobyard_admin) {
            axios({
                method: 'get',
                url: `${API_ROUTE}/api/companies/`,
                headers: {
                    'Authorization': `Token ${auth.token}`,
                    "Content-Type": "application/json"
                },
            })
                .then((response) => {
                    console.log(response);

                    setCompanies(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [auth]);

    const handleCompanyClick = (company) => {
        dispatch(updateAuth({
            user: auth.user,
            contractor: {
                ...auth.contractor,
                company: company.id,
            },
            token: auth.token,
        }));

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/contractors/${auth.contractor.id}/`,
            data: {
                'user': auth.user.id,
                'company': company.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });

    }

    if (!auth.user || auth.contractor?.bobyard_admin !== true) {
        return (
            <div>
                <h1>Access Denied</h1>
            </div>
        )
    }

    return (
        <div>
            <h1>Bobyard Admin</h1>

            <div>
                <div>
                    Companies
                </div>

                <div>
                    {companies?.map((company) =>
                        <div
                            key={company.id}
                            className={"p-2 border border-gray-300 rounded-lg w-50 " + (company.id === auth.contractor.company ? " bg-blue-100" : "")}
                            onClick={() => handleCompanyClick(company)}
                        >
                            <div>
                                {company.name}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}