import { useState } from "react";

import './styles/InfoIcon.css';
import { Tooltip } from "react-tooltip";
import { IconQuestionMark } from "@tabler/icons-react";

export default function InfoIcon({ children, id }) {
    return (
        <>
            <div id={id} className="info-icon">
                <IconQuestionMark size={18} stroke={1} />
            </div>

            <Tooltip anchorSelect={'#' + id} place="bottom" delayShow={500} positionStrategy="fixed" style={{ zIndex: 1000 }}>
                {children}
            </Tooltip>
        </>
    );
}