import { useContext, useEffect } from "react";
import { TakeoffContext } from "../../helper/Context";
import { Modal } from "react-bootstrap";
import DeleteButton from "../../../components/DeleteButton";
import DefaultButton from "../../../components/DefaultButton";

import '../../styles/DeleteMeasurementModal.css';

export default function DeleteMeasurementModal({
    showDeleteMeasurementModal, setShowDeleteMeasurementModal,
    handleDelete,
}) {
    const {
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        selectedMeasurements, setSelectedMeasurements,
    } = useContext(TakeoffContext);

    /*useeffect to listen to escape key to leave modal */
    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setShowDeleteMeasurementModal(false);
            }
        }

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        }
    }, [setShowDeleteMeasurementModal]);

    if (selectedMeasurements.length > 1) {
        return (
            <Modal
                show={showDeleteMeasurementModal}
                onHide={() => setShowDeleteMeasurementModal(false)}
                size='small'
            >
                <Modal.Header>
                    <Modal.Title>
                        <div className="deletemeasurementmodal-title">
                            Delete Measurement
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        You have selected <b>{new Set(selectedMeasurements).size}</b> measurements!
                    </div>

                    <div>
                        Are you sure you want to delete them all?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <DeleteButton
                        handleClick={() => handleDelete()}
                    >
                        Delete All
                    </DeleteButton>
                    <DefaultButton
                        handleClick={() => setShowDeleteMeasurementModal(false)}
                    >
                        Cancel
                    </DefaultButton>
                </Modal.Footer>
            </Modal>
        )
    }

    return (
        <Modal
            show={showDeleteMeasurementModal}
            onHide={() => setShowDeleteMeasurementModal(false)}
            size='small'
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="deletemeasurementmodal-title">
                        Delete Measurement
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    Are you sure you want to delete this measurement?
                </div>

                <br></br>

                <div className="deletemeasurementmodal-row">
                    <div>
                        <div className="deletemeasurementmodal-row-name">
                            Name
                        </div>
                        <div>
                            {measurements[currentMeasurement]?.name
                                ? measurements[currentMeasurement]?.name
                                : measurements[currentMeasurement]?.type === 'count'
                                    ? 'Count'
                                    : measurements[currentMeasurement]?.type === 'line'
                                        ? measurements[currentMeasurement]?.height
                                            ? 'Area'
                                            : 'Length'
                                        : measurements[currentMeasurement]?.type === 'polygon'
                                            ? measurements[currentMeasurement]?.depth
                                                ? 'Volume'
                                                : 'Area'
                                            : measurements[currentMeasurement]?.type === 'rectangle'
                                                ? measurements[currentMeasurement]?.depth
                                                    ? 'Volume'
                                                    : 'Area'
                                                : measurements[currentMeasurement]?.type === 'circle'
                                                    ? measurements[currentMeasurement]?.depth
                                                        ? 'Volume'
                                                        : 'Area'
                                                    : null
                            }
                        </div>
                    </div>

                    <div>
                        <div className="deletemeasurementmodal-row-name">
                            Type
                        </div>
                        <div>
                            {measurements[currentMeasurement]?.type === 'count'
                                ? 'Count'
                                : measurements[currentMeasurement]?.type === 'line'
                                    ? 'Line'
                                    : measurements[currentMeasurement]?.type === 'polygon'
                                        ? 'Polygon'
                                        : measurements[currentMeasurement]?.type === 'rectangle'
                                            ? 'Rectangle'
                                            : measurements[currentMeasurement]?.type === 'circle'
                                                ? 'Circle'
                                                : null
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DeleteButton
                    handleClick={() => handleDelete()}
                >
                    Delete
                </DeleteButton>
                <DefaultButton
                    handleClick={() => setShowDeleteMeasurementModal(false)}
                >
                    Cancel
                </DefaultButton>
            </Modal.Footer>
        </Modal>
    )
}