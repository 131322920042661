import TextField from '../components/TextField';
import { EstimateNumericField, EstimateOverridableNumericField, EstimateSelectField, EstimateTextField } from '../estimate2/components/styledcomponents';

const Playground = () => {
  return (
    <div className='p-4 w-96'>
      <EstimateSelectField>
        <option value='hello'>hello</option>
        <option value='world'>world</option>
      </EstimateSelectField>
    </div>
  );
};

export default Playground;
