import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

import { IconArrowLeft, IconArrowRight, IconDownload, IconTrashX, IconUpload, IconX } from '@tabler/icons-react';
import { Tooltip } from 'react-tooltip';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useDropzone } from "react-dropzone";
import TextareaAutosize from 'react-textarea-autosize';

import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import { selectAuth } from '../redux/slices/authSlice';
import { API_ROUTE, WEBSOCKET_ROUTE } from '..';

import ProjectDetailsNavbar from './components/ProjectDetailsNavbar';
import DeleteButton from '../components/DeleteButton';
import DefaultButton from '../components/DefaultButton';
import SidebarFile from './components/SidebarFile';

import './styles/ProjectDetails.css';
import ProjectDetailsPage from './components/ProjectDetailsPage';
import { Blocks } from 'react-loader-spinner';
import Skeleton from 'react-loading-skeleton';

export default function ProjectDetailsModal({ project, setProject, showProjectDetails, setShowProjectDetails }) {
    const auth = useSelector(selectAuth);

    const handleSaveProjectAddress = (lat, lng, address) => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                title: project.title,
                address: address,
                desc: project.desc,
                lat: lat,
                lng: lng,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleUpdateProject = () => {
        axios({
            method: 'put',
            url: `${API_ROUTE}/api/projects/${project.id}/`,
            data: {
                author: project.author,
                company: project.company,
                title: project.title,
                address: project.address,
                desc: project.desc,
                lat: project.lat,
                lng: project.lng,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <Modal
            show={showProjectDetails}
            onHide={() => setShowProjectDetails(false)}
            size='lg'
        >
            <Modal.Header>
                <div className='projectdetails-details-modal-header'>
                    <div className='projectdetails-details-modal-title'>
                        Project Details
                    </div>
                    <div className='projectdetails-details-modal-close'>
                        <IconX size={24} onClick={() => setShowProjectDetails(false)} />
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='projectdetails-details-modal-body'>
                    <div className='projectdetails-details-modal-body-row'>
                        <div className='projectdetails-details-modal-body-row-title'>
                            Title
                        </div>
                        <div className='projectdetails-details-modal-body-row-value'>
                            <input
                                type='text'
                                className='profile-details-input'
                                value={project?.title || ''}
                                onChange={(e) => {
                                    setProject(prev => ({
                                        ...prev,
                                        title: e.target.value || ''
                                    }))
                                }}
                                onBlur={() => handleUpdateProject()}
                                placeholder='Project title...'
                            />
                        </div>
                    </div>

                    <PlacesAutocomplete
                        value={project?.address}
                        onChange={(a) => setProject(prev => ({ ...prev, ['address']: a }))}
                        onSelect={(a) => {
                            geocodeByAddress(a)
                                .then(results => getLatLng(results[0]))
                                .then(latLng => {
                                    console.log('Success', latLng);

                                    setProject(prev => ({
                                        ...prev,
                                        address: a,
                                        lat: latLng.lat,
                                        lng: latLng.lng,
                                    }));
                                    handleSaveProjectAddress(latLng.lat, latLng.lng, a);
                                })
                                .catch(error => console.error('Error', error));
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                            <div className='projectdetails-autocomplete-container'>
                                <div className='projectdetails-details-modal-body-row'>
                                    <div className='projectdetails-details-modal-body-row-title'>
                                        Address
                                    </div>
                                    <div className='projectdetails-details-modal-body-row-value'>
                                        <input
                                            {...getInputProps({
                                                type: "text",
                                                name: "address",
                                                placeholder: "Project address...",
                                            })}
                                            className='profile-details-input'
                                            onBlur={() => handleUpdateProject()}
                                        ></input>
                                    </div>
                                </div>

                                {suggestions.length > 0 &&
                                    <div className="projectdetails-autocomplete-dropdown-container">
                                        {suggestions.map(suggestion => {
                                            console.log(suggestion);
                                            const className = suggestion.active ? 'projectdetails-suggestion-item-active' : 'projectdetails-suggestion-item';
                                            return (
                                                <div {...getSuggestionItemProps(suggestion, { className })} key={suggestion.description}>
                                                    {suggestion.description}
                                                </div>
                                            );
                                        })}
                                    </div>
                                }
                            </div>
                        }
                    </PlacesAutocomplete>

                    <div className='projectdetails-details-modal-desc'>
                        <div className='projectdetails-details-modal-body-row-title'>
                            Description
                        </div>
                        <div className='projectdetails-details-modal-desc-input-container'>
                            <TextareaAutosize
                                className='projectdetails-details-modal-desc-input'
                                value={project?.desc}
                                onChange={(e) => setProject(prev => ({ ...prev, ['desc']: e.target.value || '' }))}
                                onBlur={() => handleUpdateProject()}
                                minRows={3}
                                maxRows={10}
                                placeholder='Project description...'
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DefaultButton
                    handleClick={() => setShowProjectDetails(false)}
                    text='Done'
                />
            </Modal.Footer>
        </Modal >
    )
}