import { IconBriefcase2, IconChartHistogram, IconKeyboard, IconReceipt2, IconSettings, IconUser } from '@tabler/icons-react';
import '../styles/ProfileSidebar.css';

export default function ProfileSidebar({ current }) {
  return (
    <div className='profilesidebar-container'>
      {current === 'company' ? (
        <div className='profilesidebar-item profilesidebar-item-active'>
          <IconBriefcase2 size={20} stroke={2} />
          <div>Company</div>
        </div>
      ) : (
        <a className='profilesidebar-item-link' href='/company'>
          <div className='profilesidebar-item'>
            <IconBriefcase2 size={20} stroke={2} />
            <div>Company</div>
          </div>
        </a>
      )}

      {current === 'profile' ? (
        <div className='profilesidebar-item profilesidebar-item-active'>
          <IconUser size={20} stroke={2} />
          <div>Profile</div>
        </div>
      ) : (
        <a className='profilesidebar-item-link' href='/profile'>
          <div className='profilesidebar-item'>
            <IconUser size={20} stroke={2} />
            <div>Profile</div>
          </div>
        </a>
      )}

      {current === 'report' ? (
        <div className='profilesidebar-item profilesidebar-item-active'>
          <IconChartHistogram size={20} stroke={2} />
          <div>Report</div>
        </div>
      ) : (
        <a className='profilesidebar-item-link' href='/report'>
          <div className='profilesidebar-item'>
            <IconChartHistogram size={20} stroke={2} />
            <div>Report</div>
          </div>
        </a>
      )}

      {current === 'settings' ? (
        <div className='profilesidebar-item profilesidebar-item-active'>
          <IconSettings size={20} stroke={2} />
          <div>Settings</div>
        </div>
      ) : (
        <a className='profilesidebar-item-link' href='/settings'>
          <div className='profilesidebar-item'>
            <IconSettings size={20} stroke={2} />
            <div>Settings</div>
          </div>
        </a>
      )}

      {current === 'keybinds' ? (
        <div className='profilesidebar-item profilesidebar-item-active'>
          <IconKeyboard size={20} stroke={2} />
          <div>Shortcuts</div>
        </div>
      ) : (
        <a className='profilesidebar-item-link' href='/keybind'>
          <div className='profilesidebar-item'>
            <IconKeyboard size={20} stroke={2} />
            <div>Shortcuts</div>
          </div>
        </a>
      )}

      {current === 'billing' ? (
        <div className='profilesidebar-item profilesidebar-item-active'>
          <IconReceipt2 size={20} stroke={2} />
          <div>Billing</div>
        </div>
      ) : (
        <a className='profilesidebar-item-link' href='/billing'>
          <div className='profilesidebar-item'>
            <IconReceipt2 size={20} stroke={2} />
            <div>Billing</div>
          </div>
        </a>
      )}
    </div>
  );
}
