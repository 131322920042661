import { lensPath, view, set, pipe, compose, andThen, tap, isNil, reduce, lensProp, filter, over, project, map, concat, ifElse, reject, equals, prop, always } from 'ramda';
import { useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { axiosCurry, formDataFromObj } from '../utilities/utilities';
import { API_ROUTE } from '../index';
import { selectAuth } from '../redux/slices/authSlice';
import { useSelector } from 'react-redux';
import { IconDownload, IconMenu, IconMenu2, IconTrashX, IconX } from '@tabler/icons-react';
import axios from 'axios';
import { DndContext, useSensor, MouseSensor } from '@dnd-kit/core';
import { SortableContext, arrayMove } from '@dnd-kit/sortable';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { uploadFormData } from '../utilities/utilities';
import './PDFExport.css';
import { PDFDocument } from 'pdf-lib';
import { andThenAll, getBlobFromByteArray, getPDFDocFromURL, mergePDFs, saveBlob, saveByteArray } from './utilities';
import { renderToStaticMarkup } from 'react-dom/server';
import { Blocks } from 'react-loader-spinner';
import { dataLens, getExportFileFileKeyLens, getExportFileNameLens } from '../files/Files';
import { generateDummyID } from '../files/Utilities';

const SortableItem = ({ children, id }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className='estimate-pdfexport-attachment-item'>
      {children}
    </div>
  );
};

const initialState = {
  remote: { project: { preamble: undefined, postamble: undefined, title_page_file: null, title_page_name: '' }, attachments: [] },
  titlePageUploadProgress: null,
  logoFileUploadProgress: null,
  uploadProgressDict: {}, //id: {name, value}
  isPDFExportLoading: false,
};

const remoteLens = lensPath(['remote']);
const projectLens = compose(remoteLens, lensPath(['project']));
const projectTitleLens = compose(projectLens, lensPath(['title']));
const projectAddresslens = compose(projectLens, lensPath(['address']));
const projectDescLens = compose(projectLens, lensPath(['desc']));
const projectUUIDLens = compose(projectLens, lensPath(['uuid']));
const preambleLens = compose(projectLens, lensPath(['preamble']));
const postambleLens = compose(projectLens, lensPath(['postamble']));
const titlePageURLLens = compose(projectLens, lensPath(['title_page_file']));
const titlePageNameLens = compose(projectLens, lensPath(['title_page_name']));
const logoFileURLLens = compose(projectLens, lensPath(['logo_file']));
const logoFileNameLens = compose(projectLens, lensPath(['logo_name']));
const attachmentsLens = compose(remoteLens, lensPath(['attachments']));
const titlePageUploadProgressLens = lensPath(['titlePageUploadProgress']);
const uploadProgressDictLens = lensPath(['uploadProgressDict']);
const logoFileUploadProgressLens = lensPath(['logoFileUploadProgress']);
const isPDFExportLoadingLens = lensPath(['isPDFExportLoading']);

const eventFileLens = lensPath(['target', 'files', 0]);
const nameLens = lensPath(['name']);
const idLens = lensPath(['id']);
const fileURLLens = lensPath(['file']);
const canHaveChildrenLens = lensPath(['canHaveChildren']);

const responseDataLens = lensPath(['data']);
const responseTitlePageURLLens = compose(responseDataLens, lensPath(['project', 'title_page_file']));
const responseLogoFileURLLens = compose(responseDataLens, lensPath(['project', 'logo_file']));
const responseAttachmentLens = compose(responseDataLens, lensPath(['attachments']));
const responseEstimatePDFFileLens = compose(responseDataLens, lensPath(['estimate_file']));
const awsPresignedFieldsLens = compose(responseDataLens, lensPath(['fields']));
const awsPresignedKeyLens = compose(awsPresignedFieldsLens, lensPath(['key']));
const awsPresignedURLLens = compose(responseDataLens, lensPath(['url']));

const clearTitlePage = pipe(set(titlePageURLLens)(null), set(titlePageNameLens)(''));
const clearLogoFile = pipe(set(logoFileURLLens)(null), set(logoFileNameLens)(''));
const formDataAppend = (key) => (value) => (formData) => {
  formData.append(key, value);
  return formData;
};
const formDataFromObject = (formData) => pipe(Object.entries, reduce((accFormData, [k, v]) => formDataAppend(k)(v)(accFormData))(formData));
const setProgressValue = (key) => set(compose(uploadProgressDictLens, lensPath([key, 'value'])));
const setProgressName = (key) => set(compose(uploadProgressDictLens, lensPath([key, 'name'])));
const viewProgressValue = (key) => view(compose(uploadProgressDictLens, lensPath([key, 'value'])));
const viewProgressName = (key) => view(compose(uploadProgressDictLens, lensPath([key, 'name'])));

const getIndexOfAttachmentByID = (id) => (state) =>
  view(attachmentsLens)(state)
    .map((attachment) => view(idLens)(attachment))
    .indexOf(id);
const moveAttachmentsIndex = (oldIndex) => (newIndex) => over(attachmentsLens)((arr) => arrayMove(arr, oldIndex, newIndex));
const reorderAttachmentsFromEvent = (e) => (state) => {
  if (!e.over) return state;
  if (e.active.id !== e.over.id) {
    const oldIndex = getIndexOfAttachmentByID(e.active.id)(state);
    const newIndex = getIndexOfAttachmentByID(e.over.id)(state);
    return moveAttachmentsIndex(oldIndex)(newIndex)(state);
  }
  return state;
};

const PDFExport = ({ projectID, pdfTableArrayBuffer }) => {
  const [state, setState] = useState(initialState);
  const auth = useSelector(selectAuth);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });
  const projectUUID = useMemo(() => view(projectUUIDLens)(state), [view(projectUUIDLens)(state)]);
  const getAWSPresignedHeaders = (filename) => axiosCurry(`${API_ROUTE}/api/aws-presigned-headers-api/`)({ Authorization: `Token ${auth.token}` })('post')({ filename });
  const pdfExportRoute = axiosCurry(`${API_ROUTE}/api/pdf-export-api/${projectID}`);
  const pdfExportAPI = pdfExportRoute({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' });
  const filesAPI = useMemo(
    () => axiosCurry(`${API_ROUTE}/api/files-api/${projectUUID}/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' }),
    [API_ROUTE, auth.token, projectUUID]
  );
  const renderEstimatePDFAPI = axiosCurry(`${API_ROUTE}/api/render-estimate-pdf/`)({ Authorization: `Token ${auth.token}`, 'Content-Type': 'application/json' });
  const sendMarkdownToRenderEstimatePDF = (markdown) => renderEstimatePDFAPI('post')({ project_id: projectID, markdown });
  const fetchProject = () => pdfExportAPI('get')(null);
  const deleteTitlePage = () => pdfExportAPI('post')({ type: 'delete_title_page' });
  const deleteLogoFile = () => pdfExportAPI('post')({ type: 'delete_logo_file' });
  const handlePreambleBlur = () => pdfExportAPI('post')({ type: 'edit_project', edits: [['preamble', view(preambleLens)(state)]] });
  const handlePostambleBlur = () => pdfExportAPI('post')({ type: 'edit_project', edits: [['postamble', view(postambleLens)(state)]] });
  const handleAPIResponse = pipe(view(responseDataLens), set(remoteLens), setState);

  const uploadBlobToS3 = (filename) => (blob) => {
    const presignedHeaders = andThen(view(responseDataLens))(axiosCurry(`${API_ROUTE}/api/aws-presigned-headers-api/`)({ Authorization: `Token ${auth.token}` })('post')({ filename }));
    const urlPromise = andThen(prop('url'))(presignedHeaders);
    const fieldsPromise = andThen(prop('fields'))(presignedHeaders);
    const keyPromise = andThen(prop('key'))(fieldsPromise);
    const file = new File([blob], filename);
    const formDataPromise = pipe(
      andThen((fields) => ({ ...fields, file })),
      andThen(formDataFromObj)
    )(fieldsPromise);
    return pipe(
      (promises) => Promise.all(promises),
      andThen(([url, formData]) => axios.post(url, formData)),
      andThen(always(keyPromise))
    )([urlPromise, formDataPromise]);
  };

  const generateProjectHeaderElement = () => (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', borderBottomStyle: 'solid', paddingBottom: '10px' }}>
      <div style={{ width: '100%', textAlign: 'right', paddingBottom: '10px' }}>{view(projectAddresslens)(state)}</div>
      <div style={{ width: '100%', minHeight: '100px' }}>
        {view(logoFileURLLens)(state) && <img src={view(logoFileURLLens)(state)} alt={view(logoFileNameLens)(state)} style={{ maxWidth: '100%', maxHeight: '100px' }} />}
        <span style={{ float: 'right' }}>
          <span style={{ fontWeight: 'bold' }}>Print Date:</span> {new Date().toLocaleDateString()}
        </span>
      </div>
      <div style={{ fontSize: 'x-large', paddingBottom: '10px' }}>{view(projectTitleLens)(state)}</div>
      <div style={{ fontSize: 'large' }}>{view(projectDescLens)(state)}</div>
    </div>
  );

  const generatePDF = () => {
    const headerPageMarkup = pipe(
      generateProjectHeaderElement,
      renderToStaticMarkup,
      concat('<div style="width:100%;display:flex;flex-direction:column;font-family:sans-serif;">'),
      (top) => concat(top)(view(preambleLens)(state)),
      (top) => concat(top)('</div>')
    )();
    const postamblePageMarkup = pipe(
      generateProjectHeaderElement,
      renderToStaticMarkup,
      concat('<div style="width:100%;display:flex;flex-direction:column;font-family:sans-serif;">'),
      (top) => concat(top)(view(postambleLens)(state)),
      (top) => concat(top)('</div>')
    )();
    const titlePagePDFPromise = pipe(
      view(titlePageURLLens),
      ifElse(isNil, () => null, getPDFDocFromURL)
    )(state);
    const headerPagePDFPromise = pipe(sendMarkdownToRenderEstimatePDF, andThen(view(responseEstimatePDFFileLens)), andThen(getPDFDocFromURL))(headerPageMarkup);
    const pdfTablePromise = PDFDocument.load(pdfTableArrayBuffer);
    const postamblePDFPromise = ifElse(
      equals('<p><br></p>'),
      () => null,
      pipe(
        concat('<div style="width:100%;display:flex;flex-direction:column;font-family:sans-serif;">'),
        (top) => concat(top)('</div>'),
        sendMarkdownToRenderEstimatePDF,
        andThen(view(responseEstimatePDFFileLens)),
        andThen(getPDFDocFromURL)
      )
    )(postamblePageMarkup);
    const attachmentsPDFPromises = pipe(view(attachmentsLens), map(view(lensProp('file'))), map(getPDFDocFromURL))(state);
    return pipe(
      andThenAll(reject(isNil)),
      andThen(reduce((accPromise, nextPDF) => accPromise.then((accPDF) => mergePDFs(accPDF, nextPDF)))(PDFDocument.create())),
      andThen((mergedPDF) => mergedPDF.save())
      // andThen(saveByteArray('estimate.pdf'))
    )([titlePagePDFPromise, headerPagePDFPromise, pdfTablePromise, postamblePDFPromise, ...attachmentsPDFPromises]);
  };

  useEffect(() => {
    fetchProject().then(handleAPIResponse);
  }, []);

  const getFileUploadMetaData = (file) => {
    if (!file) return null;
    const name = view(nameLens)(file);
    return getAWSPresignedHeaders(name).then((response) => {
      const key = view(awsPresignedKeyLens)(response);
      const url = view(awsPresignedURLLens)(response);
      const headers = view(awsPresignedFieldsLens)(response);
      const formData = pipe(formDataFromObject(new FormData()), formDataAppend('file')(file))(headers);
      return { name, key, url, formData };
    });
  };

  return (
    <>
      <div className='estimate-pdfexport-uploads'>
        <div className='estimate-pdfexport-upload-body'>
          <div className='estimate-pdfexport-uploads-title'>Company logo</div>
          {view(logoFileURLLens)(state) ? (
            <div className='estimate-pdfexport-upload-preview'>
              <img className='estimate-pdfexport-upload-preview-image' src={view(logoFileURLLens)(state)} alt={view(logoFileNameLens)(state)} />
              <div className='estimate-pdfexport-upload-preview-name'>{view(logoFileNameLens)(state)}</div>
              <a href={view(logoFileURLLens)(state)} className='estimate-pdfexport-upload-item-link'>
                <div className='estimate-pdfexport-upload-item-download'>
                  <IconDownload size={20} stroke={1} />
                </div>
              </a>
              <button className='estimate-pdfexport-upload-preview-delete' onClick={() => deleteLogoFile().then(setState(clearLogoFile))}>
                <IconTrashX size={20} stroke={1} />
              </button>
            </div>
          ) : (
            <>
              <button className='estimate-pdfexport-upload-button' onClick={() => document.getElementById('estimate-pdfexport-upload-logo-input').click()}>
                Upload
              </button>
              <input
                type='file'
                accept='image/*'
                id='estimate-pdfexport-upload-logo-input'
                className='estimate-pdfexport-upload-input'
                onChange={(e) =>
                  getFileUploadMetaData(view(eventFileLens)(e)).then(({ name, key, url, formData }) =>
                    pipe(
                      () => setState(set(logoFileNameLens)(name)),
                      () => setState(set(logoFileURLLens)(null)),
                      () => uploadFormData(url)(formData)(({ progress }) => setState(set(logoFileUploadProgressLens)(progress))),
                      andThen(() => pdfExportAPI('post')({ name, key, type: 'upload_logo_file_by_key' })),
                      andThen(view(responseLogoFileURLLens)),
                      andThen(pipe(set(logoFileURLLens), setState)),
                      andThen(() => setState(set(logoFileUploadProgressLens)(null))),
                      andThen(() => (e.target.value = null))
                    )()
                  )
                }
              />
            </>
          )}
          {view(logoFileUploadProgressLens)(state) && <progress className='estimate-pdfexport-upload-progress' value={view(logoFileUploadProgressLens)(state)} max='1' />}
        </div>
        <div className='estimate-pdfexport-upload-body'>
          <div className='estimate-pdfexport-uploads-title'>Title page</div>
          {view(titlePageURLLens)(state) ? (
            <div className='estimate-pdfexport-upload-item'>
              <div className='estimate-pdfexport-upload-item-name'>{view(titlePageNameLens)(state)}</div>
              <a href={view(titlePageURLLens)(state)} className='estimate-pdfexport-upload-item-link'>
                <div className='estimate-pdfexport-upload-item-download'>
                  <IconDownload size={20} stroke={1} />
                </div>
              </a>
              <button className='estimate-pdfexport-upload-preview-delete' onClick={() => deleteTitlePage().then(setState(clearTitlePage))}>
                <IconTrashX size={20} stroke={1} />
              </button>
            </div>
          ) : (
            <>
              <button className='estimate-pdfexport-upload-button' onClick={() => document.getElementById('estimate-pdfexport-upload-title-page-input').click()}>
                Upload
              </button>
              <input
                type='file'
                accept='application/pdf'
                id='estimate-pdfexport-upload-title-page-input'
                className='estimate-pdfexport-upload-input'
                onChange={(e) =>
                  getFileUploadMetaData(view(eventFileLens)(e)).then(({ name, key, url, formData }) =>
                    pipe(
                      () => setState(set(titlePageNameLens)(name)),
                      () => setState(set(titlePageURLLens)(null)),
                      () => uploadFormData(url)(formData)(({ progress }) => setState(set(titlePageUploadProgressLens)(progress))),
                      andThen(() => pdfExportAPI('post')({ name, key, type: 'upload_title_page_by_key' })),
                      andThen(view(responseTitlePageURLLens)),
                      andThen(pipe(set(titlePageURLLens), setState)),
                      andThen(() => setState(set(titlePageUploadProgressLens)(null))),
                      andThen(() => (e.target.value = null))
                    )()
                  )
                }
              />
            </>
          )}
          {view(titlePageUploadProgressLens)(state) && <progress className='estimate-pdfexport-upload-progress' value={view(titlePageUploadProgressLens)(state)} max='1' />}
        </div>
      </div>
      <div className='estimate-pdfexport-editor-container'>
        <div className='estimate-pdfexport-editor-title'>Preamble</div>
        {view(preambleLens)(state) != undefined && (
          <ReactQuill theme='snow' value={view(preambleLens)(state)} onChange={(value) => setState(set(preambleLens)(value))} onBlur={handlePreambleBlur} className='estimate-pdfexport-editor' />
        )}
      </div>
      <div className='estimate-pdfexport-editor-container'>
        <div className='estimate-pdfexport-editor-title'>Postamble</div>
        {view(postambleLens)(state) != undefined && (
          <ReactQuill theme='snow' value={view(postambleLens)(state)} onChange={(value) => setState(set(postambleLens)(value))} onBlur={handlePostambleBlur} className='estimate-pdfexport-editor' />
        )}
      </div>
      <div className='estimate-pdfexport-attachments'>
        <div className='estimate-pdfexport-attachments-title'>Attachments</div>
        <button className='estimate-pdfexport-upload-button' onClick={() => document.getElementById('estimate-pdfexport-upload-attachments-input').click()}>
          Upload
        </button>
        <input
          type='file'
          accept='application/pdf'
          id='estimate-pdfexport-upload-attachments-input'
          className='estimate-pdfexport-upload-input'
          onChange={(e) =>
            getFileUploadMetaData(view(eventFileLens)(e)).then(({ name, key, url, formData }) =>
              pipe(
                () => setState(setProgressName(key)(name)),
                () => uploadFormData(url)(formData)(({ progress }) => setState(setProgressValue(key)(progress))),
                andThen(() => pdfExportAPI('post')({ name, key, type: 'add_attachment_by_key' })),
                andThen(view(responseAttachmentLens)),
                andThen(pipe(set(attachmentsLens), setState)),
                andThen(() => setState(setProgressValue(key)(null))),
                andThen(() => (e.target.value = null))
              )()
            )
          }
        />

        <div>
          {Object.keys(view(uploadProgressDictLens)(state))
            .filter((key) => viewProgressValue(key)(state) !== null)
            .map((key) => (
              <div className='estimate-pdfexport-upload-progress-container' key={key}>
                <div className='estimate-pdfexport-upload-progress-name'>{viewProgressName(key)(state)}</div>

                <progress className='estimate-pdfexport-upload-progress' value={viewProgressValue(key)(state)} max='1' />
              </div>
            ))}
        </div>
        <div className='estimate-pdfexport-attachments-list'>
          <DndContext
            sensors={[mouseSensor]}
            onDragEnd={(e) => pipe(reorderAttachmentsFromEvent(e), tap(setState), view(attachmentsLens), (attachments) => pdfExportAPI('post')({ attachments, type: 'reorder_attachments' }))(state)}
          >
            <SortableContext items={view(attachmentsLens)(state)}>
              {view(attachmentsLens)(state).map((attachment) => (
                <SortableItem id={view(idLens)(attachment)} key={view(idLens)(attachment)}>
                  <div>
                    <IconMenu2 size={20} stroke={1} />
                  </div>
                  <div className='estimate-pdfexport-upload-item-name'>{view(nameLens)(attachment)}</div>
                  <a href={view(fileURLLens)(attachment)} className='estimate-pdfexport-upload-item-link'>
                    <div className='estimate-pdfexport-upload-item-download'>
                      <IconDownload size={20} stroke={1} />
                    </div>
                  </a>
                  <button
                    className='estimate-pdfexport-upload-preview-delete'
                    onClick={() => {
                      pipe(over(attachmentsLens), setState)(filter((a) => view(idLens)(a) != view(idLens)(attachment)));
                      pdfExportAPI('post')({ type: 'delete_attachment_by_id', id: view(idLens)(attachment) });
                    }}
                  >
                    <IconTrashX size={20} stroke={1} />
                  </button>
                </SortableItem>
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
      <button
        className='estimate-pdfexport-upload-button estimate-pdfexport-export-button'
        onClick={() => {
          const filename = `${new Date().toISOString().split('T')[0]} - ${view(projectTitleLens)(state)}.pdf`;
          const dummyID = generateDummyID();
          setState(set(isPDFExportLoadingLens)(true));
          pipe(
            generatePDF,
            andThen(getBlobFromByteArray),
            andThen(saveBlob(filename)),
            andThen(tap(() => setState(set(isPDFExportLoadingLens)(false)))),
            andThen(saveBlob(filename)),
            andThen(uploadBlobToS3(filename)),
            andThen((key) => pipe(set(getExportFileFileKeyLens(dummyID))(key), set(getExportFileNameLens(dummyID))(filename))({})),
            andThen(view(dataLens)),
            andThen(filesAPI('patch'))
          )();
          // generatePDF().then(() => setState(set(isPDFExportLoadingLens)(false)));
        }}
        disabled={view(isPDFExportLoadingLens)(state)}
      >
        <span>Export PDF</span>
        {view(isPDFExportLoadingLens)(state) && <Blocks visible={true} height='20' width='20' color='#006AFE' ariaLabel='blocks-loading' radius='10' wrapperStyle={{}} wrapperClass='blocks-wrapper' />}
      </button>
    </>
  );
};
export default PDFExport;
