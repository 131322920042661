import { lensPath, pipe, view, set, isNil } from 'ramda';
import { useState } from 'react';
import { useEffect } from 'react';
import { eventValueLens } from '../Lenses';
import { SketchPicker } from 'react-color';
import Popup from 'reactjs-popup';
import './ColorFieldPopup.css';

const initialState = { value: '', isOpen: false };
const valueLens = lensPath(['value']);
const isOpenLens = lensPath(['isOpen']);

const ColorFieldPopup = ({ onBlur = () => {}, onClick = () => {}, value, className }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(set(valueLens)(isNil(value) ? '#000000' : value));
  }, [value]);

  return (
    <div
      className='contents'
      onClick={(e) => {
        debugger;
        e.stopPropagation();
      }}
    >
      <Popup
        position='bottom'
        className='ColorFieldPopup'
        open={view(isOpenLens)(state)}
        onClose={() => setState(set(isOpenLens)(false))}
        trigger={
          <button className='relative w-full h-full text-white max-w-24 rounded-2xl max-h-6' style={{ backgroundColor: view(valueLens)(state) }} onClick={() => setState(set(isOpenLens)(true))}>
            {view(valueLens)(state)}
          </button>
        }
      >
        <SketchPicker disableAlpha={true} color={view(valueLens)(state)} onChangeComplete={({ hex }) => onBlur(hex)} onChange={({ hex }) => setState(set(valueLens)(hex))} />
      </Popup>
    </div>
  );
};

export default ColorFieldPopup;
