import React, { useState } from 'react';

import './styles/ButtonHoverInfo.css';

export default function ButtonHoverInfo({ children, message, left, right, top, bottom, size, height, width }) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const getClassName = () => {
    let className = 'buttonhoverinfo-message';
    if (left) {
      className += ' buttonhoverinfo-message-left';
    }
    if (bottom) {
      className += ' buttonhoverinfo-message-bottom';
    }
    if (right) {
      className += ' buttonhoverinfo-message-right';
    }
    if (top) {
      className += ' buttonhoverinfo-message-top';
    }

    if (size === 'small') {
      className += ' buttonhoverinfo-message-small';
    }
    return className;
  }

  return (
    <div className="buttonhoverinfo-button">
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={{ margin: 0, padding: 0, height: height, minWidth: 0, display: 'block' }}>
        {children}
      </div>

      {isHovered &&
        <div
          className={getClassName()}

          style={{
            left: left,
            right: right,
            top: top,
            bottom: bottom,
            width: width,
            maxWidth: width ? width : '200px',
            whiteSpace: width ? 'normal' : 'nowrap'
          }}
        >
          {message}
        </div>
      }
    </div>
  );
};