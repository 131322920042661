import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage } from "react-konva";
import useImage from "use-image";
import { TakeoffContext } from "../../helper/Context";

export default function CountConstructor({ countDots, setCountDots, countHistory, setCountHistory }) {
    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
    } = useContext(TakeoffContext);

    return (
        <>
            {countDots.map((dot, i) =>
                <Circle
                    key={i}
                    x={dot.x}
                    y={dot.y}
                    radius={5 / pages[pageID].zoom}
                    fill={currentGroup ? groups[currentGroup]?.color : '#9DD9F3'}
                />
            )}
            <Rect
                x={0}
                y={0}
                width={pages[pageID].width}
                height={pages[pageID].height}
                //onMouseEnter={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"}
                //onMouseOver={(e) => e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+count.svg') 8 24, auto"}
                //onMouseOut={(e) => e.target.getStage().container().style.cursor = "default"}
                onMouseUp={(e) => {
                    const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                    const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;

                    let image = new Image();
                    image.src = pages[pageID].image;

                    //crop at x, y with +- 100px
                    let crop = {
                        x: x - 100,
                        y: y - 100,
                        width: 200,
                        height: 200,
                    };

                    const canvas = document.createElement('canvas');
                    canvas.width = 200;
                    canvas.height = 200;
                    const context = canvas.getContext('2d');
                    context.drawImage(image, crop.x, crop.y, crop.width, crop.height, 0, 0, 200, 200);
                    const data = canvas.toDataURL('image/jpeg');
                    setCountDots(prev => prev.concat({
                        x: x,
                        y: y,
                        image: data,
                    }));

                    setCountHistory([]);
                }}
            />
        </>
    );
}