import { lensPath, set, view, pipe, isNil } from 'ramda';
import { forwardRef, useEffect, useState } from 'react';
import { eventValueLens } from '../estimate2/Lenses';

const initialState = { value: '' };
const valueLens = lensPath(['value']);

const TextField = forwardRef(({ onContextMenu = () => { }, onBlur = () => { }, onClick = () => { }, placeholder, className, value, id }, ref) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState(set(valueLens)(isNil(value) ? '' : value));
  }, [value]);

  return (
    <input
      id={id}
      type='text'
      placeholder={placeholder}
      className={className}
      value={view(valueLens)(state)}
      onBlur={() => onBlur(view(valueLens)(state))}
      onClick={onClick}
      onChange={pipe(view(eventValueLens), set(valueLens), setState)}
      onContextMenu={onContextMenu}
      ref={ref}
    />
  );
});

export default TextField;
