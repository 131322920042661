import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import axios from 'axios';

import { API_ROUTE } from '../index';
import ActiveButton from '../components/ActiveButton';

import "./styles/DashboardCreateProjectModal.css";

export default function DashboardCreateProjectModal({ show, onHide, auth, handleSubmit, title, setTitle }) {
    return (
        <Modal
            show={show}
            onHide={onHide}
            centered
        >
            <Modal.Header>
                <Modal.Title>
                    Create Project
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='dashboard-createprojectmodal-container'>
                    <div className='dashboard-createprojectmodal-input-container'>
                        <div className='dashboard-createprojectmodal-input-title'>
                            Title
                        </div>

                        <div>
                            <input
                                type='text'
                                className='dashboard-createprojectmodal-input'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </div>
                    </div>

                    <ActiveButton
                        text='Create'
                        handleClick={handleSubmit}
                    >
                        Create
                    </ActiveButton>
                </div>
            </Modal.Body>
        </Modal>
    )
}
