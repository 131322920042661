export const KeybindDefaults = {
    'ZoomIn': {
        key: 'c',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'ZoomOut': {
        key: 'v',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'ZoomReset': {
        key: 'z',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'ZoomToMeasurement': {
        key: 'x',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'MinimapToggle': {
        key: 'b',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawScale': {
        key: 'y',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawRectangle': {
        key: 't',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawPolygon': {
        key: 'r',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawCircle': {
        key: 'e',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawLength': {
        key: 'w',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawCount': {
        key: 'q',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'CutPolygon': {
        key: 'f',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'CutPolygonRect': {
        key: 'd',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'SplitPolygon': {
        key: 's',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'CutRectangle': {
        key: 'f',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'CutRectangleRect': {
        key: 'd',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'SplitRectangle': {
        key: 's',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'AddCount': {
        key: 'f',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    }, 
    'DeleteCount': {
        key: 'd',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'ToggleMeasurementSidebar': {
        key: 'm',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'TogglePagesSidebar': {
        key: 'p',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'ToggleAISidebar': {
        key: 'a',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'SearchMeasurement': {
        key: 'f',
        shift: false,
        control: true,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'SearchPage': {
        key: 'f',
        shift: true,
        control: true,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'Undo': {
        key: 'z',
        shift: false,
        control: true,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'Redo': {
        key: 'z',
        shift: true,
        control: true,
        tab: false,
        option: false,
        alt_key: 'y',
        alt_shift: false,
        alt_control: true,
        alt_tab: false,
        alt_option: false,
    },
    'DrawAnnotationText': {
        key: 'l',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawAnnotationArrow': {
        key: 'k',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
    'DrawAnnotationRectangle': {
        key: 'j',
        shift: false,
        control: false,
        tab: false,
        option: false,
        alt_key: null,
        alt_shift: false,
        alt_control: false,
        alt_tab: false,
        alt_option: false,
    },
}