import { IconMenu2, IconX } from '@tabler/icons-react';
import { useState, useEffect } from 'react';

export default function LandingNavbar() {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    return (
        <div className='landing4-header'>
            <a href='/'>
                <div>
                    <img
                        className='landing4-header-logo-image'
                        src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-logo.png'
                    />
                </div>
            </a>

            <div className='landing4-header-menu'>
                <a href='/meeting' className='landing4-header-menu-item-link'>
                    <div className='landing4-header-menu-item'>
                        Book meeting
                    </div>
                </a>
                <a href='/login' className='landing4-header-menu-item-link'>
                    <div>
                        <button className='landing4-header-menu-button'>
                            Login
                        </button>
                    </div>
                </a>
                <a href='/register' className='landing4-header-menu-item-link'>
                    <div>
                        <button className='landing4-header-menu-button'>
                            Register
                        </button>
                    </div>
                </a>
            </div>

            <div className='landing4-header-mobile-menu'>
                <div
                    className='landing4-header-mobile-menu-icon'
                    onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                    <IconMenu2 stroke={3} size={30} />
                </div>

                {showMobileMenu && (
                    <div className='landing4-header-mobile-menu-popup'>
                        <div className='landing4-header-mobile-menu-header'>
                            <a href='/'>
                                <div>
                                    <img
                                        className='landing4-header-logo-image'
                                        src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/landing4-logo.png'
                                    />
                                </div>
                            </a>

                            <div
                                className='landing4-header-mobile-menu-icon'
                                onClick={() => setShowMobileMenu(!showMobileMenu)}
                            >
                                <IconX stroke={3} size={30} />
                            </div>
                        </div>

                        <div className='landing4-header-menu-body'>
                            <a href='/meeting' className='landing4-header-menu-item-link'>
                                <div>
                                    <button className='landing4-header-menu-button'>
                                        Book meeting
                                    </button>
                                </div>
                            </a>
                            <a href='/login' className='landing4-header-menu-item-link'>
                                <div>
                                    <button className='landing4-header-menu-button'>
                                        Login
                                    </button>
                                </div>
                            </a>
                            <a href='/register' className='landing4-header-menu-item-link'>
                                <div>
                                    <button className='landing4-header-menu-button'>
                                        Register
                                    </button>
                                </div>
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}