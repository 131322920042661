import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';

import { selectAuth } from '../redux/slices/authSlice';

import { API_ROUTE } from '../index';

export default function DashboardNavbar({ auth, children, className, contractor }) {
  const [profile, setProfile] = useState(null);
  useEffect(() => {
    if (auth.token && !profile) {
      axios({
        method: 'post',
        url: `${API_ROUTE}/api/profile/`,
        data: {
          userID: auth.user.id,
        },
        headers: {
          Authorization: `Token ${auth.token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          setProfile(response.data);
        })
        .catch((error) => { });
    }
  }, [auth]);

  return (
    <div className={`sticky top-0 z-10 flex flex-row items-center justify-between w-full h-16 p-4 bg-white border-b border-[#EEEEEE] ${className}`}>
      <div className="shrink-0">
        <a href='/dashboard'>
          <img src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png' alt='logo' className='h-10' />
        </a>
      </div>
      {children}
      <div className="shrink-0">
        <a href={`/profile`} className='dashboard-navbar-profile-picture-link'>
          {contractor
            ? contractor?.profile_picture
              ? <img
                src={contractor?.profile_picture}
                alt='profile picture'
                className='dashboard-navbar-profile-picture'
              />
              : contractor?.first_name && contractor?.last_name
                ? <div className='dashboard-navbar-profile-picture-initials'>
                  {contractor?.first_name[0] + contractor?.last_name[0]}
                </div>
                : <img
                  src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                  alt='profile picture'
                  className='dashboard-navbar-profile-picture'
                />
            : profile?.contractor?.profile_picture
              ? < img
                src={profile?.contractor?.profile_picture}
                alt='profile picture'
                className='dashboard-navbar-profile-picture'
              />
              : <div>
                {profile?.contractor?.first_name && profile?.contractor?.last_name
                  ? <div className='dashboard-navbar-profile-picture-initials'>
                    {profile?.contractor?.first_name[0] + profile?.contractor?.last_name[0]}
                  </div>
                  : <img
                    src={'https://bobyard-public-images.s3.us-west-2.amazonaws.com/2828447.png'}
                    alt='profile picture'
                    className='dashboard-navbar-profile-picture'
                  />
                }
              </div>
          }
        </a>
      </div>
    </div>
  );
}
