import { SketchPicker } from 'react-color';

export default function ColorPicker({ color, onChange, onChangeComplete, top, bottom, left, right}) {
    return (
        <div
            style={{
                position: 'fixed',
                top: top,
                bottom: bottom,
                left: left,
                right: right,
                zIndex: 100,
            }}
            onClick={(e) => e.stopPropagation()}
        >
            <SketchPicker
                color={color}
                onChange={onChange}
                onChangeComplete={onChangeComplete}
                styles={{
                    zIndex: 300,
                }}
                disableAlpha={true}
            />
        </div>
    )
}