import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { selectAuth } from "../../../redux/slices/authSlice";
import { KeybindContext } from "../helper/Context";

import { API_ROUTE } from "../../..";

export default function KeybindInput({ action, alt }) {
    const auth = useSelector(selectAuth);

    const {
        keybinds, setKeybinds,
    } = useContext(KeybindContext);

    const [active, setActive] = useState(false);

    const [shift, setShift] = useState(false);
    const [control, setControl] = useState(false);
    const [tab, setTab] = useState(false);
    const [option, setOption] = useState(false);

    const updateKeybind = ({
        key, shift, control, tab, option
    }) => {
        let data = alt
            ? {
                keybind: keybinds['keybind'].id,
                alt_key: key,
                alt_shift: shift,
                alt_control: control,
                alt_tab: tab,
                alt_option: option,
            }
            : {
                keybind: keybinds['keybind'].id,
                key: key,
                shift: shift,
                control: control,
                tab: tab,
                option: option,
            }

        if (alt) {
            data.alt = true;
        }

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/${action.toLowerCase() + 's'}/${keybinds[action].id}/`,
            data: data,
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                console.log(response);
                setKeybinds(prev => ({
                    ...prev,
                    [action]: response.data
                }))
            })
            .catch((error) => {
                console.log(error);
            });

        setShift(false);
        setControl(false);
        setTab(false);
        setOption(false);
    }

    useEffect(() => {
        //watch for keybinds to change using listener
        const handleKeyDown = (e) => {
            if (active) {
                if (e.key === 'Escape') {
                    setActive(false);
                    return;
                }

                if (e.key === 'Shift') {
                    console.log('shift');
                    setShift(true);
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                if (e.key === 'Meta' || e.key === 'Control') {
                    console.log('control');
                    setControl(true);
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                if (e.key === 'Tab') {
                    console.log('tab');
                    setTab(true);
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                if (e.key === 'Option' || e.key === 'Alt') {
                    console.log('option');
                    setOption(true);
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }

                e.preventDefault();
                e.stopPropagation();

                //if key is pressed
                console.log(e);

                //if not shift, control, tab, or option is pressed
                if (e.key !== 'Shift' && e.key !== 'Control' && e.key !== 'Tab' && e.key !== 'Option' && e.key !== 'Meta') {
                    updateKeybind({
                        key: e.key,
                        shift: shift,
                        control: control,
                        tab: tab,
                        option: option,
                    });

                    setActive(false);
                }
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [active, shift, control, tab, option]);

    return (
        <div
            className='keybind-input'
            style={{
                borderColor: active ? '#006AFF' : ''
            }}
            onClick={() => {
                setActive(!active);
            }}
        >
            {keybinds[action]
                ? alt
                    ? <>
                        {keybinds[action].alt_control ? 'ctrl + ' : ''}
                        {keybinds[action].alt_option ? 'option + ' : ''}
                        {keybinds[action].alt_tab ? 'tab + ' : ''}
                        {keybinds[action].alt_shift ? 'shift + ' : ''}
                        {keybinds[action].alt_key ? keybinds[action].alt_key : 'none'}
                    </>
                    : <>
                        {keybinds[action].control ? 'ctrl + ' : ''}
                        {keybinds[action].option ? 'option + ' : ''}
                        {keybinds[action].tab ? 'tab + ' : ''}
                        {keybinds[action].shift ? 'shift + ' : ''}
                        {keybinds[action].key ? keybinds[action].key : 'none'}
                    </>
                : 'none'
            }
        </div>
    );
}