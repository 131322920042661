export const Descriptions = {
    'angle_snap': <div>
        <p>When enabled, the angle of the line will snap to angles defined by the settings below.</p>
        <p>When disabled, the angle of the line will be freeform.</p>
    </div>,
    'angle_snap_90': <div>
        <p>The line will snap to 0°, 90°, 180°, and 270° relative to the screen.</p>
    </div>,
    'angle_snap_45': <div>
        <p>The line will snap to 45°, 135°, 225°, and 315° relative to the screen.</p>
    </div>,
    'angle_snap_90_prev': <div>
        <p>The line will snap to 0°, 90°, 180°, and 270° relative to the previous line that was just drawn.</p>
    </div>,
    'angle_snap_45_prev': <div>
        <p>The line will snap to 45°, 135°, 225°, and 315° relative to the previous line that was just drawn.</p>
    </div>,
    'angle_snap_distance': <div>
        <p>How many degrees away from the nearest angle the line will snap.</p>
    </div>
}