import { useContext, useEffect, useState } from "react";
import { useDroppable } from '@dnd-kit/core';
import { Modal, ModalTitle } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from "axios";

import { IconCaretDownFilled, IconCaretRightFilled, IconHighlight, IconSettings, IconTrashX } from "@tabler/icons-react"

import { API_ROUTE } from "../../../..";

import { TakeoffContext } from "../../../helper/Context";
import { selectAuth } from "../../../../redux/slices/authSlice";

import DefaultIconButton from "../../../../components/DefaultIconButton"
import DeleteButton from "../../../../components/DeleteButton";
import Page from "../Page";
import Popup from "reactjs-popup";

export default function PageGridGroup({
    g,
    showPageGroups,
    setShowPageGroups,
    search,
    filterStarred,
    handleGroupPage,
    draggingPage,
    selectedPages, setSelectedPages,
    handleClickPage,
}) {
    const auth = useSelector(selectAuth)

    const {
        pages, setPages,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        project, setProject,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const { isOver, setNodeRef } = useDroppable({
        id: g.id,
    });

    const [showGear, setShowGear] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [title, setTitle] = useState(g.name);
    const [editingName, setEditingName] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if ((e.key === 'Escape' || e.key === 'Enter' || e.key === 'Return') && document.activeElement.tagName !== 'INPUT' && editingName) {
                e.preventDefault();
                e.stopPropagation();
                document.activeElement.blur();
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [])

    const handleRenameGroup = () => {
        setEditingName(false);

        setPageGroups({
            ...pageGroups,
            [g.id]: {
                ...pageGroups[g.id],
                name: title,
            }
        })

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pagegroups/${g.id}/`,
            data: {
                project: project.id,
                name: title,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteGroup = () => {
        g.pages.forEach(p => {
            setUngroupedPages([
                ...ungroupedPages,
                p,
            ])

            setPageGroups({
                ...pageGroups,
                [g.id]: {
                    ...pageGroups[g.id],
                    pages: pageGroups[g.id].pages.filter(pg => pg.id !== p.id),
                }
            })
        })

        setPageGroups(Object.values(pageGroups).filter(pg => pg.id !== g.id))

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/pagegroup/`,
            data: {
                id: g.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }


    return (
        <div
            className="pagessidebar-group"
            ref={setNodeRef}
            style={{
                backgroundColor: isOver && draggingPage
                    ? '#f0f8ff'
                    : ''
            }}
        >
            {/*<GroupModal
                g={g}
                showModal={showModal}
                setShowModal={setShowModal}
            />*/}

            <div
                className="pagessidebar-group-header"
                onClick={() => setShowPageGroups({ ...showPageGroups, [g.id]: !showPageGroups[g.id] })}
                onContextMenu={(e) => {
                    e.preventDefault();
                    setShowModal(true);
                }}
                onMouseEnter={() => setShowGear(true)}
                onMouseLeave={() => setShowGear(false)}
                style={{
                    color: isOver && draggingPage
                        ? '#006aef'
                        : g.pages?.length
                            ? ''
                            : '#a9a9a9',
                    backgroundColor: showGear
                        ? '#EEEEEE'
                        : ''
                }}
            >
                <div>
                    {showPageGroups[g.id]
                        ? <IconCaretDownFilled size={15} stroke={1} />
                        : <IconCaretRightFilled size={15} stroke={1} />
                    }
                </div>

                {editingName
                    ? <input
                        type="text"
                        className="pagessidebar-group-title-input"
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        onBlur={() => handleRenameGroup()}
                        autoFocus
                    />
                    : <div className="pagessidebar-group-title">
                        {g.name}
                    </div>
                }
                <div>
                    {showGear &&
                        <div
                            className="pagessidebar-group-gear"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowModal(true);
                            }}
                        >
                            <IconSettings size={15} stroke={1} />
                        </div>
                    }
                </div>

                <Popup
                    trigger={
                        <div
                            style={{
                                position: 'absolute',
                                top: '20px',
                                right: '20px',
                                width: '0px',
                                height: '0px',
                                color: 'transparent',
                            }}
                        >
                            &nbsp;
                        </div>
                    }
                    open={showModal}
                    on=''
                    onClose={() => {
                        setShowModal(false);
                    }}
                    position={takeoffSettings?.pages_sidebar_location === 'left' ? 'bottom left' : 'bottom right'}
                    closeOnDocumentClick
                    mouseLeaveDelay={300}
                    mouseEnterDelay={0}
                    arrow={false}
                    contentStyle={{
                        width: '188px',
                        padding: '0px',
                    }}
                >
                    <div className="pagessidebar-page-options-container">
                        <div
                            className="pagessidebar-page-options-section-first"
                            onClick={() => setEditingName(true)}
                        >
                            <div className="pagessidebar-page-options-section-icon">
                                <IconHighlight size={15} stroke={1} />
                            </div>
                            <div className="pagessidebar-page-options-section-text">
                                Name
                            </div>
                        </div>

                        <div
                            className="pagessidebar-page-options-section-delete"
                            onClick={() => setShowDeleteModal(true)}
                        >
                            <div className="pagessidebar-page-options-section-icon">
                                <IconTrashX size={15} stroke={1} />
                            </div>
                            <div className="pagessidebar-page-options-section-text">
                                Delete
                            </div>
                        </div>
                    </div>
                </Popup>
            </div >

            <Modal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                size='small'
            >
                <Modal.Header closeButton>
                    <div className='measurementsidebar-option-modal-title'>
                        Delete page group
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='measurementsidebar-option-modal-body'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Are you sure you want to delete this page group (all the pages will be ungrouped)?
                        </div>

                        <div>
                            Name: {g.name}
                        </div>

                        <div className="pagessidebar-group-modal-buttons">
                            <DeleteButton
                                handleClick={handleDeleteGroup}
                                text={'Delete Group'}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            {showPageGroups[g.id] &&
                <div
                    className="pagessidebar-body-grid"
                >
                    {g.pages.sort((a, b) => a - b).filter(p => {
                        if (filterStarred && !pages[p].starred) return false;
                        if (search && !pages[p].title.toLowerCase().includes(search.toLowerCase())) return false;
                        return true;
                    }).map(p =>
                        <Page
                            key={p}
                            p={pages[p]}
                            useGrid={true}
                            useNames={false}
                            useList={false}
                            handleGroupPage={handleGroupPage}
                            handleClickPage={handleClickPage}
                            selectedPages={selectedPages}
                        />
                    )}
                </div>
            }
        </div >
    )
}

function GroupModal({ g, showModal, setShowModal }) {
    const auth = useSelector(selectAuth)

    const {
        project, setProject,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const [title, setTitle] = useState(g.name);

    const handleRenameGroup = () => {
        setPageGroups({
            ...pageGroups,
            [g.id]: {
                ...pageGroups[g.id],
                name: title,
            }
        })

        axios({
            method: 'put',
            url: `${API_ROUTE}/api/pagegroups/${g.id}/`,
            data: {
                project: project.id,
                name: title,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleDeleteGroup = () => {
        g.pages.forEach(p => {
            setUngroupedPages([
                ...ungroupedPages,
                p,
            ])

            setPageGroups({
                ...pageGroups,
                [g.id]: {
                    ...pageGroups[g.id],
                    pages: pageGroups[g.id].pages.filter(pg => pg.id !== p.id),
                }
            })
        })

        setPageGroups(Object.values(pageGroups).filter(pg => pg.id !== g.id))

        axios({
            method: 'delete',
            url: `${API_ROUTE}/api/pagegroup/`,
            data: {
                id: g.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size='small'
            className={takeoffSettings?.pages_sidebar_location === 'left'
                ? 'pagessidebar-option-modal'
                : 'measurementsidebar-option-modal'}
        >
            <Modal.Header closeButton>
                <div className='measurementsidebar-option-modal-title'>
                    Page options
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Name
                        </div>

                        <div>
                            <input
                                type="text"
                                value={title}
                                onChange={e => setTitle(e.target.value)}
                                onBlur={() => handleRenameGroup()}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Delete Group
                        </div>

                        <div className="pagessidebar-group-modal-buttons">
                            <DeleteButton
                                handleClick={handleDeleteGroup}
                                text={'Delete Group'}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}