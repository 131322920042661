import React, { useContext, useState } from "react";
import { Circle, Rect, Layer, Line, Stage, Image } from "react-konva";
import { useSelector } from "react-redux";

import axios from "axios";

import { API_ROUTE } from "../../..";
import { TakeoffContext } from "../../helper/Context";
import { selectAuth } from "../../../redux/slices/authSlice";
import { Portal } from "react-konva-utils";

export default function AILegendConstructor({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages,
        groups, setGroups,
        currentGroup,
        createMeasurement,
        setDrawingRectangle,
        history, setHistory,
        currentHistory, setCurrentHistory,
        setMeasurements,
        tree, setTree,
        handleChangeFilter,
        AILocalization, setAILocalization,
        AIMessages, setAIMessages,
        AILegends, setAILegends,
        localizationLegends, setLocalizationLegends,
        currentLegend, setCurrentLegend,
        populatingLegend, setPopulatingLegend,
    } = useContext(TakeoffContext);

    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [drawing, setDrawing] = useState(false);

    const handlePopulate = (points) => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/populate-virtual-legend/`,
            data: {
                'page': pageID,
                'method': 'crop',
                'virtual_legend': currentLegend,
                'x1': Math.min(points.x1, points.x2),
                'y1': Math.min(points.y1, points.y2),
                'x2': Math.max(points.x1, points.x2),
                'y2': Math.max(points.y1, points.y2),
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                setPopulatingLegend({
                    'message': '',
                    'progress': 0,
                    'show': true,
                    'running': true,
                    'id': null,
                })
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <>
            {start && end &&
                <Rect
                    x={Math.min(start.x, end.x)}
                    y={Math.min(start.y, end.y)}
                    width={Math.abs(start.x - end.x)}
                    height={Math.abs(start.y - end.y)}
                    fill={'#9DD9F3'}
                    stroke={'#006AFE'}
                    opacity={0.5}
                />
            }

            <Portal
                selector={'.top-layer'}
                enabled={start}
            >
                <Rect
                    x={0}
                    y={0}
                    width={pages[pageID].width}
                    height={pages[pageID].height}
                    onMouseEnter={(e) => {
                        e.target.getStage().container().style.cursor = "url('https://bobyard-public-images.s3.us-west-2.amazonaws.com/draw+rectangle.svg') 8 24, auto"
                    }}
                    onMouseLeave={(e) => {
                        e.target.getStage().container().style.cursor = 'default';
                    }}
                    onClick={(e) => {
                        if (!populatingLegend.running) {
                            if (drawing) {
                                const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                                const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                                setDrawing(false);
                                setEnd({ x: x, y: y });
                                handlePopulate({ x1: start.x, y1: start.y, x2: x, y2: y });
                            } else {
                                setDrawing(true);
                                const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                                const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                                setStart({ x: x, y: y });
                            }
                        }
                    }}
                    onMouseMove={(e) => {
                        if (drawing) {
                            const x = (e.target.getStage().getPointerPosition().x - pages[pageID].position_x) / pages[pageID].zoom;
                            const y = (e.target.getStage().getPointerPosition().y - pages[pageID].position_y) / pages[pageID].zoom;
                            setEnd({ x: x, y: y });
                        }
                    }}
                />
            </Portal>
        </>
    );
}