import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalTitle } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { useDroppable, useDraggable, DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy
} from "@dnd-kit/sortable";

import { IconSettings, IconCircles, IconLink, IconDimensions, IconAngle, IconEyeOff, IconEye, IconSettingsShare, IconX, IconChevronRight, IconChevronDown, IconShare3, IconRestore, IconArrowMergeRight, IconRobot, IconTrashX, IconCircleArrowUpRight, IconChevronLeft, IconSearch, IconTablePlus, IconHighlight, IconCopy } from '@tabler/icons-react';

import { MeasurementSideBarContext, TakeoffContext } from '../../helper/Context';
import { selectAuth } from '../../../redux/slices/authSlice';

import { API_ROUTE } from '../../../index';

import GroupMenu from './GroupMenu';
import DefaultButton from '../../../components/DefaultButton';
import ButtonHoverInfo from '../../../components/ButtonHoverInfo';
import DeleteButton from '../../../components/DeleteButton';
import AutoResizeTextInput from '../../../components/AutoResizeTextInput';
import HoverDelay from '../../../components/HoverDelay';
import ColorInput from '../../../components/ColorInput';
import ColorPicker from '../../../components/ColorPicker';
import CustomIcon from '../../../components/CustomIcon';
import DefaultIconButton from '../../../components/DefaultIconButton';
import { numberToColorHsl } from '../../helper/Colors';
import useMousePosition from '../../../helper/useMousePosition';
import { Blocks } from 'react-loader-spinner';
import Popup from 'reactjs-popup';
import { UOM_Display, UOM_Measured_Default, calculateValue } from '../../helper/UnitConversions';
import { toast } from 'react-toastify';
import { v4 } from 'uuid';

export default function CountMeasurement({ measurement, showModal, setShowModal }) {
    const auth = useSelector(selectAuth);

    const {
        project, setProject,
        pageID,
        groups, setGroups,
        pages,
        measurements, setMeasurements,
        currentMeasurement, setCurrentMeasurement,
        editingMeasurement, setEditingMeasurement,
        handleZoomToMeasurement, handleZoomToDot,
        selectedMeasurements, setSelectedMeasurements,
        selectedDots, setSelectedDots,
        handleClickMeasurement,
        handleCountTransform,
        setShowDetails,
        currentDot, setCurrentDot,
        controlDown, setControlDown,
        optionDown, setOptionDown,
        drawing,
        DeleteMeasurement,
        takeoffSettings,
        setShowDeleteMeasurementModal,
        handleDuplicateMeasurement,
    } = useContext(TakeoffContext);

    const {
        sidebarWidth,
        setHoveringGroup,
        draggingCount, setDraggingCount,
        showDots, setShowDots,
        draggingID,
    } = useContext(MeasurementSideBarContext);

    const [showGear, setShowGear] = useState(false);
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState(measurement?.color);

    const [editingName, setEditingName] = useState(false);
    const [measurementName, setMeasurementName] = useState(measurement?.name || '');

    const [multiselectAnchor1, setMultiselectAnchor1] = useState(null);
    const [multiselectAnchor2, setMultiselectAnchor2] = useState(null);

    const { isOver, setNodeRef } = useDroppable({
        id: 'count-' + measurement.id,
        data: {
            accepts: ['dot'],
        },
        disabled: !draggingID?.includes('dot') || (draggingID?.includes('dot') && measurements[currentMeasurement]?.page != measurement.page),
        //|| measurement.count_dots.find(d => String(draggingID).includes(String(d.id)))
    });

    const [over, setOver] = useState(false);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Enter') {
                if (editingName || editingMeasurement) {
                    setEditingName(false);
                    setEditingMeasurement(false);
                    handleCountTransform(measurement);
                } else if (currentMeasurement === measurement.id && !showModal && !drawing) {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                    e.preventDefault();
                    setEditingName(true);
                }
            } else if (e.key === 'Escape' && document.activeElement.tagName === 'INPUT') {
                e.stopPropagation();
                e.preventDefault();

                document.activeElement.blur();
                setEditingName(false);
                setEditingMeasurement(false);
            }

            if ((e.key === 'ArrowLeft' || e.key === 'ArrowRight')
                && !measurement.hide && measurement.page == pageID
                && currentMeasurement === measurement.id && document.activeElement.tagName !== 'INPUT'
                && document.activeElement.tagName !== 'TEXTAREA'
            ) {
                setShowDots(prev => ({
                    ...prev,
                    [measurement.id]: true,
                }));
                if (!currentDot) {
                    setCurrentDot(measurement.count_dots[0]);
                } else {
                    let currentIndex = measurement.count_dots.findIndex(d => d.id === currentDot.id);
                    let newIndex = e.key === 'ArrowLeft'
                        ? currentIndex === 0
                            ? 0
                            : currentIndex - 1
                        : currentIndex === measurement.count_dots.length - 1
                            ? measurement.count_dots.length - 1
                            : currentIndex + 1;

                    setCurrentDot(measurement.count_dots[newIndex]);

                    if (controlDown) {
                        e.preventDefault();
                        e.stopPropagation();
                        handleZoomToDot(measurement.count_dots[newIndex]);

                        const element = document.getElementById(`measurement-dot-${measurement.count_dots[newIndex].id}`);
                        if (element && (element.getBoundingClientRect().top < 0 || element.getBoundingClientRect().top > window.innerHeight)) {
                            element.scrollIntoView();
                        }
                    }
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [editingName, editingMeasurement, measurement, currentDot, controlDown, currentMeasurement]);

    useEffect(() => {
        if (measurementName !== measurement?.name) {
            setMeasurementName(measurement?.name);
        }
    }, [measurement?.name]);

    const handleClickDot = (e, dot) => {
        if (e.defaultPrevented) return;
        e.preventDefault();

        if (e.ctrlKey || e.metaKey) {
            setCurrentDot(null);
            setSelectedDots(prev => {
                if (prev.length && prev.find((a) => a.id === dot.id)) {
                    return prev.filter((a) => a.id !== dot.id);
                }
                return [...prev, dot];
            })
            setMultiselectAnchor1(dot);
            setMultiselectAnchor2(null);
        } else if (e.shiftKey) {
            if (!multiselectAnchor1 && !currentDot) {
                setSelectedDots(Object.values(measurement.count_dots).filter((d) => {
                    if (!d.ai_detected) return true;
                    if (d.confidence <= dot.confidence) return true;
                    return false;
                }));
                setMultiselectAnchor1(dot);
            } else if (multiselectAnchor1) {
                let newSelectedDots = [...selectedDots];
                if (multiselectAnchor2) {
                    //filter out all dots between the two anchors
                    newSelectedDots = newSelectedDots.filter((d) => {
                        if (multiselectAnchor1.ai_detected) {
                            if (multiselectAnchor2.ai_detected) {
                                if (multiselectAnchor1.confidence > multiselectAnchor2.confidence) {
                                    return d.confidence > multiselectAnchor1.confidence || d.confidence < multiselectAnchor2.confidence;
                                } else {
                                    return d.confidence < multiselectAnchor1.confidence || d.confidence > multiselectAnchor2.confidence;
                                }
                            } else {
                                if (d.ai_detected) {
                                    return d.confidence <= multiselectAnchor1.confidence;
                                } else {
                                    return d.id >= multiselectAnchor1.id;
                                }
                            }
                        } else {
                            if (multiselectAnchor2.ai_detected) {
                                if (d.ai_detected) {
                                    return d.confidence <= multiselectAnchor2.confidence;
                                } else {
                                    return d.id <= multiselectAnchor2.id && d.id >= multiselectAnchor1.id;
                                }
                            } else {
                                if (multiselectAnchor1.id > multiselectAnchor2.id) {
                                    return d.id > multiselectAnchor1.id || d.id < multiselectAnchor2.id;
                                } else {
                                    return d.id < multiselectAnchor1.id || d.id > multiselectAnchor2.id;
                                }
                            }
                        }
                    });
                }

                newSelectedDots = newSelectedDots.concat(Object.values(measurement.count_dots).filter((d) => {
                    if (multiselectAnchor1.ai_detected) {
                        if (dot.ai_detected) {
                            if (multiselectAnchor1.confidence > dot.confidence) {
                                return d.confidence >= dot.confidence && d.confidence <= multiselectAnchor1.confidence;
                            } else {
                                return d.confidence >= multiselectAnchor1.confidence && d.confidence <= dot.confidence;
                            }
                        } else {
                            if (d.ai_detected) {
                                return d.confidence >= multiselectAnchor1.confidence;
                            } else {
                                return d.id <= dot.id;
                            }
                        }
                    } else {
                        if (dot.ai_detected) {
                            if (d.ai_detected) {
                                return d.confidence >= dot.confidence;
                            } else {
                                return d.id <= multiselectAnchor1.id;
                            }
                        } else {
                            if (multiselectAnchor1.id > dot.id) {
                                return d.id >= dot.id && d.id <= multiselectAnchor1.id;
                            } else {
                                return d.id <= dot.id && d.id >= multiselectAnchor1.id;
                            }
                        }
                    }
                }));

                setSelectedDots(newSelectedDots);
                setMultiselectAnchor2(dot);
            }
        } else {
            setMultiselectAnchor1(dot);
            setMultiselectAnchor2(null);
            setSelectedDots([dot]);
        }

        //console.log(selectedDots);
    }

    const handleMergeCounts = () => {
        let tempSelectedMeasurements = [...new Set(selectedMeasurements)].sort((a, b) => a - b);

        let tempDots = measurement.count_dots;
        let tempName = measurement.name;

        tempSelectedMeasurements.filter(a => Number(a) !== Number(measurement.id)).filter(a => a.page === measurement.page).forEach((m) => {
            tempDots.push(...measurements[m].count_dots);
            tempName += ` + ${measurements[m].name}`;
        })

        setSelectedMeasurements([]);
        setCurrentMeasurement(null);

        setMeasurements(prev => {
            let newMeasurements = { ...prev };

            newMeasurements[measurement.id] = {
                ...newMeasurements[measurement.id],
                count_dots: tempDots,
                name: tempName,
                count: tempDots.length,
            }

            tempSelectedMeasurements.filter(a => Number(a) !== Number(measurement.id)).filter(a => a.page === measurement.page).forEach((m) => {
                delete newMeasurements[m];
            })

            return newMeasurements;
        })

        axios({
            method: 'patch',
            url: `${API_ROUTE}/api/count/`,
            data: {
                'dest': measurement.id,
                'list': tempSelectedMeasurements.filter(a => Number(a) !== Number(measurement.id)),
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res);

                setMeasurements(res.data.measurements);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const getClassName = () => {
        let className = 'measurementsidebar-group-measurement';
        if (measurements[currentMeasurement]?.id === measurement.id) {
            className += ' measurementsidebar-group-measurement-selected';
        } else if (measurement?.page != pageID || measurement?.hide || measurement?.group && groups[measurement.group]?.hide) {
            className += ' measurementsidebar-group-measurement-differentpage';
        }

        if (selectedMeasurements.find((m) => m === measurement.id)) {
            className += ' measurementsidebar-group-measurement-multiselected';
        }
        return className;
    }

    return (
        <>
            {/*<OptionModal
                measurement={measurement}
                showModal={showModal}
                setShowModal={setShowModal}
                measurementName={measurementName}
                setMeasurementName={setMeasurementName}
            />*/}

            <div
                id={'measurement-' + measurement.id + '-count'}
                className='measurementsidebar-measurement-container'
                ref={setNodeRef}
                onMouseEnter={() => setOver(true)}
                onMouseLeave={() => setOver(false)}
                onContextMenu={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setShowModal(true);
                }}
                style={{
                    backgroundColor: (isOver) && draggingCount && '#f0f8ff',
                    border: isOver && draggingCount
                        ? '1px solid #006AFF'
                        : selectedMeasurements.find((m) => m === measurement.id) ? '1px solid aliceblue' : '1px solid transparent',
                }}
            >
                <div
                    id={'measurement-' + measurement.id}
                    className={getClassName()}
                    style={{
                        width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (measurements[measurement.id]?.path?.split('/').length - 2) * 20 - 15 + 'px',
                        backgroundColor: (isOver) && draggingCount || measurements[measurement.id]?.id === currentMeasurement || selectedMeasurements.find((m) => m === measurement.id)
                            && '#f0f8ff',
                        //: showDots[measurement.id] ? 'whitesmoke' : ''
                        height: '30px',
                    }}
                    /*onClick={(e) => {
                        setCurrentMeasurement(measurement.id);
                        handleClickMeasurement(e, measurement);
                        setShowDetails(true);
                    }}*/
                    onMouseEnter={() => {
                        if (measurement.group) {
                            setHoveringGroup('group-' + measurement.group);
                        }
                        setShowGear(true)
                    }}
                    onMouseLeave={() => {
                        setHoveringGroup(null);
                        setShowGear(false)
                    }}
                    /*={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowModal(true);
                        setEditingMeasurement(true);
                    }}*/
                    onDoubleClick={() => {
                        if (measurement.page != pageID) {
                            window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurement.page}`);
                        }

                        if (measurement.count_dots.length) {
                            handleZoomToMeasurement();
                        }
                    }}
                >
                    <div className='measurementsidebar-measurement-icon'
                        style={{
                            width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) * 0.5,
                            backgroundColor: (isOver) && draggingCount
                                ? '#f0f8ff'
                                : '',
                        }}
                    >
                        <div
                            style={{
                                height: '30px',
                                paddingLeft: '7px',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <IconCircles
                                id={'measurement-icon-' + measurement.id + '-count'}
                                size={16} color={measurement?.color} stroke={1.5}
                                style={{
                                    outline: 'none',
                                }}
                            />
                        </div>
                        <Tooltip className='tooltip' anchorSelect={'#measurement-icon-' + measurement.id + '-count'} delayShow={1000}>
                            Count measurement
                        </Tooltip>

                        {measurement?.ai_created &&
                            <div
                                style={{
                                    width: '20px',
                                }}
                            >
                                <IconRobot
                                    id={'measurement-icon-' + measurement.id + '-count-ai-badge'}
                                    size={20} color={measurement?.color} stroke={1}
                                    style={{
                                        outline: 'none',
                                    }}
                                />
                                <Tooltip className='tooltip' anchorSelect={'#measurement-icon-' + measurement.id + '-count-ai-badge'} delayShow={1000}>
                                    AI created count
                                </Tooltip>
                            </div>
                        }

                        <div
                            className='measurementsidebar-measurement-dot-toggle'
                            style={{
                                marginLeft: measurement?.ai_created ? '-4px' : '-2px',
                                paddingRight: measurement?.ai_created ? '4px' : '2px',
                            }}
                            id={'measurement-toggle-dot-' + measurement.id}
                            onClick={(e) => {
                                setShowDots(prev => ({
                                    ...prev,
                                    [measurement.id]: !prev[measurement.id],
                                }));
                                setCurrentMeasurement(measurement.id);
                                e.stopPropagation();

                                document.getElementById('measurement-toggle-dot-' + measurement.id).dispatchEvent(new MouseEvent('mouseleave'));
                            }}
                        >
                            <div className='measurementsidebar-measurement-dot-toggle-icon'>
                                {showDots[measurement.id]
                                    ? <IconChevronDown size={20} color={measurement?.color} stroke={1.5} />
                                    : <IconChevronRight size={20} color={measurement?.color} stroke={1.5} />
                                }
                            </div>
                        </div>
                        <Tooltip className='tooltip' anchorSelect={'#measurement-toggle-dot-' + measurement.id} delayShow={1000}>
                            {showDots[measurement.id] ? 'Hide dots' : 'Show dots'}
                        </Tooltip>

                        {editingName
                            ? <input
                                type='text'
                                className='measurementsidebar-measurement-name-input'
                                style={{
                                    width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (measurements[measurement.id]?.path?.split('/').length - 2) * 20 - 32 - 122 - (measurement?.ai_created ? 20 : 0) + 'px',
                                }}
                                value={measurementName}
                                autoFocus
                                placeholder='Name...'
                                onClick={(e) => e.stopPropagation()}
                                onDoubleClick={(e) => e.stopPropagation()}
                                onChange={(e) => setMeasurementName(e.target.value)}
                                onBlur={() => {
                                    handleCountTransform({
                                        ...measurement,
                                        name: measurementName,
                                    }, true);
                                    setEditingName(false);
                                    setEditingMeasurement(false);
                                }}
                            />
                            : <div
                                className={'measurementsidebar-measurement-name'}
                                id={`measurement-${measurement.id}-name`}
                                onClick={(e) => {
                                    if (optionDown || e.altKey) {
                                        setEditingName(true);
                                    }
                                }}
                                style={{
                                    width: (sidebarWidth ? sidebarWidth : 0.20 * window.innerWidth) - (measurements[measurement.id]?.path?.split('/').length - 2) * 20 - 32 - 122 - (measurement?.ai_created ? 20 : 0) + 'px',
                                }}
                            >
                                {measurementName
                                    ? measurementName
                                    : (measurement?.number ? `${measurement.number} ` : '') + 'Count'
                                }
                            </div>
                        }
                    </div>

                    {measurementName?.length > 20 &&
                        <Tooltip
                            className='tooltip'
                            anchorSelect={'#measurement-' + measurement.id + '-name'}
                            delayShow={1000}
                            positionStrategy='fixed'
                            style={{ zIndex: 99999 }}
                            place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}
                        >
                            {measurementName}
                        </Tooltip>
                    }

                    <div className='measurementsidebar-measurement-details'
                    /*style={{
                        width: (sidebarWidth ? sidebarWidth : 0.18 * window.innerWidth) * 0.40 - (measurement.path.replace(/[^-]/g, "").length + 2) * 6,
                        minWidth: (sidebarWidth ? sidebarWidth : 0.18 * window.innerWidth) * 0.40 - (measurement.path.replace(/[^-]/g, "").length + 2) * 7,
                    }}*/
                    >
                        <div
                            style={{
                                minWidth: '70px',
                                width: '70px',
                                maxWidth: '70px',
                            }}
                        >
                            {calculateValue(measurement, pages[measurement.page].scale)?.toFixed((measurement.uom || UOM_Measured_Default[measurement.type]) === 'ea' ? 0 : 1)} {UOM_Display[measurement.uom || UOM_Measured_Default[measurement.type]]}
                        </div>

                        <Popup
                            trigger={
                                <div className='measurementsidebar-measurement-details-icons'>
                                    {/*showGear && selectedMeasurements.filter((m) => measurements[m]?.type === 'count').length > 1 && selectedMeasurements.find((m) => m === measurement.id) &&
                                <>
                                    <div
                                        id={'measurementsidebar-measurement-count-merge' + measurement.id}
                                        className='measurementsidebar-measurement-hide'
                                        onClick={() => handleMergeCounts()}
                                    >
                                        <IconArrowMergeRight size={16} stroke={1} />
                                    </div>

                                    <Tooltip className='tooltip' anchorSelect={'#measurementsidebar-measurement-count-merge' + measurement.id} delayShow={1000}>
                                        Merge all selected counts (only combines counts on same page)
                                    </Tooltip>
                                </>
                        */}

                                    {(showGear || measurement.hide) && <>
                                        {measurement.page != pageID
                                            ? <>
                                                <div
                                                    className='measurementsidebar-measurement-hide'
                                                    id={'measurement-differentpage-' + measurement.id}
                                                    onClick={() => {
                                                        setCurrentMeasurement(measurement.id);
                                                        window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurement.page}`);
                                                        handleZoomToMeasurement(measurement.id);
                                                    }}
                                                    size={'small'}
                                                >
                                                    <IconShare3 size={16} stroke={1} />
                                                </div>

                                                <Tooltip className='tooltip' anchorSelect={'#measurement-differentpage-' + measurement.id} delayShow={1000}>
                                                    Go to measurement from different page
                                                </Tooltip>
                                            </>
                                            : (showGear && selectedMeasurements.filter((m) => measurements[m]?.type === 'count').length > 1 && selectedMeasurements.find((m) => m === measurement.id))
                                                ? <>
                                                    <div
                                                        id={'measurementsidebar-measurement-count-merge' + measurement.id}
                                                        className='measurementsidebar-measurement-hide'
                                                        onClick={() => handleMergeCounts()}
                                                    >
                                                        <IconArrowMergeRight size={16} stroke={1} />
                                                    </div>

                                                    <Tooltip className='tooltip' anchorSelect={'#measurementsidebar-measurement-count-merge' + measurement.id} delayShow={1000}>
                                                        Merge all selected counts (only combines counts on same page)
                                                    </Tooltip>
                                                </>
                                                : <>
                                                    <div
                                                        className='measurementsidebar-measurement-hide'
                                                        id={'measurement-hide-' + measurement.id}
                                                        style={{ visibility: (measurement?.hide || showGear) ? 'visible' : 'hidden' }}
                                                        //visibility={measurement?.hide || showGear}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();

                                                            if (e.altKey) {
                                                                let newMeasurements = { ...measurements };

                                                                if (Object.values(newMeasurements).reduce((acc, m) => {
                                                                    if (!m.hide) {
                                                                        return acc + 1;
                                                                    }
                                                                    return acc;
                                                                }, 0) > 1) {
                                                                    Object.values(newMeasurements).forEach((m) => {
                                                                        newMeasurements[m.id].hide = true;
                                                                    })
                                                                    newMeasurements[measurement.id].hide = false;
                                                                    setMeasurements(newMeasurements);
                                                                } else {
                                                                    Object.values(newMeasurements).forEach((m) => {
                                                                        newMeasurements[m.id].hide = false;
                                                                    })
                                                                    newMeasurements[measurement.id].hide = false;
                                                                    setMeasurements(newMeasurements);
                                                                }
                                                            } else {
                                                                handleCountTransform({
                                                                    ...measurement,
                                                                    hide: !measurement.hide,
                                                                });
                                                            }
                                                        }}
                                                        size={'small'}
                                                    >
                                                        {measurement?.hide
                                                            ? <IconEyeOff size={16} stroke={1} />
                                                            : <IconEye size={16} stroke={1} />
                                                        }
                                                    </div>

                                                    <Tooltip className='tooltip' anchorSelect={'#measurement-hide-' + measurement.id} delayShow={1000} place='left-end'>
                                                        <div>
                                                            <b>{measurement?.hide ? 'Show measurement' : 'Hide measurement'}</b>
                                                        </div>
                                                        {!measurement?.hide &&
                                                            <div>
                                                                Alt (Option) + click to toggle all other measurements
                                                            </div>
                                                        }
                                                    </Tooltip>
                                                </>
                                        }

                                        {/*<>
                                    <div
                                        className='measurementsidebar-measurement-hide'
                                        id={'measurement-' + measurement.id + '-gear'}
                                        style={{
                                            visibility: showGear ? 'visible' : 'hidden',
                                        }}
                                        onClick={() => {
                                            setShowModal(true);
                                            setEditingMeasurement(true);
                                        }}
                                        size={'small'}
                                    >
                                        <IconSettings size={16} stroke={1} />
                                    </div>

                                    <Tooltip className='tooltip' anchorSelect={'#measurement-' + measurement.id + '-gear'} delayShow={1000}>
                                        Measurement settings
                                    </Tooltip>
                                    </>*/}
                                    </>}
                                </div>
                            }
                            open={showModal}
                            on=''
                            onClose={() => {
                                setShowModal(false);
                                setShowColorPicker(false);
                            }}
                            position="bottom right"
                            closeOnDocumentClick
                            mouseLeaveDelay={300}
                            mouseEnterDelay={0}
                            arrow={false}
                        >
                            <div className="aisidebar-count-legend-settings">
                                <div className='aisidebar-count-legend-settings-group'>
                                    <div className='aisidebar-count-legend-settings-group-body'>
                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={(e) => handleCountTransform({
                                                ...measurement,
                                                hide: !measurement.hide,
                                            })}
                                        >
                                            {measurement.hide
                                                ? <IconEyeOff size={20} stroke={1} />
                                                : <IconEye size={20} stroke={1} />
                                            } Visibility
                                        </div>

                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setShowColorPicker(true);
                                            }}
                                        >
                                            <div
                                                className='measurementsidebar-option-color-placeholder'
                                                style={{
                                                    backgroundColor: color,
                                                }}
                                            ></div>

                                            Color
                                        </div>

                                        {showColorPicker
                                            ? <div
                                                style={{
                                                    position: 'relative',
                                                    top: '3px',
                                                    left: '-50px',
                                                    zIndex: 100,
                                                }}
                                            >
                                                <ColorPicker
                                                    color={color}
                                                    onChange={(color) => setColor(color.hex)}
                                                    onChangeComplete={(color) => {
                                                        setColor(color.hex);
                                                        handleCountTransform({
                                                            ...measurement,
                                                            color: color.hex,
                                                            use_group_color: false,
                                                        });
                                                    }}
                                                />
                                            </div>
                                            : null}

                                        {!measurement.use_group_color &&
                                            <div
                                                className='aisidebar-count-legend-settings-group-item'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCountTransform({
                                                        ...measurement,
                                                        use_group_color: true,
                                                        color: groups[measurement.group]?.color || "#9DD9f3",
                                                    })
                                                }}
                                            >
                                                <IconRestore size={20} stroke={1} />
                                                Use group color
                                            </div>
                                        }

                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                setEditingName(true);
                                                setShowModal(false);
                                            }}
                                        >
                                            <IconHighlight size={20} stroke={1} /> Rename
                                        </div>

                                        <div
                                            className='aisidebar-count-legend-settings-group-item'
                                            onClick={() => {
                                                handleDuplicateMeasurement(measurement);
                                                setShowModal(false);
                                            }}
                                        >
                                            <IconCopy size={20} stroke={1} /> Duplicate
                                        </div>
                                    </div>
                                </div>

                                <hr className='aisidebar-count-legend-settings-hr'></hr>

                                <div className='aisidebar-count-legend-settings-group'>
                                    <div className='aisidebar-count-legend-settings-group-body'>
                                        <div
                                            className='aisidebar-count-legend-settings-group-item-delete'
                                            onClick={(e) => {
                                                setCurrentMeasurement(measurement.id);
                                                setSelectedMeasurements([measurement.id]);
                                                setShowDeleteMeasurementModal(true);
                                                setShowModal(false);
                                            }}
                                        >
                                            <IconTrashX size={20} stroke={1} style={{ color: 'red' }} /> Delete
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    </div>
                </div >

                {showDots[measurement.id] &&
                    <Dots
                        measurement={measurement}
                        isOver={isOver}
                        over={over}
                        draggingCount={draggingCount}
                        handleClickDot={handleClickDot}
                        setMultiselectAnchor1={setMultiselectAnchor1}
                        setMultiselectAnchor2={setMultiselectAnchor2}
                    />
                }
            </div >
        </>
    )
}

function Dots({ measurement, isOver, over, draggingCount, handleClickDot, setMultiselectAnchor1, setMultiselectAnchor2 }) {
    //console.log('rendering all dots', measurement.id);

    return (
        <div
            className='measurementsidebar-measurement-dots-container'
            style={{
                backgroundColor: isOver && draggingCount
                    ? '#f0f8ff'
                    : '',
            }}
        >
            {measurement?.count_dots?.length > 0 && measurement?.count_dots?.sort((a, b) => {
                if (a.ai_detected && b.ai_detected) return a.confidence - b.confidence || a.id - b.id
                if (a.ai_detected) return -1;
                if (b.ai_detected) return 1;
                return a.id - b.id;
            }).map((dot, index) =>
                <DotContainer
                    key={index}
                    dot={dot}
                    index={index}
                    measurement={measurement}
                    handleClickDot={handleClickDot}
                    setMultiselectAnchor1={setMultiselectAnchor1}
                    setMultiselectAnchor2={setMultiselectAnchor2}
                />
            )}

            {measurement?.ai_count_example?.image && <ExampleDot measurement={measurement} />}

            {measurement?.file &&
                <div
                    className='measurementsidebar-measurement-dot'
                    style={{
                        backgroundColor: 'whitesmoke',
                    }}
                >
                    <div
                        className='measurementsidebar-measurement-dot-container'
                        id={'measurement-dot-' + measurement.id + '-example'}
                    >
                        {measurement.file
                            ? <img
                                src={measurement.file}
                                alt='dot'
                                className='measurementsidebar-measurement-dot-image'
                            />
                            : <div className='measurementsidebar-measurement-dot-number'>
                                Ex
                            </div>
                        }
                    </div>

                    <Tooltip className='tooltip' anchorSelect={'#measurement-dot-' + measurement.id + '-example'} delayShow={1000}>
                        <div>
                            Example symbol from virtual legend.
                        </div>
                    </Tooltip>
                </div>
            }
        </div>
    )
}

function ExampleDot({ measurement }) {
    const {
        handleZoomToDot,
        project,
        pageID,
    } = useContext(TakeoffContext);

    return (
        <div
            className='measurementsidebar-measurement-dot'
            style={{
                backgroundColor: 'whitesmoke',
            }}
            onDoubleClick={() => {
                if (measurement.page != pageID) {
                    window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurement.page}`);
                }

                handleZoomToDot({
                    x: (measurement.ai_count_example.x1 + measurement.ai_count_example.x2) / 2,
                    y: (measurement.ai_count_example.y1 + measurement.ai_count_example.y2) / 2,
                    measurement: measurement.id,
                });
            }}
        >
            <div
                className='measurementsidebar-measurement-dot-container'
                id={'measurement-dot-' + measurement.ai_count_example.id + '-example'}
            >
                {measurement.ai_count_example.image
                    ? <img
                        src={measurement.ai_count_example.image}
                        alt='dot'
                        className='measurementsidebar-measurement-dot-image'
                    />
                    : <div className='measurementsidebar-measurement-dot-number'>
                        Ex
                    </div>
                }
            </div>

            <Tooltip className='tooltip' anchorSelect={'#measurement-dot-' + measurement.ai_count_example.id + '-example'} delayShow={1000}>
                <div>
                    Example symbol in legend used for AI auto find.
                </div>
            </Tooltip>
        </div>
    )
}

function DotContainer({ dot, index, measurement, draggingCount, handleClickDot, setMultiselectAnchor1, setMultiselectAnchor2 }) {
    const auth = useSelector(selectAuth);
    //console.log('rendering dot container', dot.id);

    const {
        currentMeasurement, setCurrentMeasurement,
        handleZoomToMeasurement, handleZoomToDot,
        selectedDots, setSelectedDots,
        handleCountTransform,
        currentDot, setCurrentDot,
        pages,
        project,
        pageID,
        takeoffSettings,
        measurements, setMeasurements,
        setGroups,
        setTree,
        setAILocalization,
        setAILegends,
    } = useContext(TakeoffContext);

    const mousePosition = useMousePosition();

    const toastID = useRef(null);

    const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
        id: 'dot-' + dot.id + '-count-' + measurement.id,
        data: {
            type: 'dot',
        },
        //disabled: showModal,
    });

    const style = useMemo(() => {
        return transform ? {
            position: 'fixed',
            top: mousePosition.y - 20,
            left: mousePosition.x - 20,
            zIndex: 100,
        } : undefined;
    }, [transform, mousePosition]);

    const projectUUID = useMemo(() => project?.uuid, [project]);
    const currentDotID = useMemo(() => currentDot?.id, [currentDot]);

    if (isDragging && selectedDots?.find((d) => d.id === dot.id) && selectedDots.length > 1) {
        return null;
    }

    const changeCount = (countID) => {
        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/change-dot-count/`,
            'data': {
                'dot': dot.id,
                'count': countID,
                'selectedDots': [dot.id],
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res);

                setMeasurements(res.data.measurements);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    const handleManageDot = (type, dot, callback, measurementID) => {
        if (type === 'change') {
            toastID.current = v4();
            toast.loading('Creating new count', {
                position: 'top-right',
                autoClose: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: toastID.current,
            });

            axios({
                'method': 'put',
                'url': `${API_ROUTE}/api/manage-virtual-legend/`,
                'data': {
                    'dot': dot.id,
                },
                'headers': {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((res) => {
                    console.log(res);

                    setMeasurements(res.data.measurements);
                    setGroups(res.data.groups);
                    setTree(res.data.tree);
                    setAILocalization(res.data.localization_symbols);

                    setAILegends(prev => ({
                        ...prev,
                        [res.data.entry.virtual_legend]: {
                            ...prev[res.data.entry.virtual_legend],
                            entries: [
                                ...prev[res.data.entry.virtual_legend].entries,
                                res.data.entry
                            ]
                        }
                    }));

                    callback();
                    toast.dismiss(toastID.current);

                    axios({
                        'method': 'post',
                        'url': `${API_ROUTE}/api/create-ai-preview/`,
                        'data': {
                            'page': res.data.measurement.page,
                            'measurements': [res.data.measurement.id],
                        },
                        'headers': {
                            'Authorization': `Token ${auth.token}`,
                            'Content-Type': 'application/json',
                        }
                    })
                        .then((res) => {
                            console.log(res);
                        })
                        .catch((err) => {
                            console.log(err);
                        })

                })
                .catch((err) => {
                    console.log(err);
                })
        } else if (type === 'delete') {
            toastID.current = v4();
            toast.loading('Deleting dots', {
                position: 'top-right',
                autoClose: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: toastID.current,
            });

            axios({
                'method': 'delete',
                'url': `${API_ROUTE}/api/manage-virtual-legend/`,
                'data': {
                    'dot': dot.id,
                },
                'headers': {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((res) => {
                    console.log(res);

                    setMeasurements(res.data.measurements);
                    setAILocalization(res.data.localization_symbols);

                    callback();
                    toast.dismiss(toastID.current);
                })
                .catch((err) => {
                    console.log(err);
                })
        } else if (type === 'move') {
            toastID.current = v4();
            toast.loading('Moving dots', {
                position: 'top-right',
                autoClose: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: toastID.current,
            });

            axios({
                'method': 'patch',
                'url': `${API_ROUTE}/api/manage-virtual-legend/`,
                'data': {
                    'dot': dot.id,
                    'measurement': measurementID,
                },
                'headers': {
                    'Authorization': `Token ${auth.token}`,
                    'Content-Type': 'application/json',
                }
            })
                .then((res) => {
                    console.log(res);

                    setMeasurements(res.data.measurements);

                    callback();
                    toast.dismiss(toastID.current);
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    return (
        <>
            <div
                id={'measurement-dot-' + dot.id}
                ref={setNodeRef} style={style} {...listeners} {...attributes}
                onMouseDown={() => setCurrentMeasurement(measurement.id)}
            >
                <Dot
                    dot={dot}
                    index={index}
                    measurement={measurement}
                    isDragging={isDragging}
                    selectedDots={selectedDots}
                    currentDotID={currentDotID}
                    projectUUID={projectUUID}
                    pageID={pageID}

                    handleClickDot={handleClickDot}
                    setMultiselectAnchor1={setMultiselectAnchor1}
                    setMultiselectAnchor2={setMultiselectAnchor2}
                    setCurrentMeasurement={setCurrentMeasurement}
                    handleZoomToDot={handleZoomToDot}
                    setSelectedDots={setSelectedDots}
                    handleCountTransform={handleCountTransform}
                    setCurrentDot={setCurrentDot}
                    takeoffSettings={takeoffSettings}
                    changeCount={changeCount}
                    measurements={measurements}

                    handleManageDot={handleManageDot}
                />
            </div>
        </>
    )
}

const Dot = memo(function Dot({
    dot, index, measurement, isDragging, selectedDots, currentDotID, projectUUID, pageID,
    handleClickDot, setMultiselectAnchor1, setMultiselectAnchor2,
    setCurrentMeasurement, handleZoomToDot, setSelectedDots, handleCountTransform, setCurrentDot,
    takeoffSettings, changeCount, measurements,
    handleManageDot,
}) {
    //console.log('rendering dot', dot.id);
    const [showModal, setShowModal] = useState(false);
    const [showGroupOptions, setShowGroupOptions] = useState(false);
    const [hovering, setHovering] = useState(false);

    const [search, setSearch] = useState('');

    const [managingDot, setManagingDot] = useState(false);
    const [showManageMeasurements, setShowManageMeasurements] = useState(false);

    return (
        <div
            id={'measurement-dot-' + dot.id + '-body'}
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            onContextMenu={(e) => {
                setCurrentMeasurement(measurement.id);
                setCurrentDot(dot)
                e.preventDefault();
                e.stopPropagation();
                setShowModal(true);
            }}
            onClick={(e) => {
                setCurrentMeasurement(measurement.id);

                //console.log(currentDot, measurement.count_dots);
                if (!currentDotID || (currentDotID && !measurement.count_dots.find((d) => d.id === currentDotID))) {
                    //console.log('setting current dot');
                    setCurrentDot(dot);
                    setMultiselectAnchor1(dot);
                    setMultiselectAnchor2(null);
                    setSelectedDots([dot]);
                } else {
                    //console.log('handling click dot');
                    handleClickDot(e, dot);
                    setCurrentDot(dot)
                }
            }}
            onDoubleClick={() => {
                if (measurement.page != pageID) {
                    window.history.pushState({}, '', `/takeoff/${projectUUID}/${measurement.page}`);
                }

                handleZoomToDot(dot);
            }}
            className={currentDotID === dot.id ? 'measurementsidebar-measurement-dot measurementsidebar-measurement-dot-selected' : 'measurementsidebar-measurement-dot'}
        >

            <div
                className='measurementsidebar-measurement-dot-container'
                style={{
                    borderColor: currentDotID === dot.id ? '#006AFF' : selectedDots.find((d) => d.id === dot.id) ? '#006AFF' : '',
                }}
            >
                {dot.image
                    ? <LazyLoadDotPreview
                        dot={dot}
                        currentDotID={currentDotID}
                        selectedDots={selectedDots}
                    />
                    : <div className='measurementsidebar-measurement-dot-number'>
                        {index + 1}

                        <Blocks
                            visible={true}
                            height="20"
                            width="20"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>}
            </div>

            {!isDragging &&
                <Tooltip className='tooltip' anchorSelect={'#measurement-dot-' + dot.id} delayShow={1000} place='top' style={{ zIndex: 99999 }} positionStrategy='fixed'>
                    <div>
                        <b>
                            {dot.ai_detected
                                ? `AI (${Math.round(dot.confidence * 10000) / 100}% conf)`
                                : 'User added'
                            }
                        </b>
                    </div>

                    <div>
                        - Double click to zoom to dot
                    </div>
                    <div>
                        - Drag to move dot to different count
                    </div>
                    <div>
                        - Right click for more options
                    </div>
                </Tooltip>
            }

            <Popup
                trigger={
                    <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            width: '0px',
                            height: '0px',
                            color: 'transparent',
                        }}
                    >
                        &nbsp;
                    </div>
                }
                open={showModal}
                on=''
                onClose={() => {
                    setShowModal(false);
                }}
                position={takeoffSettings?.measurement_sidebar_location === 'left' ? 'bottom left' : 'bottom right'}
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                arrow={false}
                style={{
                    width: '200px',
                    padding: '0px',
                }}
            >
                <div className="pagessidebar-page-options-container">
                    {measurement.virtual_legend_entry &&
                        <div
                            className="pagessidebar-page-options-section-changecount"
                            id='pagessidebar-page-options-section-movecount'
                            onMouseEnter={() => setShowManageMeasurements(true)}
                            onMouseLeave={() => setShowManageMeasurements(false)}
                        >
                            <div className="pagessidebar-page-options-section-icon">
                                <IconCircleArrowUpRight size={15} stroke={1} />
                            </div>
                            <div className="pagessidebar-page-options-section-text">
                                Move all similar dots
                            </div>
                            <div className="pagessidebar-page-options-section-icon">
                                {takeoffSettings?.measurement_sidebar_location === 'left' ? <IconChevronRight size={15} stroke={1} /> : <IconChevronLeft size={15} stroke={1} />}
                            </div>

                            {showManageMeasurements &&
                                <div
                                    className={
                                        takeoffSettings?.measurement_sidebar_location === 'left'
                                            ? "pagessidebar-page-options-group-options"
                                            : "pagessidebar-page-options-group-options-right"
                                    }
                                >
                                    <div className="pagessidebar-page-options-group-search-container">
                                        <IconSearch size={15} stroke={1} />
                                        <input
                                            type='text'
                                            className='pagessidebar-page-options-group-search-input'
                                            placeholder='Search...'
                                            value={search}
                                            onChange={(e) => setSearch(e.target.value)}
                                        />
                                    </div>

                                    <div className="pagessidebar-page-options-group-options-container">
                                        {measurements && Object.values(measurements).filter(m => m.type === 'count').filter(m => {
                                            if (search) {
                                                return m.name.toLowerCase().includes(search.toLowerCase());
                                            }
                                            return true;
                                        }).filter(m => {
                                            if (!m.group || !m.ai_created || !m.virtual_legend_entry) return false;
                                            return measurement.virtual_legend == m.virtual_legend;
                                        }).map((m, index) =>
                                            <div
                                                key={m.id + 'count' + dot.id}
                                                id={'pagessidebar-page-options-group-option' + m.id + 'count' + dot.id}
                                                className="pagessidebar-page-options-group-option"
                                                onClick={() => {
                                                    if (managingDot) return;
                                                    setManagingDot(true);
                                                    setShowModal(false);

                                                    handleManageDot('move', dot, () => {
                                                        setManagingDot(false);
                                                    }, m.id);
                                                }}
                                                style={{
                                                    backgroundColor: m.id == dot.measurement && 'aliceblue'
                                                }}
                                            >
                                                <div
                                                    className={dot.measurement == m.id
                                                        ? "pagessidebar-page-options-group-option-icon-selected"
                                                        : "pagessidebar-page-options-group-option-icon"
                                                    }
                                                >
                                                    &nbsp;
                                                </div>
                                                <div>{m.name}</div>

                                                <Tooltip className='tooltip' anchorSelect={'#pagessidebar-page-options-group-option' + m.id + 'count' + dot.id} delayShow={1000} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}>
                                                    {m.id == dot.measurement
                                                        ? <>Currently selected</>
                                                        : <>Change count to {m.name}</>}
                                                </Tooltip>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {/*<Tooltip className='tooltip' anchorSelect='#pagessidebar-page-options-section-movecount' delayShow={1000}>
                        Move all dots similar to this dot to a different count
                    </Tooltip>*/}

                    {measurement.virtual_legend_entry &&
                        <div
                            className="pagessidebar-page-options-section-changecount pagessidebar-page-options-section-item"
                            id='pagessidebar-page-options-section-createcount'
                            onClick={() => {
                                if (managingDot) return;
                                setManagingDot(true);
                                setShowModal(false);

                                handleManageDot('change', dot, () => {
                                    setManagingDot(false);
                                });
                            }}
                        >
                            <div className="pagessidebar-page-options-section-icon">
                                <IconTablePlus size={15} stroke={1} />
                            </div>
                            <div>Create new count</div>
                            {managingDot &&
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    radius="10"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            }
                        </div>
                    }

                    <Tooltip className='tooltip' anchorSelect='#pagessidebar-page-options-section-createcount' delayShow={1000}>
                        Create a new count from this dot
                    </Tooltip>

                    {measurement.virtual_legend_entry &&
                        <div
                            className="pagessidebar-page-options-section-delete pagessidebar-page-options-section-deletesimilar"
                            id='pagessidebar-page-options-section-deletealldots'
                            onClick={() => {
                                if (managingDot) return;
                                setManagingDot(true);
                                setShowModal(false);

                                handleManageDot('delete', dot, () => {
                                    setManagingDot(false);
                                });
                            }}
                        >
                            <div className="pagessidebar-page-options-section-icon">
                                <IconTrashX size={15} stroke={1} />
                            </div>
                            <div>Delete similar dots</div>
                            {managingDot &&
                                <Blocks
                                    visible={true}
                                    height="20"
                                    width="20"
                                    radius="10"
                                    color="#006AFE"
                                    ariaLabel="blocks-loading"
                                    wrapperStyle={{}}
                                    wrapperClass="blocks-wrapper"
                                />
                            }
                        </div>
                    }

                    <Tooltip className='tooltip' anchorSelect='#pagessidebar-page-options-section-deletealldots' delayShow={1000}>
                        Delete all dots similar to this dot
                    </Tooltip>

                    <hr></hr>

                    <div
                        className="pagessidebar-page-options-section-changecount pagessidebar-page-options-section-item"
                        onMouseEnter={() => setShowGroupOptions(true)}
                        onMouseLeave={() => setShowGroupOptions(false)}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconCircleArrowUpRight size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Change count
                        </div>
                        <div className="pagessidebar-page-options-section-icon">
                            {takeoffSettings?.measurement_sidebar_location === 'left' ? <IconChevronRight size={15} stroke={1} /> : <IconChevronLeft size={15} stroke={1} />}
                        </div>

                        {showGroupOptions &&
                            <div
                                className={
                                    takeoffSettings?.measurement_sidebar_location === 'left'
                                        ? "pagessidebar-page-options-group-options"
                                        : "pagessidebar-page-options-group-options-right"
                                }
                            >
                                <div className="pagessidebar-page-options-group-search-container">
                                    <IconSearch size={15} stroke={1} />
                                    <input
                                        type='text'
                                        className='pagessidebar-page-options-group-search-input'
                                        placeholder='Search...'
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                    />
                                </div>

                                <div className="pagessidebar-page-options-group-options-container">
                                    {measurements && Object.values(measurements).filter(m => m.type === 'count').filter(m => {
                                        if (search) {
                                            return m.name.toLowerCase().includes(search.toLowerCase());
                                        }
                                        return true;
                                    }).filter(m => m.page === measurement.page).map((m, index) =>
                                        <div
                                            key={m.id + 'count' + dot.id}
                                            id={'pagessidebar-page-options-group-option' + m.id + 'count' + dot.id}
                                            className="pagessidebar-page-options-group-option"
                                            onClick={() => {
                                                changeCount(m.id);
                                                setShowModal(false);
                                            }}
                                            style={{
                                                backgroundColor: m.id == dot.measurement && 'aliceblue'
                                            }}
                                        >
                                            <div
                                                className={dot.measurement == m.id
                                                    ? "pagessidebar-page-options-group-option-icon-selected"
                                                    : "pagessidebar-page-options-group-option-icon"
                                                }
                                            >
                                                &nbsp;
                                            </div>
                                            <div>{m.name}</div>

                                            <Tooltip className='tooltip' anchorSelect={'#pagessidebar-page-options-group-option' + m.id + 'count' + dot.id} delayShow={1000} place={takeoffSettings?.measurement_sidebar_location === 'left' ? 'right' : 'left'}>
                                                {m.id == dot.measurement
                                                    ? <>Currently selected</>
                                                    : <>Change count to {m.name}</>}
                                            </Tooltip>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }
                    </div>

                    <div
                        className="pagessidebar-page-options-section-delete"
                        onClick={(e) => {
                            const newMeasurement = {
                                ...measurement,
                                count_dots: measurement.count_dots.filter(d => d.id !== dot.id),
                            }

                            handleCountTransform(newMeasurement, false, 'delete', dot.id);

                            e.stopPropagation();
                            //e.stopImmediatePropagation();
                            e.preventDefault();
                        }}
                    >
                        <div className="pagessidebar-page-options-section-icon">
                            <IconTrashX size={15} stroke={1} />
                        </div>
                        <div className="pagessidebar-page-options-section-text">
                            Delete
                        </div>
                    </div>
                </div>
            </Popup>

            {hovering &&
                <>
                    <div
                        className="countmeasurement-dot-preview-options-button"
                        onClick={(e) => {
                            setShowModal(true);
                            e.stopPropagation();
                        }}
                    >
                        <IconSettings size={15} stroke={1} />
                    </div>

                    <div className='measurementsidebar-measurement-dot-delete-container'>
                        <div
                            id={'measurementsidebar-measurement-dot-delete' + dot.id}
                            onClick={(e) => {
                                const newMeasurement = {
                                    ...measurement,
                                    count_dots: measurement.count_dots.filter(d => d.id !== dot.id),
                                    count: measurement.count - 1,
                                }

                                handleCountTransform(newMeasurement, false, 'delete', dot.id);

                                e.stopPropagation();
                                //e.stopImmediatePropagation();
                                e.preventDefault();
                            }}
                        >
                            <IconX size={15} stroke={1} style={{ color: 'red' }} />
                        </div>

                        <Tooltip className='tooltip' anchorSelect={'#measurementsidebar-measurement-dot-delete' + dot.id} delayShow={1000}>
                            Delete dot
                        </Tooltip>
                    </div>
                </>
            }
        </div>
    )
}, (prevProps, nextProps) => {
    return prevProps.dot.id === nextProps.dot.id && prevProps.dot.image === nextProps.dot.image
        && prevProps.isDragging === nextProps.isDragging
        && ((prevProps.currentDotID === prevProps.dot.id) === (nextProps.currentDotID === nextProps.dot.id))
        && prevProps.handleClickDot === nextProps.handleClickDot
        && prevProps.takeoffSettings.measurement_sidebar_location === nextProps.takeoffSettings.measurement_sidebar_location
        && prevProps.selectedDots.includes(nextProps.dot.id) === nextProps.selectedDots.includes(nextProps.dot.id)
        && prevProps.measurements === nextProps.measurements
        && prevProps.handleManageDot === nextProps.handleManageDot
        && prevProps.measurements.filter(m => m.type === 'count').map(m => m.name).join('') === nextProps.measurements.filter(m => m.type === 'count').map(m => m.name).join('');
})

const LazyLoadDotPreview = memo(function LazyLoadDotPreview({ dot, currentDotID, selectedDots }) {
    //console.log('rendering dot preview', dot.id)

    return (
        <div
            id={'measurement-dot-image-' + dot.id}
            className='measurementsidebar-measurement-dot-image'
            style={{
                borderColor: dot?.ai_detected
                    ? numberToColorHsl(dot.confidence, 0.35, 1)
                    : currentDotID === dot.id
                        ? '#006AFF'
                        : selectedDots.find((d) => d.id === dot.id)
                            ? '#006AFF'
                            : '',
                zIndex: currentDotID === dot.id ? 1000 : 0,
            }}
        >
            <LazyLoadImage
                src={dot.image} // the source of the image
                alt='dot'
                effect="opacity" // effect used when the image is loading (optional)
                wrapperClassName='measurementsidebar-measurement-dot-image-wrapper'
            />
        </div>
    )
}, (prevProps, nextProps) => {
    return prevProps.dot.id === nextProps.dot.id
        && prevProps.dot.image === nextProps.dot.image
        && ((prevProps.currentDotID === prevProps.dot.id) === (nextProps.currentDotID === nextProps.dot.id))
        && prevProps.selectedDots.includes(nextProps.dot.id) === nextProps.selectedDots.includes(nextProps.dot.id);
})

function DotModal({ dot, measurement, showModal, setShowModal }) {
    const auth = useSelector(selectAuth);

    const {
        measurements, setMeasurements,
        takeoffSettings,
    } = useContext(TakeoffContext);

    const changeCount = (e) => {
        axios({
            'method': 'post',
            'url': `${API_ROUTE}/api/change-dot-count/`,
            'data': {
                'dot': dot.id,
                'count': e.target.value,
                'selectedDots': [dot.id],
            },
            'headers': {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log(res);

                setMeasurements(res.data.measurements);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            dialogClassName={takeoffSettings?.measurement_sidebar_location === 'right' ? 'measurementsidebar-option-modal' : 'measurementsidebar-option-modal-left'}
        >
            <Modal.Header closeButton>
                <div className='measurementsidebar-option-modal-title'>
                    Dot Options
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    <div className='measurementsidebar-option-modal-split-section-dot'>
                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Count name
                            </div>
                            <div>
                                {measurement.name}
                            </div>
                        </div>

                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Dot ID
                            </div>
                            <div>
                                {dot.id}
                            </div>
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Move to different count
                        </div>

                        <div>
                            <select
                                className='measurementsidebar-option-modal-section-input'
                                value={measurement.id}
                                onChange={(e) => changeCount(e)}
                            >
                                {Object.values(measurements).filter(m => m.type === 'count').map((m, index) =>
                                    <option key={index} value={m.id}>{m.name}</option>
                                )}
                            </select>
                        </div>
                    </div>

                    {dot?.confidence &&
                        <div className='measurementsidebar-option-modal-section'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                AI confidence
                            </div>

                            <div>
                                {/*its a decimal, convert to percent and round to 2 decimal points */}
                                {Math.round(dot.confidence * 10000) / 100}%
                            </div>
                        </div>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}

function OptionModal({
    measurement,
    showModal, setShowModal,
    measurementName, setMeasurementName,
}) {
    const auth = useSelector(selectAuth);

    const {
        project,
        pageID,
        pages, setPages,
        groups, setGroups,
        DeleteMeasurement,
        currentMeasurement,
        handleCountTransform,
        groupMeasurement,
        setAddingCount,
        setDeletingCount,
        setCurrentMeasurement,
        handleZoomToMeasurement,
        setMeasurements,
        stopWatch,
        showDeleteMeasurementModal, setShowDeleteMeasurementModal,
        setSelectedMeasurements,
        setEditingMeasurement,
    } = useContext(TakeoffContext);

    const {
        createGroup,
    } = useContext(MeasurementSideBarContext);

    const [showColorPicker, setShowColorPicker] = useState(false);
    const [newGroupName, setNewGroupName] = useState('');

    const [thumbnailZoom, setThumbnailZoom] = useState(measurement?.zoom || 1);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (showModal) {
                if (e.key === 'Enter' && !e.defaultPrevented) {
                    e.preventDefault();

                    document.activeElement.blur();
                    setShowColorPicker(false);
                } else if (e.key === 'Escape') {
                    e.stopPropagation();
                    e.preventDefault();

                    document.activeElement.blur();
                    setShowColorPicker(false);

                    if (showDeleteMeasurementModal) {
                        setShowDeleteMeasurementModal(false);
                    }
                }
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [showModal, showDeleteMeasurementModal]);

    return (
        <Modal
            show={showModal}
            onHide={() => {
                if (showColorPicker) {
                    setShowColorPicker(false);
                } else {
                    setEditingMeasurement(false);
                    setShowModal(false);
                }
            }}
            dialogClassName='measurementsidebar-option-modal'
            onClick={() => setShowColorPicker(false)}
        >
            <Modal.Header closeButton>
                <div className='measurementsidebar-option-modal-title'>
                    Count Options
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='measurementsidebar-option-modal-body'>
                    {measurement.page != pageID &&
                        <div className='measurementsidebar-option-modal-section'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                From different page
                            </div>

                            <div>
                                <DefaultButton
                                    text={'Go to -> ' + pages[measurement.page].title}
                                    handleClick={() => {
                                        setCurrentMeasurement(measurement.id);
                                        window.history.pushState({}, '', `/takeoff/${project.uuid}/${measurement.page}`);
                                        setShowModal(false);
                                        handleZoomToMeasurement(measurement.id);
                                    }}
                                    size={'small'}
                                />
                            </div>
                        </div>
                    }

                    <div className='measurementsidebar-option-modal-split-section'>
                        <div className='measurementsidebar-option-modal-section-row'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Visibility
                            </div>

                            <div className='measurementsidebar-option-color-container'>
                                <DefaultIconButton
                                    handleClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        handleCountTransform({
                                            ...measurement,
                                            hide: !measurement.hide,
                                        });
                                    }}
                                >
                                    {measurement?.hide
                                        ? <IconEyeOff size={15} stroke={1} />
                                        : <IconEye size={15} stroke={1} />
                                    }
                                </DefaultIconButton>
                            </div>
                        </div>

                        <div className='measurementsidebar-option-modal-section-row'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Color
                            </div>

                            {!measurement?.use_group_color &&
                                <ButtonHoverInfo
                                    message={'Use group\'s color.'}
                                    top={20}
                                    left={0}
                                    size={'small'}
                                >
                                    <DefaultIconButton
                                        handleClick={() => {
                                            let colorTemp = groups[measurement.group]?.color ? groups[measurement.group].color : '#9DD9F3';

                                            handleCountTransform({
                                                ...measurement,
                                                use_group_color: true,
                                                color: colorTemp,
                                            });
                                        }}
                                        size={'small'}
                                    >
                                        <IconRestore size={15} stroke={1} />
                                    </DefaultIconButton>
                                </ButtonHoverInfo>
                            }

                            <div
                                style={{
                                    position: 'relative',
                                    zIndex: 100,
                                }}
                            >
                                {showColorPicker
                                    ? <ColorPicker
                                        color={measurement.color}
                                        onChange={(color) => setMeasurements(prev => ({
                                            ...prev,
                                            [measurement.id]: {
                                                ...prev[measurement.id],
                                                color: color.hex,
                                                use_group_color: false,
                                            }
                                        }))}
                                        onChangeComplete={(color) => {
                                            handleCountTransform({
                                                ...measurement,
                                                color: color.hex,
                                                use_group_color: false,
                                            });
                                        }}
                                    />
                                    : null}

                                <ButtonHoverInfo
                                    message={measurement?.use_group_color ? `Currently using group's color. Select seperate color for this measurement.` : 'Select color for this measurement.'}
                                    top={-20}
                                    left={20}
                                    size={'small'}
                                    width={200}
                                    height={20}
                                >
                                    <div
                                        className='measurementsidebar-option-color-placeholder'
                                        style={{
                                            backgroundColor: measurement?.color,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setShowColorPicker(true);
                                        }}
                                    ></div>
                                </ButtonHoverInfo>
                            </div>
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Name
                        </div>

                        <div>
                            <AutoResizeTextInput
                                type='text'
                                className='measurementsidebar-option-input'
                                value={measurementName}
                                onChange={(e) => setMeasurementName(e.target.value)}
                                onBlur={() => handleCountTransform({
                                    ...measurement,
                                    name: measurementName,
                                })}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-header'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Group
                            </div>

                            {measurement?.group ?
                                <div>
                                    <DefaultButton
                                        text={'Ungroup'}
                                        handleClick={() => groupMeasurement({
                                            id: measurement.id,
                                            group: null,
                                        })}
                                        size={'small'}
                                    />
                                </div>
                                : null}
                        </div>

                        {groups && Object.values(groups).length > 0
                            ? <GroupMenu
                                handleClick={(groupID) => groupMeasurement({
                                    id: measurement.id,
                                    group: groupID,
                                })}
                                measurement={measurement}
                            />
                            : <div>
                                <div className='measurementsidebar-option-modal-section-info'>
                                    Create a group to organize measurements
                                </div>

                                <div>
                                    <input
                                        type='text'
                                        className='measurementsidebar-option-input'
                                        placeholder='New group name...'
                                        value={newGroupName}
                                        onChange={(e) => setNewGroupName(e.target.value)}
                                        onBlur={() => {
                                            if (newGroupName) {
                                                createGroup(newGroupName)
                                                setNewGroupName('');
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>

                    <div className='measurementsidebar-option-modal-split-section'>
                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Shape
                            </div>

                            <div>
                                <select
                                    className='measurementsidebar-option-modal-section-input'
                                    value={measurement.shape ? measurement.shape : ''}
                                    onChange={(e) => {
                                        setMeasurements(prev => ({
                                            ...prev,
                                            [measurement.id]: {
                                                ...prev[measurement.id],
                                                shape: e.target.value,
                                            }
                                        }));
                                        handleCountTransform({
                                            ...measurement,
                                            shape: e.target.value,
                                        })
                                    }}
                                >
                                    <option value='circle'>Circle</option>
                                    <option value='square'>Square</option>
                                    <option value='diamond'>Diamond</option>
                                    <option value='triangle'>Triangle</option>
                                    <option value='pentagon'>Pentagon</option>
                                    <option value='hexagon'>Hexagon</option>
                                    <option value='octagon'>Octagon</option>
                                    <option value='star'>Star</option>
                                    <option value='cross'>Cross</option>
                                </select>
                            </div>
                        </div>

                        <div className='measurementsidebar-option-modal-section-item'>
                            <div className='measurementsidebar-option-modal-section-title'>
                                Size
                            </div>

                            <div>
                                <input
                                    type='number'
                                    className='measurementsidebar-option-modal-section-input'
                                    value={measurement.size ? measurement.size : ''}
                                    onChange={(e) => setMeasurements(prev => ({
                                        ...prev,
                                        [measurement.id]: {
                                            ...prev[measurement.id],
                                            size: e.target.value ? parseFloat(e.target.value) : null,
                                        }
                                    }))}
                                    onBlur={() => handleCountTransform(measurement)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Thumbnail zoom
                        </div>

                        <div>
                            <input
                                type='number'
                                className='measurementsidebar-option-modal-section-input'
                                value={thumbnailZoom || ''}
                                onChange={(e) => setThumbnailZoom(e.target.value ? parseFloat(e.target.value) : null)}
                                onBlur={() => handleCountTransform({
                                    ...measurement,
                                    zoom: thumbnailZoom,
                                })}
                            />
                        </div>
                    </div>

                    <div className='measurementsidebar-option-modal-section'>
                        <div className='measurementsidebar-option-modal-section-title'>
                            Edit count
                        </div>

                        <div className='measurementsidebar-option-modal-split-section'>
                            <DefaultButton
                                text={'Add dots'}
                                handleClick={() => {
                                    setCurrentMeasurement(measurement.id);
                                    setAddingCount(true);
                                    setShowModal(false);
                                }}
                            />
                            <DeleteButton
                                text={'Delete dots'}
                                handleClick={() => {
                                    setCurrentMeasurement(measurement.id);
                                    setDeletingCount(true);
                                    setShowModal(false);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer
                style={{
                    padding: '2%',
                }}
            >
                <DeleteButton
                    text={'Delete'}
                    handleClick={() => {
                        setCurrentMeasurement(measurement.id);
                        setSelectedMeasurements([measurement.id]);
                        setShowDeleteMeasurementModal(true);
                    }}
                />

                <DefaultButton
                    text={'Close'}
                    handleClick={() => setShowModal(false)}
                />
            </Modal.Footer>
        </Modal>
    )
}