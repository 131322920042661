import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux";
import axios from "axios";

import {
    IconFolderPlus, IconFilterStar, IconLayoutSidebarRightCollapse, IconLayoutList, IconLayoutGrid,
    IconLayoutSidebar, IconLayoutSidebarRight, IconLayoutSidebarLeftCollapse, IconListNumbers,
    IconCircleChevronLeft, IconCircleChevronRight, IconArrowBarToLeft, IconArrowBarToRight,
    IconStarFilled, IconStar, IconChevronRightPipe, IconChevronLeftPipe, IconLayout, IconSearch, IconLayoutNavbarCollapse, IconRobot, IconCircles, IconTimeline, IconPolygon,
    IconX,
} from "@tabler/icons-react";

import "../styles/PagesSidebar.css";

import { TakeoffContext } from "../helper/Context";
import { selectAuth } from "../../redux/slices/authSlice";

import { API_ROUTE } from "../../index";
import Page from "./pages/Page";
import ButtonHoverInfo from "../../components/ButtonHoverInfo";
import DefaultButton from "../../components/DefaultButton";
import PageNameList from "./pages/PageNameList";
import DefaultIconButton from "../../components/DefaultIconButton";
import PageList from "./pages/PageList";
import PageGrid from "./pages/PageGrid";
import ViewOptions from "./pages/ViewOptions";
import ContextMenu from "../../components/ContextMenu";
import { Tooltip } from "react-tooltip";
import AISidebarCount from "./ai/AISidebarCount";
import AISidebarLength from "./ai/AISidebarLength";
import AISidebarArea from "./ai/AISidebarArea";
import AISidebarOther from "./ai/AISidebarOther";

export default function AISidebar({ sidebarWidth, setSidebarWidth, currentAITab, setCurrentAITab }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        project, setProject,
        handleUpdateProject,
        pageGroups, setPageGroups,
        ungroupedPages, setUngroupedPages,
        keybinds,
        AIAutoCountRect,
        AIAutoMeasureLengthRect,
        AIAutoCountPoly,
        setMeasurements, setGroups,
        setTree,
        hintingSAM, setHintingSAM,
        hintingLength,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        hintingAutoFindDW, setHintingAutoFindDW,
        hintingAutoMeasure, setHintingAutoMeasure,
        hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
        takeoffSettings,
        handleChangeTakeoffSettings,
        AIAutoCountExample, setAIAutoCountExample,
        selectingLegend, setSelectingLegend,
        drawingLegend, setDrawingLegend,
        drawingLegendEntry, setDrawingLegendEntry,
        setTakeoffSettings,
    } = useContext(TakeoffContext);

    const sidebarRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);

    const startResizing = useCallback(() => {
        setIsResizing(true);
    }, []);

    const stopResizing = useCallback((e) => {
        if (isResizing) {
            let temp;

            if (takeoffSettings?.ai_sidebar_location === 'left') {
                temp = Math.min(Math.max(e.clientX - sidebarRef.current.getBoundingClientRect().left, 0.17 * window.innerWidth), 0.30 * window.innerWidth)
            } else {
                temp = Math.min(Math.max(sidebarRef.current.getBoundingClientRect().right - e.clientX, 0.17 * window.innerWidth), 0.30 * window.innerWidth);
            }

            handleChangeTakeoffSettings({
                ...takeoffSettings,
                ai_sidebar_width: temp,
            })
        }

        setIsResizing(false);
    }, [isResizing, takeoffSettings]);

    const resize = useCallback((e) => {
        if (isResizing) {
            let newWidth = sidebarWidth;

            if (takeoffSettings?.ai_sidebar_location === 'left') {
                newWidth = Math.min(Math.max(e.clientX - sidebarRef.current.getBoundingClientRect().left, 0.17 * window.innerWidth), 0.30 * window.innerWidth);
            } else {
                newWidth = Math.min(Math.max(sidebarRef.current.getBoundingClientRect().right - e.clientX, 0.17 * window.innerWidth), 0.30 * window.innerWidth);
            }

            setTakeoffSettings(prev => ({
                ...prev,
                ai_sidebar_width: newWidth,
            }))
        }
    }, [isResizing]);

    const handleReset = () => {
        handleChangeTakeoffSettings({
            ...takeoffSettings,
            ai_sidebar_width: window.innerWidth * 0.20,
        });

        setSidebarWidth('20%');
    }

    useEffect(() => {
        window.addEventListener("mousemove", resize);
        window.addEventListener("mouseup", stopResizing);

        return () => {
            window.removeEventListener("mousemove", resize);
            window.removeEventListener("mouseup", stopResizing);
        };
    }, [resize, stopResizing]);

    const AIActive = useMemo(() => {
        return AIAutoFinding?.[pageID]?.show
            || hintingSAM
            || hintingLength
            || AIAutoCountRect?.[pageID]?.show
            || AIAutoMeasureLengthRect?.[pageID]?.show
            || AIAutoCountPoly?.[pageID]?.show
            || hintingAutoMeasure?.[pageID]?.show
            || hintingAutoMeasurePerim?.[pageID]?.show
            || hintingAutoFindDW?.[pageID]?.show
            || selectingLegend
            || drawingLegend
            || drawingLegendEntry
            || AIAutoCountExample?.[pageID]?.show;
    }, [pageID, AIAutoFinding, hintingSAM, hintingLength, AIAutoCountRect, AIAutoMeasureLengthRect, AIAutoCountPoly, hintingAutoMeasure, hintingAutoMeasurePerim, hintingAutoFindDW, selectingLegend, AIAutoCountExample, drawingLegend, drawingLegendEntry]);

    return (
        <div
            className={takeoffSettings?.ai_sidebar_location === 'left' ? "takeoffsidebar-pages-container" : "takeoffsidebar-container"}
            style={{
                width: sidebarWidth,
                boxShadow: AIActive && "0px 0px 5px 0px #006aef",
                borderLeft: AIActive && takeoffSettings?.ai_sidebar_location === 'right' && "1px solid #006aef",
                borderRight: AIActive && takeoffSettings?.ai_sidebar_location === 'left' && "1px solid #006aef",
            }}
            ref={sidebarRef}
            id="aisidebar"
        >
            <div className={takeoffSettings?.ai_sidebar_location === 'left'
                ? "measurementsidebar-left-resizer"
                : "measurementsidebar-resizer"}
                onMouseDown={startResizing}
                onContextMenu={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsResizing(false);
                    setSidebarWidth('20%');
                    handleReset();
                }}
                onDoubleClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsResizing(false);
                    setSidebarWidth('20%');
                    handleReset();
                }}
            />

            <div className='measurementsidebar-container'>
                <div className='measurementsidebar-top'>
                    <div className='measurementsidebar-header'>
                        <div className='measurementsidebar-header-buttons'>
                            <div
                                className='measurementsidebar-header-button'
                                id='aisidebar-header-button-count'
                                onClick={() => setCurrentAITab('count')}
                                style={currentAITab === 'count' ? { color: '#006aff', backgroundColor: 'aliceblue' } : {}}
                            >
                                <IconCircles size={20} stroke={1} />
                            </div>

                            <Tooltip className='tooltip' anchorSelect='#aisidebar-header-button-count' delayShow={1000} place={takeoffSettings?.ai_sidebar_location === 'left' ? 'bottom-end' : 'bottom-start'}>
                                AI auto count
                            </Tooltip>

                            <div
                                className='measurementsidebar-header-button'
                                id='aisidebar-header-button-length'
                                onClick={() => setCurrentAITab('length')}
                                style={currentAITab === 'length' ? { color: '#006aff', backgroundColor: 'aliceblue' } : {}}
                            >
                                <IconTimeline size={20} stroke={1} />
                            </div>

                            <Tooltip className='tooltip' anchorSelect='#aisidebar-header-button-length' delayShow={1000} place={takeoffSettings?.ai_sidebar_location === 'left' ? 'bottom-end' : 'bottom-start'}>
                                AI auto measure length
                            </Tooltip>

                            <div
                                className='measurementsidebar-header-button'
                                id='aisidebar-header-button-area'
                                onClick={() => setCurrentAITab('area')}
                                style={currentAITab === 'area' ? { color: '#006aff', backgroundColor: 'aliceblue' } : {}}
                            >
                                <IconPolygon size={20} stroke={1} />
                            </div>

                            <Tooltip className='tooltip' anchorSelect='#aisidebar-header-button-area' delayShow={1000} place={takeoffSettings?.ai_sidebar_location === 'left' ? 'bottom-end' : 'bottom-start'}>
                                AI auto measure area
                            </Tooltip>

                            <div
                                className='measurementsidebar-header-button'
                                id='aisidebar-header-button-other'
                                onClick={() => setCurrentAITab('other')}
                                style={currentAITab === 'other' ? { color: '#006aff', backgroundColor: 'aliceblue' } : {}}
                            >
                                Other
                            </div>

                            <Tooltip className='tooltip' anchorSelect='#aisidebar-header-button-other' delayShow={1000} place={takeoffSettings?.ai_sidebar_location === 'left' ? 'bottom-end' : 'bottom-start'}>
                                Other AI functions
                            </Tooltip>
                        </div>

                        <div className='measurementsidebar-header-buttons'>

                            <div
                                className="measurementsidebar-header-button"
                                id="pagessidebar-move-sidebar-button"
                                onClick={() => {
                                    handleChangeTakeoffSettings({
                                        ...takeoffSettings,
                                        show_ai_sidebar: true,
                                        ai_sidebar_location: takeoffSettings?.ai_sidebar_location === 'left' ? 'right' : 'left',
                                        show_pages_sidebar: takeoffSettings?.pages_sidebar_location !== takeoffSettings?.ai_sidebar_location ? false : takeoffSettings?.show_pages_sidebar,
                                        show_measurement_sidebar: takeoffSettings?.measurement_sidebar_location !== takeoffSettings?.ai_sidebar_location ? false : takeoffSettings?.show_measurement_sidebar,
                                    })

                                    document.getElementById('pagessidebar-move-sidebar-button').dispatchEvent(new MouseEvent('mouseleave'));
                                }}
                            >
                                {takeoffSettings?.ai_sidebar_location === 'left'
                                    ? <IconChevronRightPipe size={20} stroke={1} />
                                    : <IconChevronLeftPipe size={20} stroke={1} />
                                }
                            </div>

                            <Tooltip className='tooltip' anchorSelect="#pagessidebar-move-sidebar-button" delayShow={1000} place={takeoffSettings?.ai_sidebar_location === 'left' ? 'bottom-end' : 'bottom-start'}>
                                {takeoffSettings?.ai_sidebar_location === 'left' ? "Move sidebar to the right" : "Move sidebar to the left"}
                            </Tooltip>

                            <div
                                className='measurementsidebar-header-button'
                                id='aisidebar-header-button-close'
                                onClick={() => {
                                    handleChangeTakeoffSettings({
                                        ...takeoffSettings,
                                        show_ai_sidebar: false,
                                    })
                                }}
                            >
                                <IconX size={20} stroke={1} />
                            </div>

                            <Tooltip className='tooltip' anchorSelect='#aisidebar-header-button-close' delayShow={1000} place={takeoffSettings?.ai_sidebar_location === 'left' ? 'bottom-end' : 'bottom-start'}>
                                Close AI sidebar
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div className="aisidebar-body-container">
                {currentAITab === 'count' &&
                    <AISidebarCount />
                }

                {currentAITab === 'length' &&
                    <AISidebarLength />
                }

                {currentAITab === 'area' &&
                    <AISidebarArea />
                }

                {currentAITab === 'other' &&
                    <AISidebarOther />
                }
            </div>
        </div >
    )
}