import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { closestCenter, defaultDropAnimation, DndContext, DragOverlay, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { arrayMove, SortableContext, UseSortableArguments } from '@dnd-kit/sortable';

import { buildTree, findItemDeep, flattenTree, getChildCount, getProjection, removeChildrenOf, removeItem, setProperty } from './utilities';

import { SortableTreeItem } from './SortableTreeItem';
import { customListSortingStrategy } from './SortingStrategy';
import { DatabaseContext } from '../components/Context';

const defaultPointerSensorOptions = {
  activationConstraint: {
    distance: 3,
  },
};

export const dropAnimationDefaultConfig = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ];
  },
  easing: 'ease-out',
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    });
  },
};

export function SortableTree({
  items,
  indicator,
  indentationWidth = 20,
  onItemsChanged,
  TreeItemComponent,
  pointerSensorOptions,
  disableSorting,
  dropAnimation,
  dndContextProps,
  sortableProps,
  keepGhostInPlace,
  canRootHaveChildren,
  ...rest
}) {
  const { entries, groups, handleUpdateField, hoverID, setHoverID, column_settings, draggingID, setDraggingID, selectedIDs } = useContext(DatabaseContext);

  const [activeId, setActiveId] = useState(null);
  const [overId, setOverId] = useState(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(null);

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items);
    const collapsedItems = flattenedTree.reduce((acc, { children, collapsed, id }) => (collapsed && children?.length ? [...acc, id] : acc), []);

    const result = removeChildrenOf(flattenedTree, activeId ? [activeId, ...collapsedItems] : collapsedItems);
    return result;
  }, [activeId, items]);
  const projected = getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth, keepGhostInPlace ?? false, canRootHaveChildren);
  const sensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  });
  // const [coordinateGetter] = useState(() =>
  //   sortableTreeKeyboardCoordinates(sensorContext, indentationWidth)
  // );
  const sensors = useSensors(
    useSensor(PointerSensor, pointerSensorOptions ?? defaultPointerSensorOptions)
    // useSensor(KeyboardSensor, {
    //   coordinateGetter,
    // })
  );

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems]);
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    };
  }, [flattenedItems, offsetLeft]);

  const itemsRef = useRef(items);
  itemsRef.current = items;
  const handleRemove = useCallback(
    (id) => {
      const item = findItemDeep(itemsRef.current, id);
      onItemsChanged(removeItem(itemsRef.current, id), {
        type: 'removed',
        item,
      });
    },
    [onItemsChanged]
  );

  const handleCollapse = useCallback(
    function handleCollapse(id) {
      const item = findItemDeep(itemsRef.current, id);
      onItemsChanged(
        setProperty(itemsRef.current, id, 'collapsed', (value) => {
          return !value;
        }),
        {
          type: item.collapsed ? 'collapsed' : 'expanded',
          item: item,
        }
      );
    },
    [onItemsChanged]
  );

  const announcements = useMemo(
    () => ({
      onDragStart({ active }) {
        return `Picked up ${active.id}.`;
      },
      onDragMove({ active, over }) {
        return getMovementAnnouncement('onDragMove', active.id, over?.id);
      },
      onDragOver({ active, over }) {
        return getMovementAnnouncement('onDragOver', active.id, over?.id);
      },
      onDragEnd({ active, over }) {
        return getMovementAnnouncement('onDragEnd', active.id, over?.id);
      },
      onDragCancel({ active }) {
        return `Moving was cancelled. ${active.id} was dropped in its original position.`;
      },
    }),
    []
  );

  const selectedGroups = useMemo(() => new Set(selectedIDs.filter((id) => id.includes('group'))).size, [selectedIDs]);
  const selectedEntries = useMemo(() => new Set(selectedIDs.filter((id) => id.includes('entry'))).size, [selectedIDs]);

  const strategyCallback = useCallback(() => {
    return !!projected;
  }, [projected]);
  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={disableSorting ? undefined : sensors}
      modifiers={indicator ? modifiersArray : undefined}
      collisionDetection={closestCenter}
      // measuring={measuring}
      onDragStart={disableSorting ? undefined : handleDragStart}
      onDragMove={disableSorting ? undefined : handleDragMove}
      onDragOver={disableSorting ? undefined : handleDragOver}
      onDragEnd={disableSorting ? undefined : handleDragEnd}
      onDragCancel={disableSorting ? undefined : handleDragCancel}
      {...dndContextProps}
    >
      <SortableContext items={sortedIds} strategy={disableSorting ? undefined : customListSortingStrategy(strategyCallback)}>
        {flattenedItems.map((item) => {
          return (
            <SortableTreeItem
              {...rest}
              key={item.id}
              id={item.id}
              item={item}
              childCount={item.children?.length}
              depth={item.id === activeId && projected && !keepGhostInPlace ? projected.depth : item.depth}
              indentationWidth={indentationWidth}
              indicator={indicator}
              //collapsed={Boolean(item.collapsed && item.children?.length)}
              collapsed={item.collapsed}
              //onCollapse={item.children?.length ? handleCollapse : undefined}
              onCollapse={handleCollapse}
              onRemove={handleRemove}
              isLast={item.id === activeId && projected ? projected.isLast : item.isLast}
              parent={item.id === activeId && projected ? projected.parent : item.parent}
              TreeItemComponent={TreeItemComponent}
              disableSorting={disableSorting}
              sortableProps={sortableProps}
              keepGhostInPlace={keepGhostInPlace}
            />
          );
        })}
        {createPortal(
          <DragOverlay dropAnimation={dropAnimation === undefined ? dropAnimationDefaultConfig : dropAnimation}>
            <div className='database-drag-overlay'>
              {selectedIDs.includes(activeId) && new Set(selectedIDs).size > 1 ? (
                <>
                  Dragging{' '}
                  {selectedGroups ? (selectedEntries ? `${selectedGroups} groups and ${selectedEntries} entries` : `${selectedGroups} groups`) : selectedEntries ? `${selectedEntries} entries` : null}
                </>
              ) : (
                <>Dragging {activeId?.includes('entry') ? entries[activeId.split('-')[1]]?.name : groups[activeId?.split('-')[1]]?.name}</>
              )}
            </div>
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
    </DndContext>
  );

  function handleDragStart({ active: { id: activeId } }) {
    setActiveId(activeId);
    setOverId(activeId);
    setDraggingID(activeId);

    const activeItem = flattenedItems.find(({ id }) => id === activeId);

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      });
    }

    document.body.style.setProperty('cursor', 'grabbing');
  }

  function handleDragMove({ delta }) {
    setOffsetLeft(delta.x);
  }

  function handleDragOver({ over }) {
    setOverId(over?.id ?? null);
  }

  function handleDragEnd({ active, over }) {
    setDraggingID(null);
    resetState();

    if (projected && over) {
      const { depth, parentId } = projected;
      if (keepGhostInPlace && over.id === active.id) return;
      const clonedItems = flattenTree(items);
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id);
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id);
      const activeTreeItem = clonedItems[activeIndex];
      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };
      const draggedFromParent = activeTreeItem.parent;
      let sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      if (selectedIDs.includes(active.id)) {
        for (const selectedID of selectedIDs
          .filter((id) => !id.includes('assembly'))
          .filter((id) => {
            let parent = id.includes('entry') ? entries[id.split('-')[1]].group : groups[id.split('-')[1]].group;

            while (parent) {
              if (id !== 'group-' + String(parent) && selectedIDs.includes('group-' + String(parent))) {
                return false;
              }

              parent = groups[parent].group;
            }

            return true;
          })
          .filter((id) => id !== active.id)) {
          const selectedActiveIndex = sortedItems.findIndex(({ id }) => id === selectedID);
          const selectedActiveTreeItem = sortedItems[selectedActiveIndex];
          sortedItems[selectedActiveIndex] = { ...selectedActiveTreeItem, depth, parentId };
          sortedItems = arrayMove(sortedItems, selectedActiveIndex, overIndex);
        }
      }

      const newItems = buildTree(sortedItems);
      const newActiveItem = sortedItems.find((x) => x.id === active.id);
      const currentParent = newActiveItem.parentId ? sortedItems.find((x) => x.id === newActiveItem.parentId) : null;
      // removing setTimeout leads to an unwanted scrolling
      // Use case:
      //   There are a lot of items in a tree (so that the scroll exists).
      //   You take the node from the bottom and move it to the top
      //   Without `setTimeout` when you drop the node the list gets scrolled to the bottom.
      setTimeout(() =>
        onItemsChanged(newItems, {
          type: 'dropped',
          draggedItem: newActiveItem,
          draggedFromParent: draggedFromParent,
          droppedToParent: currentParent,
        })
      );
    }
  }

  function handleDragCancel() {
    resetState();
  }

  function resetState() {
    setOverId(null);
    setActiveId(null);
    setOffsetLeft(0);
    setCurrentPosition(null);

    document.body.style.setProperty('cursor', '');
  }

  function getMovementAnnouncement(eventName, activeId, overId) {
    if (overId && projected) {
      if (eventName !== 'onDragEnd') {
        if (currentPosition && projected.parentId === currentPosition.parentId && overId === currentPosition.overId) {
          return;
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          });
        }
      }

      const clonedItems = flattenTree(items);
      const overIndex = clonedItems.findIndex(({ id }) => id === overId);
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId);
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);

      const previousItem = sortedItems[overIndex - 1];

      let announcement;
      const movedVerb = eventName === 'onDragEnd' ? 'dropped' : 'moved';
      const nestedVerb = eventName === 'onDragEnd' ? 'dropped' : 'nested';

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1];
        announcement = `${activeId} was ${movedVerb} before ${nextItem.id}.`;
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`;
        } else {
          let previousSibling = previousItem;
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId = previousSibling.parentId;
            previousSibling = sortedItems.find(({ id }) => id === parentId);
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`;
          }
        }
      }

      return announcement;
    }

    return;
  }
}

const adjustTranslate = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  };
};
const modifiersArray = [adjustTranslate];
