import { lensPath, compose } from 'ramda';
// lenses for an individual node's attributes
const nodeNameLens = lensPath(['name']);
const nodeDescriptionLens = lensPath(['description']);
const nodePageTitleLens = lensPath(['page_title']);
const nodeIDLens = lensPath(['id']);
const nodeChildrenLens = lensPath(['children']);
const nodeTypeLens = lensPath(['type']);
const nodeCountLens = lensPath(['count']);
const nodeLengthLens = lensPath(['length']);
const nodePageScaleLens = lensPath(['page_scale']);
const nodePitchLens = lensPath(['pitch']);
const nodeHeightLens = lensPath(['height']);
const nodeAreaLens = lensPath(['area']);
const nodeDepthLens = lensPath(['depth']);
const nodeLaborHoursLens = lensPath(['labor_hours']);
const nodeLaborCostLens = lensPath(['labor_cost']);
const nodeUnitAmountLens = lensPath(['unit_amount']);
const nodeUnitCostLens = lensPath(['unit_cost']);
const nodeMarkupLens = lensPath(['markup']);
const nodeTotalLens = lensPath(['total']);
const nodeColorLens = lensPath(['color']);
const nodeUseGroupColorLens = lensPath(['use_group_color']);
const nodeGroupLens = lensPath(['group']);
const nodeIndexLens = lensPath(['index']);
const nodeUOMLens = lensPath(['uom']);
const nodeQuantity1Lens = lensPath(['quantity1']);
const nodeQuantity2Lens = lensPath(['quantity2']);

// get lenses for an individual node's attributes using the nodeID
const getTreeNodeLens = (nodeID) => lensPath([nodeID]);
const getTreeNodeNameLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeNameLens);
const getTreeNodeDescriptionLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeDescriptionLens);
const getTreeNodePageTitleLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodePageTitleLens);
const getTreeNodeChildrenLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeChildrenLens);
const getTreeNodeTypeLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeTypeLens);
const getTreeNodeCountLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeCountLens);
const getTreeNodeLengthLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeLengthLens);
const getTreeNodePageScaleLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodePageScaleLens);
const getTreeNodePitchLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodePitchLens);
const getTreeNodeHeightLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeHeightLens);
const getTreeNodeAreaLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeAreaLens);
const getTreeNodeDepthLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeDepthLens);
const getTreeNodeLaborHoursLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeLaborHoursLens);
const getTreeNodeLaborCostLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeLaborCostLens);
const getTreeNodeUnitAmountLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeUnitAmountLens);
const getTreeNodeUnitCostLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeUnitCostLens);
const getTreeNodeMarkupLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeMarkupLens);
const getTreeNodeTotalLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeTotalLens);
const getTreeNodeColorLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeColorLens);
const getTreeNodeUseGroupColorLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeUseGroupColorLens);
const getTreeNodeGroupLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeGroupLens);
const getTreeNodeIndexLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeIndexLens);
const getTreeNodeUOMLens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeUOMLens);
const getTreeNodeQuantity1Lens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeQuantity1Lens);
const getTreeNodeQuantity2Lens = (nodeID) => compose(getTreeNodeLens(nodeID), nodeQuantity2Lens);

//event related lenses
const eventValueLens = lensPath(['target', 'value']);

export {
  eventValueLens,
  getTreeNodeAreaLens,
  getTreeNodeChildrenLens,
  getTreeNodeColorLens,
  getTreeNodeCountLens,
  getTreeNodeDepthLens,
  getTreeNodeDescriptionLens,
  getTreeNodeGroupLens,
  getTreeNodeHeightLens,
  getTreeNodeIndexLens,
  getTreeNodeLaborCostLens,
  getTreeNodeLaborHoursLens,
  getTreeNodeLengthLens,
  getTreeNodeLens,
  getTreeNodeMarkupLens,
  getTreeNodeNameLens,
  getTreeNodePageScaleLens,
  getTreeNodePageTitleLens,
  getTreeNodePitchLens,
  getTreeNodeQuantity1Lens,
  getTreeNodeQuantity2Lens,
  getTreeNodeTotalLens,
  getTreeNodeTypeLens,
  getTreeNodeUOMLens,
  getTreeNodeUnitAmountLens,
  getTreeNodeUnitCostLens,
  getTreeNodeUseGroupColorLens,
  nodeAreaLens,
  nodeChildrenLens,
  nodeColorLens,
  nodeCountLens,
  nodeDepthLens,
  nodeGroupLens,
  nodeHeightLens,
  nodeIDLens,
  nodeIndexLens,
  nodeLaborCostLens,
  nodeLaborHoursLens,
  nodeLengthLens,
  nodeMarkupLens,
  nodeNameLens,
  nodePageScaleLens,
  nodePitchLens,
  nodeTotalLens,
  nodeTypeLens,
  nodeUnitAmountLens,
  nodeUnitCostLens,
};
