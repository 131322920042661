import { useContext } from 'react';
import '../styles/ToolGuide.css';
import { TakeoffContext } from '../helper/Context';

import Icon from '@mdi/react';
import { mdiMouseLeftClickOutline, mdiMouseRightClickOutline } from '@mdi/js';

export default function ToolGuide() {
    const {
        pageID,
        pages, setPages,
        setPageGroups, setUngroupedPages,
        hintingSAM, setHintingSAM,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        drawingCount, drawingLine, drawingPolygon, drawingRectangle, drawingCircle,
        drawingScale,
        autoCountReference, setAutoCountReference,
        autoCountLocation, setAutoCountLocation,
        AIAutoCountRect,
        AIAutoCountPoly,
        AIAutoMeasureLengthRect,
        hintingAutoMeasure,
        hintingAutoMeasurePerim,
        hintingAutoFindDW,
        selectingLegend, setSelectingLegend,
        drawingLegend, setDrawingLegend,
        drawingLegendEntry, setDrawingLegendEntry,
        AIAutoCountExample,
        erasingCount,
        addingCount, setAddingCount,
        deletingCount, setDeletingCount,
        cuttingPolygonRect, setCuttingPolygonRect,
        cuttingRectangleRect, setCuttingRectangleRect,
        cuttingPolygon, setCuttingPolygon,
        cuttingRectangle, setCuttingRectangle,
        splittingPolygon, setSplittingPolygon,
        splittingRectangle, setSplittingRectangle,
        drawingAnnotationText,
        drawingAnnotationArrow,
        drawingAnnotationRectangle,
    } = useContext(TakeoffContext);

    return (
        <>
            {drawingScale && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Draw scale: </b>Measure a length on the drawing to set the scale.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit scale drawing
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingRectangle && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Draw rectangle: </b>Draw a rectangle on the page.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit drawing
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {erasingCount && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Erase count: </b>Draw a rectangle to delete.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit erasing
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {addingCount && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Add count: </b>add more dots to a count measurement.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to add a dot
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            &nbsp;or&nbsp; 
                            
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ENTER
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {deletingCount && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Delete count: </b>remove dots from a count measurement.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click on a dot to delete
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            &nbsp;or&nbsp;

                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ENTER
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {splittingRectangle && (
                <div className="takeoff-toolguide-drawscale">
                    <div><b>Split rectangle: </b>Draw a line to split with.</div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & place points.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ENTER
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Split
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {splittingPolygon && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Split polygon: </b>Draw a line to split with.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & place points.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ENTER
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Split
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {cuttingPolygon && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Cut polygon: </b>Draw negative polygon to cut.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Place corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ESC
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Exit cutting
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    TAB
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Redo corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    DEL
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Undo corner
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ENTER
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Create
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Click origin to create
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {cuttingRectangle && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Cut rectangle: </b>Draw negative polygon to cut.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Place corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ESC
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Exit cutting
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    TAB
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Redo corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    DEL
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Undo corner
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ENTER
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Create
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Click origin to create
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {cuttingPolygonRect && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Cut polygon (with rectangle): </b>Draw a rectangle to cut.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit cutting
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {cuttingRectangleRect && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Cut rectangle (with rectangle): </b>Draw a rectangle to cut.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit cutting
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingCircle && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Draw circle: </b>Draw a circle on the page.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit drawing
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingCount && (
                <div className="takeoff-toolguide-drawingpolygon">
                    <div className='takeoff-toolguide-hint-sam-container'>
                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Place corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ESC
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Exit drawing
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    TAB
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Redo dot
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    DEL
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Undo dot
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ENTER
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Create
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingPolygon && (
                <div className="takeoff-toolguide-drawingpolygon">
                    <div className='takeoff-toolguide-hint-sam-container'>
                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Place corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ESC
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Exit drawing
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    TAB
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Redo corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    DEL
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Undo corner
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Double click to create
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Click origin to create
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ENTER
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Create
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingLine && (
                <div className="takeoff-toolguide-drawingpolygon">
                    <div className='takeoff-toolguide-hint-sam-container'>
                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Place corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ESC
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Exit drawing
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    TAB
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Redo corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    DEL
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Undo corner
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ENTER
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Create length
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Double click to create
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {AIAutoFinding?.[pageID]?.show && (
                <div className="takeoff-toolguide-auto-find">
                    <div className="takeoff-toolguide-hint-sam-item">
                        <div className="takeoff-toolguide-auto-find-key">
                            <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                        </div>

                        <div className='takeoff-toolguide-hint-sam-item-description'>
                            Click a symbol to find
                        </div>
                    </div>

                    <div className="takeoff-toolguide-hint-sam-item">
                        <div className='takeoff-toolguide-hint-sam-item-title'>
                            ESC
                        </div>

                        <div className='takeoff-toolguide-hint-sam-item-description'>
                            Exit auto find
                        </div>
                    </div>
                </div>
            )}

            {AIAutoCountExample?.[pageID]?.show && (!AIAutoCountRect?.[pageID]?.show && !AIAutoCountPoly?.[pageID]?.show) && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <b>Select example:</b> Choose a reference
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to select
                            </div>
                        </div>
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit run
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {AIAutoCountRect?.[pageID]?.show && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <b>Draw a rectangle</b> around the region to count.
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit run
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {AIAutoMeasureLengthRect?.[pageID]?.show && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <b>Draw a rectangle</b> around the region to measure.
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit run
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {AIAutoCountPoly?.[pageID]?.show && (
                <div className="takeoff-toolguide-autocount-poly">
                    <div>
                        <b>Draw a polygon</b> around the region to count.
                    </div>

                    <div className='takeoff-toolguide-hint-sam-container'>
                        <div className="takeoff-toolguide-hint-sam-group">

                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Place corner
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ESC
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Exit drawing
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ENTER
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Create
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    DEL
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Undo corner
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Double click to create
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Click origin to create
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {hintingSAM && (
                <div className="takeoff-toolguide-hint-sam">
                    <div className="takeoff-toolguide-hint-sam-container">
                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Positive hint
                                </div>
                            </div>
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    <Icon className='takeoff-toolguide-rightclick' path={mdiMouseRightClickOutline} size={0.5} color={"white"} />
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Negative hint
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    DEL
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Undo hint
                                </div>
                            </div>

                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    TAB
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Redo hint
                                </div>
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-group">
                            <div className="takeoff-toolguide-hint-sam-item">
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ESC
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Exit hinting
                                </div>
                            </div>
                            <div className='takeoff-toolguide-hint-sam-item'>
                                <div className='takeoff-toolguide-hint-sam-item-title'>
                                    ENTER
                                </div>

                                <div className='takeoff-toolguide-hint-sam-item-description'>
                                    Create polygon
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selectingLegend && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Populate legend: </b>Click on a legend to populate with.</div>
                    </div>
                    <div className="takeoff-toolguide-selecting-legend-body">
                        <div>Or draw a rectangle with</div>
                        <div
                            className='takeoff-toolguide-selecting-legend-button'
                            onClick={() => {
                                setSelectingLegend(false);
                                setDrawingLegend(true);
                            }}
                        >
                            Populate manually
                        </div>
                    </div>
                </div>
            )}

            {drawingLegend && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Populate legend: </b>Draw rectangular crop around legend.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit auto find
                            </div>
                        </div>
                    </div>
                </div>

            )}

            {drawingLegendEntry && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Add legend entry: </b>Draw rectangle or click symbol</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit
                            </div>
                        </div>
                    </div>
                </div>

            )}

            {hintingAutoMeasure?.[pageID]?.show && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Auto measure room area: </b>Draw rectangular region to measure.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit auto find
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {hintingAutoMeasurePerim?.[pageID]?.show && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Auto measure room perim: </b>Draw rectangular region to measure.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit auto measure
                            </div>
                        </div>
                    </div>
                </div>

            )}

            {hintingAutoFindDW?.[pageID]?.show && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Auto find doors & windows: </b>Draw rectangular region to count.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit auto find
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingAnnotationArrow && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Draw arrow: </b>Draw an annotation arrow on the page.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit drawing
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingAnnotationRectangle && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Draw rectangle: </b>Draw an annotation rectangle on the page.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit drawing
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {drawingAnnotationText && (
                <div className="takeoff-toolguide-drawscale">
                    <div>
                        <div><b>Draw text: </b>Draw an annotation text on the page.</div>
                    </div>
                    <div className="takeoff-toolguide-drawscale-body">
                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className="takeoff-toolguide-auto-find-key">
                                <Icon className='takeoff-toolguide-rightclick' path={mdiMouseLeftClickOutline} size={0.5} color={'white'} />
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Click to start & click again to finish.
                            </div>
                        </div>

                        <div className="takeoff-toolguide-hint-sam-item">
                            <div className='takeoff-toolguide-hint-sam-item-title'>
                                ESC
                            </div>

                            <div className='takeoff-toolguide-hint-sam-item-description'>
                                Exit drawing
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}