import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { renderToStaticMarkup } from 'react-dom/server';
import generatePDF, { Options } from "react-to-pdf";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import axios from "axios";

import { API_ROUTE } from "../..";
import { selectAuth } from "../../redux/slices/authSlice";
import EstimatePDFTemplate from "./EstimatePDFTemplate";

import '../styles/EstimatePDF.css';

export default function EstimatePDF({ }) {
    const { projectUUID } = useParams();
    const auth = useSelector(selectAuth);

    const pdfRef = useRef();

    const [topLevelIDs, setTopLevelIDs] = useState([]);
    const [treeData, setTreeData] = useState({});
    const [project, setProject] = useState({});
    const [company, setCompany] = useState({});
    const [contractor, setContractor] = useState({});

    useEffect(() => {
        axios({
            method: 'get',
            url: `${API_ROUTE}/api/estimate-table/${projectUUID}/`,
            headers: {
                Authorization: `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                userID: auth.user.id,
            },
        })
            .then((res) => {
                console.log(res);

                setTopLevelIDs(res.data.top_level_ids);
                setTreeData(res.data.tree_data);
                setCompany(res.data.company);
                setProject(res.data.project);
                setContractor(res.data.contractor);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [projectUUID]);

    const getTargetElement = () => document.getElementById('estimatepdf-preview-container-id');

    /*
    const renderPDF = () => {
        const ContractFile = renderToStaticMarkup(
            <EstimatePDFTemplate
                topLevelIDs={topLevelIDs}
                treeData={treeData}
                project={project}
                company={company}
                contractor={contractor}
            />
        )

        html2canvas(getTargetElement()).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addhtml(imgData, 'PNG', 0, 0);
            pdf.save('Estimate.pdf');
        })

        const doc = new jsPDF();

        doc.addHTML(ContractFile, {
            callback: function (doc) {
                doc.save('Estimate.pdf');
            },
            x: 10,
            y: 10,
            width: 200,
            windowWidth: 1000,
            margin: [10, 0, 10, 0],
            autoPaging: 'text',
        });
       
        /*generatePDF(getTargetElement, {
            filename: 'Estimate.pdf',
            page: {
                margin: 10,
            }
        })
    }*/


    const renderPDF = async () => {
        const ContractFile = renderToStaticMarkup(
            <EstimatePDFTemplate
                topLevelIDs={topLevelIDs}
                treeData={treeData}
                project={project}
                company={company}
                contractor={contractor}
            />
        )

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/render-estimate-pdf/`,
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json',
            },
            data: {
                'project_id': project.id,
                'markdown': ContractFile,
            }
        })
            .then((response) => {
                console.log(response);

                window.open(response.data.estimate_file, '_blank');
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="estimatepdf-container">
            <div className="estimatepdf-sidebar">
                <div className="estimatepdf-sidebar-title">PDF preview</div>

                <button className="active-button" onClick={renderPDF}>Download PDF</button>
            </div>

            <div
                ref={pdfRef}
                className="estimatepdf-preview-container"
                id="estimatepdf-preview-container-id"
            >
                <EstimatePDFTemplate
                    topLevelIDs={topLevelIDs}
                    treeData={treeData}
                    project={project}
                    company={company}
                    contractor={contractor}
                />
            </div>
        </div>
    )
}