import { IconEdit } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import Popup from "reactjs-popup";
import { AssemblyEntryFields, GroupFields, InputCalculated, InputEditable, InputTypes } from "./Utils";
import TextField from "../../components/TextField";
import NumericField from "../../estimate2/components/NumericField";
import { Tooltip } from "react-tooltip";

export default function GroupEdit({ selectedIDs, columns, handleUpdateAllValues }) {
    const [showGroupEditModal, setShowGroupEditModal] = useState(false);
    const [currentField, setCurrentField] = useState(null);
    const [value, setValue] = useState(null);

    const viableColumns = useMemo(() => {
        if (!columns) {
            return [];
        }

        return Object.keys(columns)
            .filter(columnID => columns[columnID] && InputEditable[columnID])
            .filter(columnID => {
                if (selectedIDs.find((a) => a.includes('group'))) {
                    return GroupFields[columnID];
                } else if (selectedIDs.find((a) => a.includes('entry-'))) {
                    return true
                } else if (selectedIDs.find((a) => a.includes('assembly'))) {
                    return AssemblyEntryFields[columnID];
                }
            });
    }, [columns, selectedIDs]);

    return (
        <>
            <Tooltip anchorSelect="#database-navbar-group-edit" place="top" delayShow={1000} positionStrategy="fixed" style={{ zIndex: 1000 }}>
                Edit a field for all selected rows
            </Tooltip>

            <Popup
                trigger={open => (
                    <div
                        id="database-navbar-group-edit"
                        className={"database-navbar-item " + (new Set(selectedIDs).size <= 1 ? 'database-navbar-item-disabled' : '')}
                        onClick={() => {
                            if (new Set(selectedIDs).size > 1) {
                                setShowGroupEditModal(true);
                            }
                        }}
                    >
                        <IconEdit size={20} />
                    </div>
                )}
                on=''
                open={showGroupEditModal}
                onClose={() => setShowGroupEditModal(false)}
                position="bottom center"
                closeOnDocumentClick
                mouseLeaveDelay={300}
                mouseEnterDelay={0}
                contentStyle={{
                    width: '500px',
                }}
            >
                <div className="database-group-edit-modal-body">
                    <div className="database-group-edit-modal-body-columns">
                        {viableColumns?.map(columnID =>
                            <div
                                key={columnID}
                                className={"database-group-edit-modal-body-column " + (currentField === columnID ? 'database-group-edit-modal-body-column-selected' : '')}
                                onClick={() => {
                                    setCurrentField(columnID);
                                    setValue(null);
                                }}
                            >
                                {columnID.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                            </div>
                        )}
                    </div>

                    <div className="database-group-edit-modal-body-content">
                        <div className="database-group-edit-modal-content">
                            <div className="database-group-edit-modal-header">
                                Edit a field for all selected rows
                            </div>

                            {currentField &&
                                (InputTypes[currentField] === 'text'
                                    ? <TextField
                                        className={"database-group-edit-modal-field"}
                                        value={value}
                                        onBlur={(value) => setValue(value)}
                                        placeholder={currentField.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}
                                    />
                                    : <NumericField
                                        className={"database-group-edit-modal-field"}
                                        value={value}
                                        onBlur={(value) => setValue(value)}
                                        placeholder={0.00}
                                    />
                                )}

                            {currentField &&
                                <div className="database-group-edit-modal-buttons">
                                    <button
                                        className={"default-button"}
                                        onClick={() => {
                                            handleUpdateAllValues(currentField, value);
                                        }}
                                    >
                                        Save
                                    </button>

                                    {InputCalculated[currentField] &&
                                        <button
                                            className={"default-button"}
                                            onClick={() => {
                                                setValue(null);
                                                handleUpdateAllValues(currentField, null);
                                            }}
                                        >
                                            Revert
                                        </button>
                                    }
                                </div>
                            }
                        </div>

                        <div className="database-group-edit-modal-footer">
                            <div className="database-group-edit-modal-desc">
                                {new Set(selectedIDs.filter((a) => a.includes('group'))).size} group{new Set(selectedIDs.filter((a) => a.includes('group'))).size === 1 ? '' : 's'},&nbsp;
                                {new Set(selectedIDs.filter((a) => a.includes('entry-'))).size} item{new Set(selectedIDs.filter((a) => a.includes('entry-'))).size === 1 ? '' : 's'},<br />
                                {new Set(selectedIDs.filter((a) => a.includes('assembly'))).size} assembly item{new Set(selectedIDs.filter((a) => a.includes('assembly'))).size === 1 ? '' : 's'}
                            </div>

                            <button
                                className="default-button"
                                onClick={() => setShowGroupEditModal(false)}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    )
}