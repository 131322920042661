import { useSelector } from 'react-redux';
import { useContext, useEffect, useRef, useState } from 'react';
import { Blocks } from 'react-loader-spinner';
import axios from 'axios';

import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE } from '../../..';
import { selectAuth } from '../../../redux/slices/authSlice';
import { TakeoffContext } from '../../helper/Context';

import '../../styles/AIPopup.css';

import DefaultButton from '../../../components/DefaultButton';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { IconRobot } from '@tabler/icons-react';
import { Modal } from 'react-bootstrap';

export default function AISidebarOther({ }) {
    const auth = useSelector(selectAuth);

    const {
        pageID,
        pages, setPages,
        setMeasurements, setGroups,
        setTree,
        setPageGroups, setUngroupedPages,
        hintingSAM, setHintingSAM,
        AIAutoClassifying, setAIAutoClassifying,
        AILocalization, setAILocalization,
        AIAutoFinding, setAIAutoFinding,
        currentMeasurement, setCurrentMeasurement,
        hintingAutoFindDW, setHintingAutoFindDW,
        hintingAutoMeasure, setHintingAutoMeasure,
        hintingAutoMeasurePerim, setHintingAutoMeasurePerim,
        AIMessages, setAIMessages,
        setAIAutoCountRect,
        setAIAutoCountPoly,
        autoNameAllPages, setAutoNameAllPages,
        showLegendsPopup, setShowLegendsPopup,
        AILegends,
        autoCountReference, setAutoCountReference,
        autoCountLocation, setAutoCountLocation,
        AIAutoCountExample, setAIAutoCountExample,
    } = useContext(TakeoffContext);

    const handleAutoName = () => {
        /*let newID = uuidv4();
        let newMessage = (`Auto naming page ${pages[pageID].title}`).length > 50 ? (`Auto naming page ${pages[pageID].title}`).substring(0, 50) + '...' : (`Auto naming page ${pages[pageID].title}`);

        toast.loading(newMessage, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: false,
            theme: "light",
            toastId: newID,
        });

        console.log(toast.isActive(newID));

        setAIMessages(prev => ({
            ...prev,
            [pageID]: {
                ...prev[pageID],
                autoNamePage: {
                    'progress': 1 / 100,
                    'show': true,
                    'running': true,
                    'id': newID,
                }
            }
        }));*/

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/name-page/`,
            data: {
                'page': pageID,
                //'id': newID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);

                /*if (toast.isActive(response.data.id)) {
                    toast.done(response.data.id);
                } else {
                    toast.success(`Auto named page ${response.data.generated_text}`, {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: false,
                        theme: "light",
                    });
                }

                console.log(response.data.page);
                setAIMessages(prev => ({
                    ...prev,
                    [response.data.page]: {
                        ...prev[response.data.page],
                        'autoNamePage': {
                            ...prev[response.data.page].autoNamePage,
                            'progress': 1,
                            'show': false,
                            'running': false,
                        }
                    }
                }));

                setPages(prev => ({
                    ...prev,
                    [response.data.page]: {
                        ...prev[response.data.page],
                        title: response.data.generated_text,
                    }
                }));*/
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoNameAll = () => {
        setAutoNameAllPages(true);

        axios({
            method: 'post',
            url: `${API_ROUTE}/api/name-pages/`,
            data: {
                'project': pages[pageID].project,
                'page': pageID,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleAutoFindDW = () => {
        axios({
            method: 'post',
            url: `${API_ROUTE}/api/auto-find-door-window/`,
            data: {
                'page': pageID,
                'contractor': auth.contractor.id,
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className='aisidebar-length-container'>
            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Auto name
                    </div>
                </div>
                <div className='navbar-aipopup-group-body'>
                    {autoNameAllPages
                        ? <div className="navbar-aipopup-group-item">
                            All pages
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : <div
                            id='navbar-aipopup-item-auto-name-all'
                            className="navbar-aipopup-group-item"
                            onClick={() => handleAutoNameAll()}
                        >
                            All pages
                        </div>
                    }
                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-name-all'>
                        Auto name all pages included in this takeoff
                    </Tooltip>

                    {/*AIMessages?.[pageID]?.autoNamePage?.running
                        ? <div className="navbar-aipopup-group-item">
                            This page
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : <div
                            className='navbar-aipopup-group-item'
                            id='navbar-aipopup-item-auto-name'
                            onClick={() => handleAutoName()}
                        >
                            This page
                        </div>
                    */}
                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-name'>
                        Auto name this page
                    </Tooltip>
                </div>
            </div>

            <hr className='navbar-aipopup-hr'></hr>

            <div className='navbar-aipopup-group'>
                <div className='navbar-aipopup-group-header'>
                    <div className='navbar-aipopup-group-title'>
                        Doors & windows
                    </div>
                </div>
                <div className='navbar-aipopup-group-body'>
                    {AIMessages?.[pageID]?.autoFindDW?.running
                        ? <div className="navbar-aipopup-group-item">
                            Automatic
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : <>
                            <div
                                id='navbar-aipopup-item-auto-find-dw'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    handleTurnOffDrawing();
                                    setAIMessages(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            ...prev[pageID],
                                            'autoFindDW': {
                                                ...prev[pageID].autoFindDW,
                                                'progress': 1 / 100,
                                                'show': true,
                                                'running': true,
                                            }
                                        }
                                    }));

                                    handleAutoFindDW();
                                }}
                            >
                                Automatic
                            </div>

                            <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-find-dw'>
                                Automatically find all doors and windows
                            </Tooltip>
                        </>
                    }

                    {AIMessages?.[pageID]?.autoFindDWCrop?.running
                        ? <div className="navbar-aipopup-group-item">
                            Crop
                            <Blocks
                                visible={true}
                                height="20"
                                width="20"
                                color="#006AFE"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                            />
                        </div>
                        : hintingAutoFindDW?.[pageID]?.show
                            ? <div
                                id='navbar-aipopup-item-auto-find-dw-hints-active'
                                className="navbar-aipopup-group-item-active"
                                onClick={() => {
                                    handleTurnOffDrawing();
                                }}
                            >
                                Crop
                            </div>
                            : <div
                                id='navbar-aipopup-item-auto-find-dw-hints'
                                className="navbar-aipopup-group-item"
                                onClick={() => {
                                    handleTurnOffDrawing();
                                    setHintingAutoFindDW(prev => ({
                                        ...prev,
                                        [pageID]: {
                                            start: null,
                                            end: null,
                                            show: true,
                                        }
                                    }))
                                }}
                            >
                                Crop
                            </div>
                    }

                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-find-dw-hints-active'>
                        <div>Draw a rectangle to auto find doors and windows</div>
                        <div>Click to escape</div>
                    </Tooltip>

                    <Tooltip delayShow={1000} anchorSelect='#navbar-aipopup-item-auto-find-dw-hints'>
                        Draw a rectangle to auto find doors and windows
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}