import { Arrow, Circle, Group, Rect, Text, Transformer } from "react-konva";
import { useContext, useEffect, useRef, useState } from "react";

import { TakeoffContext } from "../../helper/Context";
import { Html, Portal } from "react-konva-utils";

export default function AnnotationArrow({ annotation }) {
    const {
        annotations, setAnnotations,
        handleCreateAnnotation,
        handleUpdateAnnotation,
        pages, pageID,
        setEditingMeasurement,
        drawing,
        currentMeasurement, setCurrentMeasurement,
        selectedMeasurements, setSelectedMeasurements,
        currentAnnotation, setCurrentAnnotation,
        selectedAnnotations, setSelectedAnnotations,
    } = useContext(TakeoffContext);

    const isSelected = (currentAnnotation === annotation.id && !drawing && !currentMeasurement);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'Escape') {
                setCurrentAnnotation(null);
            }
        }

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        }
    }, []);

    return (
        <>
            <Portal
                selector={'.top-layer'}
                enabled={isSelected}
            >
                <Arrow
                    onClick={(e) => {
                        if (e.evt.metaKey) {
                            if (selectedAnnotations.includes(annotation.id)) {
                                setSelectedAnnotations(selectedAnnotations.filter((id) => id !== annotation.id));
                            } else {
                                setSelectedAnnotations([...selectedAnnotations, annotation.id]);
                            }
                        } else {
                            setSelectedAnnotations([annotation.id]);
                            setCurrentAnnotation(annotation.id)
                        }

                        setCurrentMeasurement(null);
                        setSelectedMeasurements([]);
                    }}

                    x={annotation.arrow.x1}
                    y={annotation.arrow.y1}
                    points={[0, 0, annotation.arrow.x2, annotation.arrow.y2]}
                    fill={annotation.fill_color}
                    stroke={annotation.stroke_color}
                    strokeWidth={annotation.size}
                    opacity={isSelected || selectedAnnotations.includes(annotation.id) ? 0.7 : 0.5}

                    draggable={isSelected && !drawing}
                    onDragMove={(e) => {
                        setAnnotations({
                            ...annotations,
                            [annotation.id]: {
                                ...annotation,
                                arrow: {
                                    ...annotation.arrow,
                                    x1: e.target.x(),
                                    y1: e.target.y(),
                                }
                            }
                        })
                    }}
                    onDragEnd={(e) => {
                        handleUpdateAnnotation(annotations[annotation.id])
                    }}
                    strokeScaleEnabled={false}
                    perfectDrawEnabled={false}
                />

                {isSelected &&
                    <Circle
                        x={annotation.arrow.x1}
                        y={annotation.arrow.y1}
                        radius={10 / pages[pageID].zoom}
                        fill={annotation.fill_color}
                        stroke={annotation.stroke_color}
                        strokeWidth={1}
                        opacity={isSelected ? 0.7 : 0.5}
                        draggable={isSelected && !drawing}
                        onDragMove={(e) => {
                            setAnnotations({
                                ...annotations,
                                [annotation.id]: {
                                    ...annotation,
                                    arrow: {
                                        ...annotation.arrow,
                                        x1: e.target.x(),
                                        y1: e.target.y(),
                                    }
                                }
                            })
                        }}
                        onDragEnd={(e) => {
                            handleUpdateAnnotation(annotations[annotation.id])
                        }}
                    />
                }

                {isSelected &&
                    <Circle
                        x={annotation.arrow.x2 + annotation.arrow.x1}
                        y={annotation.arrow.y2 + annotation.arrow.y1}
                        radius={10 / pages[pageID].zoom}
                        fill={annotation.fill_color}
                        stroke={annotation.stroke_color}
                        strokeWidth={1}
                        opacity={isSelected ? 0.7 : 0.5}
                        draggable={isSelected && !drawing}
                        onDragMove={(e) => {
                            setAnnotations({
                                ...annotations,
                                [annotation.id]: {
                                    ...annotation,
                                    arrow: {
                                        ...annotation.arrow,
                                        x2: e.target.x() - annotation.arrow.x1,
                                        y2: e.target.y() - annotation.arrow.y1,
                                    }
                                }
                            })
                        }}
                        onDragEnd={(e) => {
                            handleUpdateAnnotation(annotations[annotation.id])
                        }}
                    />
                }
            </Portal>
        </>
    )
}