import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

import { API_ROUTE, WEBSOCKET_ROUTE } from "..";
import { selectAuth } from "../redux/slices/authSlice";

import './styles/ComparePages.css';
import Plans from "./components/Plans";
import Navbar from "./components/Navbar";
import Skeleton from "react-loading-skeleton";
import { Blocks } from "react-loader-spinner";
import useWebSocket from "react-use-websocket";
import { ToastContainer, toast } from "react-toastify";

export default function ComparePages() {
    const params = useParams();
    const projectUUID = params.projectUUID;

    const auth = useSelector(selectAuth)

    const [project, setProject] = useState();
    const [files, setFiles] = useState();

    const [loading, setLoading] = useState(false);

    const [compareProgress, setCompareProgress] = useState({});

    const [page1, setPage1] = useState();
    const [page2, setPage2] = useState();

    useEffect(() => {
        axios({
            method: "GET",
            url: `${API_ROUTE}/api/compare-pages/`,
            params: {
                projectUUID: projectUUID
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                console.log(res);

                setFiles(res.data.files);
                setProject(res.data.project);
                setCompareProgress({
                    'progress': 0,
                    'show': false,
                    'running': false,
                    'id': null,
                    'message': '',
                })
            })
            .catch((err) => {
                console.log(err);
            })
    }, [projectUUID]);

    const { sendMessage, lastMessage, readyState } = useWebSocket(
        `${WEBSOCKET_ROUTE}/compare-pages-consumer/${projectUUID}/`,
        {
            heartbeat: {
                message: 'ping',
                returnMessage: 'pong',
                timeout: 60000, // 1 minute, if no response is received, the connection will be closed
                interval: 25000, // every 25 seconds, a ping message will be sent
            },
            onMessage: (e) => {
                const data = JSON.parse(e.data);
                console.log(data);

                if (data.type === "compare_page_update") {
                    if (String(data.progress) === "-1") {
                        let temp = {
                            'page1': page1,
                            'page2': page2
                        }

                        axios({
                            method: 'PUT',
                            url: `${API_ROUTE}/api/compare-pages/`,
                            data: temp,
                            headers: {
                                'Authorization': `Token ${auth.token}`,
                                "Content-Type": "application/json"
                            },
                        })
                            .then((response) => {
                                console.log(response);

                                if (toast.isActive(compareProgress.id)) {
                                    toast.update(compareProgress.id, { progress: 1 });
                                    toast.done(compareProgress.id);
                                } else {
                                    toast.success(`Comparing pages done`, {
                                        position: "top-right",
                                        autoClose: 3000,
                                        hideProgressBar: true,
                                        closeOnClick: true,
                                        pauseOnHover: false,
                                        draggable: false,
                                        progress: null,
                                        theme: "light",
                                    });
                                }

                                setCompareProgress({
                                    'progress': 0 / 100,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                    'message': '',
                                });

                                setPage1(response.data.page1);
                                setPage2(response.data.page2);
                                setLoading(false);
                            })
                            .catch((error) => {
                                console.log(error);

                                toast.update(compareProgress.id, { progress: 1 });
                                toast.error(`Error comparing pages`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: true,
                                    closeOnClick: true,
                                    pauseOnHover: false,
                                    draggable: false,
                                    progress: null,
                                    theme: "light",
                                });

                                setCompareProgress({
                                    'progress': 0 / 100,
                                    'show': false,
                                    'running': false,
                                    'id': null,
                                    'message': '',
                                });
                                setLoading(false);
                            });
                    } else {
                        if (!compareProgress?.id) {
                            let newID = uuidv4();
                            let message = 'Comparing pages';

                            toast.info(message, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: 1 / 100,
                                theme: "light",
                                toastId: newID,
                            });

                            setCompareProgress({
                                'progress': 1 / 100,
                                'show': true,
                                'running': true,
                                'id': newID,
                                'message': message,
                            });
                        } else if (compareProgress?.show && compareProgress?.running) {
                            let progress = Number(data.progress) / 100;

                            toast.update(compareProgress.id, { progress });

                            setCompareProgress(prev => ({
                                ...prev,
                                'progress': progress,
                            }));
                        }
                    }
                }
            },
            onClose: (e) => {
                console.log(e);
            },
            shouldReconnect: (closeEvent) => true,
            onOpen: (e) => {
                console.log(e);
            },
        },
    );

    const handleCompare = (page1, page2) => {
        setLoading(true);

        setPage1(page1);
        setPage2(page2);

        setCompareProgress({
            'progress': 0,
            'show': true,
            'running': true,
            'id': null,
            'message': '',
        })

        axios({
            method: "POST",
            url: `${API_ROUTE}/api/compare-pages/`,
            data: {
                'page1': page1,
                'page2': page2
            },
            headers: {
                'Authorization': `Token ${auth.token}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                console.log(res);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    if (!project) {
        return (
            <>
                <div className="takeoff-loading-navbar">
                    <div>
                        <a href='/dashboard'>
                            <img
                                src='https://bobyard-public-images.s3.us-west-2.amazonaws.com/bobyard+(2).png'
                                alt='logo'
                                className='takeoffnavbar-logo'
                            />
                        </a>
                    </div>
                    <div><Skeleton width={200} /></div>
                    <div><Skeleton width={350} /></div>
                    <div><Skeleton width={100} /></div>
                    <div><Skeleton width={200} /></div>
                </div>

                <div className="takeoff-loading">
                    <div className="takeoff-loading-canvas">
                        <Blocks
                            visible={true}
                            height="60"
                            width="60"
                            color="#006AFE"
                            ariaLabel="blocks-loading"
                            radius="10"
                            wrapperStyle={{}}
                            wrapperClass="blocks-wrapper"
                        />
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            <Navbar
                projectUUID={projectUUID}
                project={project}
                setProject={setProject}
                page1={page1}
                page2={page2}
                files={files}
                setPage1={setPage1}
                setPage2={setPage2}
                handleCompare={handleCompare}
                loading={loading}
            />

            <ToastContainer
                className={'takeoff-toastcontainer'}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                closeButton={CloseButton}
                onClose={(e) => console.log('closed', e)}
            />

            <div className="comparepages-container">
                {page1 && page2
                    ? <Plans page1={page1} page2={page2} />
                    : <div className="comparepages-empty-message-container">
                        <div className="comparepages-empty-message-title">Compare Pages</div>
                        <div className="comparepages-empty-message-body">
                            <div className="comparepages-empty-message-body-step">
                                <div className="comparepages-empty-message-body-step-number">1</div>
                                <div className="comparepages-empty-message-body-step-text">Select a page in the red dropdown in the navbar.</div>
                            </div>
                            <div className="comparepages-empty-message-body-step">
                                <div className="comparepages-empty-message-body-step-number">2</div>
                                <div className="comparepages-empty-message-body-step-text">Select a page in the blue dropdown in the navbar.</div>
                            </div>
                            <div className="comparepages-empty-message-body-step">
                                <div className="comparepages-empty-message-body-step-number">3</div>
                                <div className="comparepages-empty-message-body-step-text">Click the solid blue compare button in the navbar.</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

function CloseButton({ closeToast }) {
    const handleClick = (e) => {
        console.log(e);

        closeToast(e);
    }

    return (
        <i
            className="takeoff-toast-close-icon"
            onClick={handleClick}
        >
            &#x2715;
        </i>
    )
}
