//adapted code from ray-casting algorithm, point is a diction with x and y, polygon is an arry of points
export function PointInsidePolygon(polygon, point){
    let x = point.x, y = point.y;
    let vs = polygon;
    let inside = false;

    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        let xi = vs[i].x, yi = vs[i].y;
        let xj = vs[j].x, yj = vs[j].y;
        
        let intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
}