export default function NotFoundPage() {
    return (
        <div className="error-boundary-container">
            <div className="error-boundary-header">
                <div className="error-boundary-title">
                    404 Page not found!
                </div>

                <div className="error-boundary-header-message">
                    Try going back to the <a className='error-boundary-link' href="/dashboard">dashboard</a>.
                </div>
            </div>

            <div className="error-boundary-content">
                <div className="error-boundary-message">
                    If you think this is a mistake, please contact your dedicated support engineer.
                </div>
            </div>
        </div>
    )
}