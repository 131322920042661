import { useContext, useEffect } from "react";
import { TakeoffContext } from "../../helper/Context";
import { Modal } from "react-bootstrap";
import DeleteButton from "../../../components/DeleteButton";
import DefaultButton from "../../../components/DefaultButton";

import '../../styles/DeleteMeasurementModal.css';

export default function DeleteAnnotationModal({ }) {
    const {
        annotations,
        currentAnnotation,
        selectedAnnotations,
        handleDeleteAnnotation,
        handleDeleteAnnotations,
        showDeleteAnnotationModal, setShowDeleteAnnotationModal
    } = useContext(TakeoffContext);

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setShowDeleteAnnotationModal(false);
            }
        }

        window.addEventListener('keydown', handleEscape);

        return () => {
            window.removeEventListener('keydown', handleEscape);
        }
    }, [setShowDeleteAnnotationModal]);

    return (
        <Modal
            show={showDeleteAnnotationModal}
            onHide={() => setShowDeleteAnnotationModal(false)}
            size='small'
        >
            <Modal.Header>
                <Modal.Title>
                    <div className="deletemeasurementmodal-title">
                        Delete Annotation
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    {(new Set(selectedAnnotations)).size > 1
                        ? `You have selected ${new Set(selectedAnnotations).size} annotations!`
                        : "You have selected an annotation!"}
                </div>

                <div>
                    {new Set(selectedAnnotations).size > 1
                        ? "Are you sure you want to delete them all?"
                        : "Are you sure you want to delete it?"}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <DeleteButton
                    handleClick={() => {
                        if (new Set(selectedAnnotations).size > 1) {
                            handleDeleteAnnotations();
                        } else {
                            handleDeleteAnnotation(currentAnnotation);
                        }
                        setShowDeleteAnnotationModal(false);
                    }}
                >
                    Delete {new Set(selectedAnnotations).size > 1 ? "All" : ""}
                </DeleteButton>
                <DefaultButton
                    handleClick={() => setShowDeleteAnnotationModal(false)}
                >
                    Cancel
                </DefaultButton>
            </Modal.Footer>
        </Modal>
    )
}