import React, { useState } from 'react';

import './styles/DefaultButton.css';

export default function DefaultButton({ text, handleClick, size, children, disabled, active, className }) {
  return (
    <button
      className={`default-button default-button-${size} ${active ? 'default-button-active' : ''} ${disabled ? 'default-button-disabled' : ''} ${className}`}
      onClick={handleClick}
      disabled={disabled}
    >
      {text}
      {children}
    </button>
  );
}
